import { useStaticQuery, graphql } from "gatsby"

import { FestivalDto } from "@common/dto"

export function useCurrentFestival() {
  const data = useStaticQuery(graphql`
    query CurrentFestivalQuery {
      wpAaiffSiteSettings {
        websiteSettings {
          ...CurrentFestival
        }
      }
      site {
        siteMetadata {
          staging
        }
      }
      stagingFestival: wpFestival(slug: { eq: "staging" }) {
        ...FestivalInfo
      }
      allWpFestival {
        nodes {
          ...FestivalInfo
        }
      }
    }
  `)

  const {
    site: {
      siteMetadata: { staging: isStaging },
    },
    wpAaiffSiteSettings,
    stagingFestival,
    allWpFestival,
  } = data

  const currentFestivalYear =
    wpAaiffSiteSettings.websiteSettings?.currentFestival
      ?.festivalGeneralInformation?.festivalYear?.slug

  const festivalsByYear = allWpFestival.nodes.reduce((acc, festivalNode) => {
    const {
      festivalGeneralInformation: {
        festivalYear: { slug },
      },
    } = festivalNode

    if (slug) {
      acc[slug] = festivalNode
      return acc
    }
  }, {})

  if (process.env.GATSBY_CURRENT_FESTIVAL_YEAR) {
    console.debug(
      "useCurrentFestival returning env override year",
      process.env.GATSBY_CURRENT_FESTIVAL_YEAR
    )

    return FestivalDto.extractFromCurrentFestivalFragment(
      festivalsByYear[process.env.GATSBY_CURRENT_FESTIVAL_YEAR]
    )
  } else if (isStaging && stagingFestival) {
    console.debug("useCurrentFestival returning staging year")

    return FestivalDto.extractFromCurrentFestivalFragment(stagingFestival)
  } else {
    return FestivalDto.extractFromCurrentFestivalFragment(
      festivalsByYear[currentFestivalYear]
    )
  }
}
