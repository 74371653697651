import React from "react"
import PropTypes from "prop-types"

export function DrawerSection({ label, hidden, children }) {
  if (hidden) {
    return null
  }

  return (
    <div className="filtersDrawer__section">
      <h5 className="filtersDrawer__title">{label}</h5>
      {children}
    </div>
  )
}

DrawerSection.propTypes = {
  label: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
}
