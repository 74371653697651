import { EventTypeSlugs } from "../constants"
import {
  FilmEntry,
  EventEntry,
  FeatureScreeningEntry,
  ShortsProgramEntry,
  PanelEntry,
} from "../wp-data"
import { flattenEntryFieldGroups } from "../../util/entry_utils"

/**
 * A Utility class used to help instantiate a Wordpress Entry node into the proper
 * Entry class.
 */
export const WpDataFactory = {
  /**
   * Attempts to instantiate a node from Wordpress into an Entry class. This works by
   * extracting the slug, id, and acf fields from the object. If the object does
   * not have a field for acf, it will try to see if the expected attributes are
   * on the root level.
   *
   * @param {Object} wpNode An object that might be able to become an instance
   * of an entry class
   * @return {Entry} The newly instantiated Entry object or undefined
   * if the object could not be instantiated as an Entry
   */
  instantiateWpEntry: function (wpNode) {
    const { slug, id, ...data } = flattenEntryFieldGroups(wpNode)

    try {
      if (!data.hasOwnProperty("eventType")) {
        return new FilmEntry(id, slug, data)
      } else {
        switch (data.eventType.slug) {
          case EventTypeSlugs.FEATURE:
          case EventTypeSlugs.TENTPOLES:
            return new FeatureScreeningEntry(id, slug, data)
          case EventTypeSlugs.SHORTS_PROGRAM:
            return new ShortsProgramEntry(id, slug, data)
          case EventTypeSlugs.PANEL_WORKSHOP:
          case EventTypeSlugs.Q_A:
          case EventTypeSlugs.MASTERCLASS:
            return new PanelEntry(id, slug, data)
          case EventTypeSlugs.SPECIAL_PRESENTATION:
            if (data.mainFilm) {
              return new FeatureScreeningEntry(id, slug, data)
            } else if (data.shortFilms && data.shortFilms.length > 0) {
              return new ShortsProgramEntry(id, slug, data)
            }
          // continue through to default case if the Special Presentation does not
          // have any films
          default:
            if (data.panelists) {
              return new PanelEntry(id, slug, data)
            }

            return new EventEntry(id, slug, data)
        }
      }
    } catch (err) {
      console.error(`Could not instantiate node ${slug}`, err)
      return null
    }
  },
}
