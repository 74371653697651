import React from "react"

import { BannerImage } from "../../banner_image"
import InnerHTML from "../../inner_html/inner_html"

import { MediaDto } from "@common/dto"

export function WipFestivalPage(props) {
  const {
    settings: { description, bannerImage },
    title,
  } = props

  const wipBanner = MediaDto.fromWpMediaItem(bannerImage)

  return (
    <>
      <header className="festivalPage">
        <h1>{title}</h1>

        {wipBanner && (
          <BannerImage
            media={{ desktop: wipBanner }}
            alt={title}
            objectFit="contain"
          />
        )}
      </header>

      {description && <InnerHTML htmlContent={description} isFestivalPage />}
    </>
  )
}
