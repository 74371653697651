import React from "react"
import PropTypes from "prop-types"
import { HeaderNavDesktop } from "./header_nav_desktop"
import { HeaderNavMobile } from "./header_nav_mobile"

export function Header(props) {
  return (
    <header className="header" id="aaiff-header-id">
      <HeaderNavDesktop {...props} />
      <HeaderNavMobile {...props} />
    </header>
  )
}

Header.propTypes = {
  navbarOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
}
