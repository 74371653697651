import { useEffect, useCallback } from "react"
import { useMediaQuery } from "@mui/material"

import { TicketingItemManager } from "../../util/TicketingItemManager"

export const useTicketingState = function (festivalPageTicketingV3) {
  const {
    passesList = [],
    packagesList = [],
    passesFeatureList = [],
    individualTicketsList = [],
  } = festivalPageTicketingV3

  // const [activeDiscounts, setActiveDiscounts] = useState([])
  const isDesktop = useMediaQuery("(min-width: 800px)")

  const ticketingManager = new TicketingItemManager()

  /**
   * This useEffect checks if any of the discounts are currently active based on the
   * current date. Since the discount timers are set based on New York's timezone, all
   * time comparisons are done in that timezone.
   *
   * This has to be done in a useEffect or otherwise, the day that the website is
   * built will be used instead of any user's current day
   */
  useEffect(() => {
    ticketingManager.refresh()
  }, [])

  /**
   * A helper function that generates an object containing all of the TicketingItems
   * that will be displayed on the ticketing page. All Access items will always be
   * returned as part of this object
   *
   * This function does the following:
   * - Formats the pass and packages information from WP as TicketingItem instances
   * - Applies any active discount to the affected pass and package items
   * - Consolidates the created TicketingItems and relevant information by ticketing
   *     item type (all access, in person, online)
   *
   * The item type data to be returned is affected by the selected type
   *
   * @returns {Object} an object containing all of the TicketingItems that will be
   * displayed on the ticketing page organized by item type. All Access items
   * will always be returned as part of this object
   */

  const getTicketingItems = useCallback(
    function () {
      // Format the pass data from WP as TicketingItem instances

      const ticketingByGroup = {
        festivalPasses: {
          passes: [],
          totalItems: 0,
        },
        ticketsAndPackages: {
          inPersonPasses: [],
          onDemandPasses: [],
          totalItems: 0,
        },
        individualTickets: {
          inPersonPasses: [],
          onDemandPasses: [],
          totalItems: 0,
        },
      }

      passesList.forEach(pass => {
        const newPassItem = ticketingManager.createTicketingItem(pass)

        // Add the common pass features to each passItem
        passesFeatureList.forEach(feature => {
          ticketingManager.addFeature(feature, newPassItem)
        })

        //add passes to festivalPasses
        ticketingManager.addTicketToGroup(
          ticketingByGroup,
          "festivalPasses",
          newPassItem
        )
      })

      // On mobile (the cards are in a column), the goldPass item should always be the first
      if (!isDesktop) {
        ticketingByGroup["festivalPasses"].passes.sort((passA, passB) => {
          if (passA.uiKey === passB.uiKey) {
            return 0
          } else if (passB.uiKey === "goldPass") {
            return 1
          } else if (passA.uiKey === "goldPass") {
            return -1
          } else {
            return 0
          }
        })
      }
      // Format the packages data from WP into TicketingItem instances
      packagesList.forEach(pack => {
        const newPackItem = ticketingManager.createTicketingItem(pack)
        ticketingManager.addTicketToGroup(
          ticketingByGroup,
          "ticketsAndPackages",
          newPackItem
        )
      })

      //Format Individual tickets
      individualTicketsList.forEach(ticket => {
        const newTicketItem = ticketingManager.createTicketingItem(ticket)
        ticketingManager.addTicketToGroup(
          ticketingByGroup,
          "individualTickets",
          newTicketItem
        )
      })

      return ticketingByGroup
    },
    [isDesktop]
  )

  return {
    ticketingItems: getTicketingItems(),
  }
}
