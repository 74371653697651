/**
 * Defines styles for icons used in SocialLinkItem component.
 * Allows for different breakpoints and possible future different icon sizes.
 */

export const MEDIUM_ICON_STYLES = {
  DESKTOP_SOCIAL : {
    WIDTH: "35px",
    HEIGHT: "36px",
    BACKGROUND_COLOR: "transparent",
    FILL: "black",
  },
  DESKTOP_GLOBE : {
    WIDTH: "31.5px",
    HEIGHT: "31.5px",
    BACKGROUND_COLOR: "transparent",
    FILL: "black",
  },
  MOBILE : {
    WIDTH: "28px",
    HEIGHT: "28px",
    BACKGROUND_COLOR: "transparent",
    FILL: "black",
  },
}