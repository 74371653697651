import {
  faqAnchorLinks,
  LinkSectionSlugs,
} from "../../common/constants/footer.constants"

export function useFooterLinks(navbarOptions, faqType) {
  const footerLinkProps = {}
  const { slug: faqSlug, name: faqSectionName } = faqType

  // Extract the anchors links for the FAQ page and create link options for them
  footerLinkProps[LinkSectionSlugs.FAQ] = {
    title: faqSectionName,
    links: faqAnchorLinks.map(({ label, anchor }) => ({
      label,
      path: `${faqSlug}#${anchor}`,
      isExternal: false,
    })),
  }

  // Extract the dropdown options from the Festival Information and the Support
  // sections and create link options for each section.
  for (let idx = 0; idx < navbarOptions.length; idx++) {
    const currOption = navbarOptions[idx]

    if (
      currOption.slug === LinkSectionSlugs.FESTIVAL_INFO ||
      currOption.slug === LinkSectionSlugs.SUPPORT
    ) {
      const linkOptions = currOption.dropdownOptions.map(
        ({ name, slug, isExternal }) => ({
          label: name,
          path: slug,
          isExternal,
        })
      )

      footerLinkProps[currOption.slug] = {
        title: currOption.labelName,
        links: linkOptions,
      }
    }
  }

  return footerLinkProps
}
