import React from "react"
import styled from "@emotion/styled"

import { ButtonLink, Link } from "@components/@aaiff"
import { EntryTicketCard, EntryTicketCardVariants } from "./entry_ticket_card"
import { EntryTicketToggle } from "./entry_ticket_toggle"

import {
  useEntryTicketSectionState,
  TicketSectionState,
} from "./entry_ticket_section.state"

export function TicketSection(props) {
  const {
    visible,
    elevent,
    external,
    ticketState,
    showUpsell,
    upsellProps,
    onToggleChange,
    toggleOptions,
    selectedScreeningId,
  } = useEntryTicketSectionState(props)

  //If there is an external link url, use that over the elevent widget
  if (external) {
    return (
      <TicketSectionRoot className="event__tickets" isVisible={visible}>
        <ButtonLink
          href={external.url}
          variant="primary1"
          className="event__ticketsCta"
        >
          {external.label}
        </ButtonLink>
      </TicketSectionRoot>
    )
  }

  return (
    <TicketSectionRoot className="event__tickets" isVisible={visible}>
      <TicketSectionHeader className="event__title">
        Tickets
      </TicketSectionHeader>

      <EntryTicketToggle
        onToggleChange={onToggleChange}
        selectedKey={selectedScreeningId}
        toggleOptions={toggleOptions}
      />

      <TicketItemList>
        {ticketState === TicketSectionState.ON_SALE && elevent ? (
          <>
            <div>
              <EntryTicketCard
                variant={EntryTicketCardVariants.NORMAL}
                eleventInfo={{
                  type: "ticket",
                  eleventId: elevent.showtimeId,
                  urlName: elevent.urlName,
                }}
              />

              <LearnMoreMsg>
                <Link to="/ticketing">Learn more</Link> about all ticket options
              </LearnMoreMsg>
            </div>

            {showUpsell ? (
              <EntryTicketCard
                variant={EntryTicketCardVariants.UPSELL}
                {...upsellProps}
              />
            ) : null}
          </>
        ) : ticketState === TicketSectionState.OFF_SALE ? (
          <EntryTicketCard
            title="Event Has Passed"
            subtitle="Tickets for this event are no longer available."
            variant={EntryTicketCardVariants.NOT_AVAILABLE}
          />
        ) : null}
      </TicketItemList>
    </TicketSectionRoot>
  )
}

const TicketItemList = styled.ol`
  list-style: none;

  display: flex;
  flex-direction: column;
  row-gap: 18px;

  min-height: 80px;
`

const TicketSectionRoot = styled.section`
  display: ${props => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  row-gap: 18px;
`

const TicketSectionHeader = styled.h2`
  margin: 0;
  line-height: 1;
`

const LearnMoreMsg = styled.span`
  font-size: 1em;
`
