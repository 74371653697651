import { EventEntry } from "./EventEntry"
import { WpEntryTypes } from "../constants"
import { MediaDto } from "../dto"

/**
 * A class that represents an Event that features guest
 * speakers or panelists. Mainly used for Panels and
 * Workshop type events
 *
 * @class
 */
export class PanelEntry extends EventEntry {
  constructor(id, slug, data) {
    super(id, slug, data)

    this.type = WpEntryTypes.PANEL
  }

  /**
   * Gets the Section title for the Panelists section.
   */
  get panelistSectionTitle() {
    return this.__data.panelistSectionTitle || undefined
  }

  get panelists() {
    return (this.__data.panelists || []).map(panelist => {
      if (panelist?.image) {
        panelist.image = new MediaDto(
          panelist.image.sourceUrl,
          panelist.image.altText,
          panelist.image?.localFile?.childImageSharp
        )
      }

      return panelist
    })
  }
}
