import React from "react"
import styled from "@emotion/styled"
import InnerHTML from "../../../inner_html/inner_html"

/**
 * Pseudo-wrapper component that renders an InnerHTML component. Does not actually
 * nest InnerHTML within any new elements, instead wrapper applies a `className`
 * generated by `@emotion/styled` library to style an entry's description.
 *
 * @param {string} htmlContent - prop forwarded to InnerHTML component
 * @returns {JSX.Element}
 */
export const EntryDescription = ({ htmlContent }) => {
  return <EDDiv htmlContent={htmlContent} />
}

const EDDiv = styled(InnerHTML)`
  margin-top: 2.5em;
  margin-bottom: 2.5em;
`
