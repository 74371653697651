import React, { useEffect } from "react"
import styled from "@emotion/styled"

import {
  EleventPackageButton,
  EleventPassButton,
  EleventTicket,
} from "../../../elevent"
import { ButtonVariants } from "../../../@aaiff/buttons/Button.constants"
import { Button } from "../../../@aaiff/buttons"

export const EntryTicketCardVariants = {
  NORMAL: "NORMAL",
  UPSELL: "UPSELL",
  NOT_AVAILABLE: "NOT_AVAILABLE",
}

export function EntryTicketCard(props) {
  const {
    variant = EntryTicketCard.NORMAL,
    eleventInfo: { type, eleventId, allocationId, urlName } = {},
    title,
    subtitle,
    itemName,
    itemPrice,
    isAvailable,
    unavailableButtonLabel,
  } = props

  return (
    <EntryTicketRoot variant={variant} hasTitle={!!title}>
      <TicketSection>
        {title && <TicketTitle>{title}</TicketTitle>}
        {subtitle && <TicketSubtitle>{subtitle}</TicketSubtitle>}
      </TicketSection>

      <EleventTicketingSection
        variant={variant}
        isHidden={
          variant == EntryTicketCardVariants.NOT_AVAILABLE || type !== "ticket"
        }
      >
        <EleventTicket urlName={urlName} showtimeId={eleventId} />
      </EleventTicketingSection>

      <TicketSection
        isHidden={
          variant == EntryTicketCardVariants.NOT_AVAILABLE || type === "ticket"
        }
      >
        <TicketItemDetails>
          <TicketPriceLabel>{itemName}</TicketPriceLabel>
          <TicketPrice>${itemPrice}</TicketPrice>
        </TicketItemDetails>
      </TicketSection>

      <TicketSection
        isHidden={
          variant == EntryTicketCardVariants.NOT_AVAILABLE || type === "ticket"
        }
      >
        {!isAvailable && (
          <StyledUnavailableButton variant={ButtonVariants.BLACK} isDisabled>
            {unavailableButtonLabel || "Unavailable"}
          </StyledUnavailableButton>
        )}

        {isAvailable && type === "pass" && (
          <StyledPassButton passId={eleventId} allocationId={allocationId}>
            Add to Cart
          </StyledPassButton>
        )}
        {isAvailable && type === "package" && (
          <StyledPackButton packageId={eleventId} allocationId={allocationId}>
            Add to Cart
          </StyledPackButton>
        )}
      </TicketSection>
    </EntryTicketRoot>
  )
}

const EntryTicketRoot = styled.li`
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: ${props => (props.hasTitle ? "17px" : "12px")};

  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.variant === EntryTicketCardVariants.NORMAL ? "#F2F2F2" : "#D77E19"};

  background-color: ${props =>
    props.variant === EntryTicketCardVariants.NORMAL ? "#F2F2F2" : "white"};

  box-sizing: border-box;
  font-size: 0.875em;
`

const TicketTitle = styled.h6`
  font-size: 14px;
  line-height: 17.5px;

  margin-bottom: 4px;
`

const TicketSubtitle = styled.span`
  display: block;
  font-size: 14px;

  line-height: 1.2;
`

const TicketPriceLabel = styled.label`
  font-size: 14px;
  font-weight: 700;

  line-height: 17.5px;
`

const TicketPrice = styled.strong`
  font-size: 21px;
  font-weight: 700;

  line-height: 20px;
`

const TicketItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`

// TODO: refactor this to reuse the button styles objects in `Button.styled.jsx`
const sharedButtonStyles = variant => {
  switch (variant) {
    case ButtonVariants.BLACK:
      return `
        width: 100%;

        background: #0a0a0a;
        color: #fcfcfc; 

        &:hover {
          background: #666666; 
        }

        &:active,
        &:focus {
          box-shadow: none;
          background: #333333; 
        }
      `
    default:
      return `
        width: 100%;

        background-color: #D77E19;
        color: white;

        &:active {
          background-color: #D77E19;
          color: white;
        }

        &:hover, &:focus {
          color: white;
          background-color: #D77E19;
          filter: brightness(1.1);
        }
      `
  }
}

const StyledPackButton = styled(EleventPackageButton)`
  .aaiff-Button {
    ${sharedButtonStyles(ButtonVariants.PRIMARY_1)}
  }
`
const StyledPassButton = styled(EleventPassButton)`
  .aaiff-Button {
    ${sharedButtonStyles(ButtonVariants.PRIMARY_1)}
  }
`

const StyledUnavailableButton = styled(Button)`
  width: 100%;
`

const TicketSection = styled.section`
  display: ${props => (props.isHidden ? "none" : "block")};
`

const EleventTicketingSection = styled.section`
  display: ${props => (props.isHidden ? "none" : "block")};

  .elevent-widget {
    min-height: auto; // override elevent's min-height style
  }

  .elevent-row.elevent-ticket-display .elevent-item-name {
    font-size: 14px;
    font-weight: 700;

    line-height: 17.5px;
  }

  .elevent-row.elevent-ticket-display .elevent-item-price {
    font-size: 21px;
    font-weight: 700;

    line-height: 20px;
  }

  button.btn.elevent-add-to-cart-btn {
    ${props =>
      sharedButtonStyles(
        props.variant === EntryTicketCardVariants.UPSELL
          ? ButtonVariants.PRIMARY_1
          : ButtonVariants.BLACK
      )}

    // These style make the elevent button look more like the aaiff buttons
    display: block;
    padding: 14px 16px;
    text-align: center;
    font-weight: bold;
    margin: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15)
  }

  // set the container of the button's width to be as wide as possible
  .elevent-col-6-sm:has(button.btn.elevent-add-to-cart-btn){
    width: 96%; // subtract elevent's 2% horizontal margins
  }

  // set any neighboring columns to 0 width
  .elevent-col-6-sm:has(button.btn.elevent-add-to-cart-btn) + .elevent-col-6-sm {
    width: 0
  }

  ${props =>
    props.variant === EntryTicketCardVariants.NORMAL &&
    ` // override the warning orange color when against the normal bg color
      .elevent-text-warning {
          color: black;
      }
    `}
  }
`
