export const WpEntryTypes = {
  FILM: "FILM",
  EVENT: "EVENT",
  FEATURE_SCREENING: "FEATURE_SCREENING",
  PANEL: "PANEL",
  SHORTS_PROGRAM: "SHORTS_PROGRAM",
}

export const EntryDetailTypes = {
  DATE: "date",
  RUNTIME: "runtime",
  SHORT_FILM_COUNT: "short-film-count",
  TYPE_GENRE: "type-genre",
  LANGUAGE: "language",
  COUNTRY: "country",
  YEAR: "year",
  CONTENT_WARNING: "content-warning",
  PREMIERE_STATUS: "premiere-status",
  REGION_RESTRICTIONS: "region-restrictions",
  VENUE: "venue",
  EVENT_DURATION: "event-duration",
}
