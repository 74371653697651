import React from "react"
import styled from "@emotion/styled"
import { MEDIUM_ICON_STYLES } from "./icon_sizes_styles"
import { SocialMediaPlatforms } from "../../common/constants";
import { Link } from "../@aaiff/links/Link";

import { 
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YouTubeIcon,
  GlobeIcon,
  TwitchIcon,
} from "../icons";

const components = {
  [SocialMediaPlatforms.FACEBOOK]: FacebookIcon,
  [SocialMediaPlatforms.INSTAGRAM]: InstagramIcon,
  [SocialMediaPlatforms.TWITTER]: TwitterIcon,
  [SocialMediaPlatforms.YOUTUBE]: YouTubeIcon,
  [SocialMediaPlatforms.WEBSITE]: GlobeIcon,
  [SocialMediaPlatforms.TWITCH]: TwitchIcon,
}

/**
 * SocialLinkItem component renders a clickable logo button for an external link
 * 
 * Smaller icons on mobile screens can be enabled by uncommenting styles tagged ENABLE_SMALLER_MOBILE_FONT
 * 
 * @param {string} platform - prop indicating which logo to display
 * @param {string} redirectUrl - prop indicating which link to redirect to
 * @returns {JSX.Element} :
 * - Uses Emotion styled DOM elements to override SCSS styles for simplicity.
 * - Displays selected icon from collection of React icon components containing <svg>,
 * sized and styled according to values centralized in `./icon_sizes_styles.js` 
 * - Icon components must accept and use `className` prop for styling to work.
 * 
 * If no icon available, renders null.
 * 
 */
export default function SocialLinkItem({ platform, redirectUrl }) {
  const BrandIcon = components[platform]

  if(!BrandIcon) {
    return null;
  }

  return (
    <SLLi key={platform}>
      <SLLink as={Link} href={redirectUrl}>
        <SocialButton BrandIcon={BrandIcon} platform={platform} />
      </SLLink>
    </SLLi>
  )
}

const SocialButton = ({ BrandIcon, platform }) => {
  return (
    <StyledIcon as={BrandIcon} platform={platform} style={MEDIUM_ICON_STYLES} />
  )
}

const SLLi = styled.li`
  line-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SLLink = styled.a`
  // override additional line-height introduced by .aaiff-Link to match Figma
  line-height: inherit !important; 
`

const StyledIcon = styled.div`
  width: ${props => props.platform === SocialMediaPlatforms.WEBSITE ? props.style.DESKTOP_GLOBE.WIDTH : props.style.DESKTOP_SOCIAL.WIDTH};
  height: ${props => props.platform === SocialMediaPlatforms.WEBSITE ? props.style.DESKTOP_GLOBE.HEIGHT : props.style.DESKTOP_SOCIAL.HEIGHT};
  fill: ${props => props.style.DESKTOP_SOCIAL.FILL};
  background-color: ${props => props.style.DESKTOP_SOCIAL.BACKGROUND_COLOR};
  
  @media only screen and (max-width: 900px) {
    // width: ${props => props.style.MOBILE.WIDTH}; // enable by uncommenting ENABLE_SMALLER_MOBILE_FONT
    // height: ${props => props.style.MOBILE.HEIGHT}; // enable by uncommenting ENABLE_SMALLER_MOBILE_FONT
    // fill: ${props => props.style.MOBILE.FILL}; // enable by uncommenting ENABLE_SMALLER_MOBILE_FONT
    // background-color: ${props => props.style.MOBILE.BACKGROUND_COLOR}; // enable by uncommenting ENABLE_SMALLER_MOBILE_FONT
	}

`
