import React from "react"
import PropTypes from "prop-types"

import { CategoriesRow } from "./categories_row"
import { NoContent } from "./no_content"
import { categoryRowOrder } from "../../../../common/constants"
import { useEntryCategories } from "../../../../hooks/components/useEntryCategories"

export function CategoriesList(props) {
  const { eventEntries, filmEntries, setActiveFilters, updateBadges } = props
  const { isLoaded, getCategoryRowProps } = useEntryCategories(
    eventEntries,
    setActiveFilters,
    filmEntries,
  )

  const rowItems = []
  categoryRowOrder.forEach(row => {
    var rowProps = getCategoryRowProps(row)
    if (rowProps.entries?.length > 0) {
      rowItems.push(
        <CategoriesRow
          key={rowProps.label}
          {...rowProps}
          isLoading={!isLoaded}
          updateBadges={updateBadges}
        />
      )
    }
  })

  if (rowItems.length === 0) {
    return <NoContent />
  }

  return <ul className="categoryRows">{rowItems}</ul>
}

CategoriesList.propTypes = {
  getCategoryRowProps: PropTypes.func.isRequired,
}
