import React, { useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  DefaultHorizontalThumbnail,
  DefaultVerticalThumbnail,
} from "@components/default_images"

export const DefaultImageTypes = {
  NONE: "NONE",
  VERTICAL: "VERTICAL",
  HORIZONTAL: "HORIZONTAL",
  LONG: "LONG",
}

/**
 * This component is used to render a gatsby image. If a gatsby image
 * query result isn't available, it will try to return an img tag using
 * the imageSrc. If neither of those options are available, it will render
 * the selected default based off the defaultImageType
 * @param {Props} props The props consisting of:
 * - media:             The results of a gatsbyImage query as a MediaDto instance
 * - alt:               The alt text
 * - className:         The css className for the image
 * - objectFit:         The objectFit property name for the gatsby-image polyfill
 * - defaultImageType:  The type of default image to render when there isn't an image
 */
export default function ImageWrapper({
  alt,
  className,
  objectFit,
  defaultImageType = DefaultImageTypes.HORIZONTAL,
  loading,
  media,
  ...rest
}) {
  /**
   * This is only required for gatsby image data. Rendering this during SSR
   * may cause a build error so we only display it afterwards
   */
  const [isLoaded, setIsLoaded] = useState(false)
  // TODO: find a way to not need this state
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  if (media?.gatsbyImageData) {
    return isLoaded ? (
      <GatsbyImage
        image={media.gatsbyImageData}
        alt={alt || media.altText}
        loading={loading}
        objectFit={objectFit}
        className={className}
        {...rest}
      />
    ) : null
  }

  if (media?.sourceUrl) {
    return (
      <div className={className}>
        <img
          src={media.sourceUrl}
          alt={alt || media.altText}
          className={className}
          style={objectFit ? { objectFit } : { objectFit: "cover" }}
          {...rest}
        />
      </div>
    )
  }

  switch (defaultImageType) {
    case DefaultImageTypes.VERTICAL:
      return (
        <DefaultVerticalThumbnail
          className={className}
          alt={alt}
          objectFit={objectFit}
        />
      )
    case DefaultImageTypes.HORIZONTAL:
    case DefaultImageTypes.LONG:
      return (
        <DefaultHorizontalThumbnail
          className={className}
          alt={alt}
          objectFit={objectFit}
        />
      )
    case DefaultImageTypes.NONE:
    default:
      return null
  }
}
