export const PC_VARIANT_NAMES = {
  YOU_MAY_LIKE: "YOU_MAY_LIKE",
  SPOTLIGHT_DESKTOP: "SPOTLIGHT_DESKTOP",
  SPOTLIGHT_MOBILE: "SPOTLIGHT_MOBILE",
  EVENT_DESKTOP: "EVENT_DESKTOP",
  EVENT_MOBILE: "EVENT_MOBILE",
  SEARCH_DESKTOP: "SEARCH_DESKTOP",
  SEARCH_MOBILE: "SEARCH_MOBILE",
}

/**
 * FIXED: card heights, padding sizes - implemented with px
 * VARIABLE: font-sizes - implemented with rem
 */
export const PC_CARD_VARIANTS = {
  SPOTLIGHT_DESKTOP: {
    NAME: PC_VARIANT_NAMES.SPOTLIGHT_DESKTOP,
    HEIGHTS: {
      CARD: "490px",
      HEADER: "100%",
      OVERLAY: "100%",
      CHIN: "0px",
    },
    GRID: {
      PAD_CARD_HORIZ: "32px",
      PAD_CARD_VERT: "32px",
      PAD_DEMAND_TITLE: "8px",
      PAD_TITLE_INFO: "14px",
      MAIN_WIDTH: "auto",
      DEMAND_HEIGHT: "1fr",
      TITLE_HEIGHT: "85px",
      INFO_HEIGHT: "42px",
    },
    TEXT: {
      TITLE_FONT_SIZE: "1.25em",
      TITLE_ALIGN_SELF: "center",
      INFO_FONT_SIZE: "0.875em",
      ICON_FONT_SIZE: "1.0625em",
      ICON_MARG_RIGHT: "0.5em",
      INFO_ROW_GAP: "0px",
    },
    BG_OVERLAY: "linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))",
    DEMAND: {
      JUSTIFY_CONTENT: "start",
      ALIGN_SELF: "start",
    },
  },
  SPOTLIGHT_MOBILE: {
    NAME: PC_VARIANT_NAMES.SPOTLIGHT_MOBILE,
    HEIGHTS: {
      CARD: "410px",
      HEADER: "100%",
      OVERLAY: "100%",
      CHIN: "0px",
    },
    GRID: {
      PAD_CARD_HORIZ: "28px",
      PAD_CARD_VERT: "28px",
      PAD_DEMAND_TITLE: "12px",
      PAD_TITLE_INFO: "12px",
      MAIN_WIDTH: "auto",
      DEMAND_HEIGHT: "1fr",
      TITLE_HEIGHT: "66px",
      INFO_HEIGHT: "42px",
    },
    TEXT: {
      TITLE_FONT_SIZE: "1em",
      TITLE_ALIGN_SELF: "end",
      INFO_FONT_SIZE: "0.75em",
      ICON_FONT_SIZE: "1.4em",
      ICON_MARG_RIGHT: "0.5em",
      INFO_ROW_GAP: "4px",
    },
    BG_OVERLAY: "linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))",
    DEMAND: {
      JUSTIFY_CONTENT: "start",
      ALIGN_SELF: "start",
    },
  },
  EVENT_DESKTOP: {
    NAME: PC_VARIANT_NAMES.EVENT_DESKTOP,
    HEIGHTS: {
      CARD: "380px",
      HEADER: "220px",
      OVERLAY: "220px",
      CHIN: "160px",
    },
    GRID: {
      PAD_CARD_HORIZ: "16px",
      PAD_CARD_VERT: "32px",
      PAD_DEMAND_TITLE: "20px",
      PAD_TITLE_INFO: "8px",
      MAIN_WIDTH: "auto",
      DEMAND_HEIGHT: "1fr",
      TITLE_HEIGHT: "85px",
      INFO_HEIGHT: "42px",
    },
    TEXT: {
      TITLE_FONT_SIZE: "1.25em",
      TITLE_ALIGN_SELF: "center",
      INFO_FONT_SIZE: "0.875em",
      ICON_FONT_SIZE: "1.0625em",
      ICON_MARG_RIGHT: "0.5em",
      INFO_ROW_GAP: "0px",
    },
    BG_OVERLAY: "linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
    DEMAND: {
      JUSTIFY_CONTENT: "start",
      ALIGN_SELF: "end",
    },
  },
  EVENT_MOBILE: {
    NAME: PC_VARIANT_NAMES.EVENT_MOBILE,
    HEIGHTS: {
      CARD: "300px",
      HEADER: "300px",
      OVERLAY: "300px",
      CHIN: "0px",
    },
    GRID: {
      PAD_CARD_HORIZ: "16px",
      PAD_CARD_VERT: "16px",
      PAD_DEMAND_TITLE: "12px",
      PAD_TITLE_INFO: "12px",
      MAIN_WIDTH: "auto",
      DEMAND_HEIGHT: "1fr",
      TITLE_HEIGHT: "66px",
      INFO_HEIGHT: "42px",
    },
    TEXT: {
      TITLE_FONT_SIZE: "1em",
      TITLE_ALIGN_SELF: "end",
      INFO_FONT_SIZE: "0.75em",
      ICON_FONT_SIZE: "1.4em",
      ICON_MARG_RIGHT: "0.5em",
      INFO_ROW_GAP: "4px",
    },
    BG_OVERLAY:
      "linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))",
    DEMAND: {
      JUSTIFY_CONTENT: "start",
      ALIGN_SELF: "start",
    },
  },
  YOU_MAY_LIKE: {
    NAME: PC_VARIANT_NAMES.YOU_MAY_LIKE,
    HEIGHTS: {
      CARD: "200px",
      HEADER: "200px",
      OVERLAY: "200px",
      CHIN: "0px",
    },
    GRID: {
      PAD_CARD_HORIZ: "16px",
      PAD_CARD_VERT: "16px",
      PAD_DEMAND_TITLE: "0px",
      PAD_TITLE_INFO: "0px",
      MAIN_WIDTH: "auto",
      DEMAND_HEIGHT: "1fr",
      TITLE_HEIGHT: "60px",
      INFO_HEIGHT: "0px",
    },
    TEXT: {
      TITLE_FONT_SIZE: "1em",
      TITLE_ALIGN_SELF: "end",
      INFO_FONT_SIZE: "0.75em",
      ICON_FONT_SIZE: "1em",
      ICON_MARG_RIGHT: "0.25em",
      INFO_ROW_GAP: "0px",
    },
    BG_OVERLAY:
      "linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))",
    DEMAND: {
      JUSTIFY_CONTENT: "start",
      ALIGN_SELF: "end",
    },
  },
  SEARCH_DESKTOP: {
    // based on em of 14px
    NAME: PC_VARIANT_NAMES.SEARCH_DESKTOP,
    HEIGHTS: {
      CARD: "380px",
      HEADER: "220px",
      OVERLAY: "220px",
      CHIN: "160px",
    },
    GRID: {
      PAD_CARD_HORIZ: "16px",
      PAD_CARD_VERT: "32px",
      PAD_DEMAND_TITLE: "24px",
      PAD_TITLE_INFO: "12px",
      MAIN_WIDTH: "auto",
      DEMAND_HEIGHT: "1fr",
      TITLE_HEIGHT: "77px", // lineheight 25px
      INFO_HEIGHT: "42px",
    },
    TEXT: {
      TITLE_FONT_SIZE: "1.42857143em",
      TITLE_ALIGN_SELF: "center",
      INFO_FONT_SIZE: "1em",
      ICON_FONT_SIZE: "1.0625em",
      ICON_MARG_RIGHT: "0.53125em",
      INFO_ROW_GAP: "0px",
    },
    BG_OVERLAY: "linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
    DEMAND: {
      JUSTIFY_CONTENT: "start",
      ALIGN_SELF: "end",
    },
  },
  SEARCH_MOBILE: {
    // based on em of 14px
    NAME: PC_VARIANT_NAMES.SEARCH_MOBILE,
    HEIGHTS: {
      CARD: "300px",
      HEADER: "300px",
      OVERLAY: "300px",
      CHIN: "0px",
    },
    GRID: {
      PAD_CARD_HORIZ: "16px",
      PAD_CARD_VERT: "32px",
      PAD_DEMAND_TITLE: "6px",
      PAD_TITLE_INFO: "6px",
      MAIN_WIDTH: "auto",
      DEMAND_HEIGHT: "1fr",
      TITLE_HEIGHT: "68px",
      INFO_HEIGHT: "42px",
    },
    TEXT: {
      TITLE_FONT_SIZE: "1.14285714em",
      TITLE_ALIGN_SELF: "end",
      INFO_FONT_SIZE: "0.85714286em",
      ICON_FONT_SIZE: "1.3em",
      ICON_MARG_RIGHT: "0.53125em",
      INFO_ROW_GAP: "4px",
    },
    BG_OVERLAY: "linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))",
    DEMAND: {
      JUSTIFY_CONTENT: "start",
      ALIGN_SELF: "start",
    },
  },
}
