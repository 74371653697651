import React from "react"

import Layout from "@components/layout/layout"
import { WipFestivalPage } from "./wip_festival_page"

/**
 * The layout used for the main Festival pages. This layout ensures that if
 * the WIP mode is active, the main components of the page are hidden the WIP
 * content is shown instead
 */
export function FestivalPageLayout(props) {
  const { title, isWipModeActive, wipModeSettings, children } = props

  return (
    <Layout>
      {!!isWipModeActive ? (
        <WipFestivalPage title={title} settings={wipModeSettings} />
      ) : (
        <>{children}</>
      )}
    </Layout>
  )
}
