import React from "react"
import { useScheduleGridView } from "../../hooks"
import { ScheduleHeader } from "./schedule_date_headers/index"
import { ScheduleGrid } from "./schedule_grid/"

export function ScheduleGridView(props) {
  const {
    datesList,
    visibleRange,
    eventsByDate,
    handleOnNext,
    handleOnPrevious,
  } = useScheduleGridView(props)

  return (
    <section className="schedule">
      <ScheduleHeader
        visibleRange={visibleRange}
        datesList={datesList}
        handleOnNext={handleOnNext}
        handleOnPrevious={handleOnPrevious}
      />
      <ScheduleGrid
        visibleRange={visibleRange}
        eventsByDate={eventsByDate}
        datesList={datesList}
      />
    </section>
  )
}
