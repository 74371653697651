import "./src/styles/main.scss"

/**
 * A list of scroll configurations for specific paths. Here is the list of options that each
 * object can include:
 *
 * - `path` - the path the configuration is for
 * - `shouldOnlyScrollToAnchors` - Default: false. if `true`, will attempt to scroll to an
 * element with an ID matching the value of the hash. Will scroll to the top of the page if
 * no element is found.
 * - `shouldPreventScrollToTop` - Default: false, Will prevent the default behavior of
 * scrolling to the top of the page on navigation.
 */
const pathScrollConfiguration = [
  {
    path: "/faq",
    shouldOnlyScrollToAnchors: true,
  },
]
/**
 * An array containing the strings that indicate a hash value should be considered empty
 */
const emptyHashStrings = ["", "#"]

/**
 * This function determines the initial scroll behavior when navigating to a page. By default, we will
 * scroll to the top of the page when navigating to it. The behavior of a specific path can be changed
 * by including it into the `pathScrollConfiguration`. The behavior can be changed to scroll
 * to anchor points by using the hash or not scrolling at all.
 *
 * This function is added to fix gatsby's bug of some pages not scrolling to the top when navigating
 * reference to the solution: https://github.com/gatsbyjs/gatsby/issues/38201
 *
 * Note: set time out is not to delay the scroll, without the setTimeOut it doesn't work
 * setTimeout seems to prevent the scroll from getting interrupted.
 */
export const shouldUpdateScroll = ({ routerProps }) => {
  const { pathname, hash } = routerProps.location

  // Check for scroll configuration for the specific path
  const scrollConfig = pathScrollConfiguration.find(
    settings => settings.path === pathname
  )

  if (scrollConfig) {
    const {
      shouldOnlyScrollToAnchors = false,
      shouldPreventScrollToTop = false,
    } = scrollConfig

    if (!shouldOnlyScrollToAnchors && !shouldPreventScrollToTop) {
      // scroll top the top of the page
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    } else if (shouldOnlyScrollToAnchors) {
      // scroll to anchor points specified by the hash
      setTimeout(() => {
        let targetEl = emptyHashStrings.includes(hash)
          ? null
          : document.getElementById(hash.substring(1)) // remove the '#' character from the hash

        if (targetEl) {
          targetEl.scrollIntoView(true)
        } else {
          // scroll to top if no element was found
          window.scrollTo(0, 0)
        }
      }, 0)
    } else {
      // Do not scroll to top
    }

    // return false so that the default gatsby scroll behavior is not triggered
    return false
  }

  //return true so that other pages will function as per gatsby default.
  return true
}
