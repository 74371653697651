import React from "react"
import styled from "@emotion/styled"
import { FaTicketAlt, FaCartPlus, FaEnvelopeOpen } from "react-icons/fa"
import { IconContext } from "react-icons"

const HowToBannerSection = styled("section")`
  background-color: #111111;
  text-align: center;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`
const HowToBannerHeader = styled("h2")`
  color: #fcfcfc;
  font-weight: 800;
  font-size: 1.5rem;
`
const StepContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(5, minmax(4rem, 1fr));
  grid-template-rows: auto auto;
  grid-template-areas:
    "icon1 sep1 icon2 sep2 icon3"
    "text1 . text2 . text3";
  align-items: center;
  justify-items: center;
  max-width: 30rem;

  @media (min-width: 800px) {
    grid-template-columns: repeat(5, minmax(6rem, 1fr));
  }
`

const StepText = styled("p")`
  color: #fcfcfc;
  padding-top: 1rem;
  font-size: 0.75rem;
  grid-area: ${props => (props.gridArea ? props.gridArea : "")};

  @media (min-width: 800px) {
    font-size: 1rem;
    width: 100px;
  }
`
const SeparatorLine = styled("div")`
  border-bottom: 4px solid #999999;
  min-width: 2.5rem;
  width: 100%;
  height: 0.5rem;
  grid-area: ${props => (props.gridArea ? props.gridArea : "")};
`

export function TicketingHowToBanner(props) {
  const steps = [
    {
      icon: FaCartPlus,
      color: "#FCFCFC",
      text: "Add to cart",
      area: "icon1",
      textArea: "text1",
    },
    {
      icon: FaTicketAlt,
      color: "#999999",
      text: "Check out",
      area: "icon2",
      textArea: "text2",
    },
    {
      icon: FaEnvelopeOpen,
      color: "#999999",
      text: "Email Confirmation",
      area: "icon3",
      textArea: "text3",
    },
  ]

  return (
    <HowToBannerSection>
      <HowToBannerHeader>How to Buy a Ticket</HowToBannerHeader>
      <StepContainer>
        {steps.map((step, index) => (
          <React.Fragment key={index} gridArea={step.area}>
            <IconContext.Provider value={{ color: step.color, size: "2rem" }}>
              {React.createElement(step.icon)}
            </IconContext.Provider>
            {index < steps.length - 1 && (
              <SeparatorLine gridArea={`sep${index + 1}`} />
            )}
          </React.Fragment>
        ))}
        {steps.map((step, index) => (
          <StepText key={index} gridArea={step.textArea}>
            {step.text}
          </StepText>
        ))}
      </StepContainer>
    </HowToBannerSection>
  )
}
