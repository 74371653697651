import React from "react"
import styled from "@emotion/styled"

export const ASPECT_RATIOS = {
  "4:3": "4:3",
  "16:9": "16:9",
  "2:1": "2:1",
}

/**
 * A reusable component built to create a container that enforces a width to height ratio
 * as the width changes.
 *
 * Note: do not override this component's width, otherwise the aspect ratio will not be maintained.
 *
 * This component takes advantage of the technique mentioned here:
 * https://css-tricks.com/aspect-ratio-boxes/
 */
export function AspectRatioContainer(props) {
  const { ratio, children, className } = props

  if (!Object.values(ASPECT_RATIOS).includes(ratio)) {
    console.warn(
      `AspectRatioContainer received invalid ratio of [${ratio}]. Defaulting to 16:9`
    )
  }

  let ratioAsPercent
  switch (ratio) {
    case ASPECT_RATIOS["4:3"]:
      ratioAsPercent = (3 / 4) * 100
      break
    case ASPECT_RATIOS["2:1"]:
      ratioAsPercent = (1 / 2) * 100
      break
    case ASPECT_RATIOS["16:9"]:
    default:
      ratioAsPercent = (9 / 16) * 100
      break
  }

  return (
    <AspectRatio className={className} ratio={ratioAsPercent}>
      {children}
    </AspectRatio>
  )
}

/**
 * Refer to this link for more details on creating an aspect-ratio box:
 * https://css-tricks.com/aspect-ratio-boxes/
 */
const AspectRatio = styled.div`
  height: 0;
  padding-top: ${props => `${props.ratio}%`}; // the percentage for 16:9 ratio
  overflow: hidden;
`
