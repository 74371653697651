import React from "react"
import { SponsorDto } from "../../common/dto"

import SponsorRow from "./sponsor_row"

const itemsPerTier = {
  "Title Sponsor": 3,
  "Platinum Sponsor": 3,
  "Public Support": 4,
  "Gold Sponsor": 4,
  "Silver Sponsor": 4,
  "Bronze Sponsor": 5,
  "Contributing Sponsor": 6,
  "Exhibition Partner": 6,
  "Film Partner": 4,
  "Event Partner": 5,
  "Hotel Partner": 5,
  "Print Partner": 5,
  "Community Partner": 6,
  "Leaders' Circle Donor": 5,
}

export default function SponsorList(props) {
  const { sponsors, tiers } = props

  const sponsorTierMap = new Map()
  sponsors.group.forEach(group => {
    sponsorTierMap.set(
      group.fieldValue,
      group.nodes.filter(node => node.sponsorStatus.status === "active")
    )
  })

  const sponsorRowList = tiers.nodes.map(({ name }, idx) => {
    if (!sponsorTierMap.get(name) || sponsorTierMap.get(name).length === 0)
      return null

    return (
      <li className="sponsorRow" key={`row-${idx}`}>
        {name.toLowerCase() === "public support" ? (
          <h2 className="sponsorRow__title">{name}</h2>
        ) : (
          <h2 className="sponsorRow__title">{name + "s"}</h2>
        )}

        <SponsorRow
          key={name}
          itemsPerRow={itemsPerTier[name]}
          sponsors={sponsorTierMap
            .get(name)
            .map(sponsor => SponsorDto.fromWpSponsor(sponsor))}
        />
      </li>
    )
  })

  return <ul className="sponsorList">{sponsorRowList}</ul>
}
