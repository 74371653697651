import React from "react"
import styled from "@emotion/styled"

import { TicketSection } from "./entry_ticket_section"

export function TicketSectionGroup(props) {
  const {
    visible,
    screenings,
    activeScreeningId,
    onToggleChange,
    toggleOptions,
  } = props

  const sectionItems = screenings.map(screening => {
    return (
      <TicketSection
        entry={screening}
        visible={screening.id === activeScreeningId}
        onToggleChange={onToggleChange}
        toggleOptions={toggleOptions}
        selectedScreeningId={activeScreeningId}
      />
    )
  })

  return (
    <StyledSectionContainer isVisible={visible}>
      {sectionItems}
    </StyledSectionContainer>
  )
}

const StyledSectionContainer = styled.div`
  display: ${props => (props.isVisible ? "block" : "none")};
`
