import React from "react"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"

import { ScheduleViewTypes } from "../../../../common/constants"

export function ViewTypeToggle(props) {
  const { currentViewType, handleViewTypeChange } = props

  const onChange = (e, newViewType) => {
    handleViewTypeChange(newViewType)
  }

  return (
    <ToggleButtonGroup
      value={currentViewType}
      exclusive
      onChange={onChange}
      aria-label={"Schedule View Type"}
      className="viewToggle"
    >
      <ToggleButton
        value={ScheduleViewTypes.LIST}
        aria-label={ScheduleViewTypes.LIST}
        className="viewToggle__btn"
      >
        {ScheduleViewTypes.LIST}
      </ToggleButton>
      <ToggleButton
        value={ScheduleViewTypes.GRID}
        aria-label={ScheduleViewTypes.GRID}
        className="viewToggle__btn"
      >
        {ScheduleViewTypes.GRID}
      </ToggleButton>
      {/* <ToggleButton
        value={ScheduleViewTypes.CALENDAR}
        aria-label={ScheduleViewTypes.CALENDAR}
        className="viewToggle__btn"
      >
        {ScheduleViewTypes.CALENDAR}
      </ToggleButton> */}
    </ToggleButtonGroup>
  )
}
