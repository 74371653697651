import React from "react"
import styled from "@emotion/styled"
import moment from "moment"

import ImageWrapper, { DefaultImageTypes } from "../../image_wrapper"
import { ButtonLink } from "@components/@aaiff"

import { useHomeBanner } from "./home_banner.hook"
import { useMediaByBreakpoint } from "@hooks"
import { getOrdinalString } from "../../../util/string_util"

export function HomeBanner({ bannerData }) {
  const {
    bannerImages,
    textOverlayImages,
    currentFestival,
    callToActionOptions,
  } = useHomeBanner(bannerData)
  const textOverlayMedia = useMediaByBreakpoint(textOverlayImages)
  const bannerMedia = useMediaByBreakpoint(bannerImages)

  const startDate = moment(currentFestival.startDate)
  const endDate = moment(currentFestival.endDate)

  const displayDate =
    startDate.format("MMMM") === endDate.format("MMMM")
      ? `${startDate.format("MMMM D")}-${endDate.format("D, YYYY")}`
      : `${startDate.format("MMMM D")} - ${endDate.format("MMMM D, YYYY")}`

  const festivalNum =
    currentFestival.festivalNumber +
    getOrdinalString(currentFestival.festivalNumber)

  return (
    <div>
      <BannerRoot className="homeBanner">
        <BannerBackground media={bannerMedia} />
        <OverlayRoot ctaOnly={!textOverlayMedia}>
          <TextGraphic
            media={textOverlayMedia}
            alt={`${currentFestival.festivalName} Banner`}
            objectFit="contain"
            defaultImageType={DefaultImageTypes.NONE}
          />
        </OverlayRoot>
      </BannerRoot>
      <CtaContainer>
        <CtaTitle>
          <span className="line1">{`New York's ${festivalNum} Asian American`}</span>
          <span className="line2">International Film Festival</span>
          <span className="line1-small">{`New York's ${festivalNum} Asian`}</span>
          <span className="line2-small">American International Film</span>
          <span className="line3-small">Festival</span>
        </CtaTitle>
        <CtaDate>{displayDate}</CtaDate>
        <CtaButton
          to={
            callToActionOptions.linkType === "internal"
              ? `/${callToActionOptions.path}`
              : ""
          }
          href={
            callToActionOptions.linkType === "external"
              ? callToActionOptions.url
              : ""
          }
          variant="#248458"
          size="L"
        >
          {callToActionOptions.label}
        </CtaButton>
      </CtaContainer>
    </div>
  )
}

const BannerRoot = styled.div`
  @media only screen and (max-width: 800px) {
    height: 600px;
  }
  @media only screen and (max-width: 600px) {
    height: 600px;
  }

  position: relative;
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
`

const BannerBackground = styled(ImageWrapper)`
  @media only screen and (max-width: 800px) {
    height: 600px;
  }

  width: 100%;
  height: 450px;
  margin-bottom: 2rem;
`

const OverlayRoot = styled.div`
  @media only screen and (max-width: 800px) {
    height: 600px;
    max-height: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    padding: 20px 20px 40px 20px;
  }

  @media only screen and (max-width: 600px) {
    row-gap: 10px;
    padding: 10px 10px 40px 10px;
  }

  position: absolute;
  top: 0;
  z-index: 20;

  height: 100%;
  max-height: 450px;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.ctaOnly ? "flex-end" : "center")};
  column-gap: 20px;

  box-sizing: border-box;
`

const TextGraphic = styled(ImageWrapper)`
  @media only screen and (min-width: 800px) {
    min-width: 600px;
    max-width: 850px;
    width: 60%;
    height: 100%;
    flex-grow: 2;
  }

  width: 100%;
`

const CtaContainer = styled.div`
  @media only screen and (max-width: 700px) {
    gap: 1.5rem;
    padding: 40px 20px 30px 20px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
  padding: 4.5% 20px 1% 20px;
  gap: 2rem;
`

const CtaTitle = styled.h1`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  font-size: 48px;
  font-weight: bold;
  margin: 0;

  .line1,
  .line2,
  .line1-small,
  .line2-small,
  .line3-small {
    display: block;
    line-height: 1;
  }

  .line1-small,
  .line2-small,
  .line3-small {
    display: none;
  }

  @media (max-width: 950px) {
    font-size: 36px;
  }

  @media (max-width: 700px) {
    .line1,
    .line2 {
      display: none;
    }

    .line1-small,
    .line2-small,
    .line3-small {
      display: block;
    }
  }

  @media (min-width: 701px) {
    .line1,
    .line2 {
      display: block;
    }

    .line1-small,
    .line2-small,
    .line3-small {
      display: none;
    }
  }

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 36px;
  }
`

const CtaDate = styled.h3`
  @media (max-width: 950px) {
    font-size: 30px;
  }

  @media (max-width: 700px) {
    font-size: 24px;
  }

  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  margin: 0;
`

const CtaButton = styled(ButtonLink)`
  @media (max-width: 600px) {
    font-size: 16px;
  }

  display: block;
  text-wrap: wrap;
  margin: 0 auto;
  max-width: 80%;
  color: white;
  background-color: #248458;
  font-weight: 18px;
  line-height: 22.5px;
`
