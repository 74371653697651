import React, { useState } from "react"
import { FaPlus, FaMinus } from "react-icons/fa"
import styled from "@emotion/styled"

import { LinkGroup, Link } from "@components/@aaiff"

const AccordionLi = styled.li`
  cursor: pointer;
`

export function Accordion(props) {
  const { className = "", sections } = props
  const [selected, setSelected] = useState(new Set())

  const toggleSelected = title => {
    const newSet = new Set([...selected])
    if (newSet.has(title)) {
      newSet.delete(title)
    } else {
      newSet.add(title)
    }
    setSelected(newSet)
  }

  return (
    <ul className={`accordion ${className}`}>
      {sections.map(({ title, links }) => {
        return (
          <AccordionItem
            title={title}
            key={title}
            links={links}
            isOpen={selected.has(title)}
            setSelected={toggleSelected}
          />
        )
      })}
    </ul>
  )
}

export function AccordionItem({
  className = "",
  isOpen,
  links,
  title,
  setSelected,
}) {
  // Adds a warning if the accordion exceeds 10 items
  if (links.length > 10) {
    console.warn(
      "The AccordionItem component exceeds 10 items. There might be some issues displaying all menu items. Please update the stylesheet for the component to display all of the items"
    )
  }

  return (
    <AccordionLi className={`accordion__item ${className}`}>
      <div className="accordion__itemHeader" onClick={() => setSelected(title)}>
        <h3 className="accordion__itemHeaderTitle">{title}</h3>

        {isOpen ? <FaMinus /> : <FaPlus />}
      </div>

      <LinkGroup
        direction="column"
        className={`accordion__itemMenu ${
          isOpen ? `_is-open-${links.length}` : "_is-closed"
        }`}
      >
        {links.map(({ label, path, isExternal }) => {
          return (
            <Link
              to={!isExternal && `/${path}`}
              href={isExternal && path}
              key={label}
              className="accordion__itemMenuLink"
            >
              {label}
            </Link>
          )
        })}
      </LinkGroup>
    </AccordionLi>
  )
}
