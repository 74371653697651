import { useEffect, useState, useRef, useCallback } from "react"

/**
 * The state hook for using an Input component. Options can be provided to make the input controlled
 * or give it default values.
 *
 * @param {{ inputValue?: string, defaultValue?: string, onInputChange?: (value) => void}} options the options
 * used to customized the behavior of the input field. The options include the following:
 * - value - the value of the input field (controlled)
 * - defaultValue - the initial value of the input (uncontrolled)
 * - onInputChange - a function to be called whenever the input value changes
 * @returns {{ value: string, handleInputChange: (e: Event) => void }} the value for the input
 * field and the event handler for the onChange event
 */
export function useInput({
  value: controlledValue,
  defaultValue,
  onInputChange,
} = {}) {
  const [controlled, setControlled] = useState(
    typeof controlledValue === "string"
  )
  const [hasValue, setHasValue] = useState(
    controlledValue ? controlledValue.length > 0 : false
  )

  const inputRef = useRef()
  const setInputRef = useCallback(node => {
    if (node) {
      // When setting the ref to the html input, check if it has a value and update the hasValue state
      setHasValue(node.value !== "")
    }

    inputRef.current = node
  })

  useEffect(() => {
    if (typeof controlledValue === "string") {
      setControlled(true)
      setHasValue(controlledValue ? controlledValue.length > 0 : false)
    } else {
      setControlled(false)
    }
  }, [controlledValue])

  const handleInputChange = e => {
    const newValue = e.target.value

    if (newValue.length > 0) {
      setHasValue(true)
    } else {
      setHasValue(false)
    }
    if (onInputChange) onInputChange(newValue)
  }

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = ""
      setHasValue(false)

      if (onInputChange) onInputChange("")
    }
  }

  return {
    inputRef: setInputRef,
    inputValue: controlled ? controlledValue : undefined,
    hasValue,
    defaultValue,
    handleInputChange,
    clearInput,
  }
}
