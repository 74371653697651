/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"

import { Header } from "./header/index"
import { Footer } from "./footer/index"
import { useLayout, useEleventService } from "../../hooks"

const Layout = ({ children }) => {
  const { headerNavOptions, footerLinkOptions, socialLinks } = useLayout()
  // start listeners for elevent events
  const eleventService = useEleventService()

  useEffect(() => {
    eleventService.initializeWidgets()
  }, [])

  return (
    <>
      <Header navbarOptions={headerNavOptions} />
      <main>{children}</main>
      <Footer socialLinks={socialLinks} footerLinkOptions={footerLinkOptions} />
    </>
  )
}

export default Layout
