import React from "react"
import { FaCheck, FaTimes } from "react-icons/fa"
import { IconContext } from "react-icons"
import styled from "@emotion/styled"

const FeatureListIcon = styled("td")`
  font-size: 2rem;

  @media (min-width: 800px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1280px) {
    font-size: 2rem;
  }
`
const FeatureListRow = styled("tr")`
  display: flex;
  align-items: center;
  margin: 0.5rem 0.25rem 0 0.25rem;

  @media (min-width: 1280px) {
    margin: 1rem 0.5rem 0 0.5rem;
  }
`
const FeatureListDescription = styled("tr")`
  padding-left: 1rem;

  @media (min-width: 800px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1280px) {
    font-size: 1rem;
  }
`
const FeatureListTable = styled("table")`
  margin-top: 1rem;
`

export function TicketingFeaturesList(props) {
  const { ticketingItem, showFeatureIcons = true } = props

  // Create a table use the features list so that the checks and Xs are
  // aligned separately from the descriptions themselves
  const featureRows = ticketingItem.getFeaturesList().map((feature, idx) => {
    const { description, isChecked } = feature

    const rowElements = []

    if (showFeatureIcons) {
      rowElements.push(
        <IconContext.Provider value={{ color: "#F29D2E" }}>
          <FeatureListIcon>
            {isChecked ? <FaCheck /> : <FaTimes />}
          </FeatureListIcon>
        </IconContext.Provider>
      )
    }

    rowElements.push(
      <FeatureListDescription>
        <p>{description}</p>
      </FeatureListDescription>
    )

    return (
      <FeatureListRow key={`${ticketingItem.name} Feature ${idx}`}>
        {rowElements}
      </FeatureListRow>
    )
  })

  return <FeatureListTable>{featureRows}</FeatureListTable>
}
