import React from "react"
import moment from "moment"

import { Link } from "../link"

import * as DateUtil from "../../util/date_util"

export default function UpdatesIndexItem({ date, logline }) {
  const path = DateUtil.getPagePath(date)
  const dateObj = moment(date)

  return (
    <Link to={`/updates/${path}`} className="updatesItem">
      <h1 className={"updatesItem__date"}>
        {`${dateObj.format("MM/DD/YY")}`}
        <wbr /> UPDATE
      </h1>

      <p className={"updatesItem__description"}>{logline}</p>
    </Link>
  )
}
