import React from "react"

export default function GridTimes() {
  const times = [
    "", // Add an empty time for the empty row before noon
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
  ]

  const hourItems = times.map(time => <HourItem hour={time} key={time} />)

  return <ul className="schedule__timeAxis">{hourItems}</ul>
}

const HourItem = ({ hour }) => (
  <li className="schedule__timeAxisItem">
    <span className="schedule__timeAxisItemText">{hour}</span>
  </li>
)
