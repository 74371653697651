import React from "react"

import NavbarItem from "./navbar_item"

/**
 * A component that contains all of the items for the header nav. The contents of the
 * header nav are pulled from the WordPress site and can be customized there.
 *
 * To customize the contents of the header, update the page's navbar metadata at
 * https://www.asiancinevision.org/wp-admin/edit.php?post_type=festival_pages
 *
 * Please contact an AAIFF webmaster if have any problems accessing or modifying this
 * data.
 */
export function Navbar({ navbarOptions }) {
  return (
    <nav className="navbar">
      <ul className="navbar__menu">
        {navbarOptions.map(options => (
          <NavbarItem {...options} key={options.slug} />
        ))}
      </ul>
    </nav>
  )
}
