import moment from "moment"

import { SponsorDto, VenueDto } from "@common/dto"
import { EventFormatSlugs, WpEntryTypes, EventTypeSlugs } from "../constants"
import { BaseEntry } from "./BaseEntry"
import { unescapeHTMLEntities } from "../../util/string_util"
import { flattenEntryFieldGroups, mapFilmsById } from "../../util/entry_utils"

/**
 * This class represents the base of all Event sub-classes. This class can
 * also be used for generic Events that are not a Film Screening, Shorts
 * Program, or Panel Event.
 *
 * @class
 */
export class EventEntry extends BaseEntry {
  constructor(id, slug, data) {
    super(id, slug, data)

    this.type = WpEntryTypes.EVENT
    this.isTentpole = data?.eventType?.slug === EventTypeSlugs.TENTPOLES

    // If the event is not on demand and does not have an end date value,
    // calculate the end date by adding runtime to the start time
    if (!this.__data?.endDate || !this.__data?.endTime) {
      const start = moment(`${this.__data.startDate} ${this.__data.startTime}`)

      const end =
        this.__data.eventFormat?.slug !== EventFormatSlugs.ON_DEMAND ||
        !this.__data.endDate
          ? start.add(this.__data.runtime, "minutes")
          : moment(this.__data.endDate)

      if (!this.__data.endDate) {
        this.__data.endDate = end.format("MM/DD/YYYY")
      }

      if (
        this.__data.eventFormat?.slug !== EventFormatSlugs.ON_DEMAND &&
        !this.__data.endTime
      ) {
        this.__data.endTime = end.format("HH:mm:ss")
      }
    }

    const { mainFilm, shortFilms = [] } = data

    if (mainFilm && shortFilms?.length > 0) {
      this.__films = mapFilmsById([mainFilm, ...shortFilms])
    } else if (mainFilm) {
      this.__films = mapFilmsById([mainFilm])
    } else if (shortFilms?.length > 0) {
      this.__films = mapFilmsById(shortFilms)
    } else {
      this.__films = new Map()
    }
  }

  get mainFilm() {
    if (this.__data.mainFilm) {
      return this.__films.get(this.__data.mainFilm.id)
    }

    return null
  }

  get allFilms() {
    return Array.from(this.__films.values())
  }

  get allFilmIds() {
    return Array.from(this.__films.keys())
  }

  /**
   * Returns all additional films that are not the main film
   *
   * @alias shortFilms
   */
  get additionalFilms() {
    if (!this.__data.shortFilms || this.__data.shortFilms.length < 1) {
      return []
    }

    return (this.__data?.shortFilms || []).map(film =>
      this.__films?.get(film.id)
    )
  }

  /**
   * Returns all short films for this event
   *
   * @alias additionalFilms
   */
  get shortFilms() {
    return this.additionalFilms
  }

  get spotlightPreviewDescription() {
    if (this.isTentpole) {
      return this.__data.tentpolePreviewDetails || ""
    }

    return ""
  }

  get eventNotes() {
    return this.__data.eventNotes
  }

  /**
   * Returns the event type slug for the entry. If the event is a
   * tentpole, this will return the string "Special Presentation" so
   * that it can be filtered with other special presentations.
   *
   * Use the `isTentpole` property to check if it is a tentpole
   */ get eventType() {
    if (this.isTentpole) {
      return "Special Presentations"
    }

    return unescapeHTMLEntities(this.__data.eventType.name)
  }

  /**
   * Returns the event type slug for the entry. If the event is a
   * tentpole, this will return the special presentation slug so
   * that it can be filtered with other special presentations.
   *
   * Use the `isTentpole` property to check if it is a tentpole
   */
  get eventTypeSlug() {
    if (this.isTentpole) {
      return EventTypeSlugs.SPECIAL_PRESENTATION
    }

    return this.__data.eventType.slug
  }

  get eventFormat() {
    return this.__data.eventFormat.name
  }

  get eventFormatSlug() {
    return this.__data.eventFormat.slug
  }

  /**
   * Retrieves the list of sponsor as SponsorDto object
   * @return {Array<SponsorDto>} a list of sponsor objects
   */
  get sponsors() {
    return (this.__data.sponsors || []).map(sponsor =>
      SponsorDto.fromWpSponsor(sponsor)
    )
  }

  get startDate() {
    return this.__data.startDate || ""
  }

  get startTime() {
    return this.__data.startTime || ""
  }

  get endDate() {
    return this.__data.endDate || ""
  }

  get endTime() {
    return this.__data.endTime || ""
  }

  get ticketingInfo() {
    const ticketingInfo = {
      elevent: null,
      external: null,
      upsell: null,
    }

    if (this.__data.eleventShowtimeId && this.__data.eleventUrlName) {
      ticketingInfo.elevent = {
        showtimeId: this.__data.eleventShowtimeId || "",
        urlName: this.__data.eleventUrlName || "",
      }
    }

    if (this.__data.externalTicketingUrl) {
      ticketingInfo.external = {
        url: this.__data.externalTicketingUrl,
        label: this.__data.externalTicketingButtonLabel || "Buy Tickets",
      }
    }

    if (this.__data.upsellEleventInfo) {
      ticketingInfo.upsell = this.__data.upsellEleventInfo
    }

    return ticketingInfo
  }

  get venue() {
    return this.__data.venue ? VenueDto.fromWpVenue(this.__data.venue) : null
  }

  get screeningUrls() {
    if (!this.isOnline) {
      return null
    }

    return this.__data.screeningUrls || null
  }

  get regionRestrictions() {
    if (this.eventFormatSlug === EventFormatSlugs.IN_PERSON) {
      return null
    }

    return this.__data.regionRestrictions || null
  }

  get keywords() {
    return [] // TODO: implement keywords after it has been added to WP
  }

  get relatedEvents() {
    const relatedEvents = this.__data.relatedEvents || []

    return relatedEvents
      .map(eventNode => flattenEntryFieldGroups(eventNode.event))
      .filter(eventNode => !!eventNode) // filter out any bad data nodes coming from WP that couldn't become an event object
  }

  get isOnline() {
    return this.eventFormatSlug !== EventFormatSlugs.IN_PERSON
  }

  get curatedSection() {
    return this.__data.curatedSectionTags || null
  }

  get advertisingSponsors() {
    return {
      sponsorA:
        SponsorDto.fromWpSponsor(this.__data?.advertisingSponsors?.sponsorA) ??
        null,
      sponsorB:
        SponsorDto.fromWpSponsor(this.__data?.advertisingSponsors?.sponsorB) ??
        null,
    }
  }

  get coSponsor() {
    return SponsorDto.fromWpSponsor(this.__data?.coSponsor) ?? null
  }

  get includeUncroppedImageInBody() {
    return this.__data.includeUncroppedImage
  }

  get eventDuration() {
    return this.__data.runtime
  }

  get detailsItinerary() {
    return this.__data.eventDetails?.itinerary
  }

  get detailsNote() {
    return this.__data.eventDetails?.detailsNote
  }

  /**
   * Checks if the current event contains any of the film ids. If the film is not
   * a Feature Screening, Tentpole, Special Presentation, or Shorts Program, this
   * will automatically return false
   *
   * @param {Array<string>} filmIds The array of film ids to check
   * @return {Boolean} `true` if the event contains at least one of the film ids,
   * otherwise `false`.
   */
  containsFilmIds(filmIds) {
    if (filmIds && this.hasFilmAttributes && this.__films) {
      for (let idx = 0; idx < filmIds.length; idx++) {
        if (this.__films.has(filmIds[idx])) {
          return true
        }
      }
    }

    return false
  }
}
