import { useState, useEffect, useMemo, useCallback } from "react"
import { navigate } from "gatsby"

import { WpDataFactory } from "../../common/factories/WPDataFactory"
import { FeatureScreeningEntry } from "../../common/wp-data"
import { EventFormatSlugs } from "../../common/constants"

/**
 * Gets the value of the format type slug to compare in
 * the function _sortEntriesPreferredFormatType
 *
 * @param {string} formatTypeSlug the format type slug
 * @returns the value of the format type slug
 */
const _getFormatTypeValue = formatTypeSlug => {
  const FormatTypeValue = {
    [EventFormatSlugs.IN_PERSON]: 1,
    [EventFormatSlugs.LIVESTREAM]: 2,
    [EventFormatSlugs.ON_DEMAND]: 3,
  }

  return FormatTypeValue[formatTypeSlug] || Infinity
}

/**
 * Sorts the array of events by format type. This function will
 * sorted into the following order:
 *
 * 1. In Person events
 * 2. Livestream events
 * 3. On Demand events
 *
 * @param {Array<EventEntry>} events the array of unsorted events
 * @returns {Array<EventEntry>} the sorted events array
 */
const _sortEntriesPreferredFormatType = events => {
  return events.sort((eventA, eventB) => {
    const valueA = _getFormatTypeValue(eventA.eventFormatSlug)
    const valueB = _getFormatTypeValue(eventB.eventFormatSlug)

    if (valueA < valueB) {
      return -1
    } else if (valueA > valueB) {
      return 1
    } else {
      return 0
    }
  })
}

/**
 * A helper function for determining the default entry when there are multiple
 * screenings for an event.
 *
 * @param {FilmEntry|EventEntry} baseEntry the entry representing this page
 * @param {EventEntry[]} screeningEntries the list of events that are screenings for
 * a film entry
 * @param {string} [locationHash] Optional. The hash from the url location. When this
 * value is provided, will use the event that matches this hash value
 * @returns {EventEntry} the default entry to be used
 */
const _getDefaultEntry = (baseEntry, screeningEntries, locationHash) => {
  if (
    baseEntry.isEvent ||
    (!baseEntry.isEvent && screeningEntries.length == 0)
  ) {
    return baseEntry
  } else if (locationHash) {
    // If a location hash is provided, find the event screening that has the same
    // slug as the hash value.
    const match = screeningEntries.find(
      entry => generateHashFromSlug(entry.slug) === locationHash
    )

    if (match) {
      return FeatureScreeningEntry.fromFilmAndEventEntries(baseEntry, match)
    } else {
      console.warn("Could not find event entry matching hash ", locationHash)
    }
  }

  const spotlightScreenings = [],
    regularScreenings = []

  // Separately store the spotlights from the other screenings
  screeningEntries.forEach(event => {
    if (event.isTentpole) {
      spotlightScreenings.push(event)
    } else {
      regularScreenings.push(event)
    }
  })

  // See if any of the films are tentpoles, use those as the preferred
  // instead of the regular screenings
  const sortedEvents = _sortEntriesPreferredFormatType(
    spotlightScreenings.length > 0 ? spotlightScreenings : regularScreenings
  )

  return FeatureScreeningEntry.fromFilmAndEventEntries(
    baseEntry,
    sortedEvents[0]
  )
}

const generateHashFromSlug = slug => {
  return "#" + encodeURI(slug)
}

const getDisplayedEventFormat = eventFormatSlug => {
  return eventFormatSlug === EventFormatSlugs.IN_PERSON
    ? "In-Person"
    : eventFormatSlug === EventFormatSlugs.ON_DEMAND
    ? "On-Demand"
    : "Livestream"
}

/**
 * A helper hook for managing the active screening entry for a film page. This hook provides
 * the active screening entry and provides functions for updating the active screening. For
 * films without screenings or event pages, this hook will simply return an entry for
 * that film or event.
 *
 * @param {Object} location an object containing information on the browser url
 * @param {Object} baseNode the original WordPress node for this film or event page. This
 * node is the film or event for this specific page
 * @param {Object[]} [screeningNodes] Optional. If this is a film page with multiple screenings,
 * this will contain several event nodes that are screenings of this film
 * @returns
 */
export function useActiveScreeningEntry(baseNode, screeningNodes, location) {
  const baseEntry = useMemo(
    () => WpDataFactory.instantiateWpEntry(baseNode),
    [baseNode]
  )
  const screeningEntries = useMemo(() => {
    return screeningNodes
      ? screeningNodes.map(event => WpDataFactory.instantiateWpEntry(event))
      : []
  }, [screeningNodes])

  // Add the screening entries to the film object
  screeningEntries.forEach(eventEntry => {
    baseEntry.addScreeningEvent(eventEntry)
  })

  const hasMultipleScreenings = useMemo(() => {
    return !baseEntry.isEvent && screeningEntries.length > 0
  }, [baseEntry, screeningEntries])

  const [activeScreeningEntry, setActiveScreening] = useState(
    _getDefaultEntry(baseEntry, screeningEntries, location.hash)
  )

  /**
   * When there are multiple screenings for a film, this function can be called to
   * set the active screening for the film. This will both update the local state
   * and update the hash portion of the url path
   */
  const changeActiveScreeningTo = useCallback(
    eventId => {
      if (hasMultipleScreenings && eventId !== activeScreeningEntry.id) {
        const newEvent = screeningEntries.find(entry => entry.id === eventId)

        if (newEvent) {
          setActiveScreening(
            FeatureScreeningEntry.fromFilmAndEventEntries(baseEntry, newEvent)
          )

          const { pathname, search } = location
          navigate(`${pathname}${search}${generateHashFromSlug(newEvent.slug)}`)
        } else {
          console.warn(
            `Event ${eventId} does not exist. Active screening not updated`
          )
        }
      }
    },
    [hasMultipleScreenings, activeScreeningEntry, location]
  )

  // If these ever change, reset the initial screening
  useEffect(() => {
    const newInitialScreening = _getDefaultEntry(
      baseEntry,
      screeningEntries,
      location.hash
    )

    setActiveScreening(newInitialScreening)
  }, [baseEntry, screeningEntries])

  const screeningToggleOptions =
    screeningEntries.length > 0
      ? _sortEntriesPreferredFormatType(screeningEntries).map(event => ({
          id: event.id,
          label: getDisplayedEventFormat(event.eventFormatSlug),
          eventFormatSlug: event.eventFormatSlug,
        }))
      : [
          {
            id: activeScreeningEntry.id,
            label: getDisplayedEventFormat(
              activeScreeningEntry.eventFormatSlug
            ),
            eventFormatSlug: activeScreeningEntry.eventFormatSlug,
          },
        ]

  return {
    baseEntry,
    screeningEntries,
    activeScreeningEntry,
    changeActiveScreeningTo,
    screeningToggleOptions,
  }
}
