import React, { useState } from "react"
import ReactPlayer from "react-player/lazy"
import styled from "@emotion/styled"

import ErrorIcon from "@mui/icons-material/Error"
import CircularProgress from "@mui/material/CircularProgress"

import { AspectRatioContainer } from "@components/aspect_ratio_container"

const LoadingStates = {
  LOADING: "loading",
  LOADED: "loaded",
  ERROR: "error",
}

export function TrailerPlayer(props) {
  const { videoUrl } = props
  const [loadingState, setLoadingState] = useState(LoadingStates.LOADING)
  const [errorMessage, setErrorMessage] = useState("")

  const onReady = () => {
    setTimeout(() => {
      setLoadingState(LoadingStates.LOADED)
    }, 1000)
  }

  const onError = e => {
    console.error("Error playing trailer video", e)

    setLoadingState(LoadingStates.ERROR)
    if (typeof e === "string") {
      setErrorMessage(e)
    } else if (typeof e === "object" && Object.hasOwn(e, "message")) {
      setErrorMessage(e.message)
    } else {
      setErrorMessage("Error playing trailer")
    }
  }

  // if the video cannot be played or no url is provided, don't render anything
  if (!videoUrl || !ReactPlayer.canPlay(videoUrl)) {
    console.warn('TrailerPlayer - Invalid url "${videoUrl}" cannot be played')
    return null
  }

  return (
    <PlayerAspectRatio ratio={"16:9"}>
      <PlayerContent>
        <PlayerOverlay loadingState={loadingState}>
          {loadingState === LoadingStates.LOADING ? (
            <OverlayMessage>
              <CircularProgress color="inherit" />
            </OverlayMessage>
          ) : loadingState === LoadingStates.ERROR ? (
            <OverlayErrorMessage>
              <ErrorIcon /> <span>{errorMessage}</span>
            </OverlayErrorMessage>
          ) : null}
        </PlayerOverlay>

        <ReactPlayer
          url={videoUrl}
          controls={true}
          onReady={onReady}
          onError={onError}
          width="100%"
          height="100%"
          style={
            loadingState !== LoadingStates.LOADED ? { opacity: 0 } : undefined
          }
        />
      </PlayerContent>
    </PlayerAspectRatio>
  )
}

const PlayerAspectRatio = styled(AspectRatioContainer)`
  position: relative;

  margin-bottom: 1em;
`

const PlayerContent = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`

const PlayerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${props => (props.loadingState === LoadingStates.LOADED ? -1 : 20)};

  opacity: ${props => (props.loadingState === LoadingStates.LOADED ? 0 : 1)};
  background: rgba(0, 0, 0, 0.5);

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

const OverlayMessage = styled.div`
  color: #fcfcfc;
  font-size: 16px;
`

const OverlayErrorMessage = styled.div`
  display: flex;
  align-items: center;

  color: #fcfcfc;
  font-size: 16px;

  svg {
    margin-right: 8px;
  }
`
