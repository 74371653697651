import React from "react"
import moment from "moment"
import styled from "@emotion/styled"

import ImageWrapper from "../../../image_wrapper"
import { EntryLink } from "../../../entry_link"
import { EventTypeSlugs, SearchPageTypes } from "../../../../common/constants"
import { TicketAvailabilityBadge } from "../../../badges/ticket_availability_badge"

export function EventResultItem(props) {
  const { entry } = props

  const eventDate = function () {
    if (!!entry.startTime) {
      var H = +entry.startTime.substr(0, 2)
      var h = H % 12 || 12
      var ampm = H < 12 || H === 24 ? "am" : "pm"
      const timeString = h + entry.startTime.substr(2, 3) + ampm
      return (
        moment(entry.startDate).format("dddd, MMMM Do, YYYY") +
        " at " +
        timeString
      )
    } else {
      return moment(entry.startDate).format("dddd, MMMM Do YYYY")
    }
  }
  const colorClass = function () {
    switch (entry.eventTypeSlug) {
      case EventTypeSlugs.TENTPOLES:
      case EventTypeSlugs.SPECIAL_PRESENTATION:
      case EventTypeSlugs.SHOWCASE:
        return "spotlightPresentations"
      case EventTypeSlugs.PANEL_WORKSHOP:
      case EventTypeSlugs.MASTERCLASS:
        return "panelWorkshop"
      case EventTypeSlugs.SOCIAL_EVENTS:
        return "socialEvents"
      case EventTypeSlugs.Q_A:
        return "qAs"
      case EventTypeSlugs.SPECIAL_EVENT:
      default:
        return "specialLiveEvents"
    }
  }

  return (
    <li className="listResultItem">
      <EntryLink
        year={entry.festivalYear}
        path={
          entry.mainFilm?.slug
            ? `${entry.mainFilm.slug}#${entry.slug}`
            : entry.slug
        }
        className="listResultItem__root"
      >
        <StyledImageWrapperContainer>
          <TicketAvailabilityBadge 
            entry={entry} 
            pageType={SearchPageTypes.EVENTS} 
            className="listResultItem__imageWrapper"
          />
          <ImageWrapper
            media={entry.posterImageMedia}
            alt={entry.title}
            objectFit="cover"
            className="listResultItem__image"
          />
        </StyledImageWrapperContainer>
        <div className={"listResultItem__bar listResultItem__" + colorClass()}>
          <span className="listResultItem__eventType">
            {entry.isTentpole ? "Spotlights" : entry.eventType}
          </span>
          <h6 className="listResultItem__barContent">{entry.title}</h6>
          <span className="listResultItem__date">{eventDate()}</span>
        </div>
      </EntryLink>
    </li>
  )
}

const StyledImageWrapperContainer = styled.div`
  position: relative; // to anchor the TicketAvailabilityBadge, which is position:absolute
`