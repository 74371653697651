import React from "react"
import styled from "@emotion/styled"
import Stack from "@mui/material/Stack"
import { FaCalendar } from "react-icons/fa"

import { Link, LinkGroup } from "@components/@aaiff"
import { SectionStyles, HeaderStyles, ListStyles } from "./film_details"
import { EventFormatSlugs } from "../../../common/constants"

const RESection = styled.section`
  ${SectionStyles}

  .aaiff-LinkGroup .aaiff-Link {
    text-decoration: underline;
  }
`

const REHeader = styled.div`
  margin: 1.25em 0;
`

const RETitle = styled.h2`
  ${HeaderStyles}

  margin: 0;
`
const REList = styled.ol`
  ${ListStyles}
`

const EventFormatLabel = styled.i`
  font-style: italic;
  font-size: 1em;
`

export function RelatedEvents({ events, isEvent }) {
  if (!events.length) {
    return null
  }

  const eventItems = events.map(event => {
    return <EventItems key={event.pageSlug} {...event} />
  })

  return (
    <RESection className="">
      <REHeader className="">
        <RETitle>{isEvent ? "Associated Events" : "Featured Events"}</RETitle>
        <span>
          {isEvent
            ? ""
            : "This film will be screened as a part of these events"}
        </span>
      </REHeader>

      <LinkGroup className="" direction="column">
        <REList className="">{eventItems}</REList>
      </LinkGroup>
    </RESection>
  )
}

const StyledEventItem = styled.li`
  .title {
    font-weight: bold;
  }
`

const EventFormatDisplayName = {
  [EventFormatSlugs.IN_PERSON]: "In Person",
  [EventFormatSlugs.ON_DEMAND]: "On Demand",
  [EventFormatSlugs.LIVESTREAM]: "Livestream",
}

function EventItems({ title, pageSlug, displayedDate, eventFormatSlug }) {
  if (!pageSlug) return null

  const formatName = EventFormatDisplayName[eventFormatSlug]

  return (
    <StyledEventItem className="">
      <span>
        <Link to={pageSlug}>
          <strong className="title">{title}</strong>
        </Link>{" "}
        {formatName ? (
          <>
            &mdash; <EventFormatLabel>{formatName}</EventFormatLabel>
          </>
        ) : null}
      </span>
      <Stack className="date" direction="row" alignItems="center" spacing={1}>
        <FaCalendar />
        <div>{`${displayedDate}`}</div>
      </Stack>
    </StyledEventItem>
  )
}
