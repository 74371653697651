import { useStaticQuery, graphql } from "gatsby"

import {
  ELEVENT_BASE_URL,
  EleventApiPaths,
} from "@common/constants/elevent-api.constants"

export function useEleventAPI() {
  const data = useStaticQuery(graphql`
    query EleventApiKey {
      site {
        siteMetadata {
          eleventApiKey
        }
      }
    }
  `)

  const { eleventApiKey = "" } = data.site.siteMetadata

  const getHeaders = () => {
    const headers = new Headers()
    headers.append(
      "Authorization",
      "Basic " + window.btoa("anystring:" + eleventApiKey)
    )

    return headers
  }

  const fetchAllShowtimeAvailabilities = async () => {
    try {
      const response = await fetch(
        `${ELEVENT_BASE_URL}/${EleventApiPaths.allShowtimeAvailability}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      )

      return await response.json()
    } catch (err) {
      console.error("error while fetching show time availabilities")
      console.error(err)

      return []
    }
  }

  const fetchAllShowtimeSummarizedAvailability = async () => {
    try {
      const response = await fetch(
        `${ELEVENT_BASE_URL}/${EleventApiPaths.allShowtimeSummarizedAvailability}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      )

      return await response.json()
    } catch (err) {
      console.error("error while fetching show time summarized availabilities")
      console.error(err)

      return []
    }
  }

  const fetchShowtimeAvailabilityByEvent = async eventId => {
    try {
      const response = await fetch(
        `${ELEVENT_BASE_URL}/${EleventApiPaths.showtimeAvailabilityByEventID(
          eventId
        )}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      )

      return await response.json()
    } catch (err) {
      console.error(`error while fetching ${eventId} event availability`)
      console.error(err)

      return []
    }
  }

  const fetchShowtimeAvailabilityByShowtime = async showtimeId => {
    try {
      const response = await fetch(
        `${ELEVENT_BASE_URL}/${EleventApiPaths.showtimeAvailabilityByShowtimeID(
          showtimeId
        )}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      )

      return await response.json()
    } catch (err) {
      console.error(`error while fetching ${showtimeId} show time availability`)
      console.error(err)

      return []
    }
  }

  const fetchShowtimeSectionAvailability = async showtimeId => {
    try {
      const response = await fetch(
        `${ELEVENT_BASE_URL}/${EleventApiPaths.showtimeSectionAllocationAvailability(
          showtimeId
        )}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      )

      return await response.json()
    } catch (err) {
      console.error(
        `error while fetching ${showtimeId} show time section availability`
      )
      console.error(err)

      return []
    }
  }

  return {
    fetchAllShowtimeAvailabilities,
    fetchAllShowtimeSummarizedAvailability,
    fetchShowtimeAvailabilityByEvent,
    fetchShowtimeAvailabilityByShowtime,
    fetchShowtimeSectionAvailability,
  }
}
