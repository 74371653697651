import React from "react"
import PropTypes from "prop-types"
// import { SearchField } from "../search_field"
import { SearchField } from "@components/@aaiff"
import { FilterByOptions } from "./filter_by_options"
import { DrawerSection } from "./drawer_section"
import { Button } from "../../../buttons"
import { ViewTypeToggle } from "./view_type_toggle"
import { ScheduleViewTypes } from "../../../../common/constants"

export function EventDrawerDesktop(props) {
  const {
    query,
    setSearchQuery,
    filterOptions,
    activeFilters,
    handleFilterChange,
    clearAllFilters,
    currentViewType,
    handleViewTypeChange,
    pageType,
  } = props

  return (
    <aside className="filtersDrawer">
      <div className="filtersDrawer__content">
        <DrawerSection label="View">
          <ViewTypeToggle
            currentViewType={currentViewType}
            handleViewTypeChange={handleViewTypeChange}
          />
        </DrawerSection>

        {currentViewType === ScheduleViewTypes.LIST ? (
          <>
            <DrawerSection label="Search For An Event">
              <SearchField
                defaultValue={query}
                onInputChange={setSearchQuery}
                isClearable
                label="Search by Title or Director"
              />
            </DrawerSection>

            <Button
              quiet
              variant="secondary"
              onClick={clearAllFilters}
              className="filtersDrawer__drawerBtn"
              offset="atStart"
            >
              Clear All Filters
            </Button>

            <DrawerSection label="Filter By">
              <FilterByOptions
                pageType={pageType}
                filterOptions={filterOptions}
                activeFilters={activeFilters}
                handleFilterChange={handleFilterChange}
              />
            </DrawerSection>
          </>
        ) : null}
      </div>
    </aside>
  )
}

EventDrawerDesktop.propTypes = {
  query: PropTypes.string,
  sortOrder: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
}
