import { useEffect, useState } from "react"
import moment from "moment-timezone"
import jstz from "jstz"

import { AAIFF_TIMEZONE } from "@common/constants"

export const useLocalTimezone = () => {
  const [currentTZ, setCurrentTZ] = useState("EST")

  // After the initial mount, check for the current timezone
  useEffect(() => {
    setCurrentTZ(getTimezone())
  }, [])

  /**
   * Finds the current timezone for the user. If the timezone cannot be
   * found, uses New York's timezone as the default (either EDT/EST)
   *
   * @returns the current timezone
   */
  const getTimezone = () => {
    /// store the timezone in session storage for quick access
    if (!sessionStorage.getItem("timezone")) {
      const timezone = jstz.determine().name() || AAIFF_TIMEZONE // use New York's timezone as the default

      sessionStorage.setItem("timezone", timezone)
    }

    return sessionStorage.getItem("timezone")
  }

  /**
   * Converts the given @dateTime to a moment time object converted
   * to the current time zone.
   *
   * @param {String} date either a string that represents a date
   * @param {String} [time] the string representing the time
   * @returns the converted moment time object or null if it is an invalid date
   */
  const convertToLocalTimeZone = (date, time) => {
    let dateString = date

    if (time) {
      // Dates from wordpress are all in EDT (GMT-400)
      dateString += ` ${time} -0400`
    }

    const momentTime = time
      ? moment(dateString.trim(), "L HH:mm:ss Z")
      : moment(dateString.trim(), "L")

    return momentTime.tz(currentTZ)
  }

  return {
    timezone: currentTZ,
    convertToLocalTimeZone,
  }
}
