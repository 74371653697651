import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { sortEntries } from "../../../../util/search_utils"
import { EventResultItem } from "./event_result_item"
import { NoContent } from "./no_content"
import { NoResults } from "./no_results"
import { SearchPageTypes } from "../../../../common/constants"

import { SortOrders } from "@common/constants"

export function EventsList(props) {
  const { events = [], isSearchActive = false, query } = props

  // filter out any invalid events or events without start dates
  const eventsToDisplay = events.filter(event => event && event.startDate)
  if (eventsToDisplay.length < events.length) {
    console.warn(
      `Ignored ${
        events.length - eventsToDisplay.length
      } events that were invalid or had invalid start date/times`
    )
  }

  // do not render the list if there are no events to display
  if (eventsToDisplay.length == 0) {
    return isSearchActive ? <NoResults query={query} pageType={SearchPageTypes.EVENTS} /> : <NoContent />
  }

  const [currPage, setCurrPage] = useState(1)

  useEffect(() => {
    setCurrPage(1)
  }, [events.length])

  const pages = Array.from(
    { length: Math.ceil(eventsToDisplay.length / 10) },
    (_, i) => i + 1
  )

  const scrollToTop = function () {
    window.scrollTo(0, 0)
  }

  const pagination = pages.map(page => {
    if (page == currPage) {
      return (
        <button
          className="pageButton active"
          onClick={() => setCurrPage(page) + scrollToTop()}
          key={page}
        >
          {page}
        </button>
      )
    } else {
      return (
        <button
          className="pageButton"
          onClick={() => setCurrPage(page) + scrollToTop()}
          key={page}
        >
          {page}
        </button>
      )
    }
  })

  const fullPagination = (
    <div>
      <button
        className="pageButton"
        onClick={() => setCurrPage(currPage - 1) + scrollToTop()}
        disabled={currPage - 1 < 1}
        key="previous"
      >
        Previous
      </button>
      {pagination}
      <button
        className="pageButton"
        onClick={() => setCurrPage(currPage + 1) + scrollToTop()}
        disabled={currPage + 1 > pages.length}
        key="next"
      >
        Next
      </button>
    </div>
  )

  const sortedResults = sortEntries(eventsToDisplay, SortOrders.DATE)

  const dateArray = []
  // get the 10 events to show for the current page
  const paginatedResults = sortedResults.slice(
    currPage * 10 - 10,
    currPage * 10
  )

  const finalResults = paginatedResults.map(entry => {
    let firstOfDate = dateArray.includes(entry.startDate)

    if (!firstOfDate) {
      dateArray.push(entry.startDate)
      return (
        <div key={entry.title}>
          <h3>{moment(entry.startDate).format("dddd, MMMM Do, YYYY")}</h3>
          <EventResultItem entry={entry} key={entry.id} />
        </div>
      )
    } else {
      return <EventResultItem entry={entry} key={entry.id} />
    }
  })

  return (
    <section>
      <ul className="eventList" style={{ width: "100%" }}>
        {finalResults}
      </ul>
      {pages.length > 1 ? fullPagination : null}
    </section>
  )
}

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  isSearchActive: PropTypes.bool,
}
