import React from "react"
import moment from "moment-timezone"
import styled from "@emotion/styled"
import { useMediaQuery } from "@mui/material"

import { Link } from "@components/link"
import ImageWrapper, { DefaultImageTypes } from "@components/image_wrapper"
import { EntryFormatLabel } from "@components/entry_format_label"

import { EventFormatSlugs, AAIFF_TIMEZONE } from "../../../common/constants"
import { CalendarIcon, VenueIcon, GlobeIcon } from "../../icons"
import { PC_VARIANT_NAMES, PC_CARD_VARIANTS } from "./program_card_variants"
import { useSelectFestivalByYear } from "@hooks"
import { TicketAvailabilityBadge } from "../../badges/ticket_availability_badge"

/**
 * The ProgramCard component displays one of several variants of a card representing an entry
 * on the Festival Program page, and can also be used in other sections such as "You May Like."
 *
 * Possible variants:
 * - SPOTLIGHT_DESKTOP,
 * - SPOTLIGHT_MOBILE,
 * - EVENT_DESKTOP,
 * - EVENT_MOBILE,
 * - YOU_MAY_LIKE,
 *
 * @imports `./variants.js` for style constants, and variant names.
 * @style cards have fixed heights, and unfixed widths so containers where component is used can decide spacing.
 *
 * @param {string} variantProp (prop) - Component self-determines its variant @variant as spotlight/event + desktop/mobile.
 * Prop can override explicitly; it is also how YOU_MAY_LIKE variant can be chosen.
 *
 * @constant {boolean} isOnDemand - checks if entry is "on-demand" format.
 * @constant {boolean} isMobile - uses useMediaQuery, at 560px breakpoint based on "mobile" in categories_row.jsx
 *
 */
export function ProgramCard(props) {
  const { entry, linkProps = {}, isSearchResult = false, variantProp } = props

  let filmEntry // Represents the film entry with `screeningEvents` array
  let programEntry // Represents the event entry being displayed

  if (entry.screeningEvents) {
    filmEntry = entry
    programEntry = entry.getFirstScreening(moment().tz(AAIFF_TIMEZONE))
  } else if (entry.mainFilm) {
    filmEntry = entry.mainFilm
    programEntry = entry
  } else {
    programEntry = entry
  }

  // All screening events related to the film/event, to be passed to EntryFormatLabel to decide
  // "on-demand available" vs "on-demand only" etc
  const screeningEvents = entry.screeningEventsCount
  ? entry.screeningEvents
  : entry.mainFilm?.screeningEventsCount
    ? entry.mainFilm.screeningEvents
    : [entry]

  const selectFestivalByYear = useSelectFestivalByYear()
  const currentFestival = selectFestivalByYear(entry.festivalYear)
  const isOnDemand = entry.eventFormatSlug === EventFormatSlugs.ON_DEMAND
  const formattedDateTime = formatDateTime(
    programEntry.startDate,
    programEntry.endDate,
    programEntry.startTime
  )

  // Breakpoint based on "mobile" defined for Carousel in `categories_row.jsx` and
  // the breakpoint on the program results page
  const isMobile = useMediaQuery("(max-width:640px)")
  const isSpotlight =
    programEntry.isEvent && programEntry.isTentpole && !isSearchResult
  let variantName;
  if (variantProp && PC_VARIANT_NAMES[variantProp]) {
    variantName = PC_VARIANT_NAMES[variantProp] // You May Also Like
  } else if (isSearchResult) {
    if (isMobile) {
      variantName = PC_VARIANT_NAMES.SEARCH_MOBILE // Search result - mobile
    } else {
      variantName = PC_VARIANT_NAMES.SEARCH_DESKTOP // Search result - desktop
    }
  } else {
    if (isSpotlight && !isMobile) {
      // Spotlight Desktop
      variantName = PC_VARIANT_NAMES.SPOTLIGHT_DESKTOP
    } else if (isSpotlight && isMobile) {
      // Spotlight Mobile
      variantName = PC_VARIANT_NAMES.SPOTLIGHT_MOBILE
    } else if (!isSpotlight && !isMobile) {
      // Event Desktop
      variantName = PC_VARIANT_NAMES.EVENT_DESKTOP
    } else if (!isSpotlight && isMobile) {
      // Event Mobile
      variantName = PC_VARIANT_NAMES.EVENT_MOBILE
    }
  }
  const variant = PC_CARD_VARIANTS[variantName]

  /**
   * A helper function that determines the title to show on the card.
   *
   * If the card is a spotlight variant or if the event does not have
   * a main film entry, show the event entry's title.
   *
   * If the card is not a spotlight variant and the event has a main
   * film entry, show the main film entry's title.
   *
   * @returns the title to display on the card
   */
  const getDisplayTitle = () => {
    if (isSpotlight || !filmEntry) {
      return programEntry.title
    } else {
      return filmEntry.title
    }
  }

  {/* Black chin only displays for non-spotlight, non-you-may-like, desktop cards */}
  const displayChin = (!programEntry.isTentpole || isSearchResult) && !isMobile && variant.NAME !== "YOU_MAY_LIKE";

  return (
    <StyledProgramCard
      to={`/${currentFestival.slug.toLowerCase()}/${
        filmEntry ? filmEntry.slug : programEntry.slug
      }`}
      isSpotlight={isSpotlight}
      isMobile={isMobile}
      variant={variant}
      {...linkProps}
    >
      <StyledHeader isSpotlight={isSpotlight} variant={variant}>
        <ImageWrapper
          media={
            filmEntry
              ? filmEntry.posterImageMedia
              : programEntry.posterImageMedia
          }
          alt={programEntry.title}
          objectFit="cover"
          defaultImageTypes={DefaultImageTypes.HORIZONTAL}
          className="programCard__header__poster"
        />
        <StyledProgramCardOverlay
          isSpotlight={isSpotlight}
          variant={variant}
          className="pc__overlay"
        />

        {/* Black chin only displays for non-spotlight, non-you-may-like, desktop cards */}
        {displayChin && <NonSpotlightChin variant={variant} className="pc__chin" />}
        <StyledProgramCardBody isSpotlight={isSpotlight} variant={variant}>
          <TicketAvailabilityBadge entry={entry} programVariant={variantName} />

          {/* If chin, then on-demand label goes right above the chin. Else, label is a fixed height above title, 
          which has variable "top" location due to variable number of lines (1-3) */}
          {displayChin ? 
            <>
              <EntryFormatLabel
                className="programCard__demand__info"
                screeningEvents={screeningEvents}
              />
              <h3 className="programCard__body__title">{getDisplayTitle()}</h3>
            </>
            :
            <div className="programCard__title__demand__container">
              <EntryFormatLabel
                className="programCard__demand__info"
                screeningEvents={screeningEvents}
              />
              <h3 className="programCard__body__title">{getDisplayTitle()}</h3>
            </div>
          }


          {/* YOU_MAY_LIKE variant does not display date, location/geoblocking info */}
          {!(variant.NAME === "YOU_MAY_LIKE") && (
            <div className="programCard__body__info">
              {programEntry.startDate && (
                <div className="programCard__body__date">
                  <CalendarIcon />
                  <div className="programCard__body__infoText">
                    {formattedDateTime}
                  </div>
                </div>
              )}
              {/* Possible verbiage: "All Regions" ? */}
              {isOnDemand ? (
                <div className="programCard__body__geoblocking">
                  <GlobeIcon />
                  <div className="programCard__body__infoText">
                    {programEntry.regionRestrictions
                      ? programEntry.regionRestrictions
                      : "No Restrictions"}
                  </div>
                </div>
              ) : (
                programEntry.venue && (
                  <div className="programCard__body__venue">
                    <VenueIcon />
                    <div className="programCard__body__infoText">
                      {programEntry.venue.name}
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </StyledProgramCardBody>
      </StyledHeader>
    </StyledProgramCard>
  )
}

const formatDateTime = (startDate, endDate, startTime) => {
  let formattedDateTime
  let startDateTime = moment(startDate, "MM/DD/YYYY")
  let endDateTime = moment(endDate, "MM/DD/YYYY")

  if (startDateTime.isSame(endDateTime, "day")) {
    const dateTime = moment(`${startDate} ${startTime}`, "MM/DD/YYYY HH:mm:ss")
    formattedDateTime = dateTime.format("ddd, MMMM D [at] h:mma")
  } else if (startDateTime.isSame(endDateTime, "month")) {
    formattedDateTime = `${startDateTime.format(
      "MMMM D"
    )} - ${endDateTime.format("D, YYYY")}`
  } else {
    formattedDateTime = `${startDateTime.format(
      "MMMM D"
    )} - ${endDateTime.format("MMMM D, YYYY")}`
  }

  return formattedDateTime
}

/**
 * Prevents "flickering" on iOS browsers upon swipe of ProgramCard in carousel, 
 * which occurs when gradient (StyledProgramCardOverlay) and text (StyledProgramCardBody) appears under image (ImageWrapper) when swiped.
 * 
 * This was due to the img element created by ImageWrapper's GatsbyImage applying transform:translateZ(0),
 * which on iOS devices very likely places it within a new stacking context that is above "sibling" elements in the original context,
 * regardless of siblings' z-index. Adding transform similarly to prevent ImageWrapper's sibling gradient and text components therefore will
 * have them join the same new context, without need for z-index. Using translateZ(0) as the transform value for consistency.
 * (Reference: apple documentation archive - Safari CSS Reference: -webkit-overflow-scrolling: touch)
 */
const preventiOSFlicker = "transform: translateZ(0);";

const StyledProgramCard = styled(Link)`
  ${props => {
    const { variant } = props
    return `
      height: ${variant.HEIGHTS.CARD};
  `
  }};
  display: flex;
  flex-direction: column;
  color: #fff;

  overflow: hidden;
  // the next 2 lines are required to make overflow hidden work on iPhones (legacy comment)
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin-bottom: 1.6rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

  // Negate browser default hover effects
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  &:hover {
    box-shadow: 6px 10px 20px 0px #0000004D;
  }
`

const StyledHeader = styled.header`
  ${props => {
    const { variant } = props
    return `
      height: ${variant.HEIGHTS.HEADER};
  `
  }};

  .programCard__header__poster {
    height: 100%;
  }

  // To override inherent display: inline-block generated by Gatsby plugin,
  // which can cause image to not fill container if plugin creates an
  // inner div with inline style of max-width that is less than container width.
  .gatsby-image-wrapper-constrained {
    display: block;
  }
`

const StyledProgramCardOverlay = styled.div`
  ${props => {
    const { variant } = props
    return `
      height: ${variant.HEIGHTS.OVERLAY};
      background: ${variant.BG_OVERLAY};
  `
  }};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  // Prevents this component from "flickering" or appearing underneath the img in <ImageWrapper>, upon swipe in carousel on iOS:
  ${preventiOSFlicker};
`

/**
 * Renders only on desktop non-spotlight program cards.
 * Separates the black background of most text for simpler layout of text info overlay
 */
const NonSpotlightChin = styled.div`
  ${props => {
    const { variant } = props
    return `
      height: ${variant.HEIGHTS.CHIN};
  `
  }};
  background: #000;
`

/**
 * Text overlay absolute positioned to bottom of StyledProgramCard.
 * Grid layout with grid items aligned to end, and grid items' contents aligned to their grid item's start
 * Takes a `textVariant` prop to determine styles for default, mobile, you-may-also-like.
 * Spotlight vs. non-spotlight rendering baked into each variant.
 */
const StyledProgramCardBody = styled.div`
  ${props => {
    const { variant } = props
    const vertPad = variant.GRID.PAD_CARD_VERT
    const mainWidth = variant.GRID.MAIN_WIDTH
    return `
      // Prevents this component from "flickering" or appearing underneath the img in <ImageWrapper>, upon swipe in carousel on iOS:
      ${preventiOSFlicker};

      position: absolute;
      bottom: 0;
      left: 0;

      // color: #fff;
      width: 100%;
      height: 100%;

      display: grid;
      align-content: end;
      align-items: start;

      grid-template:
        "   .             .                 .           "  ${variant.GRID.PAD_CARD_HORIZ}
        "   .             demand            .           "  ${variant.GRID.DEMAND_HEIGHT}
        "   .             .                 .           "  ${variant.GRID.PAD_DEMAND_TITLE}
        "   .             title             .           "  ${variant.GRID.TITLE_HEIGHT}
        "   .             .                 .           "  ${variant.GRID.PAD_TITLE_INFO}
        "   .             info              .           "  ${variant.GRID.INFO_HEIGHT}
        "   .             .                 .           "  ${variant.GRID.PAD_CARD_HORIZ}
        /   ${vertPad}    ${mainWidth}      ${vertPad}  ;

      .programCard__demand__info {
        grid-area: demand;
        font-size: ${variant.TEXT.INFO_FONT_SIZE};
        justify-content: ${variant.DEMAND.JUSTIFY_CONTENT};
        align-self: ${variant.DEMAND.ALIGN_SELF};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .programCard__body__title {
        grid-area: title;
        align-self: ${variant.TEXT.TITLE_ALIGN_SELF};
        width: 100%;
        font-size: ${variant.TEXT.TITLE_FONT_SIZE};
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        box-orient: vertical;
        white-space: normal;
      }

      .programCard__title__demand__container {
        grid-area: title; // when title & demand contained in a wrapper div, wrapper div occupies 'title' area in grid
        align-self: end;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .programCard__body__info {
        grid-area: info;
        display: flex;
        flex-direction: column;
        align-self: end; // because it is a grid item, align-self as grid item needed instead of justify-content: end
        font-size: ${variant.TEXT.INFO_FONT_SIZE};
        overflow: hidden;
        row-gap: ${variant.TEXT.INFO_ROW_GAP};
      }

      .programCard__body__date,
      .programCard__body__venue,
      .programCard__demand__info,
      .programCard__body__geoblocking {
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; // likely, text needs wrap in display: -webkit-box; to work?
        -webkit-line-clamp: 1;
        line-clamp: 1;

        &>*:first-child {
          flex-shrink: 0; // icons do not shrink when container width is small
          margin-right: ${variant.TEXT.ICON_MARG_RIGHT};
          font-size: ${variant.TEXT.ICON_FONT_SIZE};
        }
      }
    `
  }};
`
