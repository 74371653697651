import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link as GatsbyLink } from "gatsby"

/**
 * A Link component allows users to navigate to a different location. If the location
 * is internal, a Gatsby <Link> component will be used. If the location is another
 * host, an <OutboundLink> component with analytics will be used
 */
export const Link = forwardRef((props, ref) => {
  const { children, to, href, className = "", ...otherProps } = props

  if (!to && !href) {
    console.error(
      'Either a "to" or "href" prop is required to render the Link component'
    )
    return null
  }

  if (!!to) {
    return (
      <GatsbyLink
        to={to}
        className={`aaiff-Link ${className}`}
        ref={ref}
        {...otherProps}
      >
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <OutboundLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`aaiff-Link ${className}`}
        ref={ref}
        {...otherProps}
      >
        {children}
      </OutboundLink>
    )
  }
})

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
}
