import React from "react"
import PropTypes from "prop-types"

import SponsorRowItem from "./sponsor_row_item"
import { SponsorDto } from "@common/dto"

export default function SponsorRow({ sponsors, itemsPerRow }) {
  if (!sponsors || sponsors.length === 0) return null

  const sponsorItemList = sponsors.map(sponsor => {
    return (
      <SponsorRowItem
        key={sponsor.name}
        sponsor={sponsor}
        itemsPerRow={itemsPerRow}
      />
    )
  })

  return <ul className="sponsorRow__list">{sponsorItemList}</ul>
}

SponsorRow.propTypes = {
  itemsPerRow: PropTypes.number,
  sponsors: PropTypes.arrayOf(PropTypes.instanceOf(SponsorDto)),
}
