import React from "react"
import styled from "@emotion/styled"
import useMediaQuery from "@mui/material/useMediaQuery"
import { SearchPageTypes } from "../../../../common/constants"

export function NoResults({ query, pageType }) {
  const isMobile = useMediaQuery("(max-width:900px)")
  return (
    <StyledNoResultsContainer pageType={pageType} isMobile={isMobile}>
      <StyledNoResultsTitle>No Results</StyledNoResultsTitle>
      {query ? (
        <StyledNoResultsPara>
          We didn't find anything for "
          <StyledNoResultsQuery>{query}</StyledNoResultsQuery>"
        </StyledNoResultsPara>
      ) : (
        <StyledNoResultsPara>
          We didn't find anything for the specified filters
        </StyledNoResultsPara>
      )}
      <StyledNoResultsPara>Try another search</StyledNoResultsPara>
    </StyledNoResultsContainer>
  )
}

const StyledNoResultsContainer = styled.section`
  ${props =>
    props.pageType === SearchPageTypes.EVENTS || props.isMobile
      ? "margin-top: 4rem;"
      : ""}
  margin-bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1em;
  flex: 1;
`

const StyledNoResultsTitle = styled.h1`
  padding: 0;
  margin: 0;
  border: 0;
`

const StyledNoResultsPara = styled.p`
  padding: 0;
  margin: 0;
  border: 0;
`

const StyledNoResultsQuery = styled.span`
  font-weight: 700;
`
