import React, { useRef, useEffect, useState } from "react"

export default function ContactList({ contacts }) {
  const [loaded, updateLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      updateLoaded(true)
    }, 250)
  }, [])

  const contactListItems = contacts.map(({ department, emailAddress }, idx) => {
    return (
      <ContactListItem
        key={`contact-${idx}`}
        department={department}
        email={emailAddress}
        isLoaded={loaded}
      />
    )
  })

  return (
    <section className="contactSection">
      <div className="contactTable">
        <ul className="contactTable__list">{contactListItems}</ul>
      </div>
    </section>
  )
}

/**
 * This component displays the department's name with it's associated email. This
 * component includes a simple tactic for reducing the scrapability of the emails
 * by generating the link's href only when the user mouses over or touches the email.
 */
function ContactListItem({ department, email, isLoaded }) {
  const emailRef = useRef(null)
  /**
   * A helper function that generates and sets the href attribute of the email tag
   */
  const generateEmail = () => {
    emailRef.current.setAttribute("href", "mailto:" + email)
  }

  return (
    <li className="contactTable__listItem">
      <strong className="contactTable__listItemText contactTable__listItem--department">
        {department}
      </strong>
      <a
        className="contactTable__listItemText contactTable__listItem--email"
        ref={emailRef}
        onClick={isLoaded ? null : e => e.preventDefault()}
        onTouchStart={generateEmail}
        onFocus={generateEmail}
        onMouseOver={generateEmail}
        href="/#"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* Lazy load the email address after the initial render */}
        {isLoaded ? email : ""}
      </a>
    </li>
  )
}
