import React, { useRef } from "react"
import PropTypes from "prop-types"

import { Link, Button } from "@components/@aaiff"

import {
  DEFAULT_SIZE,
  DEFAULT_VARIANT,
  ButtonSizes,
  ButtonVariants,
} from "./Button.constants"

/**
 * A Link component that uses Button styles and props
 */
export function ButtonLink(props) {
  const {
    to,
    href,
    children,
    variant = DEFAULT_VARIANT,
    size = DEFAULT_SIZE,
    isDisabled = false,
    className = "",
    ...otherProps
  } = props
  const linkRef = useRef()

  // forward any button click events to the contained Link
  const handleClick = () => {
    if (linkRef.current) {
      linkRef.current.click()
    }
  }

  const fullClassName = `aaiff-Button aaiff-Button--${variant} aaiff-Button--size-${size} ${className}`

  return (
    <Button
      variant={variant}
      size={size}
      disabled={isDisabled}
      className={fullClassName}
      onClick={handleClick}
    >
      <Link
        disabled={isDisabled}
        to={to}
        href={href}
        ref={linkRef}
        {...otherProps}
      >
        {typeof children === "string" ? (
          <span className="aaiff-ButtonLabel">{children}</span>
        ) : (
          children
        )}
      </Link>
    </Button>
  )
}

ButtonLink.propTypes = {
  /**
   * The variant of the button to use
   */
  variant: PropTypes.oneOf(Object.values(ButtonVariants)),
  /**
   * The size of the button to use
   */
  size: PropTypes.oneOf(Object.values(ButtonSizes)),
  to: PropTypes.string,
  href: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
}
