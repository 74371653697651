/**
 * A helper function to convert each film taxonomy to a comma-seperated
 * string.
 *
 * Can include options to `append` or `prepend` text to the string. The
 * `useAnd` option adds the "and" word before the last item in the list
 *e
 * @param {Array} tags the taxonomy to convert to a string
 * @param {Object} [options] An object containing keys to `prepend` and/or `append`
 * text
 * @returns the list of a taxonomies as a comma-separated string
 */
export const convertTaxonomiesToString = (tags, options = {}) => {
  if (!Array.isArray(tags) || tags.length === 0) {
    return ""
  }

  const { append, prepend, useAnd = false } = options
  let returnStr = ""

  if (Array.isArray(tags)) {
    if (useAnd && tags.length > 1) {
      const lastItem = tags[-1]

      returnStr = tags.slice(0, -2).join(", ")
      returnStr += ", and " + lastItem
    } else {
      returnStr = tags.join(", ")
    }
  }

  if (prepend) {
    returnStr = prepend + " " + returnStr
  }

  if (append) {
    returnStr += " " + append
  }

  return returnStr.trim()
}

/**
 * Splits a comma-separated list of taxonomies. Each string is trimmed of any
 * leading and trailing spaces.
 *
 * @param {String} taxStr the string containing a list of taxonomies
 * @returns
 */
export const extractTaxonomiesFromString = taxStr => {
  if (!taxStr) {
    return []
  }

  return taxStr.split(",").map(tax => tax.trim())
}

/**
 * Splits the comma-separated name string into an array of names
 *
 * @param {String} namesString a comma-separated string of names
 * @returns the array of names
 */
export const parseCastCrewString = namesString => {
  if (namesString && typeof namesString === "string") {
    return namesString.split(",").map(name => name.trim())
  }

  return []
}
