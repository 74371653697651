export function camelCase(string) {
  if (typeof string !== "string") {
    return string
  }

  return string.replace(/^([A-Z])|[\s-_](\w)/g, function (
    match,
    p1,
    p2,
    offset
  ) {
    if (p2) return p2.toUpperCase()
    return p1.toLowerCase()
  })
}

// Modified version of the unescape function from underscore.js
// List of HTML entities for escaping
const unescapeMap = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&#x27;": "'",
  "&#x60;": "`",
}
//Function for escaping and unescaping strings to / from HTML interpolation.
function createEscaper(map) {
  var escaper = function (match) {
    return map[match]
  }

  // Regexes for identifying a key that needs to be escaped.
  var source = "(?:" + Object.keys(map).join("|") + ")"
  var testRegexp = RegExp(source)
  var replaceRegexp = RegExp(source, "g")
  return function (string) {
    string = string == null ? "" : "" + string
    return testRegexp.test(string)
      ? string.replace(replaceRegexp, escaper)
      : string
  }
}
export const unescapeHTMLEntities = createEscaper(unescapeMap)

// adds "st", "nd", "rd", or "th" suffix to numbers
export function getOrdinalString(i) {
  if (typeof i === "string") {
    i = parseInt(i)
  }

  let j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return "st"
  }
  if (j === 2 && k !== 12) {
    return "nd"
  }
  if (j === 3 && k !== 13) {
    return "rd"
  }
  return "th"
}
