import React from "react"
import styled from "@emotion/styled"
import { Title } from "../title"
import { TrailerPlayer } from "../../trailer_player"
import { useCurrentFestival } from "@hooks"

const TrailerRoot = styled.section`
  width: 100%;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1180px;
  margin: 0 auto;
  padding: 4% 0;

  @media (max-width: 1220px) {
    width: 100%;
    padding: 4% 40px;
  }

  @media (max-width: 600px) {
    height: 100%;
    padding: 40px 20px;
  }
`

const VideoContainer = styled.article`
  width: 100%;
  height: auto;
  margin-top: 1rem;
`
export function FestivalTrailer(props) {
  const {
    title: sectionTitle,
    isHidden,
    useCurrentFestivalTrailer,
    customUrl,
  } = props

  const currentFestival = useCurrentFestival()
  const videoUrl =
    useCurrentFestivalTrailer && currentFestival?.trailerUrl
      ? currentFestival.trailerUrl
      : customUrl

  // Early exit if `isHidden` is true or null/undefined or if there
  // is no valid trailer url
  if (isHidden || !videoUrl) {
    if (!videoUrl) {
      console.warn("[FestivalTrailer] hidden because of invalid url")
    }

    return null
  }

  return (
    <TrailerRoot>
      <Content>
        <Title>{sectionTitle}</Title>
        <VideoContainer>
          <TrailerPlayer videoUrl={videoUrl} />
        </VideoContainer>
      </Content>
    </TrailerRoot>
  )
}
