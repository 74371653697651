import React from "react"

import { useMediaByBreakpoint } from "@hooks"

import ImageWrapper, { DefaultImageTypes } from "../image_wrapper"

export function BannerImage(props) {
  const {
    media: { desktop, tablet, mobile },
    alt,
    className = "",
    defaultImageType = DefaultImageTypes.NONE,
    objectFit,
  } = props

  const bannerMedia = useMediaByBreakpoint({ desktop, tablet, mobile })

  if (!bannerMedia && defaultImageType === DefaultImageTypes.NONE) {
    return null
  }

  return (
    <figure className={`banner ${className}`}>
      <ImageWrapper
        media={bannerMedia}
        className="banner__image"
        alt={alt}
        defaultImageType={defaultImageType}
        loading="eager"
        objectFit={objectFit}
      />
    </figure>
  )
}
