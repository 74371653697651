import React from "react"
import { graphql } from "gatsby"

import InnerHTML from "../components/inner_html/inner_html"
import ContactList from "../components/contact_page/contact_list"
import { FestivalPageLayout } from "@components/layout"
import { PageHead } from "@components/page_head"

export default function ContactPage({ data }) {
  const {
    content,
    festivalPageMetadata: { title },
    wipMode: { isWipModeActive, wipModeSettings },
    festivalPageContact,
  } = data.wpFestivalPage

  return (
    <FestivalPageLayout
      title={title}
      isWipModeActive={isWipModeActive}
      wipModeSettings={wipModeSettings}
    >
      <InnerHTML htmlContent={content} isFestivalPage={true} />

      <ContactList contacts={festivalPageContact.contact} />
    </FestivalPageLayout>
  )
}

export const Head = ({ data }) => {
  const {
    festivalPageMetadata: { title, description },
  } = data.wpFestivalPage

  return <PageHead title={title} description={description} />
}

export const query = graphql`
  query ContactPageQuery {
    wpFestivalPage(slug: { eq: "contact" }) {
      content
      festivalPageContact {
        contact {
          department
          emailAddress
        }
      }
      festivalPageMetadata {
        description
        title
      }
      ...PageWipModeSettings
    }
  }
`
