/**
 * These slugs should match the ones on the Event - Formats page in WordPress.
 *
 * Note: Make sure to keeps this enum up-to-date as the WordPress event formats change
 *
 * @See https://www.asiancinevision.org/wp-admin/edit-tags.php?taxonomy=event_formats&post_type=events
 */
export const EventFormatSlugs = {
  IN_PERSON: "in-person",
  LIVESTREAM: "livestream",
  ON_DEMAND: "on-demand",
}

/**
 * These slugs should match the one on the Event - Types page in WordPress
 *
 * Note: Make sure to keeps this up-to-date as the WordPress event types change
 *
 * @See https://www.asiancinevision.org/wp-admin/edit-tags.php?taxonomy=event_types&post_type=events
 */
export const EventTypeSlugs = {
  SPECIAL_EVENT: "special_event",
  FEATURE: "feature-screening",
  MASTERCLASS: "masterclasses",
  PANEL_WORKSHOP: "panels",
  Q_A: "qa",
  SHORTS_PROGRAM: "shorts_program",
  SHOWCASE: "showcase",
  SPECIAL_PRESENTATION: "special_presentation",
  SOCIAL_EVENTS: "social_event",
  TENTPOLES: "tentpole", // Also known as Spotlights
}
