import React, { useState } from "react"
import PropTypes from "prop-types"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { EventResultItem } from "./event_result_item"

import "react-big-calendar/lib/css/react-big-calendar.css"

import moment from "moment"

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

export function EventsCalendar(props) {
  const [state, setState] = useState({
    selectedEvent: null,
  })
  const formattedEvents = props.events.map(event => {
    let formattedEvent = event
    let startDateTime = Date.parse(event.startDate + " " + event.startTime)
    let endDateTime = Date.parse(event.endDate + " " + event.endTime)
    formattedEvent.start = startDateTime
    formattedEvent.end = endDateTime

    return formattedEvent
  })

  const selected = function (event) {
    setState({
      selectedEvent: event,
    })
  }

  const eventDetail = state.selectedEvent ? (
    <ul className="eventList" style={{ width: "100%" }}>
      <EventResultItem
        entry={state.selectedEvent}
        key={state.selectedEvent.id}
      />
    </ul>
  ) : (
    <div></div>
  )

  return (
    <div className="calendarContainer">
      <div className="calendar">
        <Calendar
          localizer={localizer}
          events={formattedEvents}
          startAccessor="start"
          endAccessor="end"
          popup={true}
          selectable={true}
          onSelectEvent={selected}
          views={["month"]}
          showMultiDayTimes={true}
        />
      </div>
      <div class="calResultSection">{eventDetail}</div>
    </div>
  )
}
