import React, { useState } from "react"
import styled from "@emotion/styled"
import { InputContainer, BaseInput, Label, Subtext } from "./inputs.styled"

import { useInput } from "./inputs.hook"

export function TextField(props) {
  const {
    label,
    description,
    errorMessage,
    width = "100%",
    isValid = true,
    isDisabled = false,
    className = "",
    value,
    defaultValue,
    onInputChange,
    ...otherProps
  } = props

  const { inputValue, handleInputChange, inputRef } = useInput({
    value,
    defaultValue,
    onInputChange,
  })

  return (
    <InputContainer className="aaiff-TextField" width={width}>
      <BaseInput
        ref={inputRef}
        value={inputValue}
        className={`aaiff-TextField-input ${className}`}
        onChange={handleInputChange}
        {...otherProps}
      />
      {!!label && (
        <Label className={`aaiff-TextFieldLabel ${value ? "is_filled" : ""}`}>
          <NoBreakSpan>{label}</NoBreakSpan>
        </Label>
      )}
      {!!description && (
        <Subtext className="aaiff-HelpText">{description}</Subtext>
      )}
    </InputContainer>
  )
}

// Due to deprecation of <nobr>
const NoBreakSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`