import { useStaticQuery, graphql } from "gatsby"

import { PQueue } from "../util/PriorityQueue"
import { filterEventWithRepeatedFilms } from "../util/entry_utils"
import { WpDataFactory } from "../common/factories/WPDataFactory"

/**
 * The types of tags to compare when calculating the similarity score
 */
const TagTypes = {
  KEYWORD: "KEYWORD",
  EVENT_TYPE: "EVENT_TYPE",
  EVENT_FORMAT: "EVENT_FORMAT",
  FILM_FORMAT: "FILM_FORMAT",
  FILM_TYPE: "FILM_TYPE",
  GENRE: "GENRE",
  LANGUAGE: "LANGUAGE",
  COUNTRY: "COUNTRY",
}

/**
 * The multiplier/weights of each tag type when calculating the similarity score
 */
const TagWeights = {
  [TagTypes.KEYWORD]: 0.5,
  [TagTypes.EVENT_TYPE]: 1,
  [TagTypes.EVENT_FORMAT]: 1,
  [TagTypes.FILM_FORMAT]: 0.3,
  [TagTypes.FILM_TYPE]: 0.2,
  [TagTypes.GENRE]: 0.12,
  [TagTypes.LANGUAGE]: 0.15,
  [TagTypes.COUNTRY]: 0.15,
}

/**
 * A hook used to find the 3 most similar events to `entryToMatch`.
 * The most recommended events are calculated by comparing each
 * event's attributes with `entryToMatch`.
 *
 * The following attributes are compared:
 * - Keywords
 * - Event Type
 * - Film Format
 * - Film Type
 * - Genres
 * - Languages
 * - Country
 *
 * @param {Entry} entryToMatch the entry to compare the all other entries in the
 * same festival year
 * @return {Array<Entry>} the 3 recommended events
 */
export function useRecommendedEvents(entryToMatch) {
  const data = useStaticQuery(graphql`
    {
      allWpEvent {
        nodes {
          id
          slug
          ...EventGeneralInformation
          ...EventTaxonomies
          ...EventFilms
        }
      }
    }
  `)

  /**
   * Finds the 3 most recommended events compared to `entryToMatch` within the same
   * festival year
   *
   * @return {Array<Entry>} The top 3 recommended entries
   */
  const getRecommendedEvents = () => {
    const { nodes: eventNodes } = data.allWpEvent
    const eventEntries = eventNodes
      .map(node => WpDataFactory.instantiateWpEntry(node))
      .filter(eventEntry => {
        if (
          eventEntry &&
          eventEntry?.festivalYear === entryToMatch.festivalYear
        ) {
          // exclude any entries that contain any of the films featured in
          // the event entry to match
          if (
            entryToMatch.hasFilmAttributes &&
            ((entryToMatch.isEvent &&
              eventEntry.containsFilmIds(entryToMatch.allFilmIds)) ||
              (!entryToMatch.isEvent &&
                eventEntry.containsFilmIds([entryToMatch.id])))
          ) {
            return false
          }

          return true
        }

        return false
      })

    // Order each event by their similarity score. Higher scores will be ordered
    // ahead of others
    const scoreQueue = new PQueue()
    eventEntries.forEach(event => {
      if (event.id !== entryToMatch.id) {
        const score = calculateEntrySimilarityScore(event)

        scoreQueue.enqueue(event, score)
      }
    })

    // return scoreQueue.toArray().slice(0, 3)
    return filterEventWithRepeatedFilms(scoreQueue.toArray(), 3)
  }

  /**
   * Calculates the similarity score of the `entry` compared to `entryToMatch` by
   * first finding the number of matching tags and the multiplying those numbers
   * by their respective tag weight.
   *
   * The higher the score, the closer the entries are in common. The lower the score,
   * the less relevant the entries are to each other.
   *
   * @param {Entry} entry the entry to calculate a similarity score
   * @return {Number} The similarity score of the `entry`
   */
  const calculateEntrySimilarityScore = entry => {
    const tagCounts = getMatchingTagCounts(entry)

    let score = 0
    Object.keys(TagTypes).forEach(type => {
      score += TagWeights[type] * tagCounts[type]
    })

    return round(score, 5)
  }

  /**
   * Rounds the `value` to the decimal place specified by `precision`
   *
   * @param {Number} value the value to round
   * @param {Number} precision the number decimal places this should round to. Default: `0`
   * @return {Number} the number rounded to the `precision` decimal place
   */
  function round(value, precision = 0) {
    var multiplier = Math.pow(10, precision)
    return Math.round(value * multiplier) / multiplier
  }

  /**
   * A helper function for tabulating the number of matching tags that an `entry`
   * has with `entryToMatch`. This function checks for each entries keywords. If
   * both entries are Events, their event type is compared. Finally, if both entries
   * have film attributes, those attributes are then compared.
   *
   * @param {Entry} entry the Entry class to check against `entryToMatch`
   * @return {Object} the object containing the counts for each tag type
   */
  function getMatchingTagCounts(entry) {
    const tagCounts = {
      [TagTypes.KEYWORD]: 0,
      [TagTypes.EVENT_TYPE]: 0,
      [TagTypes.EVENT_FORMAT]: 0,
      [TagTypes.FILM_FORMAT]: 0,
      [TagTypes.FILM_TYPE]: 0,
      [TagTypes.GENRE]: 0,
      [TagTypes.LANGUAGE]: 0,
      [TagTypes.COUNTRY]: 0,
    }

    // Check number of shared keywords
    const toMatchKeywords = new Set(entryToMatch.keywords)
    tagCounts[TagTypes.KEYWORD] = entry.keywords.filter(keyword =>
      toMatchKeywords.has(keyword)
    ).length

    if (entryToMatch.isEvent) {
      if (entryToMatch.eventType === entry.eventType) {
        tagCounts[TagTypes.EVENT_TYPE] += 1
      }

      if (entryToMatch.eventFormat === entry.eventFormat) {
        tagCounts[TagTypes.EVENT_FORMAT] += 1
      }
    }

    /**
     * Compares both entries by creating sets for each film attribute and then
     * getting the number of unique matches.
     */
    if (entryToMatch.hasFilmAttributes && entry.hasFilmAttributes) {
      if (entryToMatch.filmFormat === entry.filmFormat) {
        tagCounts[TagTypes.FILM_FORMAT] += 1
      }

      const toMatchFilmTypes = new Set(entryToMatch.filmTypes)
      const toMatchGenres = new Set(entryToMatch.genres)
      const toMatchLanguages = new Set([
        ...entryToMatch.languages,
        ...entryToMatch.subtitledLanguages,
      ])
      const toMatchCountries = new Set(entryToMatch.countries)

      tagCounts[TagTypes.FILM_TYPE] = new Set(
        [...entry.filmTypes].filter(type => toMatchFilmTypes.has(type))
      ).size
      tagCounts[TagTypes.GENRE] = new Set(
        [...entry.genres].filter(genre => toMatchGenres.has(genre))
      ).size
      tagCounts[TagTypes.LANGUAGE] = new Set(
        [...entry.languages, ...entry.subtitledLanguages].filter(language =>
          toMatchLanguages.has(language)
        )
      ).size
      tagCounts[TagTypes.COUNTRY] = new Set(
        [...entry.countries].filter(country => toMatchCountries.has(country))
      ).size
    }

    return tagCounts
  }

  return getRecommendedEvents()
}
