import React, { useState } from "react"
import PropTypes from "prop-types"
import { SearchField } from "@components/@aaiff"
import { SortByOptions } from "./sort_by_options"
import { DrawerSection } from "./drawer_section"
import { Button } from "../../../buttons"
import { FilterSelects } from "./filter_selects"
import styled from "@emotion/styled"
import { FilterBadges } from "./filter_badges"
import Stack from "@mui/material/Stack"

const StyledDesktopProgramDrawer = styled.aside`
  margin-bottom: 4rem;
`

const StyledSearchWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  & > div {
    margin-bottom: 0; // override InputContainer default margin-bottom, margin-bottom should be on StyledSearchWrapper
  }
`

const StyledToggleFilterButton = styled.button`
  white-space: nowrap;
  text-transform: none;
  font-weight: normal;
  padding: 0;
  margin: 0 0 0 1rem;
  background: none;
  box-shadow: none;
  font-size: 1em;
  // align-self: end;
  // justify-self: end;
  display: flex;
  justify-content: end;
  align-items: end;
`
const StyledClearFilterButton = styled.div`
  display: flex;
  align-items: center;
  .aaiffBtn {
    text-decoration: underline;
    text-transform: none;
    font-weight: normal;
    padding: 0;
    margin: 0;
    left: 0;
    .aaiffBtn__content {
      margin: 0;
    }
  }
`
const StyledSortByContainer = styled.div`
  margin-bottom: 1rem;
  .filtersDrawer__section {
    display: flex;
    justify-content: end;
    align-items: center;
    min-width: 25%;
    margin-left: auto;
    margin-bottom: 0;
    .filtersDrawer__title {
      font-size: 1rem;
      margin: 0 1rem 0 0;
    }
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
`

export function DrawerDesktop(props) {
  const {
    query,
    setSearchQuery,
    sortOrder,
    setSortOrder,
    filterOptions,
    activeFilters,
    handleFilterChange,
    isSearchActive,
    filterBadges,
    setFilterBadges,
    clearAllFilters,
  } = props
  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  return (
    <StyledDesktopProgramDrawer className="filtersDrawer">
      <div className="filtersDrawer__content">
        <StyledSearchWrapper>
          <SearchField
            defaultValue={query}
            onInputChange={setSearchQuery}
            label="Search by Title or Director"
            isClearable
            pageType={"program"}
          />
          <StyledToggleFilterButton
            onClick={toggleFilters}
            className="toggleFilters__button"
          >
            {showFilters ? "Hide filters" : "Show filters"}
          </StyledToggleFilterButton>
        </StyledSearchWrapper>

        {showFilters && (
          <FilterSelects
            filterOptions={filterOptions}
            activeFilters={activeFilters}
            handleFilterChange={handleFilterChange}
            filterBadges={filterBadges}
            setFilterBadges={setFilterBadges}
          />
        )}

        {(filterBadges.length > 0 || isSearchActive) && (
          <StyledSortByContainer isHidden={!isSearchActive}>
            <DrawerSection label="Sort By">
              <SortByOptions
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            </DrawerSection>
          </StyledSortByContainer>
        )}

        {filterBadges.length > 0 && (
          <Stack direction="row" alignItems="center" flexWrap="wrap">
            <FilterBadges
              handleFilterChange={handleFilterChange}
              filterBadges={filterBadges}
              setFilterBadges={setFilterBadges}
              activeFilters={activeFilters}
            >
              <StyledClearFilterButton>
                <Button
                  quiet
                  variant="secondary"
                  onClick={() => {
                    clearAllFilters()
                    setFilterBadges([])
                  }}
                  className="filtersDrawer__drawerBtn"
                >
                  Clear all filters
                </Button>
              </StyledClearFilterButton>
            </FilterBadges>
          </Stack>
        )}
      </div>
    </StyledDesktopProgramDrawer>
  )
}

DrawerDesktop.propTypes = {
  query: PropTypes.string,
  sortOrder: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
}
