import styled from "@emotion/styled"

import { Button } from "@components/@aaiff"

export const InputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  height: 56px;
  width: ${props => props.width || "fit-content"};
  margin-bottom: 1rem;
  padding: 6px 16px;
  border-bottom: 1px solid #000;
  border-radius: 3px;
  background: #fff;

  text-overflow: ellipsis;
  overflow: hidden;

  &:focus-within label {
    transform: translate(0, 6px) scale(calc(2 / 3));
    width: calc((100% - 32px) * 4 / 3);
  }
`

export const BaseInput = styled.input`
  height: 24px;
  max-height: 24px;
  width: 100%;
  flex-grow: 1;

  font-weight: 400;
  size: 16px; // TODO: replace with theme font size
  line-height: 1.5;
  color: #0a0a0a; // TODO: replace with black

  border: none;
  background: none;
`
export const Label = styled.label`
  display: flex;
  align-items: center;

  pointer-events: none;
  position: absolute;
  left: 16px;
  top: 0;
  width: calc(100% - 32px);
  margin-right: 16px;

  transform: translate(0, 15px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  font-size: 16px;
  line-height: 1.5;
  color: #666666; // TODO: replace with grey 60

  & .aaiff-SearchIcon {
    margin-right: 8px;
  }

  &.is_filled {
    transform: translate(0, 6px) scale(calc(2 / 3));
    width: calc((100% - 32px) * 4 / 3);
  }

  @media (min-width: 1060px) {
    top: 0;
  }
`
export const Subtext = styled.span`
  position: absolute;
  top: 62px;
  margin: 0 16px;
  width: ${props => `calc(${props.width} - 32px)`};
  height: 18px

  font-size: 12px; // TODO: replace with theme font size
  line-height: 1.5;
  text-align: left;
`

export const BaseClearButton = styled(Button)`
  &.aaiff-Button {
    position: absolute;
    bottom: 0;
    right: 0;

    & ~ .aaiff-TextField-input {
      width: calc(100% - 16px);
    }
  }
`
