export const ButtonSizes = {
  XS: "XS",
  S: "S",
  M: "M",
  L: "L",
}

export const ButtonVariants = {
  PRIMARY_1: "primary1",
  PRIMARY_2: "primary2",
  BLACK: "black",
  WHITE: "white",
  GHOST: "ghost",
  OVER_BACKGROUND: "overBackground",
}

export const DEFAULT_VARIANT = ButtonVariants.BLACK
export const DEFAULT_SIZE = ButtonSizes.M
