import { useStaticQuery, graphql } from "gatsby"

import { EleventService } from "@services/EleventService"

export function useEleventService() {
  const data = useStaticQuery(graphql`
    query EleventWidgetKey {
      site {
        siteMetadata {
          eleventWidgetKey
        }
      }
    }
  `)
  const { eleventWidgetKey } = data.site.siteMetadata
  const eleventService = new EleventService()

  // If the service hasn't been initialized yet, initialize it
  // with the widget key
  if (!eleventService.isInitialized) {
    eleventService.init(eleventWidgetKey)
  }

  return eleventService
}
