import React from "react"
import GridColumnsItem from "./grid_columns_item"

export default function GridColumns({ visibleRange, datesList, eventsByDate }) {
  const columnItems = []

  for (let idx = visibleRange.start; idx < visibleRange.end; idx++) {
    const date = datesList[idx]

    columnItems.push(
      <GridColumnsItem
        key={`col-${idx}`}
        events={eventsByDate[date]}
        isOddColumn={idx % 2 !== 0}
      />
    )
  }

  return <ul className="schedule__grid">{columnItems}</ul>
}
