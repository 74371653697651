import React from "react";
import styled from "@emotion/styled"
import InnerHTML from "../../../inner_html/inner_html";
import { EventFormatSlugs } from "../../../../common/constants";
import moment from "moment-timezone";
import { useLocalTimezone } from "../../../../hooks";

/**
 * EventItineraryRow component displays a row with a timestamp, followed by
 * the related activity name, optional description, and location.
 * 
 * NOTE REGARDING TIME: 
 * Takes an optional argument to forcibly convert times to the user's local timezone.
 * Default behavior:
 * - on-demand, livestream: user's local timezone;
 * - in-person: EDT.
 * 
 * @param {Object} rowData - prop: row data
 * @param {string} eventFormatSlug - prop: whether event is "in-person", "livestream", "on-demand"
 * @param {string} startDate - prop: date of the event
 * @param {boolean} convertToLocalTimeZoneEnabled  prop - optional: force display of user's TZ
 */
export function EventItineraryRow( props ) {
	const { rowData, eventFormatSlug, startDate, convertToLocalTimeZoneEnabled } = props;
	const { description, location, name, time } = rowData;
	const { timezone, convertToLocalTimeZone } = useLocalTimezone();

	/**
	 * Time string formatting:
	 * - on-demand, livestream: user's local TZ
	 * - in-person: EDT, unless convertToLocalTimeZoneEnabled === true
	 */
	const formatString = "h:mm A";
	let timeString12hr;
	switch(eventFormatSlug) {
		case EventFormatSlugs.ON_DEMAND:
		case EventFormatSlugs.LIVESTREAM:
			timeString12hr = convertToLocalTimeZone(startDate, time).format(formatString);
			break;
		case EventFormatSlugs.IN_PERSON:
			if(convertToLocalTimeZoneEnabled) {
				timeString12hr = convertToLocalTimeZone(startDate, time).format(formatString);
			} else {
				timeString12hr = moment(`${startDate} ${time}`).format(formatString);
			}
			break;
	}
	// Convert format "10:00 AM" to "10:00am"
	timeString12hr = timeString12hr.split(" ").map((part, idx) => {
		return (idx === 0) ? part : part.toLowerCase();
	}).join("");

	return (
		<EIRow>
			<TimeLabel>{timeString12hr}</TimeLabel>
			<ItemDetails>
				<ItemTextBox>
					<ItemName>{name}</ItemName>
					<ItemDescription htmlContent={description} />
				</ItemTextBox>
				<ItemLocation>{location}</ItemLocation>
			</ItemDetails>
		</EIRow>
	)
}

const EIRow = styled.li`
	display: flex;
`

const TimeLabel = styled.label`
	width: 7em;
	flex-shrink: 0;
	flex-grow: 0;

	@media only screen and (max-width: 900px) {
		width: 6em;
	}
`

const ItemDetails = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.25em;
`

const ItemTextBox = styled.div`
	display: flex;
	flex-direction: column;
`

const ItemName = styled.p`
	font-weight: 700;
	line-height: 1.25em;
`

const ItemDescription = styled(InnerHTML)`
	p:last-child {
		margin-bottom: 0;
	}
`

const ItemLocation = styled.p`
	font-size: 0.875em;
	line-height: 1.3125em;
	&::before {
		content: "at ";
	}
`