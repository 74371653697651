import React from 'react';
import InnerHTML from '../inner_html/inner_html';
import styled from "@emotion/styled";

const ArrivingSection =styled('div')`
    background-color:#F2F2F2;
    display:flex;
    flex-direction:columns;
    justify-content:center;
    align-items:center;
    padding:3rem 1rem;
`
const Content = styled('div')`
    max-width:1180px;
`

const Title = styled('h2')`
    font-weight:bold;
    font-size:1.5rem;
    text-align:center;
`

export function TicketingArriving({arrivalInfo}) {

    const {title, content} = arrivalInfo;
    return (
        <ArrivingSection>
            <Content>
                <Title>{title}</Title>
                <InnerHTML
                    htmlContent={content}
                    isFestivalPage={false}
                />
            </Content>
        </ArrivingSection>
    )
}
