import React from "react"
import { useFooterLinks } from "../../../hooks"
import { LinkSectionSlugs } from "../../../common/constants/footer.constants"
import { FooterNewsletterField } from "./footer_newsletter_field"
import { FooterLogos } from "./footer_logos"
import { FooterSocialLinks } from "./footer_social_links"
import { Accordion } from "../../accordion"

const accordionOrder = [
  LinkSectionSlugs.FAQ,
  LinkSectionSlugs.FESTIVAL_INFO,
  LinkSectionSlugs.SUPPORT,
]

export function FooterContentMobile({ socialLinks, footerLinkOptions }) {
  const sections = accordionOrder.map(section => footerLinkOptions[section])

  return (
    <div className="footer__content _is-mobile">
      <Accordion sections={sections} className="footer__accordion" />

      <FooterNewsletterField />
      <FooterSocialLinks socialLinks={socialLinks} />
      <FooterLogos />
    </div>
  )
}
