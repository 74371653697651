import React from "react"

export default function InnerHTML({ htmlContent, isFestivalPage, className }) {
  if (!htmlContent) return null

  //remove styling from any links
  const parseHtmlForButtonStyling = htmlContent.replace(
    /(<a.*)(style=".*?\")/g,
    "$1"
  )

  //add button for download buttons
  const addButtonToDownloads = parseHtmlForButtonStyling.replace(
    /(<a h.*?)(class="wp-block.*?button")(.*<\/a>)/g,
    "<button class='aaiff-Button aaiff-Button--black aaiff-Button--size-S'>$1$3</button>"
  )

  //add buttons to link buttons
  const addButtonToHtmlButtons = addButtonToDownloads.replace(
    /(<a )class="wp-block.*?button.*?"(.*<\/a>)/g,
    "<button class='aaiff-Button aaiff-Button--black aaiff-Button--size-M'>$1$2</button>"
  )

  //add custom class for links
  const parsedHtmlForLinks = addButtonToHtmlButtons.replace(
    /<a ([^c])/g,
    '<a class="aaiff-Link" $1'
  )

  if (isFestivalPage) {
    return (
      <section>
        <div
          className={`wpContent festivalPage ${className || ""}`}
          dangerouslySetInnerHTML={{
            __html: parsedHtmlForLinks.split("\n").join(""),
          }}
        />
      </section>
    )
  }

  return (
    <div
      className={`wpContent ${className || ""}`}
      dangerouslySetInnerHTML={{
        __html: parsedHtmlForLinks.split("\n").join(""),
      }}
    />
  )
}
