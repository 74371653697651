import React from "react"

import { Link } from "../link"

import ImageWrapper, { DefaultImageTypes } from "../image_wrapper"

export default function SponsorRowItem({ sponsor, itemsPerRow = 6 }) {
  // TODO: handle sponsors with no website url

  return (
    <li className={`sponsorRowItem col-${itemsPerRow}`}>
      <Link className="sponsorRowItem__link" to={sponsor.websiteUrl} external>
        {sponsor.logo ? (
          <ImageWrapper
            media={sponsor.logo}
            alt={sponsor.name}
            className="sponsorRowItem__linkImage"
            objectFit="contain"
            defaultImageType={DefaultImageTypes.NONE}
          />
        ) : (
          <span className="sponsorRowItem__linkCaption sponsorRowItem__linkCaption--visible">
            {sponsor.name}
          </span>
        )}
      </Link>
    </li>
  )
}
