import { useStaticQuery, graphql } from "gatsby"
import { useState, useEffect, useMemo } from "react"
import moment from "moment"

import { EleventService } from "@services"

import { useTicketingItems } from "../../../../hooks"
import { EventFormatSlugs } from "../../../../common/constants"

export const TicketSectionState = {
  INITIAL: "INITIAL",
  OFF_SALE: "OFF_SALE",
  ON_SALE: "ON_SALE",
}

/**
 * A helper function that checks if the end date of the event entry has already
 * passed.
 *
 * @return {boolean} returns `true` if the event has already ended or `false` if
 * the event has not ended
 */
const _checkIfEventHasPassed = entry => {
  const eventEnd =
    entry.eventFormatSlug === EventFormatSlugs.ON_DEMAND
      ? moment(`${entry.endDate} 23:59:59`)
      : moment(`${entry.endDate} ${entry.endTime}`)

  return eventEnd.isBefore(moment()) || eventEnd.isSame(moment())
}

export function useEntryTicketSectionState(props) {
  const { entry, visible, onToggleChange, toggleOptions, selectedScreeningId } =
    props
  const { elevent, external, upsell } = entry.ticketingInfo || {}

  const queryData = useStaticQuery(graphql`
    query UpsellDefaultSettings {
      wpAaiffSiteSettings {
        websiteSettings {
          upsellDefaults {
            urlName
            uiKey
            title
            subtitle
            showtimeId
            itemType
          }
        }
      }
    }
  `)
  const { upsellDefaults } = queryData.wpAaiffSiteSettings.websiteSettings

  const [ticketState, setTicketState] = useState(TicketSectionState.INITIAL)
  const ticketItemsByUiKey = useTicketingItems()

  /**
   * If the event has not already passed, set up an interval that checks if the
   * event has passed every minute.
   */
  useEffect(() => {
    const hasEventAlreadyPassed = _checkIfEventHasPassed(entry)

    let interval
    if (!hasEventAlreadyPassed) {
      setTicketState(TicketSectionState.ON_SALE)

      interval = setInterval(() => {
        const hasPassed = _checkIfEventHasPassed(entry)

        // Clear the interval now that the event has passed
        if (hasPassed === true) {
          clearInterval(interval)
        }

        if (ticketState === TicketSectionState.ON_SALE && hasPassed) {
          setTicketState(TicketSectionState.OFF_SALE)
        }
      }, 60000)
    } else {
      setTicketState(TicketSectionState.OFF_SALE)
    }

    // Clean up the interval when the component unmounts
    return () => {
      if (!hasEventAlreadyPassed) {
        clearInterval(interval)
      }
    }
  }, [])

  /**
   * In order to determine which item to display in the upsell, we check for the following things:
   * 1. Are we using a custom upsell?
   *      1a. Is the custom upsell a "Ticket"? If so, use the provided showtimeId and urlName to generate
   * an elevent widget
   *      1b. Is the custom upsell a "Pass" or "Package"? If so, use the provided uiKey to find the ticketing
   * item from the Ticketing query
   * 2. If we fail to return custom upsell data or we aren't using a custom upsell, we will default to
   * showing selected item in the upsell default settings
   */
  const upsellProps = useMemo(() => {
    // const defaultItem = ticketItemsByUiKey["goldPass"]

    if (upsell && upsell?.useCustomUpsell) {
      const { eleventEntityType, uiKey, showtimeId, urlName, messages } = upsell

      if (eleventEntityType === "ticket") {
        if (showtimeId && urlName) {
          return {
            eleventInfo: {
              type: eleventEntityType,
              eleventId: showtimeId,
              urlName,
            },
            title: messages.header,
            subtitle: messages.subtitle,
          }
        } else {
          console.error(
            `Upsell ${eleventEntityType} type did not receive valid showtimeId and urlName properties`
          )
        }
      } else {
        const customItem = ticketItemsByUiKey[uiKey]

        if (customItem) {
          return {
            eleventInfo: {
              type: customItem.eleventInfo.type,
              eleventId: customItem.eleventInfo.id,
              allocationId: customItem.eleventInfo.allocationId,
            },
            itemName: customItem.name,
            itemPrice: customItem.price,
            title: messages.header,
            subtitle: messages.subtitle,
            isAvailable: customItem.isAvailable,
            unavailableButtonLabel: customItem.unavailableButtonLabel,
          }
        } else {
          console.error(
            `Upsell ${eleventEntityType} type did not receive valid uiKey property`
          )
        }
      }
    }

    // if we have reached this point, use the default settings for the upsell
    if (upsellDefaults.itemType == "ticket") {
      if (upsellDefaults.showtimeId && upsellDefaults.urlName) {
        return {
          eleventInfo: {
            type: upsellDefaults.itemType,
            eleventId: upsellDefaults.showtimeId,
            urlName: upsellDefaults.urlName,
          },
          title: upsellDefaults.title,
          subtitle: upsellDefaults.subtitle,
        }
      } else {
        console.error("Upsell default has missing showtime ID or urlName")
      }
    } else {
      const defaultItem = ticketItemsByUiKey[upsellDefaults.uiKey]

      if (defaultItem) {
        return {
          eleventInfo: {
            type: defaultItem.eleventInfo.type,
            eleventId: defaultItem.eleventInfo.id,
            allocationId: defaultItem.eleventInfo.allocationId,
          },
          itemName: defaultItem.name,
          itemPrice: defaultItem.price,
          title: upsellDefaults.title,
          subtitle: upsellDefaults.subtitle,
          isAvailable: defaultItem.isAvailable,
          unavailableButtonLabel: defaultItem.unavailableButtonLabel,
        }
      } else {
        console.error("Upsell default is set to an invalid ticketing item")
      }
    }

    return null
  }, [ticketItemsByUiKey, upsell, upsellDefaults])

  return {
    external,
    elevent,
    ticketState,
    upsellProps,
    // Return null if not visible or if there is no ticketing information
    visible: Boolean(visible && (elevent || external)).valueOf(),
    // Show upsell if `hideUpsell` is false and we have props to pass
    showUpsell: !upsell?.hideUpsell && upsellProps,
    onToggleChange,
    toggleOptions,
    selectedScreeningId,
  }
}
