import React, { useContext } from "react"
import styled from "@emotion/styled"
import { useMediaQuery } from "@mui/material"
import moment from "moment-timezone"
import { AAIFF_TIMEZONE, EventFormatSlugs, SearchPageTypes } from "../../common/constants";
import { ShowtimeAvailabilitiesContext } from "../../context/ShowtimeAvailabilitiesContext";
import { PC_CARD_VARIANTS } from "../cards/program_card/program_card_variants";
import { 
  TABADGE_VARIANT_NAMES, 
  TABADGE_LABELS,
  TABADGE_COLORS,
  TABADGE_SMALL_TEXT_BREAKPOINTS,
  TABADGE_VARIANTS,
} from "./ticket_avail_badge_variants";

/**
 * The TicketAvailabilityBadge component adds a label to an event card to communicate
 * whether the event is sold out or near sold out, and potentially whether it is a past event,
 * and makes distinctions as to whether on-demand is still available.
 * 
 * @param {*} props.entry - the entry for which badge info may be shown
 * @param {*} props - the rest of props used to determine styling
 */
export function TicketAvailabilityBadge(props) {
  const { entry, programVariant, pageType } = props;
  const availabilities = useContext(ShowtimeAvailabilitiesContext).getAvailabilities();
  const smallEventText = useMediaQuery(`(max-width:${TABADGE_SMALL_TEXT_BREAKPOINTS.EVENT_LIST_SMALL_TEXT})`); // To match small text breakpoint on /events - list view
  const smallProgramText = useMediaQuery(`(max-width:${TABADGE_SMALL_TEXT_BREAKPOINTS.PROGRAM_SMALL_TEXT})`); // To match small text breakpoint on /program

  /**
   * @var {EventEntry} programEntry determines the EventEntry whose availability info will be displayed with this component.
   * If entry prop is a FilmEntry, gets first in-person screening. Otherwise, use the entry prop itself.
   * E.g. a short film FilmEntry alone will not have ticketing/event info, only the ShortsProgramEntry collection
   * it belongs to will.
   */
  let programEntry 
  if (entry.screeningEvents) {
    programEntry = entry.getFirstScreening(moment().tz(AAIFF_TIMEZONE)) //TODO: add entry function `getFirstUpcomingScreening`
  } else {
    programEntry = entry
  }

  // Determine if it has on-demand available
  let hasOnDemand = false;
  let screeningEvents = entry.screeningEventsCount ? entry.screeningEvents : entry.mainFilm?.screeningEventsCount ? entry.mainFilm.screeningEvents : [];
  screeningEvents.forEach(event => {
    if(event.eventFormatSlug === EventFormatSlugs.ON_DEMAND) hasOnDemand = true;
  })
  
  /**
   * Based on elevent data, determine:
   * @var {number} remainingPercentage, rounded to nearest whole number, except for values n 
   * where (1% >= n > 0%) will be rounded to 1%. If percentage data N/A, value is undefined,
   * possible as elevent query only shows percentage for events 1 hour before now or later.
   * 
   * @var {string} badgeLabel, the corresponding badge type / badge text to display.
   * If percentage data N/A, value is undefined.
   */
  let remainingPercentage;
  let badgeLabel;
  const programEntryShowtimeId = programEntry?.ticketingInfo?.elevent?.showtimeId;
  const eleventEntryData = availabilities[programEntryShowtimeId];
  const soldOutPercentage = eleventEntryData?.PublicAllocationSoldOutPercentage;
  if(soldOutPercentage !== undefined) {
    remainingPercentage = 100 - soldOutPercentage;
    if(remainingPercentage < 1 && remainingPercentage > 0) {
      remainingPercentage = 1
    } else {
      remainingPercentage = Math.round(remainingPercentage);
    }

    // Determine badge type / text
    if(remainingPercentage <= 25 && remainingPercentage > 0) {
      badgeLabel = TABADGE_LABELS.ALMOST_SOLD_OUT;
    } 
    else if(remainingPercentage === 0 && hasOnDemand) {
      badgeLabel = TABADGE_LABELS.IN_PERSON_SOLD_OUT;
    }
    else if(remainingPercentage === 0 && !hasOnDemand) {
      badgeLabel = TABADGE_LABELS.SOLD_OUT;
    }
  }
  else {
    // Possibly do not show, may discourage people from checking for on-demand alternative to past in-person event.
    // badgeLabel = AVAILABILITY_BADGE_TYPES.PAST_EVENT; 
  }

  // Only display component if ticketing info available, and only 25% or less available tickets remain.
  if(badgeLabel === undefined || remainingPercentage >= 25) {
    return null;
  }
  
  /**
   * Logic for determining badge styles, to pass as props to styled component.
   */
  let badgeVariantName;
  if(programVariant) {
    if(smallProgramText) badgeVariantName = TABADGE_VARIANT_NAMES.PROGRAM_SMALL_TEXT;
    else badgeVariantName = TABADGE_VARIANT_NAMES.PROGRAM_NORMAL_TEXT;
  }
  else {
    if(smallEventText) badgeVariantName = TABADGE_VARIANT_NAMES.EVENT_LIST_SMALL_TEXT;
    else badgeVariantName = TABADGE_VARIANT_NAMES.EVENT_LIST_NORMAL_TEXT;
  }
  /**
   * Note regarding badge styles on ProgramCard (indicated by presence of programVariant):
   * - due to multiple ProgramCard variants, values for font-size, top, right mirror
   * analogous style values for the ProgramCard's variant.
   * - regarding top, right, figma requires equivalent top === right vals,
   * hence choosing the larger if PAD_CARD_VERT and PAD_CARD_HORIZ are different.
   */
  const fontSizeVal = programVariant ? PC_CARD_VARIANTS[programVariant].TEXT.INFO_FONT_SIZE : TABADGE_VARIANTS[badgeVariantName].FONT_SIZE;
  const topVal = programVariant ? PC_CARD_VARIANTS[programVariant].GRID.PAD_CARD_VERT : TABADGE_VARIANTS[badgeVariantName].TOP;
  const rightVal = programVariant ? PC_CARD_VARIANTS[programVariant].GRID.PAD_CARD_VERT : TABADGE_VARIANTS[badgeVariantName].RIGHT;
  const paddingVal = TABADGE_VARIANTS[badgeVariantName].PADDING;

  return (
    <StyledAvailabilityBadge 
      badgeLabel={badgeLabel} 
      fontSizeVal={fontSizeVal}
      topVal={topVal}
      rightVal={rightVal}
      paddingVal={paddingVal}
    >
      {badgeLabel}
    </StyledAvailabilityBadge>
  )
}

const StyledAvailabilityBadge = styled.div`
  // Consistent styles for all badge variants:
  white-space: nowrap;
  z-index: 10;
  position: absolute;
  border-radius: 5px;
  font-weight: 500;
  ${props => {
    let styleString = `
        font-size: ${props.fontSizeVal};
        top: ${props.topVal};
        right: ${props.rightVal};
        padding: ${props.paddingVal};
    `;
    switch(props.badgeLabel) {
      case TABADGE_LABELS.SOLD_OUT:
      case TABADGE_LABELS.IN_PERSON_SOLD_OUT: {
        styleString += `
          color: ${TABADGE_COLORS.FONT_WHITE};
          background-color: ${TABADGE_COLORS.BG_RED};
        `
        break;
      }
      case TABADGE_LABELS.ALMOST_SOLD_OUT: {
        styleString += `
          color: ${TABADGE_COLORS.FONT_BLACK};
          background-color: ${TABADGE_COLORS.BG_ORANGE};
        `
        break;
      }
      case TABADGE_LABELS.PAST_EVENT: {
        styleString += `
          color: ${TABADGE_COLORS.FONT_BLACK};
          background-color: ${TABADGE_COLORS.BG_WHITE};
        `
        break;
      }
    }
    return styleString;
  }};

`