import React from "react"
import PropTypes from "prop-types"
// import { Link } from "../../link"
import { Link, LinkGroup } from "@components/@aaiff"

export function FooterLinkList({ title, links }) {
  return (
    <div className="footer__links footer__section">
      <h3>{title}</h3>

      <LinkGroup className="footer__linksNav" direction="column">
        {links.map(link => {
          return (
            <Link
              to={!link.isExternal ? `/${link.path}` : undefined}
              href={link.isExternal ? link.path : undefined}
              key={link.path}
              className="footer__linksNavItem"
            >
              {link.label}
            </Link>
          )
        })}
      </LinkGroup>
    </div>
  )
}

FooterLinkList.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.exact({
      path: PropTypes.string,
      label: PropTypes.string,
      isExternal: PropTypes.bool,
    })
  ).isRequired,
}
