import React from "react"
import { graphql } from "gatsby"

import { PageHead } from "@components/page_head"
import { WpEntryLayout } from "../components/wp-entry"

export default function Film({ data, location }) {
  const { wpFilm, allWpEvent } = data

  return (
    <WpEntryLayout
      wpNode={wpFilm}
      screeningNodes={allWpEvent.nodes}
      location={location}
      fromProgram={location.state ? location.state.fromProgram : false}
    />
  )
}

export const Head = ({ data }) => {
  const {
    filmGeneralInformation: { title, filmLoglineSynopsis },
  } = data.wpFilm

  return <PageHead title={title} description={filmLoglineSynopsis?.logline} />
}

export const query = graphql`
  query ($id: String, $eventIds: [String]) {
    wpFilm(id: { eq: $id }) {
      id
      slug
      ...FilmGeneralInformation
      ...FilmTaxonomies
      ...FilmCredits
      ...FilmSocialAndBonus
    }
    allWpEvent(filter: { id: { in: $eventIds } }) {
      nodes {
        ...FilmPageEvent
      }
    }
  }
`
