import React from "react"

export function TicketingBanner(props) {
  const { activeDiscount } = props

  if (!activeDiscount || !activeDiscount.bannerMessage) return null

  return (
    <div className="ticketing__banner">
      <strong className="ticketing__banner__message">
        {activeDiscount.bannerMessage}
      </strong>
    </div>
  )
}
