import React from "react"
import styled from "@emotion/styled"
import { SearchIcon, ArrowRightIcon } from "../../icons/non_social_media_icons"
import { InputContainer, BaseInput, Label, Subtext } from "./inputs.styled"
import { ClearButton } from "./ClearButton"
import { useInput } from "./inputs.hook"
import useMediaQuery from "@mui/material/useMediaQuery"

export function SearchField(props) {
  const {
    label = "Search",
    description,
    errorMessage,
    width = "100%",
    isValid = true,
    isDisabled = false,
    isHidden = false,
    className = "",
    value,
    defaultValue,
    onInputChange,
    isClearable,
    pageType,
    ...otherProps
  } = props

  const { inputRef, inputValue, hasValue, handleInputChange, clearInput } =
    useInput({
      value,
      onInputChange,
    })

  const isMobile = useMediaQuery("(max-width:900px)");

  if (isHidden) {
    return null
  }

  return (
    <StyledInputContainer 
      className="aaiff-TextField" 
      width={width} 
      pageType={pageType} 
      hasValue={hasValue}
      isMobile={isMobile}
    >
      <Label className={`aaiff-TextFieldLabel ${hasValue ? "is_filled" : ""}`}>
        <StyledSearchIcon className="aaiff-SearchIcon" pageType={pageType} />
        <NoBreakSpan>{label}</NoBreakSpan>
      </Label>
      <StyledBaseInput
        ref={inputRef}
        value={inputValue}
        defaultValue={defaultValue}
        className={`aaiff-TextField-input ${className}`}
        onChange={handleInputChange}
        {...otherProps}
      />
      {(pageType === "program") ? (
        !hasValue ? <StyledArrowRightIcon /> : <StyledClearButton as={ClearButton} onClick={clearInput} isHidden={!isClearable || !hasValue}/>
      ) : <StyledClearButton as={ClearButton} onClick={clearInput} isHidden={!isClearable || !hasValue} />}
      
      {!!description && (
        <Subtext className="aaiff-HelpText">{description}</Subtext>
      )}
    </StyledInputContainer>
  )
}

const StyledInputContainer = styled(InputContainer)`
  ${props => props.pageType === "program" && `          // Ensures does not affect Schedule page existing style
    margin-bottom: ${props.isMobile ? "0rem" : "inherit"};
    padding: 0;
    border-radius: 0px;
    & > label {
      left: 0;
      bottom: 0.75em;
    }

    button {
      padding: 0;
      font-size: 1.5em;
    }

    @media only screen and (max-width: 640px) {
      label {
        font-size: 0.75rem;
        bottom: 0.3em;
      }
    }

    &:focus-within label {
      transform: translate(0, -4px) scale(calc(2 / 3));
      width: calc((100% - 32px) * 4 / 3);
      svg {
        color: inherit;
      }
    }

    label.is_filled {
      transform: translate(0, -4px) scale(calc(2 / 3));
      width: calc((100% - 32px) * 4 / 3);
      svg {
        color: inherit;
      }
    }
  `}
`

const StyledSearchIcon = styled(SearchIcon)`
  ${props => props.pageType === "program" && `          // Ensures does not affect Schedule page existing style
    color: #000;
    font-size: 1.5em;
  `}
`

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.5em;
  margin-bottom: 5px;

  & ~ .aaiff-TextField-input {
    width: calc(100% - 16px);
  }

  @media only screen and (max-width: 640px) {
    font-size: 1.2em;
  }
}
`

const StyledClearButton = styled(ClearButton)`
  margin-bottom: -4px;
`

const StyledBaseInput = styled(BaseInput)`
  width: calc(100% - 24px - 20px);
  flex: 1;
  @media only screen and (max-width: 640px) {
    font-size: 0.875rem;
  }
`

// Due to deprecation of <nobr>
const NoBreakSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`