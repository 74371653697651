exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latest-news.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-sponsors-partners-js": () => import("./../../../src/pages/sponsors-partners.js" /* webpackChunkName: "component---src-pages-sponsors-partners-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event_template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-festival-page-js": () => import("./../../../src/templates/festival_page.js" /* webpackChunkName: "component---src-templates-festival-page-js" */),
  "component---src-templates-film-template-js": () => import("./../../../src/templates/film_template.js" /* webpackChunkName: "component---src-templates-film-template-js" */),
  "component---src-templates-home-template-jsx": () => import("./../../../src/templates/home_template.jsx" /* webpackChunkName: "component---src-templates-home-template-jsx" */),
  "component---src-templates-ticketing-template-js": () => import("./../../../src/templates/ticketing_template.js" /* webpackChunkName: "component---src-templates-ticketing-template-js" */),
  "component---src-templates-update-js": () => import("./../../../src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */)
}

