import React from "react"
import PropTypes from "prop-types"

import {
  DEFAULT_SIZE,
  DEFAULT_VARIANT,
  ButtonSizes,
  ButtonVariants,
} from "./Button.constants"

/**
 * A version of the AAIFF button using SCSS classes. Remove this file once the website
 * transitions to using styled components
 */
export function Button(props) {
  const {
    children,
    offset,
    variant = DEFAULT_VARIANT,
    size = DEFAULT_SIZE,
    isDisabled = false,
    className = "",
    ...otherProps
  } = props

  let fullClassName = `aaiff-Button aaiff-Button--${variant} aaiff-Button--size-${size} ${className}`
  if (offset && ["atStart", "atEnd"].includes(offset)) {
    fullClassName += offset === "atStart" ? " _offset-start" : " _offset-end"
  }

  return (
    <button disabled={isDisabled} className={fullClassName} {...otherProps}>
      {typeof children === "string" ? (
        <span className="aaiff-ButtonLabel">{children}</span>
      ) : (
        children
      )}
    </button>
  )
}

Button.propTypes = {
  /**
   * The variant of the button to use
   */
  variant: PropTypes.oneOf(Object.values(ButtonVariants)),
  /**
   * The size of the button to use
   */
  size: PropTypes.oneOf(Object.values(ButtonSizes)),
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Offsets the button component at the start so the end of the button label
   * visually aligns with offset side
   */
  offset: PropTypes.oneOf(["atStart", "atEnd"]),
}
