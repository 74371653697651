import { useStaticQuery, graphql } from "gatsby"

import { useTicketingState } from "./useTicketingState"

export function useTicketingItems() {
  const data = useStaticQuery(graphql`
    query TicketingData {
      wpFestivalPage(slug: { eq: "ticketing" }) {
        ...PageTicketingConfigV3
      }
    }
  `)

  const {
    wpFestivalPage: { festivalPageTicketingConfig, festivalPageTicketingV3 },
  } = data

  const { ticketingItems } = useTicketingState(festivalPageTicketingV3)

  const ticketingItemsByUiKey = {}
  ticketingItems.ticketsAndPackages.inPersonPasses.forEach(pass => {
    ticketingItemsByUiKey[pass.uiKey] = pass
  })
  ticketingItems.ticketsAndPackages.onDemandPasses.forEach(pass => {
    ticketingItemsByUiKey[pass.uiKey] = pass
  })
  ticketingItems.festivalPasses.passes.forEach(pass => {
    ticketingItemsByUiKey[pass.uiKey] = pass
  })

  return ticketingItemsByUiKey
}
