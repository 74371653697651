import React from "react"

import ScheduleItem from "./schedule_item"
import OverlapGroup from "./grid_overlap_group"
// import { doEntriesOverlap } from "../../../util/date_util"
import { doEntriesOverlap } from "../../../util/entry_utils"

export default function GridColumnsItem({
  events = [],
  isOddColumn,
  // onMouseEnter,
}) {
  if (!events) return null

  // Iterate through each event and create ScheduleItems. If
  // events overlap, an OverlapGroup component is created.
  const columnItems = []
  let overlappingEvents = []
  let maxOverlaps = 2

  for (let idx = 0; idx < events.length; idx++) {
    if (
      idx === events.length - 1 ||
      !doEntriesOverlap(events[idx], events[idx + 1])
    ) {
      if (overlappingEvents.length === 0) {
        columnItems.push(
          <ScheduleItem event={events[idx]} key={`col-item-${idx}`} />
        )
      } else {
        overlappingEvents.push(events[idx])

        columnItems.push(
          <OverlapGroup
            key={`col-item-${idx}`}
            events={overlappingEvents}
            maxOverlaps={maxOverlaps}
          />
        )

        // reset these variables now that the
        // overlap group has been made
        overlappingEvents = []
        maxOverlaps = 2
      }
    } else {
      overlappingEvents.push(events[idx])

      // If there are more than 2 overlapping events,
      // find the section with the most overlapped events
      if (overlappingEvents.length > 2) {
        let overlapCount = 2
        for (let idx2 = idx - 1; idx2 > 0; idx2--) {
          if (doEntriesOverlap(events[idx2], events[idx + 1])) {
            overlapCount++
          } else {
            break
          }
        }

        if (overlapCount > maxOverlaps) {
          maxOverlaps = overlapCount
        }
      }
    }
  }

  return (
    <li
      className={`schedule__gridColumn ${
        isOddColumn ? "schedule__gridColumn--dark" : ""
      }`}
      // onMouseEnter={onMouseEnter}
    >
      <ul className="schedule__gridColumnItemList">{columnItems}</ul>
    </li>
  )
}
