import { TicketingItem } from "./TicketingItem"
import moment from "moment-timezone"
import { AAIFF_TIMEZONE } from "@common/constants"
/**
 * TicketingItems Manager Class: A singleton class that is initiated inside useTicketingState
 * Purpose: Create, organize and update ticketingItem information.
 * The Manager, can create ticketingItem,  manages discount, refresh and update ticketing information,
 * The Manager also contains helper functions, setAvailabilityDate,
 *  Grouping tickets, and getting active discounts depending on the current Dates.
 */

export class TicketingItemManager {
  constructor() {
    //Check if an instance is already exists and returns that if it does
    if (TicketingItemManager.instance) {
      return TicketingItemManager.instance
    }

    TicketingItemManager.instance = this

    this.tickets = []
  }

  createTicketingItem(ticket) {
    const {
      name,
      uiKey,
      price,
      showMissingFeatures,
      description,
      type: ticketingType,
      eleventInfo,
      isHighlighted,
      makeUnavailable,
      unavailableButtonLabels,
      purchaseInstructions,
      purchaseDates,
      discounts,
    } = ticket

    const newTicketItem = new TicketingItem({
      name,
      uiKey,
      price,
      description,
      ticketingType,
      eleventInfo,
      showMissingFeatures,
      isHighlighted,
      makeUnavailable,
      unavailableButtonLabels,
      purchaseInstructions,
      purchaseDates,
      discounts,
    })

    //set Button Label
    this.setItemAvailabilityByDate(
      newTicketItem,
      makeUnavailable,
      unavailableButtonLabels,
      purchaseDates
    )
    //Get active discount, and apply discount
    this.addDiscount(newTicketItem)
    //Add price label
    newTicketItem.setPriceLabel(ticketingType)
    this.addTicket(newTicketItem)

    return newTicketItem
  }

  addFeature(feature, ticket) {
    const { description, validUiKeys } = feature
    // if the `validPassNames` property doesn't include the current pass's name,
    // the feature will be unchecked
    ticket.addFeature(description, validUiKeys.includes(ticket.uiKey))
  }

  addTicket(ticket) {
    if (ticket instanceof TicketingItem) {
      this.tickets.push(ticket)
    } else {
      throw new Error("Invalid ticket")
    }
  }

  applyCurrentDiscount(ticket, currentDiscount) {
    //only get the date of the endDatetime
    const endDate = currentDiscount.endDatetime.split(" ")[0]

    ticket.setDiscountInfo(currentDiscount.name, endDate)
    ticket.applyDiscount(
      currentDiscount.reductionAmount,
      currentDiscount.reductionType
    )
  }

  addDiscount(ticket) {
    if (ticket.discounts) {
      const currentDiscount = this.getActiveDiscount(ticket.discounts)

      if (currentDiscount) {
        this.applyCurrentDiscount(ticket, currentDiscount)
      }
    }
  }

  refresh() {
    this.tickets.forEach(ticket => {
      //update discount
      this.addDiscount(ticket)

      //update Availability by date
      this.setItemAvailabilityByDate(
        ticket,
        ticket.isAvailable,
        ticket.unavailableButtonLabel,
        ticket.purchaseDates
      )
    })
  }

  getActiveDiscount(discounts) {
    if (discounts && discounts.length) {
      const today = moment().tz(AAIFF_TIMEZONE)

      //Check for discount during the current time period
      for (let idx = 0; idx < discounts.length; idx++) {
        const currDiscount = discounts[idx]

        const startDate = currDiscount.startDatetime
        const endDate = currDiscount.endDatetime

        if (today.isSameOrAfter(startDate) && today.isSameOrBefore(endDate)) {
          return currDiscount
        }
      }
    }
  }

  /**
   * A helper function to determine a ticketing item's availability depending
   * on the current date.
   *
   * - If it is before the start date, the item should be unavailable and show the
   * first day available
   * - If it is after the end date, the item should not be available
   * - If it is in the range between start and end, we use the WordPress availability
   * settings
   *
   * @param {TicketingItem} ticketingItem the TicketingItem instance to update
   * @param {Boolean} isAvailable if it is available
   * @param {String} unavailableButtonLabel the message to show on the button when unavailable
   * @param {String} [availableStart] Optional. the start of the available date range
   * @param {String} [availableEnd] Optional. the end of the available date range
   */
  setItemAvailabilityByDate(
    ticketingItem,
    makeUnavailable,
    unavailableButtonLabel,
    purchaseDates
  ) {
    const { afterAvailableDates, beforeAvailableDates, overrideOn } =
      unavailableButtonLabel
    const { endAvailableDatetime, startAvailableDatetime } = purchaseDates

    const today = moment().tz(AAIFF_TIMEZONE)

    const start = startAvailableDatetime
      ? moment(startAvailableDatetime).tz(AAIFF_TIMEZONE)
      : null
    const end = endAvailableDatetime
      ? moment(endAvailableDatetime).tz(AAIFF_TIMEZONE)
      : null

    if (start && start.isAfter(today)) {
      ticketingItem.makeUnavailable(beforeAvailableDates)
    } else if (end && end.isBefore(today)) {
      ticketingItem.makeUnavailable(afterAvailableDates)
    } else if (!makeUnavailable) {
      ticketingItem.makeUnavailable(overrideOn)
    } else {
      ticketingItem.makeAvailable
    }
  }

  addTicketToGroup(ticketingByGroup, group, ticket) {
    // Validate the group exists
    if (!ticketingByGroup[group]) {
      console.warn(`[TicketingManager] Invalid group: ${group}`)
      return
    }

    // Handle passes in festivalPasses
    if (group === "festivalPasses") {
      ticketingByGroup[group].passes.push(ticket)
      ticketingByGroup[group].totalItems++
    }

    // Handle tickets for individualTickets or ticketsAndPackages
    if (group === "individualTickets" || group === "ticketsAndPackages") {
      if (ticket.ticketingType === "inPerson") {
        ticketingByGroup[group].inPersonPasses.push(ticket)
      } else if (ticket.ticketingType === "onDemand") {
        ticketingByGroup[group].onDemandPasses.push(ticket)
      } else {
        console.warn(
          `[TicketingManager] Invalid ticketingType: ${ticket.ticketingType} for group: ${group}`
        )
        return
      }
      ticketingByGroup[group].totalItems++
    }
  }
}
