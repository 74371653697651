import { WpEntryTypes } from "../constants"
import { MediaDto } from "../dto"

/**
 * This class represents a base entry from the WordPress database.
 *
 * @class
 */
export class BaseEntry {
  constructor(id, slug, data) {
    this.slug = slug
    // There might be either a wordpress_id or id field in the given data
    this.id = id
    // If the wpNode doesn't contain an acf field, try
    // checking if the rest of the node fields contains
    // the expected data
    this.__data = data
  }

  get title() {
    return this.__data.title
  }

  get runtime() {
    return this.__data.runtime
  }

  get logline() {
    return this.__data.logline
  }

  get description() {
    return this.__data.description || this.__data.synopsis
  }

  get synopsis() {
    return this.__data.synopsis || this.__data.description
  }

  get festivalYear() {
    return this.__data.festivalYear?.slug
  }

  /**
   * @return {MediaDto} the poster image as a MediaDto instance
   */
  get posterImageMedia() {
    const mediaItem =
      this.__data.filmGeneralInformation?.posterImage ||
      this.__data.eventGeneralInformation?.posterImage ||
      this.__data.posterImage

    return MediaDto.fromWpMediaItem(mediaItem)
  }

  get isValid() {
    return !!this.__data && Object.keys(this.__data).length > 0
  }

  /**
   * Returns `true` if the entry is an Event or any of the Event subclasses: Film
   * Screening, Shorts Program, and Panel Event.
   *
   * Otherwise, returns `false`
   */
  get isEvent() {
    if (!this.type || this.type === WpEntryTypes.FILM) {
      return false
    }

    return true
  }

  /**
   * Returns `true` if the entry has film attribute. The entry types that have
   * film attributes are Films and FeatureScreenings
   */
  get hasFilmAttributes() {
    return this.oneOf([
      WpEntryTypes.FILM,
      WpEntryTypes.FEATURE_SCREENING,
      WpEntryTypes.SHORTS_PROGRAM,
    ])
  }

  /**
   * Determines if the entry's type matches one of the given types.
   *
   * @param {Array<WpEntryTypes>} types a list of possible types
   * @returns `true` if one of the given types matches or `false` if
   * none of them do.
   */
  oneOf(types) {
    if (Array.isArray(types)) {
      for (let i = 0; i < types.length; i++) {
        if (types[i] === this.type) {
          return true
        }
      }
    }

    return false
  }
}
