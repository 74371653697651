import React from "react"
import PropTypes from "prop-types"
import { ButtonLink } from "../../../buttons"

export function ScreeningLinks(props) {
  const { visible, screeningUrls } = props

  if (!visible || !screeningUrls || screeningUrls.length === 0) return null

  const buttonItems = []

  Object.keys(screeningUrls).forEach(urlType => {
    if (screeningUrls[urlType]) {
      const btnText =
        urlType === "other"
          ? "Watch Livestream"
          : `Watch on ${urlType[0].toUpperCase() + urlType.slice(1)}`

      buttonItems.push(
        <ButtonLink
          key={`btn-${urlType}`}
          to={screeningUrls[urlType]}
          external
          target="_blank"
          rel="noreferrer noopener"
          variant="bordered"
        >
          {btnText}
        </ButtonLink>
      )
    }
  })

  if(buttonItems.length === 0) {
    return null;
  }

  return <nav className="event__screeningLinks">{buttonItems}</nav>
}

ScreeningLinks.propTypes = {
  visible: PropTypes.bool.isRequired,
  screeningUrls: PropTypes.object,
}
