import React from "react"
import { EntrySubList } from "./entry_sublist"

export function PanelistIndex({
  visible,
  panelists = [],
  contentType = "html", // render <innerHTML> for panelist bio
  sectionTitle = "Guest Speakers", // if no title is provided, default to "Guest Speakers"
}) {
  const panelistItems = panelists.map(panelist => {
    return {
      title: panelist.name,
      description: panelist.biography,
      posterImageMedia: panelist.image,
    }
  })

  return (
    <EntrySubList
      sectionTitle={sectionTitle}
      items={panelistItems}
      visible={visible}
      contentType={contentType}
    ></EntrySubList>
  )
}
