import React from "react"
import { Link } from "../link"
import { useSelectFestivalByYear } from "../../hooks"

export function EntryLink(props) {
  const { year, path, children, ...rest } = props
  const festival = useSelectFestivalByYear()(year)

  return (
    <Link to={`/${festival.slug.toLowerCase()}/${path}`} {...rest}>
      {children}
    </Link>
  )
}
