import React from "react"
import styled from "@emotion/styled"

import { PlayIcon } from "../icons"
import {
  EventFormatSlugs,
  EventTypeSlugs,
  WpEntryTypes,
} from "../../common/constants"

export function EntryFormatLabel(props) {
  const { className, id, screeningEvents = [] } = props

  let hasOnDemand = false,
    hasInPerson = false,
    hasLivestream = false

  screeningEvents.forEach(event => {
    const { eventFormatSlug } = event

    switch (eventFormatSlug) {
      case EventFormatSlugs.IN_PERSON:
        hasInPerson = true
        break
      case EventFormatSlugs.LIVESTREAM:
        hasLivestream = true
        break
      case EventFormatSlugs.ON_DEMAND:
        hasOnDemand = true
        break
    }
  })

  const hasShortsProgram = screeningEvents.some(
    event => event.eventTypeSlug === EventTypeSlugs.SHORTS_PROGRAM
  )

  const displayLabel = _getLabel({
    hasInPerson,
    hasOnDemand,
    hasLivestream,
    hasShortsProgram,
  })

  if (screeningEvents.length == 0 || !displayLabel) {
    return null
  }

  return (
    <LabelRoot className={className} id={id}>
      <PlayIcon />
      <LabelContent>{displayLabel}</LabelContent>
    </LabelRoot>
  )
}

const LabelRoot = styled.div`
  display: flex;
  align-items: center;
`

const LabelContent = styled.span`
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
`

function _getLabel(labelOptions) {
  const { hasInPerson, hasOnDemand, hasLivestream, hasShortsProgram } =
    labelOptions

  if (hasInPerson && hasLivestream && hasOnDemand) {
    return "On-Demand & Livestream Available"
  } else if (!hasInPerson && hasLivestream && hasOnDemand) {
    return "On-Demand & Livestream Only"
  } else if (hasInPerson && hasLivestream) {
    return "Livestream Available"
  } else if (hasInPerson && hasOnDemand) {
    return "On-Demand Available"
  } else if (hasLivestream) {
    return "Livestream Only"
  } else if (hasOnDemand) {
    return hasShortsProgram
      ? "On-Demand" // TODO: remove this once the the shorts programs have been consolidated
      : "On-Demand Only"
  } else {
    return ""
  }
}
