import { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useNavbarOptions, useFooterLinks, useEleventService } from "../"

export function useLayout() {
  const queryData = useStaticQuery(graphql`
    query Layout {
      aaiffSettings: wpAaiffSiteSettings {
        slug
        websiteSettings {
          socialMedia {
            facebook
            instagram
            twitch
            twitter
            youtube
          }
          acvLogo: acvLogoUrl {
            sourceUrl
          }
        }
      }
      headings: allWpFestivalPageType(
        sort: { description: ASC }
        filter: { slug: { nin: ["home", "other", "archive"] } }
      ) {
        nodes {
          name
          slug
        }
      }
      groupedPages: allWpFestivalPage(
        filter: {
          festivalPageNavigation: {
            isMainPage: { ne: true }
            hiddenFromNav: { ne: true }
          }
        }
        sort: { festivalPageNavigation: { order: ASC } }
      ) {
        group(field: { festivalPageNavigation: { type: { slug: SELECT } } }) {
          fieldValue
          nodes {
            festivalPageMetadata {
              description
              title
            }
            festivalPageNavigation {
              navName
            }
            slug
          }
        }
      }
      hasMainPage: allWpFestivalPage(
        filter: { festivalPageNavigation: { isMainPage: { eq: true } } }
      ) {
        group(field: { festivalPageNavigation: { type: { slug: SELECT } } }) {
          fieldValue
          nodes {
            festivalPageNavigation {
              navName
            }
            slug
          }
        }
      }
      faqType: wpFestivalPageType(slug: { eq: "faq" }) {
        name
        slug
      }
    }
  `)

  const { aaiffSettings, headings, groupedPages, hasMainPage, faqType } =
    queryData

  const { socialMedia } = aaiffSettings.websiteSettings
  const headerNavOptions = useNavbarOptions(
    groupedPages.group,
    headings.nodes,
    hasMainPage.group
  )
  const footerLinkOptions = useFooterLinks(headerNavOptions, faqType)
  const eleventService = useEleventService()

  // Initialize the elevent widgets when the layout first mounts
  useEffect(() => {
    if (typeof window !== undefined) {
      eleventService.initializeWidgets()
    }
  }, [])

  return {
    headerNavOptions,
    footerLinkOptions,
    socialLinks: socialMedia,
  }
}
