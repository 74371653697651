import { useStaticQuery, graphql } from "gatsby"

/**
 * A hook to use the `selectFestivalByYear` function which retrieves the
 * information of a film festival that a occurred on a specific year.
 */
export const useSelectFestivalByYear = () => {
  const data = useStaticQuery(graphql`
    query FestivalDatesQuery {
      festivals: allWpFestival {
        nodes {
          slug
          festivalGeneralInformation {
            name
            endDate
            startDate
            festivalNumber
            festivalYear {
              slug
            }
          }
        }
      }
    }
  `)

  /**
   * Retrieves the information for the AAIFF festival that occurred on the
   * specified year. If there is no festival, an empty object is returned.
   *
   * @param {String} year the festival year to check for information
   * @return {Object} an object containing the found film festival information
   */
  const selectFestivalByYear = year => {
    const festival = data.festivals.nodes.find(
      node => node.festivalGeneralInformation.festivalYear.slug === year
    )

    if (!festival) {
      return {}
    } else {
      const {
        festivalGeneralInformation: {
          name,
          startDate,
          endDate,
          festivalNumber,
          festivalYear,
        },
        slug,
      } = festival

      return {
        name,
        slug,
        startDate,
        endDate,
        festivalNumber,
        year: festivalYear.slug,
      }
    }
  }

  return selectFestivalByYear
}
