import { StringParam, ObjectParam } from "serialize-query-params"

export const SearchParamTypes = {
  PROGRAM_PARAMS: "ProgramParams",
  Event_PARAMS: "ScheduleParams",
}

/**
 * The param map for the `encodeQueryParams` and `decodeQueryParams` functions
 * by page
 */
export const SearchParamMaps = {
  [SearchParamTypes.PROGRAM_PARAMS]: {
    search: StringParam,
    filters: ObjectParam,
    categoryRef: StringParam,
  },
  [SearchParamTypes.Event_PARAMS]: {
    search: StringParam,
    filters: ObjectParam,
    view: StringParam,
  },
}
