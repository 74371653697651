import { FaPlayCircle as PlayIcon } from "react-icons/fa";
import { FaCalendar as CalendarIcon } from "react-icons/fa";
import { FaGlobe as GlobeIcon } from "react-icons/fa";
import { FaMapMarkerAlt as VenueIcon } from "react-icons/fa";
import { FaSearch as SearchIcon } from "react-icons/fa"
import { FaArrowRight as ArrowRightIcon } from "react-icons/fa";

export {
    PlayIcon,
    CalendarIcon,
    GlobeIcon,
    VenueIcon,
    SearchIcon,
    ArrowRightIcon,
}