import { useState, useEffect } from "react"
import { ScheduleViewTypes, SearchPageTypes } from "../../common/constants"

export const useScheduleState = props => {
  const { pageType, viewQuery, setViewQuery } = props

  if (pageType !== SearchPageTypes.EVENTS) {
    return {}
  }

  const [viewType, setViewType] = useState(_getViewTypeFromQuery(viewQuery))

  // When the view query changes, update the view type state
  useEffect(() => {
    setViewType(_getViewTypeFromQuery(viewQuery))
  }, [viewQuery])

  /**
   * A helper function for updating the hash route when the view type changes
   *
   * @param {ScheduleViewTypes} viewType the view type to change to
   */
  const handleViewTypeChange = viewType => {
    if (
      viewType !== ScheduleViewTypes.GRID &&
      viewType !== ScheduleViewTypes.LIST &&
      viewType !== ScheduleViewTypes.CALENDAR
    ) {
      console.warn(`${viewType} is not a valid events page view type`)
    }

    // Set the second param to `true` for views that are not the List view in order to
    // remove the filter and search params. Currently those are not supported in the
    // other views
    setViewQuery(viewType, viewType !== ScheduleViewTypes.LIST)
  }

  return {
    viewType,
    handleViewTypeChange,
  }
}

/**
 * Get the view type from the view query param. If there is no query param
 * value for view, this function defaults to the List view type.
 *
 * @param {string | undefined} viewQuery the value of the view query param
 * @returns {ScheduleViewTypes} the view type
 */
function _getViewTypeFromQuery(viewQuery) {
  switch (viewQuery) {
    case ScheduleViewTypes.GRID:
      return ScheduleViewTypes.GRID
    case ScheduleViewTypes.CALENDAR:
      return ScheduleViewTypes.CALENDAR
    case ScheduleViewTypes.LIST:
    default:
      return ScheduleViewTypes.LIST
  }
}
