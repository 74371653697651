import { EleventEvents } from "@constants/elevent.constants"

/**
 * A singleton class for managing the state of the Elevent widget API. This class provides functions
 * for initializing all of the widgets visible on the page and for cleaning up references to
 * widgets that are no longer rendered. In addition, this class also provides function for accessing
 * the cart and customer data.
 */
export class EleventService {
  static _widgetKey = null

  _isInitializingWidgets = false
  _isInitialized = false

  constructor() {
    if (EleventService._instance) {
      return EleventService._instance
    }

    EleventService._instance = this
  }

  /**
   * Attaches event listeners to each of events that Elevent provides. However, since
   * Elevent can only attach one listener function per type, each handler function
   * simply dispatches a CustomEvent that can be listened for anywhere in the application.
   */
  _setupListeners() {
    if (typeof window !== "undefined" && window?.elevent?.events) {
      console.debug("EleventService._setupListeners")

      window.elevent.events.SignIn = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterSignIn, { data: eventData })
        )
      }

      window.elevent.events.SignOut = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterSignOut, { data: eventData })
        )
      }

      // Dispatch an afterEleventInitialized event after initializing
      window.elevent.events.Initialized = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterInitialize, { data: eventData })
        )
      }

      window.elevent.events.Refreshed = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterRefresh, { data: eventData })
        )
      }

      window.elevent.events.ModalOpen = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterModalOpen, { data: eventData })
        )
      }

      window.elevent.events.ModalClose = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterModalClose, { data: eventData })
        )
      }

      window.elevent.events.AddToCart = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterAddToCart, { data: eventData })
        )
      }

      window.elevent.events.TransactionComplete = eventData => {
        window.dispatchEvent(
          new CustomEvent(EleventEvents.afterTransactionComplete, {
            data: eventData,
          })
        )
      }
    } else {
      console.warn("Unable to attach elevent listeners")
    }
  }

  get isInitialized() {
    return this._isInitialized
  }

  /**
   * Initializes the EleventService with the provided widget key and setups
   * up the event listeners on the elevent object.
   *
   * @param {string} widgetKey the Elevent widget key
   */
  init(widgetKey) {
    EleventService._widgetKey = widgetKey

    this._setupListeners()

    this._isInitialized = true
  }

  addEventListener(eleventEvent, callback) {
    if (!this._isInitialized) {
      throw new Error("EleventService not yet initialized")
    }

    if (Object.values(EleventEvents).includes(eleventEvent)) {
      window.addEventListener(eleventEvent, callback)
    } else {
      throw new Error(
        `Cannot add event listener for an invalid Elevent event ${eleventEvent}`
      )
    }
  }

  removeEventListener(eleventEvent, callback) {
    if (!this._isInitialized) {
      throw new Error("EleventService not yet initialized")
    }

    if (Object.values(EleventEvents).includes(eleventEvent)) {
      window.removeEventListener(eleventEvent, callback)
    } else {
      throw new Error(
        `Cannot remove event listener for an invalid Elevent event ${eleventEvent}`
      )
    }
  }

  /**
   * This function will initialize all Elevent widgets on the page that have not been initialized
   */
  async initializeWidgets() {
    if (!this._isInitialized) {
      throw new Error("EleventService not yet initialized")
    }

    return new Promise((resolve, reject) => {
      try {
        if (!this._isInitializingWidgets) {
          this._isInitializingWidgets = true

          // Clean up orphaned references before calling the initialize function.
          // Orphaned elevent widgets will cause errors to be thrown by the initialization
          // function.
          this.cleanupTicketingWidgetReferences()

          window.elevent.Initialize(EleventService._widgetKey)
        }

        const handleAfterInitialize = () => {
          this.removeEventListener(
            EleventEvents.afterInitialize,
            handleAfterInitialize
          )

          this._isInitializingWidgets = false
          resolve()
        }

        this.addEventListener(
          EleventEvents.afterInitialize,
          handleAfterInitialize
        )
      } catch (err) {
        console.error("Error while initializing Elevent widgets", err)

        reject(err)
      }
    })
  }

  /**
   * A helper method used to clean up references to old ticketing widgets from
   * previous pages.
   *
   * When navigating between event pages, the destroy method for the ticketing
   * widget isn't triggered, so the old references need to be cleaned up. If they
   * are not, elevent will run into error when referencing elements that no longer exist.
   */
  cleanupTicketingWidgetReferences() {
    if (!this._isInitialized) {
      throw new Error("EleventService not yet initialized")
    }

    if (typeof window !== "undefined") {
      const ticketWidgets = window.elevent.widgets.tickets

      if (ticketWidgets && ticketWidgets.length > 0) {
        for (let idx = ticketWidgets.length - 1; idx >= 0; idx--) {
          // If the document no longer has the html element, remove it from
          // the list of ticketing widgets stored on the elevent object
          if (!document.getElementById(ticketWidgets[idx].Id)) {
            console.warn(
              `Removing ticketing widget #${ticketWidgets[idx]} from elevent memory`
            )
            ticketWidgets.splice(idx, 1)
          }
        }
      }
    }
  }

  getCustomer() {
    if (!this._isInitialized) {
      throw new Error("EleventService not yet initialized")
    }

    if (typeof window !== "undefined") {
      const customerData = window.elevent.data.customer()

      if (customerData && customerData.EmailAddress) {
        return customerData
      }
    }

    return null
  }

  getCart() {
    if (!this._isInitialized) {
      throw new Error("EleventService not yet initialized")
    }

    if (typeof window !== "undefined") {
      return window.elevent.data.cart() || null
    }

    return null
  }
}
