import React from "react"
import { AaiffLogo, AcvLogo } from "@components/logos"

export function FooterLogos() {
  return (
    <nav className="footer__logos">
      <AcvLogo />
      <AaiffLogo />
    </nav>
  )
}
