import React from "react"

import GridTimes from "./grid_times"
import GridColumns from "./grid_columns"

export function ScheduleGrid({ visibleRange, datesList, eventsByDate }) {
  return (
    <div className="schedule__gridContainer">
      <GridTimes />
      <GridColumns
        visibleRange={visibleRange}
        datesList={datesList}
        eventsByDate={eventsByDate}
      />
    </div>
  )
}
