import React from "react"
import PropTypes from "prop-types"
import { Radio, RadioGroup, FormControl, FormControlLabel } from "@mui/material"

import { SortOrders } from "../../../../common/constants"

export function SortByOptions(props) {
  const { sortOrder, setSortOrder, hidden } = props

  if (hidden) return null

  const onChange = e => {
    setSortOrder(e.target.value)
  }

  return (
    <FormControl>
      <RadioGroup
        aria-label="sort by"
        value={sortOrder}
        onChange={onChange}
        className="filtersDrawer__optionsGroup sortBy"
      >
        <FormControlLabel
          className="filtersDrawer__option filtersDrawer__option--radio"
          value={SortOrders.ALPHABETICAL}
          control={<Radio size="small" style={{color:'grey'}}/>}
          label="Alphabetical"
          aria-label="alphabetical"
          labelPlacement="end"
        />
        <FormControlLabel
          className="filtersDrawer__option filtersDrawer__option--radio"
          value={SortOrders.DATE}
          control={<Radio size="small" style={{color:'grey'}}/>}
          label="By Date"
          aria-label="by date"
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  )
}

SortByOptions.propTypes = {
  sortOrder: PropTypes.string.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
}
