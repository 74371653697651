import moment from "moment"

export class FestivalDto {
  constructor(
    id,
    slug,
    festivalName,
    festivalNumber,
    festivalYear,
    startDate,
    endDate,
    trailerUrl,
  ) {
    this.id = id
    this.slug = slug
    this.festivalName = festivalName
    this.festivalNumber = festivalNumber
    this.festivalYear = festivalYear
    this.startDate = startDate
    this.endDate = endDate
    this.trailerUrl = trailerUrl
  }

  get startMoment() {
    return moment(this.startDate)
  }

  get endMoment() {
    return moment(this.endDate)
  }

  /**
   * Creates a new instance of the FestivalDto class from the results of the CurrentFestival
   * fragment for the WpAaiffSiteSettings Type
   *
   * @param {Object} fragmentData the data from the fragment
   * @return {FestivalDto} the new FestivalDto instance created from the fragmentData
   */
  static extractFromCurrentFestivalFragment(festivalData) {
    if (festivalData) {
      const {
        id,
        slug,
        festivalGeneralInformation: {
          festivalNumber,
          name: festivalName,
          festivalYear: { slug: festivalYearSlug },
          startDate,
          endDate,
          trailerUrl,
        },
      } = festivalData

      return new FestivalDto(
        id,
        slug,
        festivalName,
        festivalNumber,
        festivalYearSlug,
        startDate,
        endDate,
        trailerUrl
      )
    }

    return undefined
  }
}
