import React from "react"
import { graphql } from "gatsby"

import { PageHead } from "@components/page_head"
import { WpEntryLayout } from "../components/wp-entry/index"

export default function Event({ data, location }) {
  return (
    <WpEntryLayout
      wpNode={data.wpEvent}
      location={location}
      fromProgram={location.state ? location.state.fromProgram : false}
    />
  )
}

export const Head = ({ data }) => {
  const {
    eventGeneralInformation: { title },
    eventDescription: { logline },
  } = data.wpEvent

  return <PageHead title={title} description={logline} />
}

export const query = graphql`
  query ($id: String) {
    wpEvent(id: { eq: $id }) {
      id
      slug
      ...EventGeneralInformation
      ...EventTaxonomies
      ...EventSponsorsAndPartners
      ...EventEleventWithUpsell
      ...EventScreeningUrls
      ...EventPanelists
      ...EventVenue
      ...EventAssociatedEvents
      ...EventFilms
    }
  }
`
