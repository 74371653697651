import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import styled from "@emotion/styled"

import { FilterOptionItem } from "./filter_option_item"
import {
  eventFilterTypeOrder,
  filterTypeOrder,
  filterTypeLabels,
  FilterTypes,
  SearchPageTypes,
} from "../../../../common/constants"

const StyledOptionList = styled.ul`
  height: 100%;
  overflow-y: auto;
  margin-top: ${props => (props.isMobile ? "5px" : "0")};
`

export function FilterByOptions(props) {
  const {
    filterOptions,
    activeFilters,
    handleFilterChange,
    pageType,
    mobileOpen,
    showOptionItems,
    setShowOptionItems,
    filterBadges,
    setFilterBadges,
  } = props

  const isMobile = useMediaQuery("(max-width:900px)")

  const activeFilterOrder =
    pageType === SearchPageTypes.EVENTS ? eventFilterTypeOrder : filterTypeOrder
  const filterOptionItems = activeFilterOrder.map(type => {
    return (
      <FilterOptionItem
        key={type}
        type={type}
        title={filterTypeLabels[type]}
        options={Array.from(filterOptions[type].values())}
        filterValue={activeFilters ? activeFilters[type] : null}
        onFilterChange={handleFilterChange(type)}
        multiSelect={
          type === FilterTypes.GENRE ||
          type === FilterTypes.COUNTRY ||
          type === FilterTypes.LANGUAGE
        }
        mobileOpen={mobileOpen}
        showOptionItems={showOptionItems}
        setShowOptionItems={setShowOptionItems}
        filterBadges={filterBadges}
        setFilterBadges={setFilterBadges}
        pageType={pageType}
        isMobile={isMobile}
      />
    )
  })
  return (
    <StyledOptionList mobileOpen={mobileOpen} isMobile={isMobile}>
      {filterOptionItems}
    </StyledOptionList>
  )
}
