import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { WpDataFactory } from "@common/factories/WPDataFactory"

/**
 * An utility hook that contains functions for getting the featured events for a film
 * entry
 */
export function useFeaturedEvents() {
  const data = useStaticQuery(graphql`
    query AllEventsWithFilmIds {
      allWpEvent {
        nodes {
          id
          slug
          eventFilmScreeningInformation {
            mainFilmId {
              ... on WpFilm {
                id
              }
            }
            shortFilmIds {
              ... on WpFilm {
                id
              }
            }
          }
          eventGeneralInformation {
            title
            startTime
            startDate
            endTime
            endDate
            runtime
            festivalYear {
              slug
              name
            }
          }
          ...EventTaxonomies
        }
      }
    }
  `)

  /**
   * An object that maps film IDs to an array of event objects that the films
   * are featured in
   */
  const eventsPerFilm = useMemo(() => {
    const eventsMap = {}

    const {
      allWpEvent: { nodes: events },
    } = data
    
    events.forEach(event => {
      const {
        id,
        slug,
        eventFilmScreeningInformation: {
          mainFilmId: mainFilm,
          shortFilmIds: shortFilms,
        },
        ...remainingEventInfo
      } = event

      let films = []
      if (mainFilm) {
        films.push(mainFilm)
      }
      if (shortFilms && Array.isArray(shortFilms)) {
        films = films.concat(shortFilms)
      }
      // extract the film ids that this event is a part of
      const filmIds = films.map(film => film.id)

      filmIds.forEach(filmId => {
        const eventEntry = WpDataFactory.instantiateWpEntry({
          id,
          slug,
          ...remainingEventInfo,
        })

        if (eventsMap[filmId] && Array.isArray(eventsMap[filmId])) {
          eventsMap[filmId].push(eventEntry)
        } else {
          eventsMap[filmId] = [eventEntry]
        }
      })
    })
    return eventsMap
  }, [data])

  /**
   * Returns a list of Event entries that feature the specified film
   *
   * @param {FilmEntry} film the film entry
   * @return {Array<EventEntry>} the list of events that feature the film
   */
  const getFeaturedEvents = film => {
    if (!film || film.isEvent) {
      return []
    }

    return eventsPerFilm[film.id] || []
  }

  /**
   * Returns a list of ids for the events that feature the film
   *
   * @param {FilmEntry} film the film entry
   * @return {Array<String>} the list of event ids
   */
  const getFeaturedEventIds = film => {
    return getFeaturedEvents(film).map(event => event.id)
  }

  return {
    getFeaturedEvents,
    getFeaturedEventIds,
  }
}
