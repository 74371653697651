import React from "react"
import { graphql } from "gatsby"

import { FestivalPageLayout } from "@components/layout"
import { PageHead } from "@components/page_head"
import { Search } from "../components/search"
import { useEventState } from "../hooks"

import { ShowtimeAvailabilitiesProvider } from "../context/ShowtimeAvailabilitiesContext"

export default function EventsPage(props) {
  const {
    wipMode: { isWipModeActive, wipModeSettings },
    festivalPageMetadata: { title },
  } = props.data.page
  const { getSearchProps } = useEventState(props)

  return (
    <ShowtimeAvailabilitiesProvider>
      <FestivalPageLayout
        title={title}
        isWipModeActive={isWipModeActive}
        wipModeSettings={wipModeSettings}
      >
        <div className="eventSchedule searchWrapper">
          <Search {...getSearchProps()} />
        </div>
      </FestivalPageLayout>
    </ShowtimeAvailabilitiesProvider>
  )
}

export const Head = ({ data }) => {
  const {
    festivalPageMetadata: { title, description },
  } = data.page

  return <PageHead title={title} description={description} />
}

export const query = graphql`
  query EventsPage {
    localSearchCurrentFestivalEntries {
      index
      store
    }
    page: wpFestivalPage(slug: { eq: "events" }) {
      content
      festivalPageMetadata {
        title
        description
      }
      ...PageWipModeSettings
    }
    eventTypes: allWpEventType {
      nodes {
        name
      }
    }
    events: allWpEvent {
      nodes {
        id
        slug
        ...EventGeneralInformation
        ...EventTaxonomies
        ...EventFilms
        ...EventElevent
      }
    }
    films: allWpFilm(filter: { status: { eq: "publish" } }) {
      nodes {
        id
        slug
        ...FilmGeneralInformation
        ...FilmTaxonomies
      }
    }
  }
`
