import moment from "moment"
import { useState, useEffect } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"

import { useCurrentFestival } from "../useCurrentFestival"
import { sortEntriesByDate } from "../../util/entry_utils"

const getDatesList = (start, end) => {
  const datesList = []

  const currentDay = moment(start)
  const endDay = moment(end)

  while (currentDay.isBefore(endDay) || currentDay.isSame(endDay)) {
    datesList.push(currentDay.format("MM/DD/YYYY"))

    currentDay.add(1, "day")
  }

  return datesList
}

export function useScheduleGridView(props) {
  const { events = [] } = props
  const { startDate, endDate } = useCurrentFestival()

  const datesList = getDatesList(startDate, endDate)
  const isDesktop = useMediaQuery("(min-width: 950px)")
  const isMobile = useMediaQuery("(max-width: 464px)")
  const visibleDates = isDesktop ? 3 : isMobile ? 1 : 2

  const [visibleRange, setVisibleRange] = useState({
    start: 0,
    end: visibleDates,
  })

  useEffect(() => {
    const { start, end } = visibleRange

    const newVisibleDates = isDesktop ? 3 : isMobile ? 1 : 2
    const currVisibleDates = end - start

    if (visibleDates !== currVisibleDates) {
      if (start + newVisibleDates > datesList.length) {
        setVisibleRange({
          end: datesList.length,
          start: datesList.length - newVisibleDates,
        })
      } else {
        setVisibleRange({
          start,
          end: start + newVisibleDates,
        })
      }
    }
  }, [isDesktop, isMobile])

  const getEventsByDate = () => {
    const eventsByDate = {}

    // Create an array for each day in the dates list
    datesList.forEach(date => {
      eventsByDate[date] = []
    })

    events.forEach(entry => {
      if (entry.startDate) {
        const date = moment(entry.startDate).format("MM/DD/YYYY")

        if (eventsByDate.hasOwnProperty(date)) {
          eventsByDate[date].push(entry)
        }
      }
    })

    // Sort each day's events
    Object.keys(eventsByDate).forEach(date => {
      eventsByDate[date] = eventsByDate[date].sort(sortEntriesByDate())
    })

    return eventsByDate
  }

  const handleOnNext = () => {
    if (visibleRange.end + visibleDates <= datesList.length) {
      setVisibleRange({
        start: visibleRange.start + visibleDates,
        end: visibleRange.end + visibleDates,
      })
    } else {
      setVisibleRange({
        end: datesList.length,
        start: datesList.length - visibleDates,
      })
    }
  }

  const handleOnPrevious = () => {
    if (visibleRange.start - visibleDates >= 0) {
      setVisibleRange({
        start: visibleRange.start - visibleDates,
        end: visibleRange.end - visibleDates,
      })
    } else {
      setVisibleRange({
        start: 0,
        end: visibleDates,
      })
    }
  }

  return {
    datesList,
    visibleRange,
    eventsByDate: getEventsByDate(),
    handleOnNext,
    handleOnPrevious,
  }
}
