import React from "react"
import styled from "@emotion/styled"

const FormatedSectionTitle = styled.h3`
  font-size: 2.8rem;
  margin-bottom: 0;

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
`
export function Title({ children }) {
  return <FormatedSectionTitle>{children}</FormatedSectionTitle>
}
