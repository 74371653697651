import React from "react"
import PropTypes from "prop-types"
import { Link } from "../link"
import ImageWrapper, { DefaultImageTypes } from "../image_wrapper"

import { AdSpaceTypes } from "../../common/constants"
import { SponsorDto } from "@common/dto"

export function AdSpace(props) {
  const { type, sponsor, className = "" } = props

  if (!type) {
    throw new Error('The <AdSpace> component requires a property of "type"')
  }

  if (!sponsor?.adImages?.[type]) {
    return null
  }

  return (
    <div className={`adSpace ${className}`}>
      <Link
        to={sponsor.customAdUrl || sponsor.websiteUrl}
        external
        className={`adSpace__link adSpace--${type}`}
      >
        <ImageWrapper
          media={sponsor.getAdImage(type)}
          alt={sponsor.name}
          defaultImageType={DefaultImageTypes.NONE}
          className="adSpace__image"
        />
      </Link>
    </div>
  )
}

AdSpace.propTypes = {
  type: PropTypes.oneOf([
    AdSpaceTypes.HORIZONTAL,
    AdSpaceTypes.VERTICAL,
    AdSpaceTypes.MOBILE,
  ]).isRequired,
  sponsor: PropTypes.instanceOf(SponsorDto).isRequired,
  className: PropTypes.string,
}
