export const ELEVENT_BASE_URL = "https://api.goelevent.com"

export const EleventApiPaths = {
  allShowtimeAvailability: "Showtime/Availability",
  allShowtimeSummarizedAvailability: "Showtime/Allocation/Availability",
  showtimeAvailabilityByEventID: eventId => `Event/${eventId}/Availability`,
  showtimeAvailabilityByShowtimeID: showtimeId =>
    `Showtime/${showtimeId}/Availability`,
  showtimeSectionAllocationAvailability: showtimeId =>
    `Showtime/${showtimeId}/Allocation/Availability`,
}
