export class PQueue {
  constructor() {
    this.items = []
    this.length = 0
  }

  enqueue(item, priority) {
    const newItem = new PQueueItem(item, priority)
    let contains = false

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].priority < newItem.priority) {
        this.items.splice(i, 0, newItem)
        contains = true
        break
      }
    }

    if (!contains) {
      this.items.push(newItem)
    }

    this.length++
  }

  dequeue() {
    if (!this.isEmpty()) {
      this.length--
      return this.items.shift()
    }

    return undefined
  }

  isEmpty() {
    return this.length === 0
  }

  toArray() {
    return this.items.map(el => el.item)
  }
}

export class PQueueItem {
  constructor(item, priority) {
    this.item = item
    this.priority = priority
  }
}
