import { useState, useEffect, useMemo } from "react"

import { useEleventAPI } from "@hooks"

import {
  CategoryTypes,
  EventTypeSlugs,
  FilterTypes,
  ProgramTypes,
  CategoryTypeLabels,
} from "../../common/constants"
import { applyActiveFilters } from "../../util/search_utils"
import {
  sortEntriesByDate,
  filterEventWithRepeatedFilms,
  sortEventsByEventFormat,
  extractFilmsFromEvents,
  sortFilmsByFirstScreening,
} from "../../util/entry_utils"
import { FeatureScreeningEntry } from "../../common/wp-data"

/**
 * A hook that creates a function that will generate the entries for each category row
 *
 * @param {Array<Entry>} eventEntries the event entries
 * @param {Function} setFilters the function to set the current active filters
 * @param {Array<Entry> | undefined} filmEntries the film entries to show in the
 * Program page
 * @returns a function that gets the props for a specific category row
 */
export function useEntryCategories(eventEntries, setFilters, filmEntries) {
  const { fetchAllShowtimeAvailabilities } = useEleventAPI()

  const [isLoaded, setLoadedState] = useState(false)
  const [eventAvailabilities, setEventAvailabilities] = useState({})

  /**
   * A helper function for creating an onClick handler for the
   * see more button on the category rows
   *
   * @param {Object} filters the filters to apply
   * @returns the onClick handler for the see more button for the row
   */
  const _buildHandleSeeMore = (filters, categoryType) => () => {
    if (window) {
      window.scrollTo(0, 0)
    }

    setFilters(filters, categoryType)
  }

  // Represents the combination of all event entries and the earliest screening
  // events for each film
  const allEventEntries = useMemo(() => {
    const filmScreenings = []
    const screeningIds = new Set(eventEntries.map(event => event.id)) // Create a Set to track unique added screenings

    filmEntries?.forEach(film => {
      // Check if the film has any screening events
      if (film.screeningEventsCount > 0) {
        let screeningToAdd = null

        if (film.hasMixedScreenings) {
          // If the film has both in-person and VOD screenings, only show the
          // first in-person screening
          screeningToAdd = film.screeningEvents
            .filter(screening => !screening.isOnline)
            .sort(sortEntriesByDate())[0]

          if (filmScreenings.every(entry => entry.id !== screeningToAdd.id)) {
            filmScreenings.push(screeningToAdd)
          }
        } else {
          // Otherwise, show the earliest screening
          screeningToAdd = film.screeningEvents.sort(sortEntriesByDate())[0]
        }

        // Check if the screening has already been added by checking the ID
        if (screeningToAdd && !screeningIds.has(screeningToAdd.id)) {
          filmScreenings.push(screeningToAdd)
          screeningIds.add(screeningToAdd.id)
        }
      }
    })

    return eventEntries.concat(filmScreenings)
  }, [eventEntries, filmEntries])

  /**
   * Once the component has finished its first render, fetch the event availabilities
   * from Elevent and store them in state.
   */
  useEffect(() => {
    setLoadedState(false)

    fetchAllShowtimeAvailabilities().then(results => {
      setLoadedState(true)

      // For each event result, store the url name with the boolean of whether
      // or not there are public tickets left
      const availabilities = {}
      results.forEach(result => {
        availabilities[result.EventUrlName] =
          result?.PublicAllocationSoldOutPercentage < 100
      })

      setEventAvailabilities(availabilities)
    })
  }, [])

  /**
   * Sort the entries by whether or not they have tickets available. If they do not,
   * the event is moved to the end of the list
   *
   * @param {EventEntry} entryA the first event entry
   * @param {EventEntry} entryB the second event entry
   */
  const sortEntriesWithTicketAvailability = (entryA, entryB) => {
    const { urlName: urlNameA } = entryA.ticketingInfo?.elevent || {}
    const { urlName: urlNameB } = entryB.ticketingInfo?.elevent || {}

    const hasAvailabilityA = !!eventAvailabilities[urlNameA]
    const hasAvailabilityB = !!eventAvailabilities[urlNameB]

    if (!hasAvailabilityA && hasAvailabilityB) {
      return 1
    } else if (hasAvailabilityA && !hasAvailabilityB) {
      return -1
    } else {
      return 0
    }
  }

  /**
   * A helper function to get the props for each Category Row component, such
   * as the displayed label, the event entries, and whether or not the See More
   * button is visible
   */
  const getCategoryRowProps = categoryType => {
    const rowProps = {
      label: "",
      entries: [],
      seeMoreVisible: false,
      handleSeeMore: null,
    }

    let filters, entries
    switch (categoryType) {
      case CategoryTypes.NARRATIVE_FEATURE: {
        filters = {
          [FilterTypes.FILM_TYPE]: "Narrative",
          [FilterTypes.FILM_FORMAT]: "Features",
        }

        const eventEntries = applyActiveFilters(allEventEntries, filters)
          .filter(
            entry =>
              !entry.isTentpole &&
              entry.eventTypeSlug !== EventTypeSlugs.SPECIAL_PRESENTATION
          )
          .filter(entry => !!entry.startDate)
          .sort(sortEntriesWithTicketAvailability)

        const filmsWithScreenings = extractFilmsFromEvents(
          eventEntries,
          filmEntries
        ).sort(sortFilmsByFirstScreening)

        rowProps.label = CategoryTypeLabels[categoryType]
        rowProps.entries = filmsWithScreenings.slice(0, 3)
        rowProps.seeMoreVisible = filmsWithScreenings.length > 3
        rowProps.handleSeeMore = _buildHandleSeeMore(filters, categoryType)
        rowProps.filters = filters
        break
      }
      case CategoryTypes.DOCUMENTARY_FEATURE: {
        filters = {
          [FilterTypes.FILM_TYPE]: "Documentary",
          [FilterTypes.FILM_FORMAT]: "Features",
        }

        const eventEntries = applyActiveFilters(allEventEntries, filters)
          .filter(
            entry =>
              !entry.isTentpole &&
              entry.eventTypeSlug !== EventTypeSlugs.SPECIAL_PRESENTATION // ignore tentpole entries
          )
          .filter(entry => !!entry.startDate)
          .sort(sortEntriesWithTicketAvailability)

        const filmsWithScreenings = extractFilmsFromEvents(
          eventEntries,
          filmEntries
        ).sort(sortFilmsByFirstScreening)

        rowProps.label = CategoryTypeLabels[categoryType]
        rowProps.entries = filmsWithScreenings.slice(0, 3)
        rowProps.seeMoreVisible = filmsWithScreenings.length > 3
        rowProps.handleSeeMore = _buildHandleSeeMore(filters, categoryType)
        rowProps.filters = filters
        break
      }
      case CategoryTypes.TENTPOLES:
        const eventEntries = applyActiveFilters(allEventEntries, filters)
          .filter(entry => entry.isTentpole)
          .filter(entry => !!entry.startDate)
          .sort(sortEntriesWithTicketAvailability)
        const filmsWithScreenings = extractFilmsFromEvents(
          eventEntries,
          filmEntries
        ).sort(sortFilmsByFirstScreening)
        rowProps.label = CategoryTypeLabels[categoryType]
        rowProps.entries = filmsWithScreenings.slice(0,3);
        rowProps.seeMoreVisible = false
        break
      case CategoryTypes.EVENT:
        filters = {
          [FilterTypes.PROGRAM_TYPE]: ProgramTypes.EVENTS_ONLY,
        }

        entries = applyActiveFilters(allEventEntries, filters)
          .filter(
            entry => !entry.isTentpole // ignore tentpole entries
          )
          .filter(entry => !!entry.startDate)
          .sort(sortEntriesByDate({ includePassed: false }))
          .sort(sortEntriesWithTicketAvailability)

        rowProps.label = CategoryTypeLabels[categoryType]
        rowProps.entries = entries.slice(0, 3)
        rowProps.seeMoreVisible = entries.length > 3
        rowProps.handleSeeMore = _buildHandleSeeMore(filters, categoryType)
        rowProps.filters = filters
        break
      case CategoryTypes.SHORTS_PROGRAMS:
        filters = {
          [FilterTypes.EVENT_TYPE]: "Shorts Programs",
        }

        entries = applyActiveFilters(allEventEntries, filters)
          .filter(entry => !!entry.startDate)
          .sort(sortEventsByEventFormat())
          .sort(sortEntriesByDate({ includePassed: false }))
          .sort(sortEntriesWithTicketAvailability)
          .sort(sortEventsByEventFormat())

        rowProps.label = CategoryTypeLabels[categoryType]
        rowProps.entries = filterEventWithRepeatedFilms(entries, 3)
        rowProps.seeMoreVisible = entries.length > 3
        rowProps.handleSeeMore = _buildHandleSeeMore(filters, categoryType)
        rowProps.filters = filters
        break
      case CategoryTypes.SPECIAL_PRESENTATIONS: {
        filters = {
          [FilterTypes.EVENT_TYPE]: "Special Presentations",
        }

        const eventEntries = applyActiveFilters(allEventEntries, filters)
          .filter(event => !event.isTentpole)
          .filter(entry => !!entry.startDate)
          .sort(sortEntriesWithTicketAvailability)

        const mixedEntries = extractFilmsFromEvents(
          eventEntries,
          filmEntries
        ).sort(sortFilmsByFirstScreening)

        rowProps.label = CategoryTypeLabels[categoryType]
        rowProps.entries = mixedEntries.slice(0, 3)
        rowProps.seeMoreVisible = mixedEntries.length > 3
        rowProps.handleSeeMore = _buildHandleSeeMore(filters, categoryType)
        rowProps.filters = filters
        break
      }
      case CategoryTypes.PANEL_WORKSHOP:
        filters = {
          [FilterTypes.EVENT_TYPE]: "Panels/Workshops",
        }

        entries = applyActiveFilters(allEventEntries, filters)
          .filter(entry => !!entry.startDate)
          .sort(sortEventsByEventFormat())
          .sort(sortEntriesByDate({ includePassed: false }))
          .sort(sortEntriesWithTicketAvailability)

        rowProps.label = CategoryTypeLabels[categoryType]
        rowProps.entries = entries.slice(0, 3)
        rowProps.seeMoreVisible = entries.length > 3
        rowProps.handleSeeMore = _buildHandleSeeMore(filters, categoryType)
        rowProps.filters = filters
        break
      default:
        break
    }

    return rowProps
  }

  return { isLoaded, getCategoryRowProps }
}
