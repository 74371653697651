import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Link } from "../link"
import ImageWrapper, { DefaultImageTypes } from "../image_wrapper"
import { MediaDto } from "@common/dto"

export function AaiffLogo({ className = "", imgClassName = "" }) {
  const data = useStaticQuery(graphql`
    query CurrentAaiffLogo {
      aaiffSettings: wpAaiffSiteSettings {
        websiteSettings {
          aaiffLogo: aaifflogo {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `)

  const {
    aaiffSettings: {
      websiteSettings: { aaiffLogo },
    },
  } = data

  const logoMedia = useMemo(
    () => MediaDto.fromWpMediaItem(aaiffLogo),
    [aaiffLogo?.sourceUrl]
  )

  return (
    <Link to="/" className={className}>
      <ImageWrapper
        media={logoMedia}
        altText="AAIFF Logo"
        objectFit="contain"
        className={imgClassName}
        defaultImageType={DefaultImageTypes.NONE}
      />
    </Link>
  )
}
