import React from "react"

import { Button } from "../../../buttons"
import { ProgramCard } from "@components/cards"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import styled from "@emotion/styled"
import { useMobileDetect } from "@hooks"
import { CustomArrowButton } from "@components/multi_carousel"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

const RowContainer = styled("li")`
  margin-bottom: calc(100px - 3.5rem);
  @media (max-width: 900px) {
    margin-bottom: calc(40px - 1.5rem);
  }
  button {
    color: #000000;
    font-size: 0.875rem;
    font-weight: 800;
    text-decoration: underline;
    text-transform: capitalize;

    @media (min-width: 901px) {
      font-size: 20px;
    }
  }

  // Do not display margin-right for last carousel item, to maintain alignment with rest of page.
  .lastCarouselItem {
    margin-right: 0px;
  }
`
const CategoryTitleContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  overflow-x: hidden;

  .aaiffBtn {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const CategoryTitle = styled("h2")`
  font-size: 1.125rem;
  font-weight: 800;
  margin: 0;

  @media (min-width: 901px) {
    font-size: 1.75rem;
  }
`

const CarouselItem = styled("div")`
  margin-right: 1.5rem;

  @media (min-width: 1280px) {
    max-width: 26rem;
  }
`

const ViewAllButton = styled(Button)`
  text-wrap: nowrap;
`

export function CategoriesRow(props) {
  let {
    label,
    entries,
    seeMoreVisible,
    handleSeeMore,
    filters,
    isLoading,
    updateBadges,
  } = props
  const { actualDeviceType } = useMobileDetect()

  const handleSeeMoreAndUpdateBadges = () => {
    handleSeeMore()
    updateBadges(filters)
  }

  //need the length to prevent carousel from breaking when there isn't enough items to fill the space.
  const entriesLength = entries.length

  if (!entries || entriesLength === 0 || isLoading) {
    return null
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1000 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1000, min: 750 },
      items: 2,
      partialVisibilityGutter: entriesLength <= 2 ? 0 : 35,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 }, // previously, max: 560
      items: 1,
      partialVisibilityGutter: entriesLength <= 1 ? 0 : 30,
    },
  }

  return (
    <RowContainer>
      <CategoryTitleContainer>
        <CategoryTitle>{label}</CategoryTitle>
        {seeMoreVisible ? (
          <ViewAllButton
            onClick={handleSeeMoreAndUpdateBadges}
            variant="secondary"
            quiet
            offset="atEnd"
          >
            View all
          </ViewAllButton>
        ) : null}
      </CategoryTitleContainer>
      <Carousel
        ssr={true}
        deviceType={actualDeviceType}
        responsive={responsive}
        partialVisible={true}
        draggable={true}
        slidesToSlide={1}
        swipeable={true}
        arrows={true}
        customLeftArrow={
          <CustomArrowButton
            actualDeviceType={actualDeviceType}
            onClick={() => onClick()}
            direction="left"
            icon={<FaChevronLeft />}
            size="1.25rem"
          />
        }
        customRightArrow={
          <CustomArrowButton
            actualDeviceType={actualDeviceType}
            onClick={() => onClick()}
            direction="right"
            icon={<FaChevronRight />}
            size="1.25rem"
          />
        }
      >
        {entries.map((entry, index) => (
          <CarouselItem key={entry.title} className={(index === entries.length - 1) ? "lastCarouselItem" : ""}>
            <ProgramCard
              entry={entry}
              linkProps={{ state: { fromProgram: true } }}
            />
          </CarouselItem>
        ))}
      </Carousel>
    </RowContainer>
  )
}
