import { createContext, useState, useEffect } from "react";
import { useEleventAPI } from "../hooks";

export const ShowtimeAvailabilitiesContext = createContext({
  getAvailabilities: () => {
    console.error('ShowtimeAvailabilitiesContext used outside of ShowtimeAvailabilitiesProvider');
    return {};
  }
});

/**
 * The TicketAvailabilityProvider component provides ticket availability data 
 * about events from elevent to the /program and /event pages without need for prop drilling.
 */
export function ShowtimeAvailabilitiesProvider({ children }) {  
  const [availabilities, setAvailabilities] = useState([]);
  const {fetchAllShowtimeAvailabilities} = useEleventAPI();

  useEffect(() => {
    (async () => {
      const fetchedAvailabilitiesArray = await fetchAllShowtimeAvailabilities();
      const fetchedAvailabilitiesObject = {};
      fetchedAvailabilitiesArray.forEach(availability => fetchedAvailabilitiesObject[availability.ShowtimeId] = availability)
      setAvailabilities(fetchedAvailabilitiesObject);
    })();
  }, [])

  return (
    <ShowtimeAvailabilitiesContext.Provider value={{
      getAvailabilities: () => availabilities || {},
    }}>
      {children}
    </ShowtimeAvailabilitiesContext.Provider>
  )
}