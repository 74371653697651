import React from "react"

export const NoContent = () => {
  return (
    <div className="categoryRows--noContent">
      <h3>There are no films or events available at this time.</h3>
      <span>Please check back again later</span>
    </div>
  )
}
