import React from "react"
import styled from "@emotion/styled"
import { FILM_DETAILS_ORDER, DISPLAY_FORMATS, WpEntryTypes } from "@constants"
import { FilmDetailsItem } from "./film_details_item"
import { SocialLinks } from "../../../social_links"

/**
 * FilmDetails Component
 *
 * This component displays the details of a film based on the provided `entry` prop.
 * It dynamically generates FilmDetailsItem components for each detail specified
 * in the FILM_DETAILS_ORDER map.
 *
 * **** NOTE: *****
 * ENABLE_SMALLER_MOBILE_FONT: uncomment marked styles with this key
 * to enable smaller font-related sizes on mobile/small screens.
 */
export function FilmDetails({ entry }) {
  /**
   * Only render component if entry is a "film".
   *
   * Mainly checks if `entry`: (1) is not a FILM or FEATURE_SCREENING,
   * or (2) if it is a FEATURE_SCREENING and does not have a `entry.__data.mainFilm` property.
   *
   * Cannot just check for both (1) && (2), since some "films" also do not have `.mainFilm` .
   *
   * EDGE CASE: some FEATURE_SCREENING entries are not films, such as
   * aaiff46/centerpiece-presentation-music-night-out ,
   * which do not have a `.mainFilm` .
   *
   * Backup check - length of details available to entry.
   */
  if (
    !entry.oneOf([WpEntryTypes.FILM, WpEntryTypes.FEATURE_SCREENING]) ||
    (entry.oneOf([WpEntryTypes.FEATURE_SCREENING]) && !entry.__data.mainFilm)
  ) {
    return null
  }

  const filmDetailsItems = []

  for (const [detailGetterName, detailInfo] of FILM_DETAILS_ORDER) {
    const [label, format] = detailInfo
    const filmDetailsItem = (
      <FilmDetailsItem
        key={detailGetterName}
        detailLabel={label}
        format={format}
        value={entry[detailGetterName]}
        directorsBio={
          format === DISPLAY_FORMATS.DIRECTORS_BIO ? entry.directorsBio : null
        }
      />
    )
    filmDetailsItems.push(filmDetailsItem)
  }

  /**
   * Backup check for exceptions that "look like" films but are not films
   */
  if (filmDetailsItems.length < 3) {
    return null
  }

  return (
    <FDSection>
      <FDHeader>Film Details</FDHeader>
      <FDList>{filmDetailsItems}</FDList>

      <SocialLinks
        links={entry.socialLinks}
        sectionTitle={"Connect with the Film"}
      />
    </FDSection>
  )
}

/**
 * margins-top, margin-bottom: Figma specifies 80px, if adjacent components have margins, should collapse.
 * - Desktop: 80px
 * - Mobile: 60px
 */

export const SectionStyles = `
  margin-top: 5em; // 80px desktop / 60px mobile
  margin-bottom: 5em; // 80px desktop / 60px mobile

  @media only screen and (max-width: 900px) {
    // font-size: 0.75em; 				// ** scaling should cascade to all contained elements ENABLE_SMALLER_MOBILE_FONT
  }
`
const FDSection = styled.section`
  border-top: 3px solid #0a0a0a;
  ${SectionStyles}
`
export const HeaderStyles = `
  color: #0a0a0a;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 2em; // 32px desktop / 24px mobile
  line-height: 1.25em; // 40px desktop / 30px mobile
  margin-top: 1.25em; // 40px desktop / 20px mobile
  margin-bottom: 1.25em;

  @media only screen and (max-width: 900px) {
    // margin-top: 0.83em;				// different scaling ENABLE_SMALLER_MOBILE_FONT
    // margin-bottom: 0.83em;    // different scaling ENABLE_SMALLER_MOBILE_FONT
  }
`

const FDHeader = styled.h2`
  ${HeaderStyles}
`

export const ListStyles = `
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.25em; // 20px desktop / 15px mobile
`

const FDList = styled.ol`
  ${ListStyles}
`
