import {
  FaSquareXTwitter as TwitterIcon,
} from "react-icons/fa6"

import {
  FaFacebookSquare as FacebookIcon,
  FaInstagramSquare as InstagramIcon,
  FaYoutubeSquare as YouTubeIcon,
  FaTwitch as TwitchIcon,
} from "react-icons/fa"

export {
	FacebookIcon,
	InstagramIcon,
	YouTubeIcon,
	TwitchIcon,
	TwitterIcon
}