import moment from "moment"

import {
  parseCastCrewString,
  extractTaxonomiesFromString,
} from "../../util/film_event_utils"
import { sortEntries } from "../../util/search_utils"
import { EventFormatSlugs, SortOrders, WpEntryTypes } from "../constants"

import { BaseEntry } from "./BaseEntry"
import { FeatureScreeningEntry } from "./FeatureScreeningEntry"
import { ShortsProgramEntry } from "./ShortsProgramEntry"
import { EventEntry } from "./EventEntry"

/**
 * This class represents a single Film entry.
 *
 * @class
 */
export class FilmEntry extends BaseEntry {
  constructor(id, slug, data) {
    super(id, slug, data)

    this.type = WpEntryTypes.FILM
  }

  get filmTypes() {
    return extractTaxonomiesFromString(this.__data.filmType)
  }

  get filmFormat() {
    return this.__data.format || null
  }

  get countries() {
    return extractTaxonomiesFromString(this.__data.countries)
  }

  get genres() {
    return extractTaxonomiesFromString(this.__data.genres)
  }

  get languages() {
    return extractTaxonomiesFromString(this.__data.languages)
  }

  get subtitledLanguages() {
    return extractTaxonomiesFromString(this.__data.subtitledLanguages)
  }

  get contentAdvisories() {
    return extractTaxonomiesFromString(this.__data.contentAdvisories)
  }

  get premiereStatus() {
    return this.__data.premiereStatus || null
  }

  get keywords() {
    return extractTaxonomiesFromString(this.__data.additionalTags)
  }

  get socialLinks() {
    return this.__data.socialLinks
  }

  get bonusContent() {
    return this.__data.bonusContent || []
  }

  get trailerLink() {
    return this.__data.trailerLink
  }

  get relatedEvents() {
    if (!this.__data.featuredEvents) {
      return []
    }

    return this.__data.featuredEvents.map(event => ({
      id: event.id,
      title: event.acf.title,
      slug: event.slug,
      startDate: event.acf.startDate,
      startTime: event.acf.startTime,
    }))
  }

  get relatedEventIds() {
    return this.__data.featuredEvents?.map(event => event.id) || []
  }

  get directors() {
    return (
      (this.__data.castAndCrew &&
        parseCastCrewString(this.__data.castAndCrew.directors)) ||
      []
    )
  }

  get directorsBio() {
    return this.__data.castAndCrew && this.__data.castAndCrew.directorBio
  }

  get producers() {
    return (
      this.__data.castAndCrew &&
      parseCastCrewString(this.__data.castAndCrew.producers)
    )
  }

  get writers() {
    return (
      this.__data.castAndCrew &&
      parseCastCrewString(this.__data.castAndCrew.writers)
    )
  }

  get castMembers() {
    return (
      this.__data.castAndCrew &&
      parseCastCrewString(this.__data.castAndCrew.cast)
    )
  }

  get crewMembers() {
    if (
      this.__data.castAndCrew &&
      typeof this.__data.castAndCrew.crewMembers === "string"
    ) {
      const crewMemberData = this.__data.castAndCrew.crewMembers

      if (crewMemberData.includes(";")) {
        // Is a semicolon separated list. Will only separate by semicolons and not on commas
        return crewMemberData.split(";")
      } else if (crewMemberData.includes(",")) {
        // is a semicolor separated list. will separate by commas
        return crewMemberData.split(",")
      } else {
        // Unknown list type, will just be a plain string
        return [crewMemberData]
      }
    }

    return null
  }

  get year() {
    return this.__data.year
  }

  /**
   * Get a list of all unique screening events for this film, sorted by date
   * and screening type.
   * @returns {EventEntry[] | undefined} A list of all screening events for this film
   * or undefined if there are no screening events
   */
  get screeningEvents() {
    return this.__data.screeningEvents
  }

  /**
   * A helper function that returns the number of feature screenings this film is a part of
   * @returns {number} The number of feature screenings this film is a part of
   */
  get screeningEventsCount() {
    return this.__data.screeningEvents?.length || 0
  }

  /**
   * A helper function that determines if the film is screened in any shorts programs
   * @returns {boolean} `true` if the film belongs to a shorts program, otherwise `false`
   */
  get hasShortsProgram() {
    return (
      this.screeningEvents &&
      this.screeningEvents.some(
        event => event.type === WpEntryTypes.SHORTS_PROGRAM
      )
    )
  }

  /**
   * Add a screening event to the list of screening events for this film.
   * Each time a screening event is added, the list of screening events is sorted
   * by both date and screening type. The sorting order is:
   * - Sorted by date
   * - In-person screenings first
   * - On-demand screenings second
   * This ensures that the earliest screening is always shown first, prioritizing
   * in-person screenings over on-demand screenings.
   * @param {FeatureScreeningEntry | ShortsProgramEntry} event A screening event object
   */
  addScreeningEvent(event) {
    if (!this.__data.screeningEvents) {
      this.__data.screeningEvents = []
      this.__data.screeningEventIds = new Set()
    }

    if (!this.__data.screeningEventIds.has(event.id)) {
      this.__data.screeningEventIds.add(event.id)

      const unsortedScreenings = this.__data.screeningEvents
      unsortedScreenings.push(event)

      const sortedByDate = sortEntries(unsortedScreenings, SortOrders.DATE)
      const sortedByScreeningType = sortEntries(
        sortedByDate,
        SortOrders.SCREENING_TYPE
      )

      this.__data.screeningEvents = sortedByScreeningType
    }
  }

  /**
   * A helper function that returns the first screening event for this film.
   * This function prioritizes events that are Shorts Programs, events that are In Person
   * or Live Stream, and has the earliest start date.
   *
   * If a time filter argument is provided, this function will only return events
   * that are after the specified date. If all events have already passed, or if
   * no time filter is provided, this function will return the first event in the
   * list of screening events.
   *
   * @param {moment.Moment} timeFilter An optional time filter to only return events
   * that are after the specified date. For example, you may call `getFirstScreening(moment())`
   * to only return events that are after the current time.
   * @returns {EventEntry | null} The first screening event for this film or `null`
   */
  getFirstScreening(timeFilter) {
    if (!this.screeningEvents) {
      return null
    }

    const eventsByPreference = this.screeningEvents.sort(
      _sortEventsByPreferredScreeningOrder
    )

    if (timeFilter) {
      const upcomingEvents = eventsByPreference.filter(event =>
        moment(`${event.startDate} ${event.startTime}`).isAfter(timeFilter)
      )

      return upcomingEvents[0] ?? eventsByPreference[0]
    } else {
      return eventsByPreference[0]
    }
  }
}

/**
 * This helper function sorts the events by following preferences:
 * - Shorts Programs are preferred over other event types
 * - In Person and Live Streams are preferred over On Demand
 * - Earlier start dates are preferred
 */
const _sortEventsByPreferredScreeningOrder = (eventA, eventB) => {
  const isShortProgramA = eventA.type === WpEntryTypes.SHORTS_PROGRAM
  const isShortProgramB = eventB.type === WpEntryTypes.SHORTS_PROGRAM

  // Compare the type of events
  if (isShortProgramA && !isShortProgramB) {
    return -1
  } else if (!isShortProgramA && isShortProgramB) {
    return 1
  } else {
    const isOnDemandA = eventA.eventFormatSlug === EventFormatSlugs.ON_DEMAND
    const isOnDemandB = eventB.eventFormatSlug === EventFormatSlugs.ON_DEMAND

    // Compare the view format of the event
    if (isOnDemandA && !isOnDemandB) {
      return 1
    } else if (!isOnDemandA && isOnDemandB) {
      return -1
    } else {
      const startA = moment(`${eventA.startDate} ${eventA.startTime}`)
      const startB = moment(`${eventB.startDate} ${eventB.startTime}`)

      // Compare the start date of the event
      if (startA.isBefore(startB)) {
        return -1
      } else if (startA.isAfter(startB)) {
        return 1
      } else {
        return 0
      }
    }
  }
}
