import React from "react"

export default function ScheduleHeaderButton({
  isVisible,
  onClick,
  className,
  children,
}) {
  if (!isVisible) return null

  return (
    <button className={`schedule__arrow ${className}`} onClick={onClick}>
      {children}
    </button>
  )
}
