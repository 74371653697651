import React from "react"
import { graphql } from "gatsby"
import moment from "moment"

import { FestivalPageLayout } from "@components/layout"
import { PageHead } from "@components/page_head"
import UpdatesIndexItem from "../components/updates/updates_index_item"

export default function LatestNews({ data }) {
  const {
    allWpUpdate: { nodes },
    page: {
      wipMode: { isWipModeActive, wipModeSettings },
      festivalPageMetadata: { title },
    },
  } = data
  let indexItems = nodes
    .sort((nodeA, nodeB) => {
      const {
        updatesGeneralInformation: { date: dateA },
      } = nodeA
      const {
        updatesGeneralInformation: { date: dateB },
      } = nodeB

      const momentA = moment(dateA),
        momentB = moment(dateB)
      if (momentA.isBefore(momentB)) {
        return 1
      } else if (momentA.isAfter(momentB)) {
        return -1
      } else {
        return 0
      }
    })
    .map(node => {
      const {
        updatesGeneralInformation: { title, date, logline },
      } = node

      return (
        <UpdatesIndexItem
          key={`update-${date}`}
          date={date}
          title={title}
          logline={logline}
        />
      )
    })

  if (indexItems.length === 0) {
    indexItems = <h3>It looks like there are no updates</h3>
  }

  return (
    <FestivalPageLayout
      title={title}
      isWipModeActive={isWipModeActive}
      wipModeSettings={wipModeSettings}
    >
      <h1 className="update__title">All Updates</h1>

      <section className="update__index">
        <ul className="update__indexList">{indexItems}</ul>
      </section>
    </FestivalPageLayout>
  )
}

export const Head = ({ data }) => {
  const {
    page: {
      festivalPageMetadata: { title, description },
    },
  } = data

  return <PageHead title={title} description={description} />
}

export const query = graphql`
  query LatestNewsPage {
    allWpUpdate {
      nodes {
        updatesGeneralInformation {
          title
          logline
          date
        }
      }
    }
    page: wpFestivalPage(slug: { eq: "latest-news" }) {
      festivalPageMetadata {
        description
        title
      }
      ...PageWipModeSettings
    }
  }
`
