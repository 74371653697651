import React from "react"
import moment from "moment"
import { Link } from "../../link"
import { useSelectFestivalByYear } from "../../../hooks"
import { EventTypeSlugs } from "../../../common/constants"

export default function ScheduleItem({ event, className }) {
  const {
    title,
    slug,
    runtime,
    startDate,
    startTime,
    endDate,
    endTime,
    festivalYear,
    eventTypeSlug,
    mainFilm,
  } = event

  const festival = useSelectFestivalByYear()(festivalYear)

  /**
   * Converts the given `duration` into the number of hours and minutes rounded
   * to the nearest quarter hour.
   *
   * @param {number} duration an amount of minutes to be converted
   * @return {Object} an object containing the hours and minutes rounded to the nearest
   * quarter hour
   */
  const getHoursAndMinutes = duration => {
    const hours = Math.floor(duration / 60)
    const unroundedMinutes = duration - hours * 60

    const minutes =
      unroundedMinutes >= 52.5
        ? // Round to 60 minutes if the nearest quarter is 60 minutes instead of 45
          60
        : // Calculate and round to the nearest quarter hour
          (Math.round(unroundedMinutes / 15) % 4) * 15

    return {
      hours,
      minutes,
    }
  }

  const start = moment(`${startDate} ${startTime}`)
  const end = moment(`${endDate} ${endTime}`)
  const noon = moment(`${startDate} 12:00:00`)

  const timeSinceNoon = getHoursAndMinutes(
    Math.abs(noon.diff(start, "minutes"))
  )
  const totalDuration = getHoursAndMinutes(Math.abs(end.diff(start, "minutes")))

  const getBorderColorType = () => {
    switch (eventTypeSlug) {
      case EventTypeSlugs.TENTPOLES:
      case EventTypeSlugs.SPECIAL_PRESENTATION:
      case EventTypeSlugs.SHOWCASE:
        return "special-presentation"
      case EventTypeSlugs.MASTERCLASS:
      case EventTypeSlugs.PANEL_WORKSHOP:
        return "panel"
      case EventTypeSlugs.Q_A:
        return "qa"
      case EventTypeSlugs.SOCIAL_EVENTS:
        return "social-event"
      case EventTypeSlugs.SPECIAL_EVENT:
      default:
        return "special-event"
    }
  }

  const toPath = mainFilm?.slug
    ? `/${festival.slug}/${mainFilm.slug}#${slug}`
    : `/${festival.slug}/${slug}`

  return (
    <li
      className={`
        schedule__item 
        schedule__item--${getBorderColorType()}
        gridItemPos--hours-${timeSinceNoon.hours} 
        gridItemPos--minutes-${timeSinceNoon.minutes} 
        gridItemHeight--hours-${totalDuration.hours}
        gridItemHeight--minutes-${totalDuration.minutes}
        ${className ? className : ""}
      `}
    >
      <Link to={toPath}>
        <div className="schedule__imgContainer">
          <div className="schedule__imgContainerOverlay">
            <div className="schedule__item__content">
              <div>
                <div className="redBar">
                  <p className="time">
                    {start.format("hh:mm A")} |{" "}
                    {Math.abs(end.diff(start, "minutes"))} Min
                  </p>
                </div>
                <h2
                  className={`title ${
                    title.length > 35
                      ? "title--fit2"
                      : title.length > 25
                      ? "title--fit1"
                      : ""
                  }`}
                >
                  {title}
                </h2>
              </div>

              {/* <div className="venue">
                <FaMapMarkerAlt />
                <span>{displayedVenueName}</span>
              </div> */}
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}
