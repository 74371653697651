import React from 'react';
import styled from '@emotion/styled';
import { IconContext } from 'react-icons';

/*
Custom Arrows made for react-multi-carousel 

Props explanation: 
onclick is a function that comes from react-multi-library, no need to write handleClick or anything.
top, topTablet, topDesktop is to control the position of arrow, 
should be added as a string "calc(percentage - height of the arrow)"
currently height of the arrow is 3rem. 
Default is 50%, but if the carousel item has label at the bottom, 
to have it center of the image only this will need to be adjust accordingly. 
*/

const ArrowButton = styled('button')`
    display:${(props => props.display ? "flex":"none")};
    left:${(props => props.left ? props.left:"")};
    right:${(props => props.right ? props.right:"")};
    top: ${(props) => (props.top ? props.top : "calc(50% - 3rem)")};
    position:absolute;
    cursor: pointer;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    width: 3rem;
    height: 3rem;
    background-color:transparent;
    padding:2rem;
    border:none;
    box-shadow:none;

    @media(min-width:560px){
        top: ${(props) => (props.topTablet ? props.topTablet : "calc(50% - 3rem)")};
    }

    @media(min-width:1280px){
        top: ${(props) => (props.topDesktop ? props.topDesktop : "calc(50% - 3rem)")};
    }
`

const ArrowIconContainer = styled('div')`
    background-color:${(props) => (props.backgroundColor ? props.backgroundColor : "#333333")};
    color:${(props) => (props.color ? props.color : "#FFFFFF")};
    border-radius:50%;
    min-width: 2.65rem;
    min-height: 2.65rem;
    opacity: 1;
    display:flex;
    justify-content: center;
    align-items: center;
`
//function to get actual device type, should be coming from useMobileDetect actualDeviceType state. 

const showArrow = (deviceType)=>{
    return deviceType === 'desktop' ? true:false;
}

export const CustomArrowButton = (props) => {
    const { actualDeviceType, onClick,
        direction, icon, top, 
        topTablet, topDesktop,
        size
    } = props
    const isLeft = direction === 'left';
    const isRight = direction === 'right';
    
        return (
        <ArrowButton
            display={showArrow(actualDeviceType)}
            onClick={onClick}
            left={isLeft ? "0" : ""}
            right={isRight ? "0" : ""}
            top={top}
            topTablet={topTablet}
            topDesktop={topDesktop}
        >
            <ArrowIconContainer>
            <IconContext.Provider value={{ size: size ? size:'1.5rem'}}>
                {icon}
            </IconContext.Provider>
            </ArrowIconContainer>
        </ArrowButton>
        );
    };


