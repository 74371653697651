import React from "react"
import { Button } from "@components/@aaiff"
import { FaShoppingCart } from "react-icons/fa"
import styled from "@emotion/styled"

const AccountButton = styled(Button)`
  padding: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0.05px !important;
  text-wrap: nowrap !important;
`

const SignInButton = styled(Button)`
  padding: 0 !important;
  font-size: 1.36rem !important;
  font-weight: bold !important;
  color: $black !important;
  letter-spacing: 1px !important;
  box-shadow: none !important;
  

  @media (min-width: 450px) {
    text-wrap: nowrap !important;
    font-size: 16px !important;
    padding: 8px 16px !important; 
    border: 1px solid #fcfcfc !important;
    border-radius: 3px !important;
  }

  &&:hover {
    background-color: #333333;
    border-radius: 4px;
    text-decoration: none;
  }
`

/**
 * A component that represents a list of available tickets for
 * a specific event. Displays a warning message if there are no
 * tickets available.
 *
 * @param {String} urlName the elevent urlName for the event
 * @param {String} showtimeId the showtime ID for the event
 */
export const EleventTicket = props => {
  const { urlName, showtimeId, ...rest } = props

  return (
    <elevent-ticket-widget
      event={urlName}
      showtime={showtimeId}
      {...rest}
    ></elevent-ticket-widget>
  )
}

export const EleventTicketButton = props => {
  const { urlName, showtimeId, ...rest } = props

  return (
    <elevent-ticket-button-widget
      event={urlName}
      showtime={showtimeId}
      {...rest}
    ></elevent-ticket-button-widget>
  )
}

/**
 * A component that represents a list of packages available for purchase.
 * Can optionally be filtered by package and/or allocation type
 *
 * @param {String} [packageId] the specific package type to show. Optional.
 * @param {String} [allocationId] the specific allocation type to show. Optional.
 * @returns
 */
export const EleventPackageList = props => {
  const { packageId, allocationId, ...rest } = props

  return (
    <elevent-package-widget
      package={packageId}
      allocationId={allocationId}
      {...rest}
    ></elevent-package-widget>
  )
}

/**
 * A button component for adding a specific package and allocation to the cart
 *
 * @param {String} packageId the package to purchase
 * @param {String} allocationId the allocation to purchase
 * @param {Boolean} [isGift] indicates if the package should be added to the
 * cart as a gift. Default: `false`
 */
export const EleventPackageButton = props => {
  const {
    children,
    packageId,
    allocationId,
    isGift,
    className,
    buttonClassName,
    ...rest
  } = props

  return (
    <elevent-package-button-widget
      package={packageId}
      allocation={allocationId || "public"}
      gift={isGift}
      class={className}
    >
      <Button {...rest} className={buttonClassName}>
        {children}
      </Button>
    </elevent-package-button-widget>
  )
}

/**
 * A list of all available passes that can be added to cart. Can be filtered
 * by a pass type or allocation type.
 *
 * @param {String} [passId] the pass type to filter by. Optional.
 * @param {String} [allocationId] the allocation type to filter by. Optional.
 */
export const EleventPassList = props => {
  const { passId, allocationId, ...rest } = props

  return (
    <elevent-pass-widget
      pass={passId}
      allocation={allocationId}
      {...rest}
    ></elevent-pass-widget>
  )
}

/**
 * A button component for adding a specific pass item to the cart
 *
 * @param {String} passId the pass type
 * @param {String} allocationId the allocation type
 * @param {String} [isGift] indicates if the specific pass should be added as a
 * gift. Default: `false`
 * @returns
 */
export const EleventPassButton = props => {
  const {
    children,
    passId,
    allocationId,
    isGift,
    className,
    buttonClassName,
    ...rest
  } = props

  return (
    <elevent-pass-button-widget
      pass={passId}
      allocation={allocationId || "public"}
      gift={isGift}
      class={className}
    >
      <Button {...rest}>{children}</Button>
    </elevent-pass-button-widget>
  )
}

/**
 * A list component containing all available extras that can be added to the cart.
 * Can be filtered by extra type and allocation type.
 *
 * @param {String} [extraId] the extra type. Optional.
 * @param {String} [allocationId] the allocation type. Optional.
 */
export const EleventExtra = props => {
  const { extraId, allocationId, ...rest } = props

  return (
    <elevent-extra-widget
      extra={extraId}
      allocation={allocationId}
      {...rest}
    ></elevent-extra-widget>
  )
}

/**
 * A button component for adding a specific extra item to the cart
 *
 * @param {String} extraId the extra type
 * @param {String} allocationId the allocation type
 * @param {Boolean} [isGift] indicates if the extra should be added as a gift. Default: `false`
 */
export const EleventExtraButton = props => {
  const {
    children,
    extraId,
    allocationId,
    isGift,
    className,
    buttonClassName,
    ...rest
  } = props

  return (
    <elevent-extra-button-widget
      extra={extraId}
      allocation={allocationId || "public"}
      gift={isGift}
      class={className}
    >
      <Button {...rest} className={buttonClassName}>
        {children}
      </Button>
    </elevent-extra-button-widget>
  )
}

/**
 * A list component that contains all available memberships. Can be filtered by
 * a specific membership type.
 *
 * @param {String} [membershipId] the membership type. Optional.
 */
export const EleventMembershipList = props => {
  const { membershipId, className, ...rest } = props

  return (
    <elevent-membership-widget
      membership={membershipId}
      class={className}
      {...rest}
    ></elevent-membership-widget>
  )
}

/**
 * A button component for adding a specific membership to the car
 *
 * @param {String} membershipId the membership type
 */
export const EleventMembershipButton = props => {
  const { membershipId, children, className, buttonClassName, ...rest } = props

  return (
    <elevent-membership-button-widget
      membership={membershipId}
      class={className}
    >
      <Button {...rest} className={buttonClassName}>
        {children}
      </Button>
    </elevent-membership-button-widget>
  )
}

/**
 * A component for adding a donation item to the cart
 *
 * @param {String} donationId the donation type
 */
export const EleventDonation = props => {
  const { donationId, ...rest } = props

  return (
    <elevent-donation-widget
      donation={donationId}
      {...rest}
    ></elevent-donation-widget>
  )
}

/**
 * A button component for the shopping cart.
 */
export const EleventCart = props => {
  const { className, buttonClassName, ...restProps } = props
  return (
    <elevent-cart-widget class={className}>
      <Button {...restProps} className={buttonClassName}>
        <FaShoppingCart />
      </Button>
    </elevent-cart-widget>
  )
}

/**
 * A component containing the number of items that are currently in the cart
 */
export const EleventCartCount = props => {
  return <elevent-cart-count {...props}></elevent-cart-count>
}

/**
 * A button component for opening the elevent signin modal
 */
export const EleventSignIn = props => {
  const { children, className, buttonClassName, ...rest } = props

  return (
    <elevent-signin-widget class={className}>
      <SignInButton {...rest}>{children}</SignInButton>
    </elevent-signin-widget>
  )
}

/**
 * A button component for signing out the current user
 */
export const EleventSignOut = props => {
  const {
    children,
    className,
    buttonClassName,
    asButton = false,
    ...rest
  } = props

  return (
    <elevent-signout-widget class={className}>
      {asButton ? (
        <AccountButton {...rest}>{children}</AccountButton>
      ) : (
        children
      )}
    </elevent-signout-widget>
  )
}

/**
 * A button component for opening the user account modal
 */

export const EleventAccount = props => {
  const { children, className, buttonClassName, ...rest } = props

  return (
    <elevent-account-widget class={className}>
      <AccountButton {...rest}>{children}</AccountButton>
    </elevent-account-widget>
  )
}
