import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export function DefaultHorizontalThumbnail({ className, alt, objectFit }) {
  const data = useStaticQuery(graphql`
    query DefaultHorizontal {
      wpAaiffSiteSettings {
        websiteSettings {
          defaultPlaceholderImages {
            horizontalUrl {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    wpAaiffSiteSettings: {
      websiteSettings: {
        defaultPlaceholderImages: {
          horizontalUrl: {
            localFile: { childImageSharp },
          },
        },
      },
    },
  } = data

  return (
    <GatsbyImage
      image={childImageSharp.gatsbyImageData}
      alt={alt}
      className={className}
      objectFit={objectFit}
    />
  )
}
