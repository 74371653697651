import React from "react"
import styled from "@emotion/styled"
import { ButtonLink, Button } from "@components/@aaiff"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment-timezone"
import { AAIFF_TIMEZONE } from "@common/constants"

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1.5rem 1rem;
  row-gap: 1.2rem;
`
const Text = styled("p")`
  font-weight: bold;
  font-size: ${props => (props.size ? props.size : "1.5rem")};
`
const TextFooter = styled("p")`
  display: flex;
  flex-direction: column;
`

export function TicketingQuestions(props) {
  const { date } = props
  const { wpFestivalPage } = useStaticQuery(graphql`
    query ContactQuery {
      wpFestivalPage(slug: { eq: "contact" }) {
        festivalPageContact {
          contact {
            department
            emailAddress
          }
        }
      }
    }
  `)
  const { festivalPageContact } = wpFestivalPage
  const { contact } = festivalPageContact
  const boxOfficeContact = contact.filter(
    contactType => contactType.department === "Box Office"
  )

  const footerTexts = [
    { type: "pre", size: "2.25rem", content: "See you there!" },
    { type: "pre", size: "2.5rem", content: `${date.format("MMMM D, YYYY")}` },
    {
      type: "post",
      size: "2.5rem",
      content: `Thank you for attending`,
    },
  ]

  const currentDate = moment().tz(AAIFF_TIMEZONE)

  const currentFooterText = currentDate.isAfter(date)
    ? footerTexts.filter(footer => footer.type === "post")
    : footerTexts.filter(footer => footer.type === "pre")

  return (
    <Content>
      <Text>Have a question?</Text>
      <ButtonLink href="/faq/">Explore the FAQ</ButtonLink>
      {boxOfficeContact[0].emailAddress ? (
        <>
          <Text>Contact Us</Text>
          <Button>
            <div className="wpContent">
              <a
                class="c-link"
                href={`mailto:${boxOfficeContact[0].emailAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                data-stringify-link={`mailto:${boxOfficeContact[0].emailAddress}`}
                data-sk="tooltip_parent"
                aria-haspopup="menu"
                aria-describedby="sk-tooltip-30267"
              >
                {`${boxOfficeContact[0].emailAddress}`}
              </a>
            </div>
          </Button>
        </>
      ) : null}
      <TextFooter>
        {currentFooterText.map((footer, index) => (
          <Text key={index} size={footer.size}>
            {footer.content}
          </Text>
        ))}
      </TextFooter>
    </Content>
  )
}
