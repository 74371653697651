import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"

import { Button } from "@components/@aaiff"
import { AaiffLogo } from "@components/logos"
import { FaBars, FaTimes } from "react-icons/fa"
import { HeaderMobileMenu } from "./header_mobile_menu"
import { HeaderBarRight } from "./header_bar_right"

export function HeaderNavMobile({ navbarOptions }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  // Whenever the mobile menu is open, prevent the body tag from allowing the
  // user to scroll until the mobile menu is closed.
  useEffect(() => {
    const bodyEl = document.getElementById("gatsby-body-tag")

    if (bodyEl) {
      if (mobileMenuOpen) {
        bodyEl.style.overflow = "hidden"
      } else {
        bodyEl.style.removeProperty("overflow")
      }
    }
  }, [mobileMenuOpen])

  return (
    <div className="headerBar _is-mobile">
      <Grid container>
        <Grid item xs={3} className="headerBar__grid">
          <Button
            variant="ghost"
            offset="atStart"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="headerBar__hamburger"
          >
            {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          </Button>
        </Grid>
        <Grid item xs={6} className="headerBar__grid">
          <AaiffLogo className="header__logo" imgClassName="header__logoImg" />
        </Grid>
        <Grid item xs={3} className="headerBar__grid">
          <HeaderBarRight isMobile />
        </Grid>
      </Grid>

      <HeaderMobileMenu open={mobileMenuOpen} navbarOptions={navbarOptions} />
    </div>
  )
}
