import React, { useEffect, useState } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { UpcomingEventsItem } from "./upcoming_events_item"
import { MediaDto } from "@common/dto"
import styled from "@emotion/styled"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { useMobileDetect } from "@hooks"
import { CustomArrowButton } from "@components/multi_carousel"
import { Title } from "../title"

const CarouselContainer = styled("div")`
  margin-top: 1rem;

  .react-multi-carousel-dot button {
    border: none;
    background-color: #999999;
  }

  .react-multi-carousel-dot--active button {
    background-color: #333333;
  }
`

export function UpcomingEventsMulti({ title, isHidden, itemData }) {
  const { actualDeviceType, width } = useMobileDetect()

  let initialWidth
  if (typeof window !== `undefined`) {
    initialWidth = window.innerWidth
  } else {
    initialWidth = width
  }

  const [screenWidth, setScreenWidth] = useState(initialWidth)

  useEffect(() => {
    //check if window is defined
    if (typeof window !== `undefined`) {
      //reset screenWidth when screen changes
      const handleResize = () => {
        setScreenWidth(window.innerWidth)
      }

      window.addEventListener("resize", handleResize)

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  const numberOfItems = itemData.length

  // Exit early if `isHidden` is true or there is no carousel data
  if (isHidden || !itemData || numberOfItems === 0) {
    return null
  }

  const breakPoints = {
    desktop: 1000,
    tablet: 750,
    mobile: 560,
  }
  /*
    This is to remove when there is one single dots to navigate the carousel 
    Since when there is the same number of items to items showing on the carousel 
    It will just show a single dot. 
    */
  const shouldShowDots = (screenWidth, numberOfItems) => {
    if (
      (screenWidth < breakPoints.mobile && numberOfItems === 1) ||
      (screenWidth >= breakPoints.tablet &&
        screenWidth < breakPoints.desktop &&
        numberOfItems === 2) ||
      (screenWidth >= breakPoints.desktop && numberOfItems === 3)
    ) {
      return false
    }
    return true
  }
  // mobile max to 560px, because otherwise the carousel will be too big
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: breakPoints.desktop },
      items: 3,
      partialVisibilityGutter: numberOfItems <= 3 ? 0 : 40,
    },
    tablet: {
      breakpoint: { max: breakPoints.desktop, min: breakPoints.tablet },
      items: 2,
      partialVisibilityGutter: numberOfItems <= 2 ? 0 : 35,
    },
    mobile: {
      breakpoint: { max: breakPoints.mobile, min: 0 },
      items: 1,
      partialVisibilityGutter: numberOfItems === 1 ? 0 : 30,
    },
  }

  return (
    <section className="upcomingEventsRoot">
      <Title>{title}</Title>
      <CarouselContainer>
        <Carousel
          ssr={true}
          deviceType={actualDeviceType}
          arrows={true}
          partialVisible={true}
          showDots={shouldShowDots(screenWidth, numberOfItems)}
          draggable={true}
          responsive={responsive}
          slidesToSlide={1}
          swipeable={true}
          customLeftArrow={
            <CustomArrowButton
              actualDeviceType={actualDeviceType}
              onClick={() => onClick()}
              direction="left"
              icon={<FaArrowLeft />}
              top="calc(45% - 3rem)"
              topTablet="calc(40% - 3rem)"
              topDesktop="calc(38% - 3rem)"
            />
          }
          customRightArrow={
            <CustomArrowButton
              actualDeviceType={actualDeviceType}
              onClick={() => onClick()}
              direction="right"
              icon={<FaArrowRight />}
              top="calc(45% - 3rem)"
              topTablet="calc(40% - 3rem)"
              topDesktop="calc(38% - 3rem)"
            />
          }
          renderButtonGroupOutside={true}
        >
          {itemData.map((event, idx) => (
            <UpcomingEventsItem
              key={idx}
              label={event.label}
              link={event.link}
              imageMedia={MediaDto.fromWpMediaItem(event.image)}
            />
          ))}
        </Carousel>
      </CarouselContainer>
    </section>
  )
}
