export const SortOrders = {
  DATE: "DATE",
  ALPHABETICAL: "ALPHABETICAL",
  SCREENING_TYPE: "SCREENING_TYPE",
}

export const FilterTypes = {
  FILM_TYPE: "filmType",
  FILM_FORMAT: "filmFormat",
  GENRE: "genre",
  LANGUAGE: "language",
  COUNTRY: "country",
  EVENT_TYPE: "eventType",
  EVENT_FORMAT: "eventFormat",
  CURATED_SECTION: "curatedSection",
  PROGRAM_TYPE: "programType",
  // camel-case aliases
  filmType: "filmType",
  filmFormat: "filmFormat",
  genre: "genre",
  language: "language",
  country: "country",
  eventType: "eventType",
  eventFormat: "eventFormat",
  curatedSection: "curatedSection",
  programType: "programType",
}

/**
 * Maps the singular and plural names of the filters
 * to their singular name
 */
export const FilterSingularNameMap = {
  countries: FilterTypes.COUNTRY,
  country: FilterTypes.COUNTRY,
  genres: FilterTypes.GENRE,
  genre: FilterTypes.GENRE,
  languages: FilterTypes.LANGUAGE,
  language: FilterTypes.LANGUAGE,
  filmTypes: FilterTypes.FILM_TYPE,
  filmType: FilterTypes.FILM_TYPE,
  eventTypes: FilterTypes.EVENT_TYPE,
  eventType: FilterTypes.EVENT_TYPE,
  eventFormats: FilterTypes.EVENT_FORMAT,
  eventFormat: FilterTypes.EVENT_FORMAT,
}

export const FormatOptions = {
  ALL: "ALL",
  ON_DEMAND: "ON_DEMAND",
  LIVESTREAM: "LIVESTREAM",
}

export const IndexResultType = {
  FILM: "FILM",
  EVENT: "EVENT",
}

export const PanelTypes = {
  PROGRAM: "PROGRAM",
  POPUP_MARKET: "POPUP_MARKET",
}

/**
 * The order that the filter options should appear in
 */
export const filterTypeOrder = [
  FilterTypes.EVENT_FORMAT,
  FilterTypes.PROGRAM_TYPE,
  FilterTypes.EVENT_TYPE,
  FilterTypes.FILM_FORMAT,
  FilterTypes.FILM_TYPE,
  FilterTypes.GENRE,
  FilterTypes.COUNTRY,
  FilterTypes.LANGUAGE,
  // FilterTypes.CURATED_SECTION,
]

export const eventFilterTypeOrder = [
  FilterTypes.EVENT_FORMAT,
  FilterTypes.EVENT_TYPE,
]

/**
 * The names to display for each filter type
 */
export const filterTypeLabels = {
  [FilterTypes.EVENT_FORMAT]: "Viewing Options",
  [FilterTypes.EVENT_TYPE]: "Event Types",
  [FilterTypes.FILM_TYPE]: "Film Type",
  [FilterTypes.GENRE]: "Genres",
  [FilterTypes.LANGUAGE]: "Languages",
  [FilterTypes.COUNTRY]: "Country of Origin",
  [FilterTypes.FILM_FORMAT]: "Film Format",
  [FilterTypes.CURATED_SECTION]: "Curated Sections",
  [FilterTypes.PROGRAM_TYPE]: "Program Type",
}

/**
 * An enum of category types. The are using
 * random characters instead of a readable string since
 * these will show up in the search query
 */
export const CategoryTypes = {
  TENTPOLES: "tentpoles",
  NARRATIVE_FEATURE: "narrativeFeatures",
  DOCUMENTARY_FEATURE: "documentaryFeatures",
  SHORTS_PROGRAMS: "shortsPrograms",
  EVENT: "events",
  SPECIAL_PRESENTATIONS: "specialPresentations",
  PANEL_WORKSHOP: "panelsWorkshops",
}

export const CategoryTypeLabels = {
  [CategoryTypes.TENTPOLES]: "Spotlight Events",
  [CategoryTypes.NARRATIVE_FEATURE]: "Narrative Features",
  [CategoryTypes.DOCUMENTARY_FEATURE]: "Documentary Features",
  [CategoryTypes.SHORTS_PROGRAMS]: "Shorts Programs",
  [CategoryTypes.EVENT]: "Events",
  [CategoryTypes.SPECIAL_PRESENTATIONS]: "Special Presentations",
  [CategoryTypes.PANEL_WORKSHOP]: "Panels and Workshops",
}

export const categoryRowOrder = [
  CategoryTypes.TENTPOLES,
  CategoryTypes.SPECIAL_PRESENTATIONS,
  CategoryTypes.NARRATIVE_FEATURE,
  CategoryTypes.DOCUMENTARY_FEATURE,
  CategoryTypes.SHORTS_PROGRAMS,
  CategoryTypes.PANEL_WORKSHOP,
  CategoryTypes.EVENT,
]

export const ProgramTypes = {
  FILMS_ONLY: "Films",
  EVENTS_ONLY: "Events",
}

/**
 * Constants for the different view types available on the schedule/events page
 */
export const ScheduleViewTypes = {
  LIST: "list",
  GRID: "grid",
  CALENDAR: "calendar",
}

export const SearchPageTypes = {
  EVENTS: "schedule",
  PROGRAM: "program",
}
