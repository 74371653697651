import PropTypes from "prop-types"
import { ButtonOffsetTypes, ButtonTypes } from "../../common/constants"

export function useButton(props) {
  const {
    children,
    className = "",
    quiet = false,
    disabled = false,
    variant = ButtonTypes.PRIMARY,
    offset = ButtonOffsetTypes.NONE,
    onClick,
    ...rest
  } = props

  // If warn the user that the CTA button does not have a quiet style
  if (quiet && variant === ButtonTypes.CTA) {
    console.warn('The CTA button variant does not have a "quiet" version')
  }

  /**
   * A helper function for constructing the css class name of the button
   * based on the component's props
   * @returns the class name
   */
  const constructClassName = () => {
    let btnClassName = `aaiffBtn aaiffBtn--${variant}`

    if (disabled) {
      btnClassName += " _is-disabled"
    }

    if (quiet) {
      btnClassName += " _is-quiet"
    }

    // The offset shift the button to the left if the button is at the start of a container
    // or to the right if at the end of a container. The shift is meant to offset the
    // button padding and margins to make it appear as if it is fitted to the edge of the
    // container
    if (offset === ButtonOffsetTypes.AT_START) {
      btnClassName += " _offset-start"
    } else if (offset === ButtonOffsetTypes.AT_END) {
      btnClassName += " _offset-end"
    }

    if (className) {
      btnClassName += ` ${className}`
    }

    return btnClassName
  }

  return {
    className: constructClassName(),
    onClick,
    disabled,
    buttonProps: rest,
    children,
  }
}

useButton.propTypes = {
  variant: PropTypes.oneOf([
    ButtonTypes.CTA,
    ButtonTypes.PRIMARY,
    ButtonTypes.SECONDARY,
    ButtonTypes.NEGATIVE,
    ButtonTypes.OVER_BACKGROUND,
  ]),
  offset: PropTypes.oneOf([
    ButtonOffsetTypes.NONE,
    ButtonOffsetTypes.AT_END,
    ButtonOffsetTypes.AT_START,
  ]),
  quiet: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
