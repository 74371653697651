import React from "react"
import PropTypes from "prop-types"

/**
 * A LinkGroup expects AAIFF Link components as children. This component handles
 * orienting a list of Link components and slightly changes the hover animations for them
 */
export function LinkGroup(props) {
  const { children, className = "", direction = "row" } = props

  return (
    <nav className={`aaiff-LinkGroup ${className}`} direction={direction}>
      {children}
    </nav>
  )
}

LinkGroup.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf([
    "row",
    "column",
    "row-reverse",
    "column-reverse",
  ]),
}

// TODO: add this back once we move to using styled components
// const LinkList = styled.nav`
//   display: flex;
//   flex-direction: ${props => props.direction};

//   & .aaiff-Link {
//     text-decoration: none;

//     &:hover {
//       text-decoration: underline;
//     }
//   }
// `
