import { useState, useEffect } from "react"
import {
  encodeQueryParams,
  decodeQueryParams,
  StringParam,
  ObjectParam,
} from "serialize-query-params"
import { stringify, parse } from "querystring"
import { navigate } from "gatsby"
import { SearchParamMaps } from "../../common/constants/search-params.constants"

/**
 * A hook used to modify the query params for any page using the Search.jsx component.
 *
 * @param {Object} location the gatsby location prop
 * @param {SearchParamTypes} searchParamType the type of search params to use
 * @return An array containing the current query parameter object first and a function to
 * modify the query string with a new set of parameters
 */
export function useSearchQueryParams(location, searchParamType) {
  const paramsMap = SearchParamMaps[searchParamType] || {}

  /**
   * The current decoded query params
   */
  const [queryParams, setQueryParams] = useState(
    _getQueryParams(location.hash, paramsMap)
  )

  // update the queryParams object when the location.hash string changes
  useEffect(() => {
    setQueryParams(_getQueryParams(location.hash, paramsMap))
  }, [location.hash, searchParamType])

  /**
   * Updates the the url with the new query string while preserving the same
   * pathname and hash values.
   * @param {Object} paramsObj the new params for the query string
   */
  const setQuery = paramsObj => {
    const formattedParams = _formatQueryParams(paramsObj, paramsMap)
    const { pathname } = location

    const encodedQuery = encodeQueryParams(paramsMap, formattedParams)
    const searchStr = stringify(encodedQuery)

    let pathStr = pathname
    pathStr += searchStr ? `#${searchStr}` : ""

    navigate(pathStr)
  }

  return [queryParams, setQuery]
}

/**
 * Retrieves the current decoded search query params from the location search
 * string. If there are no params in the search string, returns an empty object
 *
 * @param {string} searchString the search string
 * @param {Object} paramsMap the map of the paramName to the parameter config
 * @return the decoded search query params
 */
const _getQueryParams = (searchString, paramsMap) => {
  if (searchString.length) {
    const parsed = parse(searchString.slice(1)) // remove the "#" from the search string
    return decodeQueryParams(paramsMap, parsed) // ignore other possible query params
  }

  return {}
}

/**
 * A helper function that ensures that if the search or filters query params
 * have only falsey values, the corresponding param is set to undefined so that
 * it will not show in the URL.
 *
 * @param {Object} queryParams the query params to format
 * @param {Object} paramsMap the map of the paramName to the parameter config
 * @returns the formatted query parameters
 */
const _formatQueryParams = (queryParams, paramsMap) => {
  let formattedParams = {}

  for (const paramName in paramsMap) {
    const paramType = paramsMap[paramName]

    if (Object.hasOwn(queryParams, paramName)) {
      const paramValue = queryParams[paramName]

      if (paramType === ObjectParam) {
        // Check if any of the values for each param are falesy.
        // and delete the key
        if (paramValue) {
          Object.keys(paramValue).forEach(type => {
            if (!paramValue[type]) {
              delete paramValue[type]
            }
          })

          // Only assign the param value if all of the values are truthy
          if (Object.values(paramValue).every(filter => !!filter)) {
            formattedParams[paramName] = paramValue
          }
        }
      } else if (paramType === StringParam) {
        // Only assign the param string value if the value is not falsey
        if (!!paramValue) {
          formattedParams[paramName] = paramValue
        }
      }
    }
  }

  return formattedParams
}
