import { UniversalDrawerMobile } from "./universal_drawer_mobile"

/**
 * NOTE: `drawer_mobile.jsx` and `event_drawer_mobile.jsx`
 * simply return component exported by `universal_drawer_mobile.jsx`,
 * wherein their very similar logic is consolidated.
 *
 * Please make all changes in `universal_drawer_mobile.jsx`
 */
export function DrawerMobile(props) {
  return <UniversalDrawerMobile {...props} />
}
