import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { getOrdinalString } from "../../util/string_util"

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
export function PageHead({ description, title }) {
  const {
    site,
    wpAaiffSiteSettings: { websiteSettings },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
        wpAaiffSiteSettings {
          websiteSettings {
            defaultMetaDescription
            currentFestival {
              ... on WpFestival {
                id
                festivalGeneralInformation {
                  festivalNumber
                }
              }
            }
            socialCardImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    `
  )

  const festivalNum =
    websiteSettings.currentFestival.festivalGeneralInformation.festivalNumber
  const metaDescription = description || websiteSettings.defaultMetaDescription
  const metaImage = websiteSettings.socialCardImage

  return (
    <>
      <title>{`${title} | ${festivalNum + getOrdinalString(festivalNum)} ${
        site.siteMetadata.title
      }`}</title>
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:description" content={metaDescription} />
      metaImage ?
      <>
        <meta name="og:image" content={metaImage.sourceUrl} />
        <meta name="og:image:width" content={metaImage.mediaDetails.width} />
        <meta name="og:image:height" content={metaImage.mediaDetails.height} />
        <meta name="twitter:card" content="summary_large_image" />
      </>
      : <meta name="twitter:card" content="summary" />
    </>
  )
}
