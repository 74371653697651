import React from "react"
import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link as GatsbyLink } from "gatsby"

export function Link(props) {
  const { to, external = false, children, ...rest } = props

  if (external) {
    return (
      <OutboundLink
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </OutboundLink>
    )
  } else {
    return (
      <GatsbyLink to={to} {...rest}>
        {children}
      </GatsbyLink>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
}
