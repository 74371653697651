import React from "react"
import { Link } from "../../../link"
import styled from "@emotion/styled"
import { useMediaQuery, useTheme } from "@mui/material"
import ImageWrapper from "../../../image_wrapper"
import InnerHTML from "../../../inner_html/inner_html"

const EntrySubListContainer = styled.ul`
  display: flex;
  flex-direction: ${props =>
    !props.isALink && props.isDesktop ? "row" : "column"};
  flex-wrap: ${props =>
    !props.isALink && props.isDesktop ? "wrap" : "nonwrap"};
  gap: 32px;
  padding: 1.5rem 0;
`
const IndividualSubListItem = styled.li`
  width: ${props => (!props.isALink && props.isDesktop ? "45%" : "100%")};
  display: flex;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  height: ${props => (props.isALink && props.isDesktop ? "12rem" : "auto")};
  &:hover {
    border: ${props =>
      props.isALink && props.isDesktop
        ? "1px solid #CCCCCC"
        : "1px solid #f2f2f2"};
    box-shadow: ${props =>
      props.isALink && props.isDesktop
        ? "6px 10px 20px 0px #00000010"
        : "null"};
  }

  .individualSubListItem__thumbnailLink {
    display: flex;
    flex-direction: ${props =>
      props.isALink && props.isDesktop ? "row" : "column"};
    &:hover {
      text-decoration: none;
    }
  }

  img.individualSubListItem__thumbnail {
    width: 100%;
  }

  .individualSubListItem__thumbnail {
    margin-bottom: 0;
    object-fit: cover;
    height: ${props => (props.isALink && props.isDesktop ? "100%" : "18rem")};
    width: ${props => (props.isALink && props.isDesktop ? "50%" : "100%")};
    border-radius: ${props =>
      props.isALink && props.isDesktop ? "10px 0 0 10px" : "10px 10px 0 0"};
  }
`

const IndividualSubListItemContent = styled.div`
  width: ${props => (props.isALink && props.isDesktop ? "70%" : "100%")};
  height: "auto";
  box-sizing: border-box;
  padding: 1rem;
  line-height: 1.15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .individualSubListItemContent__title {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1em;
    line-height: 1.2rem;
    margin-top: 0.5rem;
  }

  .individualSubListItemContent__description {
    margin-top: 0.5rem;
    font-size: 1em;
  }

  .individualSubListItemContent__description--ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .individualSubListItemContent__mobile-link {
    font-size: 1em;
    text-decoration: underline;
    margin-top: 0.5rem;
  }
`

export function EntrySubList({ visible, items, sectionTitle, contentType }) {
  if (!visible || !items?.length) return null

  const theme = useTheme()
  const largerThanSm = useMediaQuery(theme.breakpoints.up("sm"))

  // Check if any item meets the isNotALinkAndisDesktop condition
  // const isNotALinkAndisDesktop = items.some(
  //   item => largerThanSm && typeof item.path !== "string"
  // )

  const isALink = items.some(item => typeof item.path === "string")

  const ListItems = items.map(item => {
    return (
      <EntrySubListItem
        key={item.title}
        path={item.path}
        posterImageMedia={item.posterImageMedia}
        title={item.title}
        subtitle={item.subtitle}
        description={item.description}
        contentType={contentType}
        // largerThanSm={largerThanSm}
        isALink={isALink}
        isDesktop={largerThanSm}
        // isNotALinkAndisDesktop={}
      />
    )
  })

  return (
    <section>
      <h2 className="event__title">{sectionTitle}</h2>

      <EntrySubListContainer isDesktop={largerThanSm} isALink={isALink}>
        {ListItems}
      </EntrySubListContainer>
    </section>
  )
}

const EntrySubListItem = ({
  contentType,
  path = null,
  posterImageMedia,
  title,
  subtitle,
  description,
  // largerThanSm,
  isALink,
  isDesktop,
  // isNotALinkAndisDesktop,
}) => {
  return (
    <IndividualSubListItem to={path} isALink={isALink} isDesktop={isDesktop}>
      <EntrySubListLink
        to={path}
        className="individualSubListItem__thumbnailLink"
        isALink={isALink}
      >
        <ImageWrapper
          media={posterImageMedia}
          objectFit="cover"
          alt={title}
          className="individualSubListItem__thumbnail"
        />
        <IndividualSubListItemContent isDesktop={isDesktop}>
          <h5 className="individualSubListItemContent__title">{title}</h5>
          <h5 className="individualSubListItemContent__title">{subtitle}</h5>
          <EntrySubListItemDescription
            className={
              isALink
                ? "individualSubListItemContent__description individualSubListItemContent__description--ellipsis"
                : "individualSubListItemContent__description"
            }
            contentType={contentType}
            description={description}
            isDesktop={isDesktop}
          />

          {typeof path === "string" && !isDesktop && (
            //render "Read More" link only on mobile for Shorts Films
            <Link
              className="individualSubListItemContent__mobile-link"
              to={path}
            >
              Learn More
            </Link>
          )}
        </IndividualSubListItemContent>
      </EntrySubListLink>
    </IndividualSubListItem>
  )
}

const EntrySubListLink = props => {
  const { to, children, isALink, ...restProps } = props
  return typeof to === "string" && isALink ? (
    <Link to={to} {...restProps}>
      {children}
    </Link>
  ) : (
    <div {...restProps}>{children}</div>
  )
}

const EntrySubListItemDescription = props => {
  const { htmlContent, contentType, description, ...restProps } = props
  return contentType === "html" ? (
    <InnerHTML htmlContent={description} {...restProps} />
  ) : (
    <p {...restProps}>{description}</p>
  )
}
