import React, { useState, useEffect } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Drawer from "@mui/material/Drawer"

import { AccordionItem } from "../../accordion"
import { EleventSignIn, EleventSignOut } from "../../elevent"
import { Link } from "../../link"
import { EleventEvents } from "../../../common/constants"
import { useEleventService } from "../../../hooks"

const HeaderMenu = styled(Drawer)`
  z-index: 199;
  top: 70px;

  & .MuiDrawer-paper {
    top: 70px;
  }
`

const HeaderMenuList = styled("ul")`
  width: 90%;
  margin: 0 auto;
  border-color: $black;
  border-style: solid;
`

export function HeaderMobileMenu({ open, className = "", navbarOptions }) {
  const eleventService = useEleventService()

  const [selected, setSelected] = useState(new Set())
  const [showSignOut, setShowSignout] = useState(!!eleventService.getCustomer())
  const [windowSize, setWindowSize] = useState(0)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowSize(window.innerWidth)
    }
  }, [])

  // Setup event listeners for Elevent sign in and sign out events so that
  // we can properly determine if the signout button should be shown
  useEffect(() => {
    if (window) {
      const handleSignIn = () => {
        setShowSignout(true)
      }

      const handleSignOut = () => {
        setShowSignout(false)
      }

      const handleResize = () => {
        setWindowSize(window.innerWidth)
      }

      window.addEventListener(EleventEvents.afterEleventSignIn, handleSignIn)
      window.addEventListener(EleventEvents.afterEleventSignOut, handleSignOut)
      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener(
          EleventEvents.afterEleventSignIn,
          handleSignIn
        )
        window.removeEventListener(
          EleventEvents.afterEleventSignOut,
          handleSignOut
        )
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  // Initialize or cleanup widgets whenever the signout button state changes
  useEffect(() => {
    if (showSignOut) {
      eleventService.initializeWidgets()
    } else {
      eleventService.cleanupTicketingWidgetReferences()
    }
  }, [showSignOut])

  const toggleSelected = title => {
    const newSet = new Set([...selected])
    if (newSet.has(title)) {
      newSet.delete(title)
    } else {
      newSet.add(title)
    }
    setSelected(newSet)
  }

  const menuItems = navbarOptions.map(option => {
    const { labelName, dropdownOptions, isLink, isExternal, slug } = option

    if (isLink) {
      return (
        <li
          key={labelName}
          className="headerMenu__listItem headerMenu__listItem--link"
        >
          <Link to={isExternal ? slug : `/${slug}`} external={isExternal}>
            {labelName}
          </Link>
        </li>
      )
    } else {
      return (
        <AccordionItem
          title={labelName}
          key={labelName}
          links={dropdownOptions.map(option => {
            return {
              label: option.name,
              path: option.slug,
              isExternal: option.isExternal,
            }
          })}
          isOpen={selected.has(labelName)}
          setSelected={toggleSelected}
          className="headerMenu__listItem"
        />
      )
    }
  })

  if (showSignOut) {
    menuItems.push(
      <li className="headerMenu__listItem" key="signout">
        <SignOutLink variant="secondary" quiet>
          Sign Out
        </SignOutLink>
      </li>
    )
  } else {
    if (windowSize < 450) {
      menuItems.push(
        <li className="headerMenu__listItem" key="signin">
          <SignInLink variant="secondary" quiet>
            Sign In
          </SignInLink>
        </li>
      )
    }
  }

  return (
    <HeaderMenu open={open}>
      <HeaderMenuList>{menuItems}</HeaderMenuList>
    </HeaderMenu>
  )
}

const SignInLink = styled(EleventSignIn)`
  display: block;
`

const SignOutLink = styled(EleventSignOut)`
  display: block;
  width: 100%;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
