import React from "react"

export function FooterCopyright() {
  return (
    <div className="footer__copyright">
      <span className="footer__copyrightText">
        &copy; {new Date().getFullYear()} AAIFF, ACV. All rights reserved.
      </span>
    </div>
  )
}
