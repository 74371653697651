import React from "react"

import { ResultsList } from "./results_list"
import { NoResults } from "./no_results"
import {
  SearchPageTypes,
  CategoryTypeLabels,
} from "../../../../common/constants"

export function SearchResults(props) {
  const {
    query: searchQuery,
    sortOrder,
    categoryQuery,
    searchResults: { exactEvents, relatedEvents, exactFilms },
  } = props

  const hasNoResults =
    exactEvents.length === 0 &&
    relatedEvents.length === 0 &&
    exactFilms.length === 0

  /**
   * Depending on the query parameters in the hash path, this function returns
   * a different value to show in the result title
   *
   * @returns the title to show over the results
   */
  const getResultTitle = () => {
    if (searchQuery) {
      // If there a search value, display it in the title
      return `Results for ${searchQuery}`
    } else if (categoryQuery) {
      // If there is a category reference, display the label of the category
      return CategoryTypeLabels[categoryQuery] || "Results"
    } else {
      return "Results"
    }
  }

  return hasNoResults ? (
    <NoResults query={searchQuery} pageType={SearchPageTypes.PROGRAM} />
  ) : (
    <ResultsList
      entries={exactFilms.concat(exactEvents).concat(relatedEvents)}
      title={getResultTitle()}
      sortOrder={sortOrder}
    />
  )
}
