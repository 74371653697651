const ReductionMethodEnum = {
  FIXED: "fixed",
  PERCENT: "percent",
}

export class TicketingItem {
  constructor({
    name,
    uiKey,
    price,
    description,
    ticketingType,
    eleventInfo = {},
    showMissingFeatures = false,
    isHighlighted = false,
    makeUnavailable,
    purchaseInstructions,
    purchaseDates,
    discounts,
  }) {
    this.name = name
    this.uiKey = uiKey
    this.price = price
    this.originalPrice = price
    this.description = description
    this.ticketingType = ticketingType
    this.showMissingFeatures = showMissingFeatures
    this.features = []
    this.eleventInfo = {}
    this.isHighlighted = isHighlighted
    this.isAvailable = makeUnavailable
    this.unavailableButtonLabel = ""
    this.discountName = ""
    this.discountFinalDate = ""
    this.setEleventInformation(eleventInfo)
    this.priceLabel = ""
    this.purchaseInstructions = purchaseInstructions
    this.purchaseDates = purchaseDates
    this.discounts = discounts
  }

  applyDiscount(reductionValue, reductionMethod) {
    if (reductionMethod === ReductionMethodEnum.FIXED) {
      this.price = this.originalPrice - reductionValue
    } else if (reductionMethod === ReductionMethodEnum.PERCENT) {
      this.price = (this.originalPrice * (100 - reductionValue)) / 100
    } else {
      console.warn(
        `TicketingItem ${this.name} received invalid reductionMethod ${reductionMethod}`
      )
    }
  }

  addFeature(description, isChecked = true) {
    this.features.push({ description, isChecked })
  }

  getFeaturesList() {
    // if `showMissingFeatures` is false, filter out all unchecked features in the list
    if (!this.showMissingFeatures) {
      return this.features.filter(feature => feature.isChecked)
    }

    return this.features
  }

  addUnavailableButtonLabel(unavailableButtonLabel) {
    this.unavailableButtonLabel = unavailableButtonLabel
  }

  setEleventInformation(eleventInfo = {}) {
    const { eleventType, eleventId, eleventAllocationId } = eleventInfo

    if (eleventType) {
      this.eleventInfo.type = eleventType
    }

    if (eleventId) {
      this.eleventInfo.id = eleventId
    }

    if (eleventAllocationId) {
      this.eleventInfo.allocationId = eleventAllocationId
    }
  }

  getEleventInformation() {
    return this.eleventInfo
  }

  getVariantEleventInformation() {
    const eleventInfoList = this.variantList.map(variant => {
      const { price, eleventInfo } = variant
      const { eleventAllLocationId, eleventId, eleventType } = eleventInfo

      return {
        price: price,
        eleventAllLocationId,
        eleventId,
        eleventType,
      }
    })
    return eleventInfoList
  }

  setTicketSettings(dateOfUse) {
    this.settings = dateOfUse
  }

  setDiscountInfo(discountName, finalDate) {
    if (discountName !== null) {
      this.discountName = discountName
    } else {
      this.discountName = null
    }
    this.discountFinalDate = finalDate
  }

  isDiscounted() {
    return this.originalPrice !== this.price
  }

  getPrice(asFormattedString = false) {
    if (asFormattedString) {
      return _formatPrice(this.price)
    }

    return this.price
  }

  getOriginalPrice(asFormattedString = false) {
    if (asFormattedString) {
      return _formatPrice(this.originalPrice)
    }

    return this.originalPrice
  }

  setPurchaseInstructions(purchaseInstructions) {
    this.purchaseInstructions = purchaseInstructions
  }

  setPriceLabel(type) {
    if (type === "inPerson") {
      this.priceLabel = "In Person"
    } else {
      this.priceLabel = "Video on Demand"
    }
  }

  makeAvailable() {
    this.isAvailable = true
  }

  makeUnavailable(buttonLabel) {
    this.isAvailable = false

    if (buttonLabel) {
      this.unavailableButtonLabel = buttonLabel
    }
  }

  //create a function to get the name of the discount /type of discount
  //early early bird
}

/**
 * Formats a price value into a string using toLocaleString('en') including
 * 2 decimal places. If the decimal places is just "00",
 * the decimal numbers will be stripped from the returned string
 *
 * @example 2100.5 => "2,100.50" | 25.0 => "25"
 *
 * @param {number} price the numeric price value
 * @returns {string} the price value formatted as a string
 */
function _formatPrice(price) {
  const localeString = price.toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  // if the cents of the price is just "00", strip off
  // off the cents value and just return the dollar value
  if (localeString.slice(-3) == ".00") {
    return localeString.slice(0, -3)
  }

  return localeString
}
