import PropTypes from "prop-types"
import moment from "moment"
import { useFlexSearch } from "react-use-flexsearch"

import { useFeaturedEvents } from "../useFeaturedEvents"
import { applyActiveFilters } from "../../util/search_utils"

import { FilmFormats } from "@common/constants"

/**
 * The `useSearch` hook takes the `searchQuery`, `entries`, and `activeFilters` and
 * the `index` and `store` from flex search and returns the results as an array.
 *
 * The hook returns 2 values in an array. The first value is a boolean value indicating if
 * the search is currently active. A search is active if there is a search query or active filter.
 * The second value is an array containing the results of the search.
 *
 * @returns An array containing 2 values. The first value indicates if a search is currently
 * active. The second is the list of results.
 */
export const useEntrySearch = ({
  searchQuery,
  entries,
  activeFilters,
  index,
  store,
}) => {
  const { getFeaturedEventIds } = useFeaturedEvents()

  const flexSearchResults = useFlexSearch(searchQuery, index, store)

  /**
   * If there is a search query, match the flex search result ids to the entries.
   * If there is no search query, return all entry values.
   *
   * @returns the matching entries
   */
  const getSearchQueryResults = () => {
    if (searchQuery && searchQuery.length > 0) {
      const filmEntries = [],
        eventResults = [],
        filmResults = []

      const eventEntryIds = new Set()

      // Get the matching entries to the flexSearchResults
      flexSearchResults.forEach(match => {
        const entry = entries.get(match.id)

        if (entry) {
          if (entry.isEvent) {
            eventResults.push(entry)
            eventEntryIds.add(entry.id)
          } else {
            filmEntries.push(entry)
          }
        }
      })

      /**
       * Related results are no longer used any more. Keep this code here in case
       * we ever need to bring it back
       */
      const relatedResults = new Set()
      filmEntries.forEach(entry => {
        const isShortFormat = [
          FilmFormats.SHORT_FILM,
          FilmFormats.DIGITAL,
        ].includes(entry.filmFormat)

        const relatedEventIds = getFeaturedEventIds(entry)

        if (relatedEventIds && relatedEventIds.length > 0) {
          /**
           * Individual short format entries don't have start dates. Use this variable
           * to store the earliest date of a related event and set that on the film entry.
           */
          let earliestStartDate

          relatedEventIds.forEach(id => {
            // Make sure the event doesn't already exist in the event entries
            if (!eventEntryIds.has(id) && entries.has(id)) {
              const relatedEvent = entries.get(id)
              relatedResults.add(relatedEvent)

              // For short format films we will try to find the earliest related event
              // and use that event's date as the film's start date
              if (isShortFormat) {
                const eventStart = moment(relatedEvent.startDate)

                if (
                  !earliestStartDate ||
                  moment(earliestStartDate).isAfter(eventStart)
                ) {
                  earliestStartDate = relatedEvent.startDate
                }
              }
            }
          })

          if (isShortFormat && earliestStartDate) {
            entry.startDate = earliestStartDate
          }
        }

        filmResults.push(entry)
      })

      return {
        exactEvents: eventResults,
        relatedEvents: Array.from(relatedResults.values()),
        exactFilms: filmResults,
      }
    }

    return {
      exactEvents: Array.from(entries.values()).filter(entry => entry.isEvent),
      relatedEvents: [],
      exactFilms: Array.from(entries.values()).filter(entry => !entry.isEvent),
    }
  }

  /**
   * Determines if there is currently an active search. A search is considered
   * active if there is a search query string and/or if any of the filters are
   * active
   *
   * @returns if a search is currently active
   */
  const isSearchActive = () => {
    let isActive = false

    if (searchQuery && searchQuery.length > 0) {
      isActive = true
    }

    if (activeFilters) {
      Object.values(activeFilters).forEach(value => {
        if (!!value) isActive = true
      })
    }

    return isActive
  }

  const getSearchResults = () => {
    if (isSearchActive()) {
      const { exactEvents, relatedEvents, exactFilms } = getSearchQueryResults()

      return {
        exactEvents: applyActiveFilters(exactEvents, activeFilters),
        relatedEvents: applyActiveFilters(relatedEvents, activeFilters),
        exactFilms: applyActiveFilters(exactFilms, activeFilters),
      }
    } else {
      return {
        exactEvents: [],
        relatedEvents: [],
        exactFilms: [],
      }
    }
  }

  return [isSearchActive(), getSearchResults()] // TODO: memoize the return value
}

useEntrySearch.propTypes = {
  searchQuery: PropTypes.string,
  entries: PropTypes.instanceOf(Map).isRequired,
  activeFilters: PropTypes.object,
  sortOrder: PropTypes.string.isRequired,
  index: PropTypes.any.isRequired,
  store: PropTypes.object.isRequired,
}
