import React from "react"
// import { useAaiffSocialLinks } from "../../../hooks"
import { SocialMediaPlatforms } from "../../../common/constants"
import { SocialLink } from "../../buttons"

const socialLinkOrder = [
  SocialMediaPlatforms.FACEBOOK,
  SocialMediaPlatforms.INSTAGRAM,
  SocialMediaPlatforms.TWITTER,
  SocialMediaPlatforms.YOUTUBE,
  SocialMediaPlatforms.TWITCH,
]

export function FooterSocialLinks({ socialLinks }) {
  // const socialLinks = useAaiffSocialLinks()

  return (
    <div className="footer__links">
      <h3>Get In Touch</h3>

      <nav className="footer__linksSocial">
        {socialLinkOrder.map(platform => (
          // <li>
          <SocialLink
            key={platform}
            platform={platform}
            redirectUrl={socialLinks[platform]}
            className="footer__linksSocialItem"
          />
          // </li>
        ))}
      </nav>
    </div>
  )
}
