import React from "react"
import { useState, useEffect } from "react"
import styled from "@emotion/styled"

import { MosaicGalleryItem } from "./mosaic_item"
import { useSelectFestivalByYear } from "@hooks"
import { Link } from "../../link"
import { ComingSoon } from "./coming_soon"
import { WpDataFactory } from "../../../common/factories/WPDataFactory"
import { Title } from "../title"

const desktopGridHeight = "570px"
const desktopGridGap = "8px"
const mobileGridGap = "16px"
const aspectRatio = 16 / 9
const maxWidth = "1180px"

export function MosaicGallery(props) {
  const { title: sectionTitle, mosaicItems, isHidden } = props
  const selectFestivalByYear = useSelectFestivalByYear()
  const [useComingSoon, setComingSoon] = useState(false)
  const [layoutSize, setLayoutSize] = useState(mosaicItems.length)
  const [updatedMosaicItems, setUpdatedMosaicItems] = useState(mosaicItems)

  useEffect(() => {
    if (mosaicItems.length === 2) {
      setComingSoon(true)
      setLayoutSize(3)
      setUpdatedMosaicItems([...mosaicItems.slice(0, 2), {}]) // This is for test
    }
  }, [mosaicItems])

  // Early exit if `isHidden` is true
  if (isHidden || mosaicItems.length === 0) {
    return null
  }

  const mosaicGalleryItems = updatedMosaicItems.map((item, idx) => {
    if (useComingSoon && idx === 2) {
      return <ComingSoon key="coming-soon" itemNumber={idx + 1} />
    }
    if (!item.event) {
      return null
    }

    const entry = WpDataFactory.instantiateWpEntry(item.event)
    const festivalData = selectFestivalByYear(entry.festivalYear)
    const title = item.overrides?.title || entry.title
    const link = {
      external: false,
      path: entry.mainFilm?.slug
        ? `/${festivalData.name.toLowerCase()}/${entry.mainFilm.slug}`
        : `/${festivalData.name.toLowerCase()}/${entry.slug}`,
    }

    return (
      <MosaicGalleryItem
        key={entry.slug}
        itemNumber={idx + 1}
        label={title}
        entry={entry}
        image={entry.posterImageMedia}
        link={link}
      />
    )
  })

  return (
    <MosaicRoot>
      <Content>
        <TitleContainer>
          <Title>{sectionTitle}</Title>
          <StyledLink to={"/program"}>View All Events</StyledLink>
        </TitleContainer>
        <Gallery
          className={`mosaicGallery mosaicGallery--layout-items-${layoutSize}`}
        >
          {mosaicGalleryItems}
        </Gallery>
      </Content>
    </MosaicRoot>
  )
}

const MosaicRoot = styled.section`
  width: 100%;
`

const Content = styled.div`
  width: ${maxWidth};
  margin: 0 auto;
  padding: 4% 0;

  @media (max-width: 1220px) {
    width: 100%;
    padding: 4% 40px;
  }
  @media (max-width: 600px) {
    height: 100%;
    padding: 40px 20px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc((100% - (3 * ${desktopGridGap})) / 4));
  // grid-template-rows: repeat(3, calc((${desktopGridHeight} - (2 * ${desktopGridGap})) / 3 * ${aspectRatio}));
  gap: ${desktopGridGap};

  &.mosaicGallery--layout-items-1 {
    grid-template-areas:
      "item1 item1 item1 item1"
      "item1 item1 item1 item1"
      "item1 item1 item1 item1";
  }

  &.mosaicGallery--layout-items-2 {
    grid-template-areas:
      "item1 item1 item1 item1"
      "item1 item1 item1 item1"
      "item2 item2 item2 item2";
  }

  &.mosaicGallery--layout-items-3 {
    grid-template-areas:
      "item1 item1 item1 item1"
      "item1 item1 item1 item1"
      "item2 item2 item3 item3";
  }

  &.mosaicGallery--layout-items-4 {
    grid-template-columns: repeat(
      3,
      calc((100% - (2 * ${desktopGridGap})) / 3)
    );
    grid-template-areas:
      "item1 item1 item1"
      "item2 item3 item4";
  }

  &.mosaicGallery--layout-items-5 {
    grid-template-areas:
      "item1 item1 item1 item1"
      "item2 item2 item3 item3"
      "item4 item4 item5 item5";
  }

  @media (max-width: 1220px) {
    gap: ${desktopGridGap};
    &.mosaicGallery--layout-items-1,
    &.mosaicGallery--layout-items-2,
    &.mosaicGallery--layout-items-3,
    &.mosaicGallery--layout-items-5 {
      // grid-template-rows: auto;
    }

    &.mosaicGallery--layout-items-4 {
      grid-template-columns: repeat(
        3,
        calc((100% - (2 * ${desktopGridGap})) / 3)
      );
      // grid-template-rows: repeat(2, calc((100vw - (2 * ${desktopGridGap})) / 3 * ${aspectRatio}));
    }
  }

  @media (max-width: 900px) {
    gap: ${desktopGridGap};

    &.mosaicGallery--layout-items-1,
    &.mosaicGallery--layout-items-2,
    &.mosaicGallery--layout-items-3,
    &.mosaicGallery--layout-items-5 {
      // grid-template-rows: auto;
    }

    &.mosaicGallery--layout-items-4 {
      grid-template-columns: repeat(
        3,
        calc((100% - (2 * ${desktopGridGap})) / 3)
      );
    }
  }

  @media (max-width: 600px) {
    height: auto;
    grid-template-columns: 1fr; /* Single column layout */
    grid-template-rows: repeat(auto-fill, calc(100% * ${aspectRatio}));
    gap: ${mobileGridGap};

    &.mosaicGallery--layout-items-1 {
      grid-template-rows: auto;
      grid-template-areas: "item1";
    }

    &.mosaicGallery--layout-items-2 {
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        "item1"
        "item2";
    }

    &.mosaicGallery--layout-items-3 {
      grid-template-rows: repeat(3, auto);
      grid-template-areas:
        "item1"
        "item2"
        "item3";
    }

    &.mosaicGallery--layout-items-4 {
      grid-template-columns: repeat(
        3,
        calc((100% - (1 * ${desktopGridGap})) / 1)
      );
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        "item1"
        "item2"
        "item3"
        "item4";
    }

    &.mosaicGallery--layout-items-5 {
      grid-template-rows: repeat(5, auto);
      grid-template-areas:
        "item1"
        "item2"
        "item3"
        "item4"
        "item5";
    }
  }
`
