import { MediaDto } from "./media.dto"
import { AdSpaceTypes } from "@common/constants"

export class SponsorDto {
  constructor(
    name,
    tier,
    status,
    websiteUrl,
    logo,
    customAdUrl = "",
    adImages = {}
  ) {
    this.name = name
    this.tier = tier
    this.status = status
    this.websiteUrl = websiteUrl
    this.customAdUrl = customAdUrl
    this.logo = MediaDto.fromWpMediaItem(logo)
    this.adImages = {
      [AdSpaceTypes.HORIZONTAL]:
        MediaDto.fromWpMediaItem(adImages.horizontal) ?? null,
      [AdSpaceTypes.VERTICAL]:
        MediaDto.fromWpMediaItem(adImages.vertical) ?? null,
      [AdSpaceTypes.MOBILE]: MediaDto.fromWpMediaItem(adImages.mobile) ?? null,
    }
  }

  /**
   * Gets the media object for the specified type. If there is no media object,
   * returns null
   *
   * @param {string} type the type of ad image
   * @return {MediaDto|null} the media object of the specified type or null if it
   * does not exist
   */
  getAdImage(type) {
    if (!Object.values(AdSpaceTypes).includes(type)) {
      throw new Error(`Invalid Ad Image type ${type}`)
    }

    return this.adImages[type]
  }

  /**
   * Creates an instance of SponsorDto using data from a WpSponsor type object
   *
   * @param {WpSponsor} wpSponsor the raw data for a WpSponsor type object
   * @return {SponsorDto} the new SponsorDto object
   */
  static fromWpSponsor(wpSponsor) {
    if (wpSponsor?.sponsorGeneralInformation && wpSponsor?.sponsorStatus) {
      const {
        sponsorAdInformation,
        sponsorGeneralInformation: { name, websiteUrl, tier, logoUrl: logo },
        sponsorStatus: { status },
      } = wpSponsor

      return new SponsorDto(
        name,
        tier,
        status,
        websiteUrl,
        logo,
        sponsorAdInformation?.customAdUrl,
        sponsorAdInformation?.adImages
      )
    }

    return undefined
  }
}
