import React from "react"
import { Map } from "../../components/map/map"
import styled from "@emotion/styled"
import InnerHTML from "../inner_html/inner_html"

const VenuesSection = styled("div")`
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    margin: 3rem 1rem;
  }
`
const Title = styled("h2")`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
`

const Subtitle = styled("p")`
  text-align: center;
  padding-bottom: 1rem;

  @media (min-width: 800px) {
    grid-column: span 2;
    justify-self: start;
    align-self: end;
    padding: 0;

    .wpContent p {
      margin: 0;
    }
  }
`
const VenueList = styled("ol")`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  @media (min-width: 800px) {
    grid-template-columns: minmax(auto, 26rem) minmax(auto, 26rem);
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    row-gap: 0.5rem;
    overflow: hidden;
  }

  @media (min-width: 1280px) {
    max-width: 1180px;
  }
`

const Venue = styled("li")`
  max-width: 26rem;
  width: 100%;

  @media (min-width: 800px) {
    max-width: unset;
  }
`

const VenueInfo = styled("div")`
  margin: 1rem;

  @media (min-width: 800px) {
    margin: 1rem 0;
  }
`

const VenueName = styled("p")`
  font-weight: bold;
  margin-bottom: 0.5rem;
`
const VenueAddress = styled("address")`
  font-weight: bold;
`

export function TicketingFestivalVenues({ sectionContent, currentVenues }) {
  const { title, content } = sectionContent

  //sort the venues alphabetically

  const sortedVenues = currentVenues.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name < b.name) {
      return 1
    }
    return 0
  })

  return (
    <VenuesSection>
      <Title>{title}</Title>
      <VenueList>
        <Subtitle>
          <InnerHTML htmlContent={content} isFestivalPage={false} />
        </Subtitle>
        {sortedVenues.map((venue, i) => {
          const { streetAddress, addressLine2, city, state, zipCode } =
            venue.address

          // combine the address line 2 with the street address
          const address = addressLine2
            ? `${streetAddress} ${addressLine2}`
            : streetAddress

          return (
            <Venue key={venue.name}>
              <Map
                address={venue.address}
                coordinates={venue.coordinates}
                googlePlaceId={venue.coordinates?.googlePlaceId}
                width={"100%"}
                height={"17rem"}
                zoom={16}
              />
              <VenueInfo>
                <VenueName>{venue.name}</VenueName>
                <VenueAddress>
                  {`${address}, ${city}, ${state} ${zipCode}`}
                </VenueAddress>
              </VenueInfo>
            </Venue>
          )
        })}
      </VenueList>
    </VenuesSection>
  )
}
