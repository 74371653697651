import React from "react"
import PropTypes from "prop-types"

// import BonusContent from "./bonus_content"
import { SocialLinks } from "../../../social_links"
// import { WpEntryTypes } from "../../../../common/constants"

export function FilmContent({ visible, entry }) {
  if (!visible || !entry.hasFilmAttributes) return null

  return (
    <>
      {/* <BonusContent bonusContent={entry.bonusContent} /> // TODO: find as new home for this */}
      <SocialLinks
        links={entry.socialLinks}
        sectionTitle={"Connect with the Film"}
      />
    </>
  )
}

FilmContent.propTypes = {
  visible: PropTypes.bool.isRequired,
  entry: PropTypes.object.isRequired,
}
