import React from "react"
import { Grid } from "@mui/material"

import { LinkSectionSlugs } from "../../../common/constants/footer.constants"
import { FooterLinkList } from "./footer_link_list"
import { FooterSocialLinks } from "./footer_social_links"
import { FooterLogos } from "./footer_logos"
import { FooterNewsletterField } from "./footer_newsletter_field"

export function FooterContentDesktop({ socialLinks, footerLinkOptions }) {
  return (
    <div className="footer__content">
      <FooterNewsletterField />

      <Grid container>
        <Grid item xs={3}>
          <FooterLinkList {...footerLinkOptions[LinkSectionSlugs.FAQ]} />
        </Grid>
        <Grid item xs={3}>
          <FooterLinkList
            {...footerLinkOptions[LinkSectionSlugs.FESTIVAL_INFO]}
          />
        </Grid>
        <Grid item xs={3}>
          <FooterLinkList {...footerLinkOptions[LinkSectionSlugs.SUPPORT]} />
        </Grid>
        <Grid item xs={3}>
          <div className="footer__section">
            <FooterSocialLinks socialLinks={socialLinks} />

            <FooterLogos />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
