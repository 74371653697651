import React from "react"
import PropTypes from "prop-types"
import { Link } from "../link"
import { useButton } from "../../hooks"
import { ButtonTypes, ButtonOffsetTypes } from "../../common/constants"

export function ButtonLink(props) {
  const { to, external, ...rest } = props
  const { className, disabled, buttonProps, children } = useButton(rest)

  if (!to) {
    console.warn('The ButtonLink component requires a "to" property')
  }

  return (
    <Link
      to={to}
      external={external}
      className={className}
      disabled={disabled}
      {...buttonProps}
    >
      <span className="aaiffBtn__content">{children}</span>
    </Link>
  )
}

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  variant: PropTypes.oneOf([
    ButtonTypes.CTA,
    ButtonTypes.PRIMARY,
    ButtonTypes.SECONDARY,
    ButtonTypes.NEGATIVE,
    ButtonTypes.BORDERED,
    ButtonTypes.OVER_BACKGROUND,
  ]),
  offset: PropTypes.oneOf([
    ButtonOffsetTypes.NONE,
    ButtonOffsetTypes.AT_END,
    ButtonOffsetTypes.AT_START,
  ]),
  quiet: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
