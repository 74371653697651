import React from "react"
import { WpEntryTypes } from "../../../common/constants"
import { useSelectFestivalByYear } from "@hooks"
import { EntrySubList } from "./entry_sublist"

export function FilmsList({
  visible,
  films = [],
  entryType,
  contentType = "string", //will render <p> for panelist bio
}) {
  const selectFestivalByYear = useSelectFestivalByYear()

  const filmItems = films.map(film => {
    return {
      title: film.title,
      subtitle: `${film.directors.join(", ")} | ${film.countries.join(
        ", "
      )} | ${film.runtime} mins`,
      description: film.logline,
      posterImageMedia: film.posterImageMedia,
      path: `/${selectFestivalByYear(film.festivalYear).name.toLowerCase()}/${
        film.slug
      }`,
    }
  })

  return (
    <EntrySubList
      sectionTitle={
        entryType === WpEntryTypes.SHORTS_PROGRAM
          ? "Included Short Films"
          : "Included Films"
      }
      items={filmItems}
      visible={visible}
      contentType={contentType}
    />
  )
}
