import React from "react"
import styled from "@emotion/styled"
import moment from "moment"
import { FaCalendar, FaMapMarkerAlt, FaGlobe } from "react-icons/fa"

import ImageWrapper from "../../image_wrapper"
import { Link } from "../../link"
import { ImageFilterHover } from "./image_filter_hover"
import { EventFormatSlugs } from "../../../common/constants"

const formatDateTime = (startDate, endDate, startTime) => {
  let formattedDateTime
  let startDateTime = moment(startDate, "MM/DD/YYYY")
  let endDateTime = moment(endDate, "MM/DD/YYYY")

  if (startDateTime.isSame(endDateTime, "day")) {
    const dateTime = moment(`${startDate} ${startTime}`, "MM/DD/YYYY HH:mm:ss")
    formattedDateTime = dateTime.format("ddd, MMMM D [at] h:mma")
  } else if (startDateTime.isSame(endDateTime, "month")) {
    formattedDateTime = `${startDateTime.format(
      "MMMM D"
    )} - ${endDateTime.format("D, YYYY")}`
  } else {
    formattedDateTime = `${startDateTime.format(
      "MMMM D"
    )} - ${endDateTime.format("MMMM D, YYYY")}`
  }
  return formattedDateTime
}
export function MosaicGalleryItem(props) {
  const { label, link, image, itemNumber, entry } = props

  const {
    eventFormatSlug,
    startDate,
    endDate,
    startTime,
    venue,
    regionRestrictions,
  } = entry

  const isOnDemand = eventFormatSlug === EventFormatSlugs.ON_DEMAND
  const formattedDateTime = formatDateTime(startDate, endDate, startTime)

  return (
    <MosaicItemRoot
      to={link.path}
      external={link.external}
      className={`mosaicGalleryItem mosaicGalleryItem--${itemNumber}`}
    >
      <Content>
        <ImageFilterHover
          beforeGradient={true}
          afterGradient={true}
          aspectRatio={16 / 9}
          boxShadow={true}
          zoom={true}
        >
          <Image media={image} alt={label} objectFit="cover" />
        </ImageFilterHover>
        <CaptionContainer className={itemNumber > 1 ? "smallItem" : ""}>
          <CaptionTitle className={itemNumber > 1 ? "smallItem" : ""}>
            {label}
          </CaptionTitle>
          {startDate && (
            <CaptionRow className={itemNumber > 1 ? "smallItem" : ""}>
              <FaCalendar />
              <CaptionText>{formattedDateTime}</CaptionText>
            </CaptionRow>
          )}
          {isOnDemand ? (
            <CaptionRow className={itemNumber > 1 ? "smallItem" : ""}>
              <FaGlobe />
              {regionRestrictions ? regionRestrictions : "No Restrictions"}
            </CaptionRow>
          ) : (
            <CaptionRow className={itemNumber > 1 ? "smallItem" : ""}>
              {venue && (
                <>
                  <FaMapMarkerAlt />
                  <CaptionText>{venue.name}</CaptionText>
                </>
              )}
            </CaptionRow>
          )}
        </CaptionContainer>
      </Content>
    </MosaicItemRoot>
  )
}

const MosaicItemRoot = styled(Link)`
  display: block;
`

const Content = styled.figure`
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: 0; // required for overflow: hidden to work on certain iPhone versions

  @media (max-width: 1220px) {
    font-size: 1.8rem;
  }
`

const Image = styled(ImageWrapper)`
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: transform filter;
  aspect-ratio: 16/9;
  height: auto;
  width: 100%;

  &:hover {
    transform: scale(1.02);
  }
`

const CaptionContainer = styled.figcaption`
  position: absolute;
  bottom: 3%;
  left: 3%;
  color: white;
  font-size: 1.9rem;
  height: auto;
  transition: opacity 0.2s ease-in;
  z-index: 4;
  gap: 2%;
  pointer-events: none;
  padding: 0px 10px 8px 8px;

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
  @media (max-width: 750px) {
    font-size: 1.4rem;
  }
  @media (max-width: 600px) {
    font-size: 1.2rem;
    font-weight: bold;
  }

  &.smallItem {
    // Adjusted font size for items 2-5
    font-size: 1.3rem;

    @media (max-width: 1220px) {
      font-size: 1.4rem;
    }
    @media (max-width: 1070px) {
      font-size: 1.1rem;
    }
    @media (max-width: 855px) {
      font-size: 0.75rem;
    }
    @media (max-width: 600px) {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`

const CaptionTitle = styled.p`
  color: white;
  width: 100%;
  padding: 8px 0;
  font-weight: bold;

  @media (max-width: 900px) {
    padding: 7px 0;
  }
  @media (max-width: 600px) {
    padding: 4px 0;
  }

  &.smallItem {
    // Adjusted font size for items 2-5
    padding: 5px 0;

    @media (max-width: 1070px) {
      padding: 5px 0;
    }
    @media (max-width: 855px) {
      padding: 4px 0;
    }
    @media (max-width: 600px) {
      padding: 2px 0;
    }
  }
`

const CaptionRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  gap: 1rem;
  align-items: center;
  padding: 0px 0;

  @media (max-width: 900px) {
    font-size: 1.1rem;
  }
  @media (max-width: 750px) {
    font-size: 0.9rem;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
    padding: 0px 0;
  }

  &.smallItem {
    // Adjusted font size for items 2-5
    font-size: 1rem;
    padding: 1px 0;
    @media (max-width: 1220px) {
      font-size: 1rem;
    }
    @media (max-width: 1070px) {
      font-size: 0.9rem;
    }
    @media (max-width: 855px) {
      font-size: 0.75rem;
    }
    @media (max-width: 600px) {
      font-size: 1rem;
      padding: 1px 0;
    }
  }
`

const CaptionText = styled.p``
