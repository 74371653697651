import { useCurrentFestival } from "../../../hooks"
import { MediaDto } from "../../../common/dto"

export function useHomeBanner(homeBannerData) {
  const currentFestival = useCurrentFestival()

  const { dateOptions, callToActionOptions, bannerImages, textOverlayImages } =
    homeBannerData

  return {
    bannerImages: {
      desktop: MediaDto.fromWpMediaItem(bannerImages.desktop),
      mobile: MediaDto.fromWpMediaItem(bannerImages.mobile),
      tablet: MediaDto.fromWpMediaItem(bannerImages.tablet),
    },
    textOverlayImages: {
      desktop: MediaDto.fromWpMediaItem(textOverlayImages.desktop),
      mobile: MediaDto.fromWpMediaItem(textOverlayImages.mobile),
      tablet: MediaDto.fromWpMediaItem(textOverlayImages.tablet),
    },
    dateOptions,
    callToActionOptions,
    currentFestival,
  }
}
