export const TABADGE_VARIANT_NAMES = {
  PROGRAM_NORMAL_TEXT: 'PROGRAM_NORMAL_TEXT',
  PROGRAM_SMALL_TEXT: 'PROGRAM_SMALL_TEXT',
  EVENT_LIST_NORMAL_TEXT: 'EVENT_LIST_NORMAL_TEXT',
  EVENT_LIST_SMALL_TEXT: 'EVENT_LIST_SMALL_TEXT',
};

// Possible ticket statuses and badge display texts
export const TABADGE_LABELS = {
  SOLD_OUT: 'Sold Out',
  IN_PERSON_SOLD_OUT: 'In Person Sold Out',
  ALMOST_SOLD_OUT: 'Almost Sold Out',
  // PAST_EVENT: "Past Event",
  // ON_DEMAND_AVAILABLE: "On Demand Available", // potentially for events where in-person past / on-demand available
}

export const TABADGE_COLORS = {
  FONT_BLACK : '#000',
  FONT_WHITE : '#fff',
  BG_RED : '#EE0303',
  BG_ORANGE : '#FDC895',
  BG_WHITE : '#fff',
}

export const TABADGE_SMALL_TEXT_BREAKPOINTS = {
  PROGRAM_SMALL_TEXT: '640px',
  EVENT_LIST_SMALL_TEXT: '480px',
}

/**
 * For PROGRAM_NORMAL_TEXT, PROGRAM_SMALL_TEXT:
 * ProgramCard has multiple variants for both 'normal' and 'small'
 * font sizes, so cannot simply map 1:1 (TOP, RIGHT, PADDING) value sets
 * for those two TABADGE_VARIANT_NAME's.
 * Instead, these will be decided programmatically within
 * TicketAvailabilityBadge component.
 */
export const TABADGE_VARIANTS = {
  PROGRAM_NORMAL_TEXT: {
    NAME: TABADGE_VARIANT_NAMES.PROGRAM_NORMAL_TEXT,
    PADDING: '4px 12px',
  },
  PROGRAM_SMALL_TEXT: {
    NAME: TABADGE_VARIANT_NAMES.PROGRAM_SMALL_TEXT,
    PADDING: '4px 8px',
  },
  EVENT_LIST_NORMAL_TEXT: {
    NAME: TABADGE_VARIANT_NAMES.EVENT_LIST_NORMAL_TEXT,
    TOP: '14px',
    RIGHT: '14px',
    FONT_SIZE: '14px',
    PADDING: '4px 12px',
  },
  EVENT_LIST_SMALL_TEXT: {
    NAME: TABADGE_VARIANT_NAMES.EVENT_LIST_SMALL_TEXT,
    TOP: '8px',
    RIGHT: '8px',
    FONT_SIZE: '11px',
    PADDING: '2px 6px',
  },
};