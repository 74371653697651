import React from "react"
import { graphql } from "gatsby"

import { PageHead } from "@components/page_head"
import Layout from "../components/layout/layout"
import SponsorGallery from "../components/home_page/sponsor_gallery/sponsor_gallery"
import { HomeBanner } from "../components/home_page/home_banner"
import { UpcomingEventsMulti } from "../components/home_page/upcoming_events"
import { MosaicGallery } from "../components/home_page/mosaic_gallery"
import { FestivalTrailer } from "../components/home_page/festival_trailer"

export default function IndexPage({ data }) {
  const {
    wpFestivalPage: {
      festivalPageHome: {
        home: { homeBanner, latestNewsCarousel, mosaicV2, trailerSection },
      },
    },
  } = data

  return (
    <Layout className="layout">
      <HomeBanner bannerData={homeBanner} />
      <MosaicGallery
        title={mosaicV2.sectionTitle}
        isHidden={mosaicV2.isHidden}
        mosaicItems={mosaicV2.mosaicItems}
      />
      <UpcomingEventsMulti
        title={latestNewsCarousel.sectionTitle}
        isHidden={latestNewsCarousel.isHidden}
        itemData={latestNewsCarousel.carouselItems}
      />
      <FestivalTrailer
        title={trailerSection.sectionTitle}
        isHidden={trailerSection.isHidden}
        useCurrentFestivalTrailer={trailerSection.useCurrentFestivalTrailer}
        customUrl={trailerSection.customUrl}
      />
      <SponsorGallery />
    </Layout>
  )
}

export const Head = ({ data }) => {
  const {
    festivalPageMetadata: { title, description },
  } = data.wpFestivalPage

  return <PageHead title={title} description={description} />
}

export const query = graphql`
  query HomePage($id: String) {
    wpFestivalPage(id: { eq: $id }) {
      festivalPageMetadata {
        title
        description
      }
      festivalPageHome {
        home {
          ...HomeBanner
          ...HomeMosaicGalleryV2
          ...HomeLatestNewsCarousel
          ...HomeFestivalTrailer
        }
      }
    }
  }
`
