import React from "react"
import styled from "@emotion/styled"

import SocialLinkItem from "./social_link_item"

/**
 * SocialLinks component renders a section containing a header and icon links to external sites if there is at least one link.
 *
 * @param {Object} links - prop mapping site key (e.g. facebook) to url values
 * @param {string} sectionTitle - header for the section
 * @returns {JSX.Element} :
 * - SCSS classes removed in favor of Emotion styling
 */
export function SocialLinks({ links, sectionTitle }) {
  if (!links || links.length === 0) return null

  const socialLinkItems = Object.keys(links).map((platform, idx) => {
    if (!links[platform]) return null

    return (
      <SocialLinkItem
        key={`socialLink-${idx}`}
        platform={platform}
        redirectUrl={links[platform]}
      />
    )
  })

  if (socialLinkItems.filter(el => el !== null).length === 0) return null

  return (
    <SLSection>
      <SLHeader>{sectionTitle}</SLHeader>
      <SLDiv>
        <SLList>{socialLinkItems}</SLList>
      </SLDiv>
    </SLSection>
  )
}

const SLSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1.25em 0 2.5em;
  row-gap: 1em;
`

const SLHeader = styled.h2`
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.25em;
  margin: 0;
`

const SLDiv = styled.div`
  display: flex;
  margin: 0;
  line-height: 0.5;
`

const SLList = styled.ul`
  display: flex;
  column-gap: 1.125em;
  line-height: inherit;
`
