import { useState, useEffect } from 'react';
import MobileDetect from 'mobile-detect';

/*
This hook is using mobile-detect package to help detect the actual device that the user is using. 
This is essential for the react-multi-carousel SSR mode, as you need to add SSR=true + deviceType = '' prop for this mode to work properly 
(according to their documentation)
The deviceType is separate from responsive object, responsive is for different screen sizes, without regards to the type of device user is using.
*/

export const useMobileDetect = function() {
    const [actualDeviceType, setActualDeviceType] = useState('desktop');
    const [width, setWidth] = useState(1000);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const md = new MobileDetect(userAgent);

        let detectedDeviceType, width;

        if (md.tablet()) {
        detectedDeviceType = 'tablet';
        width = 750;
        } else if (md.mobile()) {
        detectedDeviceType = 'mobile';
        width=560;
        } else {
        detectedDeviceType = 'desktop';
        width = 1000;
        }

        setActualDeviceType(detectedDeviceType);
        setWidth(width);
    }, []);

    return {actualDeviceType, width};
};

