import React from "react"
import styled from "@emotion/styled"
import { TextField, Button } from "@components/@aaiff"

const SignUpButton = styled(Button)`
  height: 100%;
`

export function FooterNewsletterField() {
  return (
    <div className="footer__newsletter">
      <h5 className="footer__newsletterTitle">
        Want to receive AAIFF updates? Sign up for our newsletter!
      </h5>
      <div id="mc_embed_signup">
        <form
          action="https://asiancinevision.us18.list-manage.com/subscribe/post?u=269594565946fa70a78fc189d&amp;id=6e3d57916e"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate footer__newsletterForm"
          target="_blank"
          noValidate
        >
          <TextField
            type="text"
            name="FNAME"
            className="required"
            id="mce-FNAME"
            label="First name"
          />

          <TextField
            type="text"
            name="LNAME"
            className="required"
            id="mce-LNAME"
            label="Last name"
          />

          <TextField
            type="email"
            name="EMAIL"
            className="required email"
            label="Email address"
            id="mce-EMAIL"
          />
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_269594565946fa70a78fc189d_6e3d57916e"
              tabIndex="-1"
              value=""
            />
          </div>
          <SignUpButton
            type="submit"
            variant="primary1"
            name="subscribe"
            id="mc-embedded-subscribe"
            size="L"
          >
            Sign up
          </SignUpButton>
        </form>
      </div>
    </div>
  )
}
