import React from "react"
import * as DateUtils from "../../../util/date_util"

export default function ScheduleHeaderItem({ date, isSelected, onClick }) {
  const dateObj = new Date(date)
  const today = new Date()
  const weekDay = DateUtils.getDayOfTheWeekShortName(dateObj.getDay())

  const currentDate =
    date !== "undefined"
      ? `${DateUtils.getMonthName(
          today.getMonth()
        )} ${DateUtils.formatDoubleDigits(today.getDate())}`
      : ""

  const eventDate =
    date !== "undefined"
      ? `${DateUtils.getMonthName(
          dateObj.getMonth()
        )} ${DateUtils.formatDoubleDigits(dateObj.getDate())}`
      : ""

  let current = eventDate === currentDate ? "scheduleHeader__item--current" : ""

  return (
    <li
      className={`scheduleHeader__item ${current} ${
        isSelected ? "scheduleHeader__item--selected" : ""
      }`}
      onClick={onClick}
    >
      <div className="scheduleHeader__item__content">
        <h2 className="schedule__day">{weekDay.toUpperCase()}</h2>
        <h2 className="schedule__day">{`${DateUtils.getMonthShortName(
          dateObj.getMonth()
        )} ${eventDate.split(" ")[1]}`}</h2>
      </div>
    </li>
  )
}
