import React, { useState, useEffect } from "react"
import { FaUser } from "react-icons/fa"
import styled from "@emotion/styled"

import { Button } from "@components/@aaiff"

import { EleventAccount, EleventSignIn, EleventSignOut } from "../../elevent"
import { NavbarDropdownMenu, NavbarDropdownItem } from "../navbar"
import { ButtonOffsetTypes, EleventEvents } from "../../../common/constants"
import { useEleventService } from "../../../hooks"

const AccountRoot = styled("div")`
  position: relative;

  &:hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 10px;
  }
`

const SignInLink = styled(EleventSignIn)`
  display: none;

  @media (min-width: 450px) {
    display: block;
  }
`

const EleventAcc = styled(EleventAccount)`
  &:hover {
    background-color: #333333;
    border-radius: 4px;
    text-decoration: none;
  }
`

const UserButton = styled(Button)`
  &:hover {
    background-color: #333333;
    border-radius: 4px;
    text-decoration: none;
  }
`

export function AccountButton({
  mobile = false,
  offset = ButtonOffsetTypes.NONE,
  className = "",
}) {
  const eleventService = useEleventService()

  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [isSignedIn, setIsSignedIn] = useState(
    Boolean(eleventService.getCustomer() !== null)
  )

  /**
   * Event listeners for Elevent Events.
   */
  useEffect(() => {
    const handleInitialized = e => {
      setIsSignedIn(eleventService.getCustomer() !== null)
    }

    const handleSignIn = e => {
      setIsSignedIn(true)
    }

    const handleSignOut = e => {
      setIsSignedIn(false)
      setDropdownOpened(false)
    }

    window.addEventListener(EleventEvents.afterInitialized, handleInitialized)
    window.addEventListener(EleventEvents.afterSignIn, handleSignIn)
    window.addEventListener(EleventEvents.afterSignOut, handleSignOut)

    return () => {
      if (window) {
        window.removeEventListener(
          EleventEvents.afterInitialized,
          handleInitialized
        )
        window.removeEventListener(EleventEvents.afterSignIn, handleSignIn)
        window.removeEventListener(EleventEvents.afterSignOut, handleSignOut)
      }
    }
  }, [])

  const handleMouseEnter = () => {
    if (!mobile && isSignedIn) {
      setDropdownOpened(true)
    }
  }

  const handleMouseLeave = () => {
    if (!mobile && isSignedIn) {
      setDropdownOpened(false)
    }
  }

  const handleClick = () => {
    if (!mobile && isSignedIn) {
      setDropdownOpened(!dropdownOpened)
    }
  }

  useEffect(() => {
    if (dropdownOpened) {
      // Whenever the dropdown opens, make sure to initialize the elevent widgets.
      eleventService.initializeWidgets()
    } else {
      // Whenever the dropdown closes, cleanup the orphaned references
      eleventService.cleanupTicketingWidgetReferences()
    }
  }, [dropdownOpened])

  return (
    <AccountRoot
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {mobile ? (
        <EleventAcc
          quiet
          variant="overBackground"
          offset={offset}
          style={!isSignedIn ? { display: "none" } : { display: "block" }}
        >
          <FaUser />
        </EleventAcc>
      ) : (
        <UserButton
          variant="overBackground"
          onClick={handleClick}
          offset={offset}
          style={!isSignedIn ? { display: "none" } : { display: "block" }}
        >
          <FaUser />
        </UserButton>
      )}

      <SignInLink
        variant="overBackground"
        offset={offset}
        style={isSignedIn ? { display: "none" } : { display: "block" }}
        className={className}
      >
        <span>Sign In</span>
      </SignInLink>

      {!mobile ? (
        <NavbarDropdownMenu open={dropdownOpened} className="account__dropdown">
          <NavbarDropdownItem>
            <EleventAccount
              variant="overBackgroundDark"
              quiet
              className="account__dropdownLink"
            >
              Account
            </EleventAccount>
          </NavbarDropdownItem>
          <NavbarDropdownItem>
            <EleventSignOut
              variant="overBackgroundDark"
              quiet
              className="account__dropdownLink"
              asButton="true"
            >
              Sign Out
            </EleventSignOut>
          </NavbarDropdownItem>
        </NavbarDropdownMenu>
      ) : null}
    </AccountRoot>
  )
}
