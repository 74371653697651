import React from "react"
import { graphql } from "gatsby"

import { PageHead } from "@components/page_head"
import Layout from "../components/layout/layout"
import InnerHTML from "../components/inner_html/inner_html"
import * as DateUtil from "../util/date_util"

export default function Update({ data }) {
  const {
    wpUpdate: {
      updatesGeneralInformation: { title, content },
    },
  } = data

  return (
    <Layout>
      <h1 className="update__title">
        {title || DateUtil.formatMonthDayYear(formattedDate)}
      </h1>

      <InnerHTML htmlContent={content} isFestivalPage={true} />
    </Layout>
  )
}

export const Head = ({ data }) => {
  const {
    wpUpdate: {
      updatesGeneralInformation: { title, description, date },
    },
  } = data

  const formattedDate = new Date(date)
  const seoTitle =
    title || `${DateUtil.formatMonthDayYear(formattedDate)} Update`

  return <PageHead title={seoTitle} description={description} />
}

export const query = graphql`
  query ($id: String) {
    wpUpdate(id: { eq: $id }) {
      updatesGeneralInformation {
        content
        description
        title
        date
      }
    }
  }
`
