import React from "react"

import { ScheduleViewTypes } from "../../../../common/constants"
import { ScheduleGridView } from "../../../schedule_grid_view"
import { EventsList } from "./events_list"
import { EventsCalendar } from "./events_cal"

export function ScheduleViewsRoot(props) {
  const {
    currentViewType,
    eventEntries,
    isSearchActive,
    searchResults,
    query,
  } = props

  const events =
    currentViewType === ScheduleViewTypes.LIST && isSearchActive
      ? searchResults.exactEvents.concat(searchResults.relatedEvents)
      : eventEntries

  return (
    <>
      {currentViewType === ScheduleViewTypes.GRID && (
        <ScheduleGridView events={events} isSearchActive={isSearchActive} />
      )}
      {currentViewType === ScheduleViewTypes.LIST && (
        <EventsList
          events={events}
          isSearchActive={isSearchActive}
          query={query}
        />
      )}
      {currentViewType === ScheduleViewTypes.CALENDAR && (
        <EventsCalendar events={events} isSearchActive={isSearchActive} />
      )}
    </>
  )
}
