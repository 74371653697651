import React from "react";
import styled from "@emotion/styled"
import { EventItineraryRow } from "./event_itinerary_row";

/**
 * EventItinerary component displays a section header and for a given EventEntry's itinerary,
 * a list of timestamps and activity details per timestamp, and optional note.
 * 
 * NOTE REGARDING TIME: takes an optional argument to forcibly convert itinerary times to the user's local timezone.
 * By default, on-demand and livestream events display as user's local TZ;
 * while in-person events by default are displayed as EDT.
 * 
 * @param {Array} detailsItinerary - prop: Array of objects representing activity for each timestamp
 * @param {string} detailsNote - prop - optional: note about itinerary
 * @param {string} eventFormatSlug - prop: whether event is "in-person", "livestream", "on-demand"
 * @param {string} startDate - prop: date of the event
 * @param {boolean} convertToLocalTimeZoneEnabled - prop - optional: force convert time string to user's TZ
 */
export function EventItinerary( props ) {
	const { 
		detailsItinerary, 
		detailsNote, 
		eventFormatSlug,
		startDate,
		convertToLocalTimeZoneEnabled, 
	} = props;
	if(!detailsItinerary || detailsItinerary.length === 0) {
		return null;
	}

	return (
		<EISection>
			<EIHeader>Event Details</EIHeader>
			<EITimeline>
				{detailsItinerary.map(itineraryItem => {
					return (<EventItineraryRow 
						rowData={itineraryItem} 
						eventFormatSlug={eventFormatSlug}
						startDate={startDate}
						convertToLocalTimeZoneEnabled={convertToLocalTimeZoneEnabled} 
					/>)
				})}
			</EITimeline>
			{detailsNote && <EINote>{detailsNote}</EINote>}
		</EISection>
	)
}

/**
 * Options for spacing between child elements in EISection: 
 * (A) `&> *:not(:last-child)`, or (B) `row-gap`. 
 * Chose former because if `detailsNote` is null, the rendered null still
 * adds a gap.
 * Latter works if render two versions of JSX based on `detailsNote`,
 * but can introduce oversights during maintenance.
 */
const EISection = styled.section`
	margin-top: 2.5em;
	margin-bottom: 2.5em;
	display: flex;
	flex-direction: column;
	&> *:not(:last-child) {			// ** spacing option A
		margin-bottom: 1.25rem;		// rem instead em so doesn't compound if child calculates its own font-size
	}
	// row-gap: 1.25em;					// ** spacing option B
`

const EIHeader = styled.h2`
	font-weight: 700;
	font-size: 2em;
	line-height: 1.25em;
	margin: 0;
`

const EITimeline = styled.ol`
	display: flex;
	flex-direction: column;
	row-gap: 1.25em;
`

const EINote = styled.p`
	font-style: italic;
`