import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled';

export function ComingSoon(props) {
  const { itemNumber } = props;
  return (
    <div className={`mosaicGalleryItem mosaicGalleryItem--${itemNumber}`}>
      <ComingSoonContainer>
        <MainText>More Events</MainText>
        <SubText>Coming Soon!</SubText>
      </ComingSoonContainer>
    </div>
  );
}

ComingSoon.propTypes = {
  itemNumber: PropTypes.number.isRequired,
};

const ComingSoonContainer = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  color: #333;
  font-family: Arial, sans-serif;
  width: 100%;
  height: 100%;
  text-align: center;
  aspect-ratio: 16/9;
`;
const MainText = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 5%;

  @media(max-width: 900px) {
    font-size: 1.3rem;
  }    
  @media(max-width: 600px) {
    font-size: 1.8rem;
  }    
`;

const SubText = styled.h1`
  font-size: 1.6rem;
  margin-bottom: 0px;
  @media(max-width: 900px) {
    font-size: 1.1rem;
  }
  @media(max-width: 600px) {
    font-size: 1.6rem;
  }    
`;