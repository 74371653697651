import { useEffect } from "react"

/**
 *
 * when using elevent widgets on the page, once it is initialized,
 * it is not always completed loaded onto the page.
 * elevent does requests to get their components on the page.
 * If the browser hasn't receive the elevent component then it won't render on the page
 */

/**
 *
 * Run the XMLHttpRequest prototype override once
 * This is to intercept network requests and responses by the browser
 * */

if (typeof window !== "undefined") {
  //stores the original open and send methods, before we modify them
  //These original methods are call at the end with apply.
  const originalOpen = XMLHttpRequest.prototype.open
  const originalSend = XMLHttpRequest.prototype.send

  /**
   * override the open method
   * we add method to store the request URL
   */

  XMLHttpRequest.prototype.open = function (
    method,
    url,
    async,
    user,
    password
  ) {
    this._url = url
    return originalOpen.apply(this, arguments)
  }

  /**
   * override the send method
   * Add eventListener to detect when the request is completed
   */

  XMLHttpRequest.prototype.send = function (body) {
    this.addEventListener("load", function () {
      const event = new CustomEvent("xhrResponseReceived", {
        detail: {
          url: this._url,
          response: this.responseText,
          status: this.status,
        },
      })
      window.dispatchEvent(event)
    })
    return originalSend.apply(this, arguments)
  }
}

/**
 *
 * @param {Object} elevent elevent information for each Tickets
 * @param {function} onLoaded function to do once response is received.
 *
 *the event listener is receiving all the responses that the browser gets back
 * we single out the url that loads our essential elevent component.
 * watching the specific url by adding in elevent package and id that comes in with the modal
 */
export function useEleventWidgetLoadedListener(elevent, onLoaded) {
  const { type, id } = elevent

  useEffect(() => {
    const handleXhrResponse = event => {
      const { url } = event.detail
      if (
        url.includes(
          `https://widget.goelevent.com/view/${elevent.type}/detail?p=${elevent.id}&a=public`
        )
      ) {
        onLoaded()
      }
    }

    window.addEventListener("xhrResponseReceived", handleXhrResponse)

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("xhrResponseReceived", handleXhrResponse)
    }
  }, [type, id, onLoaded])
}
