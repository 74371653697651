import React, { useState } from "react"
import { FilterTypes, filterTypeLabels } from "../../../../common/constants"
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import styled from "@emotion/styled"

const StyledHeaderContainer = styled.div`
  padding: 0px 16px;
`

const StyledDrawerHeader = styled.div`
  background: #fff;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000;
  padding: 27px 8px 10px 8px;
  transform: ${props => props.mobileOpen ? 'translateX(0)' : 'translateX(100%)'};
  z-index: 301;
  .filterTitle {
    margin: 0;
  }
  .backArrow {
    // visibility: ${props => props.showBackArrow ? 'visibility' : 'hidden'};
    display: ${props => props.showBackArrow ? '' : 'none'};
    &:hover {
      cursor: pointer;
    }
  }
  .closeIcon {
    &:hover {
      cursor: pointer;
    }
  }
`

const StyledFilterTitle = styled.h3`
  font-size: 20px;
`

export function DrawerFilterHeader(props) {
  const { closeDrawer, backToFilters, title, showBackArrow, mobileOpen } = props
  return(
    <StyledHeaderContainer>
      <StyledDrawerHeader
        showBackArrow={showBackArrow}
        mobileOpen={mobileOpen}
        className="filterHeader"
      >
        <ArrowBackIosNewIcon
          className="backArrow"
          onClick={backToFilters}
          fontSize="small"
        />
        <StyledFilterTitle className="filterTitle">{title}</StyledFilterTitle>
        <CloseIcon 
          className="closeIcon"
          onClick={closeDrawer}
        />
      </StyledDrawerHeader>
    </StyledHeaderContainer>
  )
}