import { EventEntry } from "./EventEntry"
import { WpEntryTypes } from "../constants"
import { mapFilmsById } from "../../util/entry_utils"
/**
 * This class represents an Event that screens multiple short
 * films. Mainly used for Shorts Program containing shorts and/or
 * digital content.
 *
 * @class
 */
export class ShortsProgramEntry extends EventEntry {
  constructor(id, slug, data) {
    super(id, slug, data)

    this.type = WpEntryTypes.SHORTS_PROGRAM

    if (data.shortFilms) {
      this.__films = mapFilmsById(data.shortFilms)
    }
  }

  get allFilms() {
    return Array.from(this.__films.values())
  }

  get allFilmIds() {
    return Array.from(this.__films.keys())
  }

  get additionalFilms() {
    return this.allFilms
  }

  get mainFilm() {
    return null
  }

  /**
   * Retrieves all of the film formats of each film
   * within the shorts program
   */
  get filmFormats() {
    const filmFormats = new Set()

    this.__films.forEach(film => {
      if (film.filmFormat) {
        filmFormats.add(film.filmFormat)
      }
    })

    return Array.from(filmFormats.values())
  }

  /**
   * Alias for filmFormats getter
   */
  get filmFormat() {
    return this.filmFormats
  }

  get filmTypes() {
    const filmTypes = new Set()
    this.__films.forEach(film => {
      film.filmTypes.forEach(filmTypes.add, filmTypes)
    })

    return Array.from(filmTypes.values())
  }

  get genres() {
    const genres = new Set()
    this.__films.forEach(film => {
      film.genres.forEach(genres.add, genres)
    })

    return Array.from(genres.values())
  }

  get countries() {
    const countries = new Set()
    this.__films.forEach(film => {
      film.countries.forEach(countries.add, countries)
    })

    return Array.from(countries.values())
  }

  get languages() {
    const languages = new Set()
    this.__films.forEach(film => {
      film.languages.forEach(languages.add, languages)
    })

    return Array.from(languages.values())
  }

  get subtitledLanguages() {
    const languages = new Set()
    this.__films.forEach(function (film) {
      film.subtitledLanguages.forEach(languages.add, languages)
    })

    return Array.from(languages.values())
  }

  get contentAdvisories() {
    const advisories = new Set()

    this.__films.forEach(film => {
      film.contentAdvisories.forEach(advisories.add, advisories)
    })

    return Array.from(advisories.values())
  }

  get keywords() {
    const tags = new Set()
    this.__films.forEach(film => {
      film.keywords.forEach(tags.add, tags)
    })

    return Array.from(tags.values())
  }

  get runtime() {
    if (!this.__data.runtime) {
      let runtime = 0

      this.allFilms.forEach(film => {
        runtime += film.runtime
      })

      this.__data.runtime = runtime
    }

    return this.__data.runtime
  }
}
