import React from "react"
import { sortEntries } from "../../../../util/search_utils"
import { ProgramCard } from "@components/cards"
import styled from "@emotion/styled"

export function ResultsList(props) {
  const { title, entries, sortOrder } = props

  // Don't render the list component if there are no entries
  if (!entries || entries.length === 0) {
    return null
  }

  const sortedResults = sortEntries(entries, sortOrder)

  return (
    <section>
      <StyledSearchContentTitle className="search__contentTitle">
        {title}
      </StyledSearchContentTitle>
      <span className="search__contentSubtitle">
        {sortedResults.length === 1
          ? `Found ${sortedResults.length} Result`
          : `Found ${sortedResults.length} Results`}
      </span>
      <StyledResultsList className="searchResults">
        {sortedResults.map(entry => (
          <li className="resultItem" key={entry.slug}>
            <ProgramCard
              entry={entry}
              linkProps={{ state: { fromProgram: true } }}
              isSearchResult={true}
            />
          </li>
        ))}
      </StyledResultsList>
    </section>
  )
}

const StyledSearchContentTitle = styled.h2`
  &.search__contentTitle {
    font-size: 1.75em;
    font-weight: 700;
    margin-bottom: 10px;
  }
`

const StyledResultsList = styled.ul`
  row-gap: 75px;
  @media (max-width: 900px) {
    row-gap: 50px;
  }
  @media (max-width: 560px) {
    row-gap: 40px;
  }
`
