import { EventTypeSlugs, SearchPageTypes } from "../../common/constants"
import { useCurrentFestival } from "../useCurrentFestival"

import { WpDataFactory } from "@common/factories/WPDataFactory"

export const useProgramState = function ({ data, location }) {
  const { films, events, localSearchCurrentFestivalEntries } = data

  const currentFestival = useCurrentFestival()

  /**
   * Retrieves the props for the Search component for the Program page
   * @returns the current festival information, the events and films, and
   * the list of filters for the search component
   */
  const getSearchProps = () => {
    // Instantiate film entries and filter by current year
    const allFilmEntries = films.nodes
      .map(node => WpDataFactory.instantiateWpEntry(node))
      .filter(
        film =>
          film.festivalYear &&
          film.festivalYear === currentFestival.festivalYear
      )

    // Instantiate event entries and filter by current year
    const allEventEntries = events.nodes
      .map(node => {
        const eventEntry = WpDataFactory.instantiateWpEntry(node)
        // If the event has a main film, add the event to the main film's
        // screening events. This event will later be filtered out to
        // prevent duplicate entries (e.g. in-person and VOD screenings for
        // the same film).
        if (eventEntry?.mainFilm) {
          allFilmEntries
            .find(film => film.id === eventEntry.mainFilm.id)
            ?.addScreeningEvent(eventEntry)
        }

        // If the event is a shorts program, add the event to all of the
        // shorts program's films.
        if (eventEntry?.allFilms) {
          eventEntry.allFilms.forEach(film => {
            allFilmEntries
              .find(filmEntry => filmEntry.id === film.id)
              ?.addScreeningEvent(eventEntry)
          })
        }

        return eventEntry
      })
      .filter(
        event =>
          event.festivalYear &&
          event.festivalYear === currentFestival.festivalYear &&
          // Filter out events that have a main film here to prevent
          // duplicate cards for the same film
          !event.mainFilm
      )

    return {
      location,
      index: localSearchCurrentFestivalEntries.index,
      store: localSearchCurrentFestivalEntries.store,
      allFilmEntries,
      allEventEntries,
      pageType: SearchPageTypes.PROGRAM,
    }
  }

  return {
    getSearchProps,
  }
}
