import React, { useState, useCallback, useEffect } from "react"
import styled from "@emotion/styled"
import { CgClose } from "react-icons/cg"
import { Modal } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

import InnerHTML from "../inner_html/inner_html"
import { TicketingCardButton } from "./ticketing_card_btn"
import { EleventEvents } from "../../common/constants"
import { useEleventWidgetLoadedListener, useEleventService } from "../../hooks"

const ModalBody = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: white;
  padding: 1.5rem;
  min-width: 20rem;
  max-width: 45rem;

  @media (min-width: 800px) {
    width: 40rem;
  }

  @media (min-width: 1280px) {
    width: 45rem;
  }
`

const ModalContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: start;

  ol {
    list-style: numbers;
    list-style-position: inside;
  }
`
const ModalButtonBox = styled("div")`
  align-self: center;
  width: 11.5rem;
  height: 3rem;
  max-height: 3rem;

  button {
    background: #ffa530;
    color: black;
    font-weight: 400;
    padding: ${props => (props.isLoading ? "6.75px 1rem" : "0.875rem 1rem")};
  }

  button:focus {
    background: #ffa530;
  }
`

const ModalHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
`

const ModalTitle = styled("h1")`
  font-size: 1.75rem;
  margin: 0;
`

const ModalClose = styled("button")`
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
`

const CloseIcon = styled(CgClose)`
  font-size: 1.5rem;
`

export function TicketingModal(props) {
  const {
    isOpen,
    closeModal,
    ticketName,
    content,
    elevent,
    linkTo,
    isAvailable,
  } = props

  const [isLoading, setIsLoading] = useState(true)
  const eleventService = useEleventService()

  /**
   * When the elevent item is added to the Elevent cart, close the ticketing modal
   * so that the Elevent modal is not covered
   */
  useEffect(() => {
    if (isOpen) {
      eleventService.addEventListener(EleventEvents.afterAddToCart, closeModal)

      return () => {
        eleventService.removeEventListener(
          EleventEvents.afterAddToCart,
          closeModal
        )
      }
    }
  }, [isOpen, closeModal])

  const onLoaded = useCallback(() => setIsLoading(false))
  useEleventWidgetLoadedListener(elevent, onLoaded)

  return (
    <Modal open={isOpen} onClose={closeModal} disableRestoreFocus={true}>
      <ModalBody>
        <ModalHeader>
          <ModalTitle>{`How to Use ${ticketName}`}</ModalTitle>
          <ModalClose onClick={closeModal}>
            <CloseIcon />
          </ModalClose>
        </ModalHeader>
        <ModalContent>
          <InnerHTML htmlContent={content} />
          <ModalButtonBox isLoading={isLoading}>
            <TicketingCardButton
              itemType={elevent.type}
              eleventInfo={elevent}
              linkTo={linkTo}
              isDisabled={!isAvailable}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={30} /> : "Continue"}
            </TicketingCardButton>
          </ModalButtonBox>
        </ModalContent>
      </ModalBody>
    </Modal>
  )
}
