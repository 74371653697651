import React from "react";
import styled from '@emotion/styled';

//use example: 
// <ImageFilterHover beforeGradient={true} afterGradient={true} aspectRatio={16/9} boxShadow={true} zoom={true}>
//     <Image
//       media={image}
//       alt={label}
//       objectFit="cover"
//     />
// </ImageFilterHover>

export function ImageFilterHover(props) {
  const { beforeGradient, afterGradient, boxShadow, aspectRatio = 16/9, zoom, children } = props;

  return (
    <ImageFilterHoverContainer aspectRatio={aspectRatio}>
      {beforeGradient && <ImageFilter ></ImageFilter>}
      <ImageWrapper zoom={zoom}>
        {children}
      </ImageWrapper>
      {afterGradient && <ImageHover boxShadow={boxShadow}></ImageHover>}
    </ImageFilterHoverContainer>
  );
}

const ImageFilterHoverContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: ${(props) => props.aspectRatio};
  overflow: hidden;
`;

const ImageFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.08625) 45.5%,
    rgba(0, 0, 0, 0.35) 100%
  );
  z-index: 3;
  height: 100%;
  width: 100%;
`;

const ImageHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.25625) 37.5%,
    rgba(0, 0, 0, 0.6) 100%
  );
  box-shadow: ${(props) => (props.boxShadow ? "0px 5px 10px 0px #1111111a" : "none")};
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 3;
  transition: opacity 0.2s ease-in-out;

  ${ImageFilterHoverContainer}:hover & {
    opacity: 1;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: transform;
  z-index: 2; // Ensure image is behind the background gradient and hover effect

  ${(props) => props.zoom && `
    ${ImageFilterHoverContainer}:hover & {
      transform: scale(1.02);
    }
  `}
`;

