import React from "react"

import { ButtonLink, Button } from "@components/@aaiff"
import { EleventPackageButton, EleventPassButton } from "@components/elevent"

export function TicketingCardButton(props) {
  const { itemType, eleventInfo, children, linkTo, isDisabled, disabled } =
    props

  if (!isDisabled) {
    if (itemType === "pass") {
      return (
        <EleventPassButton
          passId={eleventInfo.id}
          allocationId={eleventInfo.allocationId}
          className="ticketing__card__footerBtn"
          disabled={disabled}
        >
          {children}
        </EleventPassButton>
      )
    } else if (itemType === "package") {
      return (
        <EleventPackageButton
          packageId={eleventInfo.id}
          allocationId={eleventInfo.allocationId}
          className="ticketing__card__footerBtn"
          disabled={disabled}
        >
          {children}
        </EleventPackageButton>
      )
    } else if (itemType === "link") {
      return (
        <ButtonLink
          to={linkTo}
          {...props}
          className="ticketing__card__footerBtn"
        >
          {children}
        </ButtonLink>
      )
    } else {
      // Log a warning if we end up rendering a button with an invalid item type. Have the
      // button be disabled
      console.warn(
        `TicketingCardButton received unexpected item type ${itemType}`
      )
    }
  }

  return (
    <Button isDisabled className="ticketing__card__footerBtn">
      {children}
    </Button>
  )
}
