export const ButtonTypes = {
  CTA: "cta",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  NEGATIVE: "negative",
  OVER_BACKGROUND: "overBackground",
  BORDERED: "bordered",
}

export const ButtonOffsetTypes = {
  AT_END: "atEnd",
  AT_START: "atStart",
  NONE: "none",
}
