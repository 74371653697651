import React from "react"

import ScheduleItem from "./schedule_item"

export default function gridItemOverlapGroup({ events, maxOverlaps }) {
  const scheduleItems = events.map((event, idx) => {
    return (
      <ScheduleItem
        key={`overlap-item-${idx}`}
        event={event}
        className={`gridItemOverlap--${maxOverlaps} 
        gridItemOverlap--col-${(idx % maxOverlaps) + 1}`}
      />
    )
  })

  return <>{scheduleItems}</>
}
