import React from "react"
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet"
import { CustomMarker } from "./custom_marker"
import zIndex from "@mui/material/styles/zIndex"

// example:
// < Map
//   address={entry.__data.venue.venueGeneralInformation.address}
//   coordinates={entry.__data.venue.venueGeneralInformation.coordinates}
//   width={'100%'}
//   height={'350px'}
// />

export function Map(props) {
  const {
    zoom = 15,
    address = null,
    width = "100%",
    height = "300px",
    coordinates = null,
    googlePlaceId,
  } = props

  if (!address || !coordinates) {
    console.error("Map - Received invalid address or coordinates")
    return null
  }

  const latlong = [coordinates.latitude, coordinates.longitude]

  function OpenInGoogleMaps({ address }) {
    const { streetAddress, addressLine2, city, state, zipCode } = address
    const fullAddress = `${streetAddress}${
      addressLine2 ? ", " + addressLine2 : ""
    }, ${city}, ${state} ${zipCode}`

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      fullAddress
    )}${googlePlaceId ? `&query_place_id=${googlePlaceId}` : ""}` // if a place ID is provided, add it to the URL

    useMapEvents({
      click: () => {
        window.open(googleMapsUrl, "_blank")
      },
    })
    return null
  }

  return (
    <MapContainer
      style={{ height: height, width: width, zIndex: 1 }}
      center={latlong}
      zoom={zoom}
      scrollWheelZoom={true}
      attributionControl={false}
      dragging={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/* <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
      /> */}
      <CustomMarker coordinates={latlong} />
      <OpenInGoogleMaps address={address} />
    </MapContainer>
  )
}
