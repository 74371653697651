import React from "react"
import PropTypes from "prop-types"
import { ButtonTypes, ButtonOffsetTypes } from "../../common/constants"
import { useButton } from "../../hooks"
export function Button(props) {
  const { className, onClick, disabled, buttonProps, children } =
    useButton(props)

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <span className="aaiffBtn__content">{children}</span>
    </button>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf([
    ButtonTypes.CTA,
    ButtonTypes.PRIMARY,
    ButtonTypes.SECONDARY,
    ButtonTypes.OVER_BACKGROUND,
    ButtonTypes.BORDERED,
    ButtonTypes.NEGATIVE,
  ]),
  offset: PropTypes.oneOf([
    ButtonOffsetTypes.NONE,
    ButtonOffsetTypes.AT_END,
    ButtonOffsetTypes.AT_START,
  ]),
  quiet: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
