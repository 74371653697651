export class MediaDto {
  constructor(sourceUrl, altText, gatsbyImageData) {
    this.sourceUrl = sourceUrl
    this.altText = altText || ""
    this.gatsbyImageData = gatsbyImageData || null
  }

  hasGatsbyImageData() {
    return !!this.gatsbyImageData
  }

  /**
   * Creates a new instance of MediaDto using data from a queried MediaItem
   *
   * @param {Object} mediaItem the MediaItem data from a graphql query
   * @return {MediaDto} the MediaItem as a new instance of MediaDto
   */
  static fromWpMediaItem(mediaItem) {
    if (mediaItem) {
      const { sourceUrl, altText = "", localFile } = mediaItem

      if (sourceUrl)
        return new MediaDto(
          sourceUrl,
          altText,
          localFile?.childImageSharp?.gatsbyImageData
        )
    }

    return undefined
  }
}
