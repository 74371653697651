import React from "react"
import PropTypes from "prop-types"
import { Button } from "../../../buttons"
import { navigate } from "gatsby"
import { FaArrowLeft } from "react-icons/fa"
import styled from "@emotion/styled"

const BackButton = styled(Button)`
  font-size: 1em;
`
export function WpEntryHeader(props) {
  const { fromProgram = false } = props

  const handleBackBtnClick = () => {
    if (fromProgram) {
      navigate(-1)
    } else {
      navigate("/program")
    }
  }

  return (
    <div className="eventContentHeader">
      <BackButton
        variant="secondary"
        quiet
        onClick={handleBackBtnClick}
        className="eventContentHeader__backBtn"
        offset="atStart"
      >
        <FaArrowLeft />
        <span>
          {fromProgram ? "Back to Program Page" : "Go to Program Page"}
        </span>
      </BackButton>
    </div>
  )
}

WpEntryHeader.propTypes = {
  entry: PropTypes.any.isRequired,
  fromProgram: PropTypes.bool,
}
