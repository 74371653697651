import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import {
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Button } from "../../../buttons"
import { SearchPageTypes } from "../../../../common/constants"

const StyledFilterItem = styled.li`
  ${props => `
    ${props.isMobile ? `` : `margin-bottom: 20px;`}
  `}
`

const StyledMobileOptionsGroup = styled.div`
  background: #fff;
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 0;
  right: 0;
  transform: ${props =>
    props.showOptions ? "translate(0, 0)" : "translate(calc(100% + 20px), 0)"};
  z-index: 1;
  .MuiButtonBase-root {
    padding: 12px 12px 12px 12px;
  }
  .filtersDrawer__option {
    margin-left: 0;
    margin-right: 0;
    .MuiFormControlLabel-label {
      font-size: 0.875rem;
    }
  }
`
const StyledDesktopOptionsGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export function FilterOptionItem(props) {
  // const isMobile = useMediaQuery("(max-width:900px)")
  const {
    condenseOptions = false,
    multiSelect = false,
    filterValue,
    title,
    type,
    options,
    onFilterChange,
    mobileOpen,
    showOptionItems,
    setShowOptionItems,
    filterBadges,
    setFilterBadges,
    pageType,
    isMobile,
  } = props
  const [state, setState] = useState({
    isCondensed: condenseOptions,
    showButton: condenseOptions,
  })

  const onChangeBadges = (value, filterValue, multiSelect) => {
    // filterValue is async, value is the value after select an option
    // if filterValue is undefined/null, add a new badge to filterBadge array
    if (!filterValue) {
      setFilterBadges(prevBadges => [
        ...prevBadges,
        { type, value, multiSelect },
      ])
    } else {
      // if multiSelct is true, split filterValue to an array to compare filterValue & value
      if (multiSelect) {
        filterValue = filterValue?.split(",")
        // if filterValue array does NOT include the selected value
        // add a new badge, else remove the badge
        if (!filterValue.includes(value)) {
          setFilterBadges(prevBadges => [
            ...prevBadges,
            { type, value, multiSelect },
          ])
        } else {
          setFilterBadges(prevBadges =>
            prevBadges.filter(badge => badge.value !== value)
          )
        }
      } else {
        // if single select
        // if selected value is same as filterValue, remove/unselect this badge
        if (value === filterValue) {
          setFilterBadges(prevBadges =>
            prevBadges.filter(badge => badge.value !== value)
          )
        } else {
          // else remove the current badge and add a new badge
          let updatedBadges = filterBadges.filter(badge => badge.type !== type)
          updatedBadges = [...updatedBadges, { type, value, multiSelect }]
          setFilterBadges(updatedBadges)
        }
      }
    }
  }

  const onClickOptionItem = e => {
    onFilterChange(e, multiSelect)
    const {
      target: { value },
    } = e

    if (isMobile) {
      onChangeBadges(value, filterValue, multiSelect)
    }
  }

  useEffect(() => {
    if (condenseOptions && filterValue) {
      setState({
        isCondense: false,
        showButton: false,
      })
    } else if (condenseOptions && !filterValue) {
      setState({
        ...state,
        showButton: true,
      })
    } else {
      setState({
        isCondensed: false,
        showButton: false,
      })
    }
  }, [condenseOptions, filterValue])

  const onButtonClick = () => {
    setState({
      ...state,
      isCondensed: !state.isCondensed,
    })
  }

  const optionsToShow = state.isCondensed
    ? options.sort().slice(0, 3)
    : options.sort()

  const radioOptionItems = optionsToShow.map(option => {
    const isChecked = multiSelect
      ? Boolean(filterValue && filterValue.split(",").includes(option))
      : option === filterValue

    return (
      <FormControlLabel
        className="filtersDrawer__option"
        key={option}
        value={option}
        label={option}
        aria-label={option}
        labelPlacement={"end"}
        sx={{
          userSelect: "none",
          ...(isMobile && {
            paddingLeft: "13px",
            paddingRight: "16px",
            "&:hover": {
              backgroundColor: "rgba(239,239,239,0.8)",
              cursor: "pointer",
            },
          }),
          ...(isChecked && {
            backgroundColor: "rgba(239,239,239,0.8)",
          }),
        }}
        control={
          multiSelect ? (
            <Checkbox
              onClick={onClickOptionItem}
              checked={isChecked}
              size="small"
              sx={{
                ...(isMobile && {
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }),
                ...(isChecked && {
                  backgroundColor: "transparent",
                }),
              }}
              style={{
                color: "lightgrey",
              }}
            />
          ) : (
            <Radio
              onClick={onClickOptionItem}
              checked={isChecked}
              size="small"
              sx={{
                ...(isMobile && {
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }),
                ...(isChecked && {
                  backgroundColor: "transparent",
                }),
              }}
              style={{
                color: "grey",
              }}
            />
          )
        }
      />
    )
  })

  // Render nothing if there 1 or less options. There's no reason to show
  // this filter if there's only 1 applicable option. This only applies to the
  // Schedule (events) page
  if (pageType === SearchPageTypes.EVENTS && options.length <= 1) {
    return null
  }

  return (
    <StyledFilterItem isMobile={isMobile}>
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        <FormLabel
          disabled={options.length ? false : true}
          onClick={
            options.length
              ? () => {
                  if (isMobile) {
                    setShowOptionItems({ ...showOptionItems, [type]: true })
                  }
                }
              : () => {}
          }
          sx={{
            userSelect: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: isMobile ? "0.875rem" : "1.05rem",
            lineHeight: "1.7",
            color: "#0a0a0a",
            fontWeight: "500",
            ...(isMobile && {
              paddingLeft: "20px",
              paddingRight: "16px",
              paddingTop: "14px",
              paddingBottom: "14px",
              "&:hover": {
                backgroundColor: "rgba(239,239,239,0.8)",
                cursor: "pointer",
              },
            }),
          }}
        >
          {title} {isMobile && <ArrowForwardIosIcon fontSize="small" />}
        </FormLabel>

        {state.showButton ? (
          <Button
            onClick={onButtonClick}
            variant="secondary"
            quiet
            offset="atStart"
            className="filtersDrawer__drawerBtn"
          >
            {state.isCondensed ? "See More" : "See Less"}
          </Button>
        ) : null}
      </FormControl>
      {isMobile ? (
        <StyledMobileOptionsGroup
          className="filtersDrawer__optionsGroup"
          showOptions={mobileOpen && showOptionItems[type]}
        >
          {radioOptionItems}
        </StyledMobileOptionsGroup>
      ) : (
        <StyledDesktopOptionsGroup>
          {radioOptionItems}
        </StyledDesktopOptionsGroup>
      )}
    </StyledFilterItem>
  )
}
