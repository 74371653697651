import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ButtonLink } from "@components/@aaiff"

import SponsorRow from "../../sponsor_list/sponsor_row"
import { SponsorDto } from "../../../common/dto"
import { Title } from "../title"

export default function SponsorGallery() {
  const data = useStaticQuery(graphql`
    query MajorSponsor {
      allWpSponsor(
        filter: {
          sponsorStatus: { status: { eq: "active" } }
          sponsorGeneralInformation: {
            tier: { slug: { regex: "/(silver|gold|platinum|title)-sponsor/" } }
          }
        }
      ) {
        tierGroup: group(
          field: { sponsorGeneralInformation: { tier: { slug: SELECT } } }
        ) {
          nodes {
            ...SponsorGeneralInfo
          }
          fieldValue
        }
      }
    }
  `)

  const { tierGroup } = data.allWpSponsor

  const tierGroupMap = new Map()
  tierGroup.forEach(group => {
    tierGroupMap.set(
      group.fieldValue,
      group.nodes.filter(node => node.sponsorStatus.status === "active") // makes sure sponsors shown are active sponsors
    )
  })

  let sponsorList = []
  if (tierGroupMap.has("title-sponsor")) {
    sponsorList = sponsorList.concat(tierGroupMap.get("title-sponsor"))
  }

  if (tierGroupMap.get("platinum-sponsor")) {
    sponsorList = sponsorList.concat(tierGroupMap.get("platinum-sponsor"))
  }

  if (tierGroupMap.get("gold-sponsor")) {
    sponsorList = sponsorList.concat(tierGroupMap.get("gold-sponsor"))
  }

  if (tierGroupMap.get("silver-sponsor")) {
    sponsorList = sponsorList.concat(tierGroupMap.get("silver-sponsor"))
  }

  sponsorList = sponsorList.map(sponsor => SponsorDto.fromWpSponsor(sponsor))

  if (!sponsorList.length) {
    return null
  }

  return (
    <section className="sponsorGallery home__section">
      <div className="sponsorGallery__title">
        <Title>A Special Thanks To Our Sponsors</Title>
      </div>
      <SponsorRow sponsors={sponsorList} itemsPerRow={6} />
      <ButtonLink to="/sponsors-partners/">View All Sponsors</ButtonLink>
    </section>
  )
}
