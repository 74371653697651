export const formatFestivalDate = dateString => {
  const date = new Date(dateString)
  const options = { month: "short", day: "numeric" }

  return new Intl.DateTimeFormat("en-US", options).format(date)
}

export const getDateTime = dateString => {
  const date = new Date(dateString)

  return date.toISOString().slice(0, 10)
}

export const formatMonthDay = dateString => {
  const date = new Date(dateString)

  const month = getMonthName(date.getMonth())

  return `${month} ${date.getDate()}`
}

export const formatMonthDayYear = dateString => {
  const date = dateString instanceof Date ? dateString : new Date(dateString)

  const month = getMonthName(date.getMonth())

  return `${month} ${date.getDate()}, ${date.getFullYear()}`
}

export const getFormattedDate = dateString => {
  const date = dateString instanceof Date ? dateString : new Date(dateString)

  return {
    month: formatDoubleDigits(date.getMonth() + 1),
    day: formatDoubleDigits(date.getDate()),
    year: date.getFullYear(),
  }
}

export const getPagePath = dateString => {
  const date = getFormattedDate(dateString)

  return `${date.month}-${date.day}-${date.year}`
}

export const getDaysSince = dateString => {
  const date = dateString instanceof Date ? dateString : new Date(dateString)

  const seconds = Math.floor((Date.now() - date) / 1000)

  const days = Math.floor(seconds / 86400)

  return days
}

export const formatDoubleDigits = dateValue => {
  if (dateValue >= 10) {
    return dateValue + ""
  }

  return "0" + dateValue
}

export const getMonthName = monthVal => {
  switch (monthVal) {
    case 0:
      return "January"
    case 1:
      return "February"
    case 2:
      return "March"
    case 3:
      return "April"
    case 4:
      return "May"
    case 5:
      return "June"
    case 6:
      return "July"
    case 7:
      return "August"
    case 8:
      return "September"
    case 9:
      return "October"
    case 10:
      return "November"
    case 11:
      return "December"
    default:
      return ""
  }
}

export const getMonthShortName = monthVal => {
  return getMonthName(monthVal).slice(0, 3)
}

export const getDayOfTheWeek = dayVal => {
  switch (dayVal) {
    case 0:
      return "Sunday"
    case 1:
      return "Monday"
    case 2:
      return "Tuesday"
    case 3:
      return "Wednesday"
    case 4:
      return "Thursday"
    case 5:
      return "Friday"
    case 6:
      return "Saturday"
    default:
      return ""
  }
}

export const getDayOfTheWeekShortName = dayVal => {
  return getDayOfTheWeek(dayVal).slice(0, 3)
}

export const currentDay = () => {
  let currentDate = new Date()
  currentDate = currentDate.setHours(0, 0, 0, 0)
  currentDate = new Date(currentDate)

  return currentDate.toString()
}

export const getTwelveHourTime = dateString => {
  let dateObj
  if (dateString instanceof Date) {
    dateObj = dateString
  } else {
    dateObj = new Date(dateString)
  }

  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()

  let amOrPm = ""
  if (hours >= 12) {
    amOrPm = "PM"
  } else {
    amOrPm = "AM"
  }

  const hoursStr = hours % 12 === 0 ? 12 : hours % 12

  return `${hoursStr}:${formatDoubleDigits(minutes)} ${amOrPm}`
}

export const sortEventDates = eventGroups => {
  let sorted = eventGroups

  for (let day in sorted) {
    sorted[day] = sorted[day].sort(
      (a, b) => getDateTime(a.start_time) - getDateTime(b.start_time)
    )
  }
}

// export const doesScreenTimeOverlap = (node1, node2) => {
//   if (node2 === null || node2 === undefined) return false

//   const eventStartTime1 = new Date(
//     `${node1.acf.start_date} ${node1.acf.start_time}`
//   )
//   const eventStartTime2 = new Date(
//     `${node2.acf.start_date} ${node2.acf.start_time}`
//   )

//   const hours1 = eventStartTime1.getHours() * 60 * 60 * 1000
//   const minutes1 = eventStartTime1.getMinutes() * 60 * 1000
//   const runtime1 = parseFloat(node1.acf.runtime) * 60 * 1000

//   const hours2 = eventStartTime2.getHours() * 60 * 60 * 1000
//   const minutes2 = eventStartTime2.getMinutes() * 60 * 1000

//   const eventDuration1 = hours1 + minutes1 + runtime1
//   const eventStart2 = hours2 + minutes2

//   return eventDuration1 > eventStart2 ? true : false
// }
