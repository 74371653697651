/**
 * These slugs should match the ones on the Film - Formats page in WordPress.
 *
 * Note: Make sure to keeps this enum up-to-date as the WordPress event formats change
 *
 * @See https://www.asiancinevision.org/wp-admin/edit-tags.php?taxonomy=film_formats&post_type=films
 */
export const FilmFormatSlugs = {
  FEATURE_FILM: "feature-film",
  SHORT_FILM: "short-film",
  DIGITAL: "digital-new-media",
  SCREENPLAY: "screenplay",
}

export const FilmFormats = {
  SHORT_FILM: "Shorts",
  DIGITAL: "Digital/New Media",
  FEATURE: "Features",
}
