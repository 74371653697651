export const EleventWidgetTypes = {
  TICKET: "ticket",
  PACKAGE: "package",
  PASS: "pass",
  EXTRA: "extra",
  MEMBERSHIP: "membership",
  DONATION: "donation",
  CART: "cart",
  SIGN_IN: "sign-in",
  SIGN_OUT: "sign-out",
  ACCOUNT: "account",
}

export const EleventEvents = {
  afterInitialize: "afterEleventInitialized",
  afterRefresh: "afterEleventRefresh",
  afterSignIn: "afterEleventSignIn",
  afterSignOut: "afterEleventSignOut",
  afterModalOpen: "afterEleventModalOpen",
  afterModalClose: "afterEleventModalClose",
  afterAddToCart: "afterEleventAddToCart",
  afterTransactionComplete: "afterEleventTransactionComplete",
}
