import { useCurrentFestival } from "../useCurrentFestival"

import { EventFormatSlugs, SearchPageTypes } from "../../common/constants"
import { WpDataFactory } from "@common/factories/WPDataFactory"

export const useEventState = function ({ data, location }) {
  const { events, films, localSearchCurrentFestivalEntries } = data
  const currentFestival = useCurrentFestival()

  /**
   * Retrieves the props for the Search component for the Program page
   * @returns the current festival information, the events and films, and
   * the list of filters for the search component
   */
  const getSearchProps = () => {
    // Instantiate film entries and filter by current year
    const allFilmEntries = films.nodes
      .map(node => WpDataFactory.instantiateWpEntry(node))
      .filter(
        film =>
          film.festivalYear &&
          film.festivalYear === currentFestival.festivalYear
      )

    // Filter events by the current festival year and ignore On-Demand events
    const allEventEntries = events.nodes
      .map(node => WpDataFactory.instantiateWpEntry(node))
      .filter(event => {
        try {
          return (
            event.festivalYear &&
            event.festivalYear === currentFestival.festivalYear &&
            event.eventFormatSlug !== EventFormatSlugs.ON_DEMAND
          )
        } catch (err) {
          console.error(`Could not processes event node ${node.slug}`, err)
          return false
        }
      })

    return {
      location,
      index: localSearchCurrentFestivalEntries.index,
      store: localSearchCurrentFestivalEntries.store,
      allEventEntries,
      allFilmEntries,
      pageType: SearchPageTypes.EVENTS,
    }
  }

  return {
    getSearchProps,
  }
}
