import React from "react"
import PropTypes from "prop-types"

// import SponsorRow from "../sponsor_list/sponsor_row"
import SponsorRow from "../../sponsor_list/sponsor_row"

export function CommunityPartners({ visible, sponsors }) {
  if (!visible || !sponsors || sponsors.length === 0) {
    return null
  }

  return (
    <section className="communityPartners">
      <h2 className="event__title">{"Sponsors & Community Partners"}</h2>
      <div className="communityPartners__list">
        <SponsorRow sponsors={sponsors} itemsPerRow={3} />
      </div>
    </section>
  )
}

CommunityPartners.propTypes = {
  visible: PropTypes.bool.isRequired,
  sponsors: PropTypes.arrayOf(PropTypes.object),
}
