import * as React from "react"
import {
  filterTypeOrder,
  filterTypeLabels,
  FilterTypes,
} from "../../../../common/constants"
import FormControl from "@mui/material/FormControl"
import { FilterSelectItems } from "./filter_select_items"
import styled from "@emotion/styled"

const StyledSelectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .MuiFormControl-root {
    width: 24%;
    margin-bottom: 1rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #000;
  }
`
export function FilterSelects(props) {
  const {
    filterOptions,
    activeFilters,
    handleFilterChange,
    filterBadges,
    setFilterBadges,
  } = props
  const filterItems = type => Array.from(filterOptions[type].values())

  return (
    <StyledSelectsContainer>
      {filterTypeOrder.map(type => (
        <FormControl key={type} fullWidth>
          <FilterSelectItems
            open={open}
            label={filterTypeLabels[type]}
            type={type}
            onFilterChange={handleFilterChange(type)}
            items={filterItems(type)}
            filterValue={activeFilters ? activeFilters[type] : null}
            multiSelect={
              type === FilterTypes.GENRE ||
              type === FilterTypes.COUNTRY ||
              type === FilterTypes.LANGUAGE
            }
            filterBadges={filterBadges}
            setFilterBadges={setFilterBadges}
          />
        </FormControl>
      ))}
    </StyledSelectsContainer>
  )
}
