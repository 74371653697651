import React, { useState, useEffect, forwardRef, useRef } from "react"
import { Radio, Checkbox, ListItemText } from "@mui/material"
import { Select as BaseSelect } from "@mui/base/Select"
import { MenuItem } from "@mui/base/MenuItem"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import styled from "@emotion/styled"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { ClickAwayListener } from "@mui/base/ClickAwayListener"
import TouchRipple from "@mui/material/ButtonBase/TouchRipple"

const StyledArrowUp = styled(IoIosArrowUp)`
  width: 1rem;
  height: auto;
`

const StyledArrowDown = styled(IoIosArrowDown)`
  width: 1rem;
  height: auto;
`
const StyledButton = styled("button")`
  font-size: 1rem;
  border-radius: 4px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  min-height: 1.4375rem;
  padding: 1rem 0.875rem;
  outline: 1px solid black;

  &:hover {
    border: 2px solid black;
    outline: none;
  }

  &:focus {
    border: 2px solid black;
    outline: none;
  }
`

const MenuListBox = styled("ul")`
  background-color: white;
  width: 100%;
  position: relative;
  outline: 0px;
  padding: 0.75rem 0;
`

const StyledPopup = styled("div")`
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  outline: 0px;
  max-height: ${props => (props.multiple ? "calc(100vh - 30rem)" : "unset")};
  width: 23%;
  max-width: 17.6875rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
`
const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 0.5rem;

  &:hover {
    background-color: rgba(239, 239, 239, 0.8);
  }

  &:focus {
    background-color: rgba(239, 239, 239, 0.8);
  }
`

const StyledCheckbox = styled(Checkbox)`
  & svg > path {
    color: lightgrey;
  }
`

const StyledRadio = styled(Radio)`
  & svg > path {
    color: grey;
  }
`

const CustomButton = forwardRef(function CustomButton(props, ref) {
  const { label, children, ...otherProps } = props

  const open = props.ownerState.listboxOpen
  return (
    <StyledButton ref={ref} {...otherProps}>
      {children}
      {label}
      {open ? <StyledArrowUp /> : <StyledArrowDown />}
    </StyledButton>
  )
})

const CustomPopup = forwardRef(function CustomPopup(props, ref) {
  const { children, ...otherProps } = props

  const multiple = props.ownerState.multiple

  return (
    <StyledPopup ref={ref} multiple={multiple} {...otherProps}>
      {children}
    </StyledPopup>
  )
})

const CustomSelect = forwardRef(function CustomSelect(props, ref) {
  const slots = {
    root: CustomButton,
    listbox: MenuListBox,
    popup: CustomPopup,
    ...props.slots,
  }

  return <BaseSelect {...props} ref={ref} slots={slots} />
})

const CustomMenuItem = forwardRef(function CustomMenuItem(props, ref) {
  const [rippleVisible, setRippleVisible] = useState(false)
  const rippleRef = useRef(null)

  const handleMouseDown = e => {
    rippleRef.current.start(e)
    setRippleVisible(true)
  }

  const handleMouseUp = () => {
    rippleRef.current.stop()
    setRippleVisible(false)
  }

  return (
    <StyledMenuItem
      role="option"
      {...props}
      ref={ref}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{ position: "relative", overflow: "hidden" }}
    >
      {props.children}
      <TouchRipple ref={rippleRef} center={true} visible={rippleVisible} />
    </StyledMenuItem>
  )
})

export function FilterSelectItems(props) {
  const {
    label,
    items,
    type,
    multiSelect,
    filterValue,
    onFilterChange,
    filterBadges,
    setFilterBadges,
  } = props
  const isDisabled = !items.length > 0
  const [open, setOpen] = useState(false)

  const onChangeBadges = (value, filterValue, multiSelect) => {
    // filterValue is async, value is the value after select an option
    // if filterValue is undefined/null, add a new badge to filterBadge array
    if (!filterValue) {
      if (multiSelect) {
        // if multiSelect, value is an array, so the new added value is the last value in value
        setFilterBadges(prevBadges => [
          ...prevBadges,
          { type, value: value[0], multiSelect },
        ])
      } else {
        setFilterBadges(prevBadges => [
          ...prevBadges,
          { type, value, multiSelect },
        ])
      }
    } else {
      if (multiSelect) {
        // split filterValue to an array to compare filterValue & value
        filterValue = filterValue?.split(",")
        // if value array's length is longer, it means a new value is added, so add a new badge
        if (value.length > filterValue.length) {
          setFilterBadges(prevBadges => [
            ...prevBadges,
            { type, value: value[value.length - 1], multiSelect },
          ])
        } else {
          // else check which item in value is not in filterValue, that's the deselect badge
          let deselectValue = filterValue.filter(item => !value.includes(item))
          // filter the deselect value out
          setFilterBadges(prevBadges =>
            prevBadges.filter(badge => badge.value !== deselectValue[0])
          )
        }
      } else {
        // if single select, and filterValue's array length is longer
        // if value === filterValue, remove/unselect the value/badge
        if (value === filterValue) {
          setFilterBadges(prevBadges =>
            prevBadges.filter(badge => badge.value !== value)
          )
        } else {
          // else remove the current badge and add a new badge
          let updatedBadges = filterBadges.filter(badge => badge.type !== type)
          updatedBadges = [...updatedBadges, { type, value, multiSelect }]
          setFilterBadges(updatedBadges)
        }
      }
    }
  }

  const onSelectChange = (e, newValue) => {
    if (!newValue && filterBadges.length === 0) {
      return
    }

    //ensure that onChangeBadges and onFilterChange only happens if user clicks on the a filter option
    //even if there is no newValue, it would still call onFIlterChange and onChangeBadge to remove selection
    if (!e) {
      return
    }

    onChangeBadges(newValue, filterValue, multiSelect)
    onFilterChange(newValue, multiSelect)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  const handleListBox = () => {
    setOpen(prev => !prev)
  }

  /** Close the dropdown menu whenever the background is scrolled */
  useEffect(() => {
    if (!open) {
      return
    }

    const onScroll = () => {
      setOpen(false)
    }
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [open])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <CustomSelect
          disabled={isDisabled}
          value={filterValue?.split(",") || (multiSelect ? [] : "")}
          label={label}
          multiple={multiSelect}
          handleListBox={handleListBox}
          onChange={onSelectChange}
          renderValue={() => ""}
          onClick={handleListBox}
          listboxOpen={open}
          IconComponent={NavigateNextIcon}
          blurOnSelect={true}
        >
          {items.sort().map(item => (
            <CustomMenuItem
              id={item}
              key={item}
              value={item}
              disableRestoreFocus={true}
            >
              {multiSelect ? (
                <StyledCheckbox
                  checked={
                    filterBadges.filter(badge => badge.value === item).length >
                    0
                  }
                />
              ) : (
                <StyledRadio
                  checked={
                    filterBadges.filter(badge => badge.value === item).length >
                    0
                  }
                />
              )}
              <ListItemText primary={item} />
            </CustomMenuItem>
          ))}
        </CustomSelect>
      </div>
    </ClickAwayListener>
  )
}
