import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

const DropDownMenu = styled("div")`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 100%;
  z-index: 100;
  background: #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
`

const DropDownContainer = styled("ul")``

const DropDownItem = styled("li")`
  transition: background 0.15s ease-in-out;
  border-bottom: 1px solid black;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      #cccccc;
  }
`

export function NavbarDropdownMenu({ open, children, debug = false }) {
  const [isOpen, setIsOpen] = useState(open)

  /**
   * If debug mode is turned on, prevent the dropdown from closing once it has
   * been opened. This should be used to help create or debug styles.
   */
  useEffect(() => {
    if (debug && open) {
      setIsOpen(open)
    } else if (!debug) {
      setIsOpen(open)
    }
  }, [open, debug])

  return (
    <>
      {isOpen ? (
        <DropDownMenu>
          <DropDownContainer>{children}</DropDownContainer>
        </DropDownMenu>
      ) : null}
    </>
  )
}

export function NavbarDropdownItem({ children }) {
  return <DropDownItem>{children}</DropDownItem>
}
