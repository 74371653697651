import React from "react"
import styled from "@emotion/styled"

import ImageWrapper from "@components/image_wrapper"
import { EntryFormatLabel } from "@components/entry_format_label"
import { Link } from "../../../link"
import { DefaultImageTypes } from "../../../image_wrapper"

const ImageContainer = styled("div")`
  width: 100%;
  height: 400px;
  position: relative;
  font-size: 16px;

  @media (min-width: 600px) {
    height: 550px;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1280px) {
    height: 700px;
  }
`

const BannerContainer = styled("div")`
  width: 100%;
  height: 25rem;
  background: linear-gradient(
    360deg,
    #000000 0%,
    #151515 8%,
    rgba(207, 207, 207, 0.189333) 78.93%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;

  @media (min-width: 600px) {
    height: 550px;
  }

  @media (min-width: 1280px) {
    height: 700px;
  }
`

const Banner = styled(ImageWrapper)`
  width: 100%;
  height: 400px;
  display: block;
  position: relative;

  @media (min-width: 600px) {
    height: 550px;
  }

  @media (min-width: 1280px) {
    height: 700px;
  }
`

const ContentContainer = styled("div")`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1.5fr 1fr 0.5fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "demand . . . "
      "title title . film "
      "cosponsor . . film";
    padding: 0 2.5rem 3rem 2.5rem;
  }
`

const StyledFormatLabel = styled(EntryFormatLabel)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  grid-area: demand;
  color: white;

  font-size: 0.75em;

  @media (min-width: 750px) {
    font-size: 1em;
  }
`

const BannerTitle = styled("h1")`
  color: white;
  font-size: 1.75em;
  margin: 0;
  line-height: 35px;

  @media (min-width: 600px) {
    font-size: 1.75em;
    line-height: 40px;
    grid-area: title;
    letter-spacing: 0.0025rem;
  }

  @media (min-width: 750px) {
    font-size: 3em;
    line-height: 60px;
    grid-area: title;
    letter-spacing: 0.0025rem;
  }
`

const CoSponsorDetails = styled("p")`
  color: white;
  font-size: 1.125em;
  line-height: 27px;
  letter-spacing: 0.0033em;

  @media (min-width: 600px) {
    font-size: 1.5em;
    line-height: 34px;
    grid-area: cosponsor;
  }

  @media (min-width: 1280px) {
    font-size: 2em;
    line-height: 48px;
  }
`

const CoSponsorLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
`

const FilmContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  grid-area: film;

  @media (min-width: 600px) {
    grid-area: film;
    align-self: end;
    text-align: right;
  }
`

const DirectedByContent = styled("p")`
  color: white;
  font-size: 0.75em;
  line-height: 18px;

  @media (min-width: 750px) {
    font-size: 1em;
    line-height: 24px;
  }
`

const Directors = styled("span")`
  color: white;
  font-size: 1em;
  line-height: 15px;
  font-weight: 700;

  @media (min-width: 750px) {
    font-size: 1em;
    line-height: 20px;
  }
`

const FilmDetails = styled("p")`
  color: white;
  font-size: 0.75em;
  line-height: 18px;

  @media (min-width: 750px) {
    font-size: 1em;
    line-height: 24px;
  }
`
const FilmDetailList = styled("span")`
  color: white;
  font-size: 1em;
  line-height: 18px;

  @media (min-width: 750px) {
    font-size: 1em;
    line-height: 24px;
  }
`

export function OverlayBanner(props) {
  const { data, screeningEvents } = props

  let listDirectors
  let listCountries
  let listFilmTypes

  if (data.type !== "SHORTS_PROGRAM" && data.type !== "PANEL") {
    listDirectors =
      data.directors && data.directors.length > 0 ? (
        <Directors>{data.directors.join(", ")}</Directors>
      ) : null
    listCountries =
      data.countries && data.countries.length > 0 ? (
        <FilmDetailList>{data.countries.join(", ")} | </FilmDetailList>
      ) : null
    listFilmTypes =
      data.filmTypes && data.filmTypes.length > 0 ? (
        <FilmDetailList>{data.filmTypes.join(", ")} | </FilmDetailList>
      ) : null
  }

  return (
    <ImageContainer>
      <Banner
        media={data.posterImageMedia}
        alt={data.title}
        defaultImageType={DefaultImageTypes.HORIZONTAL}
        loading="eager"
      ></Banner>
      <BannerContainer />
      <ContentContainer>
        <StyledFormatLabel
          screeningEvents={
            data.screeningEventsCount ? data.screeningEvents : screeningEvents
          }
        />
        <BannerTitle>{data.title}</BannerTitle>
        {data.coSponsor ? (
          <CoSponsorDetails>
            Co-presented by{" "}
            <CoSponsorLink
              to={data.coSponsor.customAdUrl || data.coSponsor.websiteUrl}
              external
            >
              {data.coSponsor.name}
            </CoSponsorLink>
          </CoSponsorDetails>
        ) : null}
        {data.type !== "SHORTS_PROGRAM" && (
          <FilmContent>
            {listDirectors && (
              <DirectedByContent>Directed By {listDirectors}</DirectedByContent>
            )}
            <FilmDetails>
              {listCountries}
              {listFilmTypes}
              {data.runtime} min
            </FilmDetails>
            <FilmDetails>
              {data.premiereStatus ? `${data.premiereStatus} Premiere` : null}
            </FilmDetails>
          </FilmContent>
        )}
      </ContentContainer>
    </ImageContainer>
  )
}
