const HardcodedOptions = {
  volunteer: "https://www.asiancinevision.org//volunteer-with-aaiff/",
  donate: "https://www.asiancinevision.org/donate/",
}

/**
 * A hook used to extract the headings to be displayed in the header/footer navbar
 * as well as any options for each heading. Each heading can have a list of dropdown
 * options, can be a link to a different page, and can have a different name than
 * the page type.
 *
 * @return {Array} A list of objects containing options for each navbar heading to
 * be displayed
 */
export function useNavbarOptions(
  groupedPages = [],
  headingNodes = [],
  hasMainPageGroups = []
) {
  // Reorganize groupedPages into a hash map
  const groupedPagesByType = {}
  groupedPages.forEach(({ fieldValue, nodes }) => {
    groupedPagesByType[fieldValue] = nodes
  })

  // Map each page type to its main page if it has one. Main pages
  // are used when using the navbar heading as a link
  const hasMainPage = {}
  hasMainPageGroups.forEach(({ fieldValue, nodes }) => {
    hasMainPage[fieldValue] = nodes[0]
  })

  const convertToDropdownOptions = slug => {
    const pages = groupedPagesByType[slug] || []

    return pages.map(
      ({ festivalPageMetadata, festivalPageNavigation, slug }) => {
        if (HardcodedOptions[slug]) {
          return {
            name: festivalPageNavigation.navName || festivalPageMetadata.title,
            slug: HardcodedOptions[slug],
            isExternal: true,
          }
        }

        return {
          name: festivalPageNavigation.navName || festivalPageMetadata.title,
          slug,
          isExternal: false,
        }
      }
    )
  }

  // Organize the navbar option data to be used by the components
  const navbarOptions = headingNodes.map(({ name, slug }) => {
    if (slug === "cinevue-navbar") {
      // The "cinevue-navbar" option is used to determine the position of the Cinevue link
      return {
        labelName: "CineVue",
        isLink: true,
        isExternal: true,
        slug: "https://www.asiancinevision.org/cinevue-home/",
      }
    } else {
      const labelName =
        hasMainPage[slug] && hasMainPage[slug].festivalPageNavigation.navName
          ? hasMainPage[slug].festivalPageNavigation.navName
          : name

      return {
        labelName,
        dropdownOptions: convertToDropdownOptions(slug),
        isLink: !!hasMainPage[slug],
        slug,
      }
    }
  })

  return navbarOptions
}
