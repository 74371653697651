import React from "react"
import { graphql } from "gatsby"

import { FestivalPageLayout } from "@components/layout"
import { PageHead } from "@components/page_head"
import InnerHTML from "../components/inner_html/inner_html"
import SponsorList from "../components/sponsor_list/sponsor_list"

export default function Sponsors({ data }) {
  const {
    wpFestivalPage: {
      content,
      festivalPageMetadata: { title },
      wipMode: { isWipModeActive, wipModeSettings },
    },
    tiers,
    sponsors,
  } = data

  return (
    <FestivalPageLayout
      title={title}
      isWipModeActive={isWipModeActive}
      wipModeSettings={wipModeSettings}
    >
      <InnerHTML htmlContent={content} isFestivalPage={true} />

      <SponsorList tiers={tiers} sponsors={sponsors} />
    </FestivalPageLayout>
  )
}

export const Head = ({ data }) => {
  const {
    festivalPageMetadata: { title, description },
  } = data.wpFestivalPage

  return <PageHead title={title} description={description} />
}

export const query = graphql`
  query SponsorsPage {
    wpFestivalPage(slug: { eq: "sponsors-partners" }) {
      content
      festivalPageMetadata {
        title
        description
      }
      ...PageWipModeSettings
    }
    tiers: allWpSponsorTier(sort: { description: ASC }) {
      nodes {
        name
      }
    }
    sponsors: allWpSponsor(
      filter: { sponsorStatus: { status: { eq: "active" } } }
      sort: { sponsorGeneralInformation: { name: ASC } }
    ) {
      group(field: { sponsorGeneralInformation: { tier: { name: SELECT } } }) {
        nodes {
          ...SponsorGeneralInfo
        }
        fieldValue
      }
    }
  }
`
