export class VenueDto {
  constructor({ name, phoneNumber, websiteUrl, address, coordinates }) {
    this.name = name
    this.address = address
    this.phoneNumber = phoneNumber
    this.websiteUrl = websiteUrl
    this.coordinates = coordinates || {}
  }

  static fromWpVenue(wpVenue) {
    if (wpVenue?.venueGeneralInformation?.name) {
      return new VenueDto(wpVenue.venueGeneralInformation)
    }

    return undefined
  }
}
