import React, { useState } from "react"
import styled from "@emotion/styled"
import { FaChevronDown } from "react-icons/fa"

import { Link } from "../../link"
import { unescapeHTMLEntities } from "../../../util/string_util"
import { NavbarDropdownMenu, NavbarDropdownItem } from "./navbar_dropdown_menu"

/**
 * A component that represents a single navbar item in the header nav. The name of the item,
 * the contents of its dropdown, and whether or not the item is a link can be customized
 * from the Wordpress site.
 *
 * To customize the contents of the header, update the page's navbar metadata at
 * https://www.asiancinevision.org/wp-admin/edit.php?post_type=festival_pages
 *
 * Please contact an AAIFF webmaster if have any problems accessing or modifying this
 * data.
 *
 * The props for this component includes the following params:
 * @param {String} labelName the name of the navbar heading/link
 * @param {Array} dropdownOptions A list of nodes that are used to populate the dropdown menu
 * @param {Boolean} isLink indicates if the main navbar item should be treated as a link
 * and redirect to a page. Uses the path provided by `slug`.
 * @param {String} slug the page path when used in a link
 */

const NavBarItemLabel = styled("span")``

const NavBarItemIcon = styled("span")`
  margin-left: 4px;
  font-size: 0.8em;
`

const NavBarItem = styled("li")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  &:hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 10px;
  }
`

const NavBarLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  color: #c8c8c8;
  font-weight: 600;

  &:hover {
    color: #fcfcfc;
    background-color: #333333;
    border-radius: 4px;
    text-decoration: none;
  }
`

const NavBarText = styled("span")`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  color: #c8c8c8;
  font-weight: 600;

  &:hover {
    color: #fcfcfc;
    background-color: #333333;
    border-radius: 4px;
    text-decoration: none;
  }
`

const DropDownLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  padding: 1rem 2rem 1rem 1.5rem;
  line-height: 1.5rem;
  font-size: 16px;
  letter-spacing: 0.05px;

  &:hover {
    text-decoration: none;
  }
`

export default function NavbarItem({
  labelName,
  slug,
  dropdownOptions,
  isLink,
  isExternal,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleMouseEnter = () => {
    setDropdownOpen(true)
  }

  const handleMouseLeave = () => {
    setDropdownOpen(false)
  }

  // Determines if the navbar item label should have a down chevron icon
  const itemContent =
    dropdownOptions && dropdownOptions.length > 0 ? (
      <NavBarItemLabel>
        {unescapeHTMLEntities(labelName)}
        <NavBarItemIcon>
          <FaChevronDown />
        </NavBarItemIcon>
      </NavBarItemLabel>
    ) : (
      <NavBarItemLabel>{unescapeHTMLEntities(labelName)}</NavBarItemLabel>
    )

  const dropdownItemList = dropdownOptions
    ? dropdownOptions.map(({ name, slug, isExternal }) => {
        return (
          <NavbarDropdownItem key={name}>
            <DropDownLink
              to={isExternal ? slug : `/${slug}`}
              external={isExternal}
            >
              {unescapeHTMLEntities(name)}
            </DropDownLink>
          </NavbarDropdownItem>
        )
      })
    : []

  return (
    <NavBarItem onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {isLink ? (
        <NavBarLink to={isExternal ? slug : `/${slug}`} external={isExternal}>
          {itemContent}
        </NavBarLink>
      ) : (
        <NavBarText>{itemContent}</NavBarText>
      )}

      <NavbarDropdownMenu open={dropdownOpen}>
        {dropdownItemList}
      </NavbarDropdownMenu>
    </NavBarItem>
  )
}
