import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout/layout"
import { Link } from "../components/link"

const Container = styled.div`
  margin: 3rem auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LinkText = styled.p`
  color: blue;
  text-decoration: underline;
`

const helpfulLinks = [
  { link: "/about", name: "About" },
  { link: "/contact", name: "Contact" },
  { link: "/donate", name: "Donate" },
  { link: "/events", name: "Events" },
]

const Links = helpfulLinks.map(link => (
  <Link to={link.link} external={false} className="">
    <LinkText>{link.name}</LinkText>
  </Link>
))

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>Oops!</h1>
      <h2>We can't seem to find the page you're looking for.</h2>
      <h3>Error code: 404</h3>
      <p>Here are some helpful links instead:</p>
      {Links}
    </Container>
  </Layout>
)

export default NotFoundPage
