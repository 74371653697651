import React, { useState, useEffect } from "react"

import { useSearchState, useScheduleState } from "../../hooks"
import { FilterDrawer } from "./components/filter_drawer"
import { SearchContent } from "./components/search_content"
import { SearchHeader } from "./components/search_header"
import { FilterTypes } from "../../common/constants"

/**
 * A helper function for getting the list of badge objects from the active filters
 *
 * @param {Object} activeFilters an object with the active filters values
 * @returns {Array} an array of badge objects to be rendered
 */
const getBadgesFromFilters = activeFilters => {
  const badges = []

  if (activeFilters) {
    Object.keys(activeFilters).forEach(filterType => {
      const activeValues = activeFilters[filterType].split(",")

      activeValues.forEach(filterValue => {
        badges.push({
          type: filterType,
          value: filterValue,
          multiSelect:
            filterType === FilterTypes.GENRE ||
            filterType === FilterTypes.COUNTRY ||
            filterType === FilterTypes.LANGUAGE,
        })
      })
    })
  }

  return badges
}

export function Search(props) {
  const {
    searchContentRef,
    searchResults,
    pageType,
    eventEntries,
    filmEntries,
    isSearchActive,
    sortOrder,
    searchQuery,
    activeFilters,
    filterOptions,
    mobileOpen,
    setSortOrder,
    setSearchQuery,
    setActiveFilters,
    handleFilterChange,
    setMobileOpen,
    getCategoryRowProps,
    clearAllFilters,
    viewQuery,
    setViewQuery,
    categoryQuery,
  } = useSearchState(props)
  const { viewType, handleViewTypeChange } = useScheduleState({
    pageType,
    viewQuery,
    setViewQuery,
  })
  const [filterBadges, setFilterBadges] = useState(
    getBadgesFromFilters(activeFilters)
  )

  // Update badges whenever the active filters change
  useEffect(() => {
    setFilterBadges(getBadgesFromFilters(activeFilters))
  }, [activeFilters])

  const updateBadges = activeFilters => {
    let resultArray = []
    const isMultiselect = type =>
      type === FilterTypes.GENRE ||
      type === FilterTypes.COUNTRY ||
      type === FilterTypes.LANGUAGE
    if (!activeFilters || !Object.keys(activeFilters)) {
      setFilterBadges([])
    } else {
      Object.keys(activeFilters).forEach(key => {
        // Check if the value is a comma-separated string
        if (
          typeof activeFilters[key] === "string" &&
          activeFilters[key].includes(",")
        ) {
          // Split the comma-separated values into an array
          let values = activeFilters[key].split(",").map(item => item.trim())
          // Create separate objects for each value and add to the result array
          values.forEach(value => {
            let newObj = {
              type: key,
              value,
              multiSelect: isMultiselect(key),
            }

            resultArray.push(newObj)
          })
        } else {
          // For values that are not comma-separated, add them directly
          let newObj = {
            type: key,
            value: activeFilters[key],
            multiSelect: isMultiselect(key),
          }
          resultArray.push(newObj)
        }
      })
      setFilterBadges(resultArray)
    }
  }

  return (
    <div className="program search">
      <SearchHeader
        mobileOpen={mobileOpen}
        query={searchQuery}
        pageType={pageType}
        currentViewType={viewType}
        setMobileOpen={setMobileOpen}
        setSearchQuery={setSearchQuery}
        handleViewTypeChange={handleViewTypeChange}
      />

      <main ref={searchContentRef} className="search__main">
        <FilterDrawer
          pageType={pageType}
          query={searchQuery}
          sortOrder={sortOrder}
          filterOptions={filterOptions}
          activeFilters={activeFilters}
          mobileOpen={mobileOpen}
          isSearchActive={isSearchActive}
          setSearchQuery={setSearchQuery}
          setSortOrder={setSortOrder}
          setMobileOpen={setMobileOpen}
          handleFilterChange={handleFilterChange}
          drawerContainerRef={searchContentRef}
          clearAllFilters={clearAllFilters}
          setActiveFilters={setActiveFilters}
          currentViewType={viewType}
          handleViewTypeChange={handleViewTypeChange}
          filterBadges={filterBadges}
          updateBadges={updateBadges}
          setFilterBadges={setFilterBadges}
        />

        <SearchContent
          pageType={pageType}
          isSearchActive={isSearchActive}
          setActiveFilters={setActiveFilters}
          searchResults={searchResults}
          sortOrder={sortOrder}
          query={searchQuery}
          eventEntries={eventEntries}
          filmEntries={filmEntries}
          getCategoryRowProps={getCategoryRowProps}
          currentViewType={viewType}
          updateBadges={updateBadges}
          categoryQuery={categoryQuery}
        />
      </main>
    </div>
  )
}
