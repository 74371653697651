import React from "react"
import styled from "@emotion/styled"
import { useMediaQuery, useTheme } from "@mui/material"

import {
  FaCalendar,
  FaLocationDot,
  FaGlobe,
} from "../../../icons/event_details_icons/index"

import {
  EntryDetailTypes,
  EventFormatSlugs,
} from "../../../../common/constants"

import { Map } from "../../../map/map"

const EntryDetailOrder = [
  EntryDetailTypes.DATE,
  EntryDetailTypes.REGION_RESTRICTIONS,
  EntryDetailTypes.VENUE,
]

const EventDetailCardContainer = styled.section`
  margin: 2rem 0;
  width: 100%;
  display: flex;
  padding: ${props => (props.largerThanSm ? "2rem 1.5rem" : "1.5rem")};
  background: #f2f2f2;
  border-radius: 10px;
  flex-direction: ${props => (props.largerThanSm ? "row" : "column")};
  justify-content: space-between;
`

const EventDetailCardContent = styled.section`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.isRowDirection ? "row" : "column")};
  justify-content: space-between;
`

const EventDetailCardList = styled.ul`
  width: auto;
  display: flex;
  flex-direction: ${props => (props.smallest ? "column" : "row")};
  padding-bottom: ${props => (props.isRowDirectionAndOnline ? "1.5rem" : "0")};

  .eventDetailsCard__list__item {
    padding-right: ${props => (props.largerThanSm ? "1.25rem" : "0")};
    &:first-child {
      padding-right: 2rem;
      padding-bottom: ${props => (props.smallest ? "1.5rem" : "0")};
    }
  }
  .eventDetailsCard__icon {
    padding-right: 0.5rem;
  }
  .eventDetailsCard__info {
    display: flex;
    align-items: center;
    padding-bottom: ${props => (props.smallest ? ".5rem" : "1rem")};
  }
  .eventDetailsCard__title {
    font-size: 1em;
    margin-bottom: 0;
  }
`
const EventDetailCardMapContainer = styled.div`
  width: ${props => {
    if (props.inBetweenSmAndLg) return "50%"
    return props.largerThanSm ? "40%" : "100%"
  }};
  height: 10rem;
`

export function EventDetailsCard(props) {
  const { entry, getDisplayedDate } = props

  const theme = useTheme()
  const smallest = useMediaQuery(theme.breakpoints.down(450))
  const largerThanSm = useMediaQuery(theme.breakpoints.up("sm"))
  const inBetweenSmAndLg = useMediaQuery(theme.breakpoints.between(900, 1000))

  /**
   * Comment extracted from now delete sideBar:
   * A helper function for creating an <EntryDetailItem> component for
   * each type of entry detail.
   *
   * @param {EntryDetailTypes} type the entry detail to create a component
   */
  const createEntryDetail = type => {
    switch (type) {
      case EntryDetailTypes.DATE:
        if (!entry.isEvent) {
          return null
        }

        // Comment extracted from now delete sideBar:
        // The Event date will be formatted as a date range for On Demand events
        // and as the start time for the other types. Make sure to append the correct
        // descriptor depending on the format
        return (
          <li key={type} className="eventDetailsCard__list__item">
            <div className="eventDetailsCard__info">
              <FaCalendar className="eventDetailsCard__icon" size="20" />
              <h4 className="eventDetailsCard__title">
                {entry.eventFormatSlug === EventFormatSlugs.ON_DEMAND
                  ? "Available Dates"
                  : "Date and Time"}
              </h4>
            </div>
            <p>{getDisplayedDate()}</p>
          </li>
        )

      case EntryDetailTypes.REGION_RESTRICTIONS:
        if (entry.eventFormatSlug !== EventFormatSlugs.ON_DEMAND) {
          // Only On-Demand events have region restrictions
          return null
        }
        return (
          <li key={type} className="eventDetailsCard__list__item">
            <div className="eventDetailsCard__info">
              <FaGlobe className="eventDetailsCard__icon" size="20" />
              <h4 className="eventDetailsCard__title">Geoblocking</h4>
            </div>
            <p>
              {!entry.isEvent || !entry.regionRestrictions
                ? "No viewing restrictions"
                : `Online viewing for this screening is limited to
                ${entry.regionRestrictions}`}
            </p>
          </li>
        )

      case EntryDetailTypes.VENUE:
        if (entry.isOnline == true || entry.venue == null) {
          return null
        }

        const {
          name,
          address: { streetAddress, city, state, zipCode },
        } = entry.venue

        return (
          <li key={type} className="eventDetailsCard__list__item">
            <div className="eventDetailsCard__info">
              <FaLocationDot className="eventDetailsCard__icon" size="20" />
              <h4 className="eventDetailsCard__title">{"Venue"}</h4>
            </div>
            <div>
              <p style={{ marginBottom: "0.25rem" }}>{name}</p>
              <address>
                <p>
                  {streetAddress} <br />
                  {city}, {state} <wbr />
                  {zipCode}
                </p>
              </address>
            </div>
          </li>
        )

      default:
        return null
    }
  }

  const detailItems = []
  EntryDetailOrder.forEach(type => {
    const item = createEntryDetail(type)

    if (item) {
      detailItems.push(item)
    }
  })

  const createEntryMap = () => {
    if (entry.isOnline == true || !entry.venue) {
      return null
    }

    return (
      <Map
        address={entry.venue.address}
        coordinates={entry.venue.coordinates}
        googlePlaceId={entry.venue.coordinates?.googlePlaceId}
        width="100%"
        height="100%"
      />
    )
  }

  // If the entry is a film without screening events, return null
  // rather than the component
  if (!entry.isEvent && !entry.screeningEventsCount) {
    return null
  }

  return (
    <EventDetailCardContainer largerThanSm={largerThanSm}>
      <EventDetailCardContent
        largerThanSm={largerThanSm}
        isRowDirection={largerThanSm && entry.isOnline == false}
      >
        <EventDetailCardList
          smallest={smallest}
          largerThanSm={largerThanSm}
          isRowDirectionAndOnline={
            (largerThanSm && entry.isOnline == true) || largerThanSm == false
          }
        >
          {detailItems}
        </EventDetailCardList>
        {entry.isOnline == false && (
          <EventDetailCardMapContainer
            largerThanSm={largerThanSm}
            inBetweenSmAndLg={inBetweenSmAndLg}
          >
            {createEntryMap()}
          </EventDetailCardMapContainer>
        )}
        {entry.isOnline && (
          <a href="/faq/" style={{ textDecoration: "underline" }}>
            {`Visit our FAQs page for more information on ${
              entry.eventFormatSlug === EventFormatSlugs.ON_DEMAND
                ? "On-Demand screenings"
                : "Livestream events"
            } `}
          </a>
        )}
      </EventDetailCardContent>
    </EventDetailCardContainer>
  )
}
