import React from "react"
import { graphql } from "gatsby"

import InnerHTML from "../components/inner_html/inner_html"
import { BannerImage } from "../components/banner_image"
import { FestivalPageLayout } from "@components/layout"
import { PageHead } from "@components/page_head"

export default function FestivalPage({ data }) {
  const {
    content,
    festivalPageMetadata: { title, bannerImage },
    wipMode: { isWipModeActive, wipModeSettings },
  } = data.wpFestivalPage

  return (
    <FestivalPageLayout
      title={title}
      isWipModeActive={isWipModeActive}
      wipModeSettings={wipModeSettings}
    >
      {bannerImage && bannerImage.sourceUrl ? (
        <BannerImage
          media={{ desktop: { sourceUrl: bannerImage.sourceUrl } }}
        />
      ) : null}

      <InnerHTML htmlContent={content} isFestivalPage={true} />
    </FestivalPageLayout>
  )
}

export const Head = ({ data }) => {
  const {
    festivalPageMetadata: { title, description },
  } = data.wpFestivalPage

  return <PageHead title={title} description={description} />
}

export const query = graphql`
  query ($id: String) {
    wpFestivalPage(id: { eq: $id }) {
      festivalPageMetadata {
        title
        description
        bannerImage {
          sourceUrl
        }
      }
      content
      ...PageWipModeSettings
    }
  }
`
