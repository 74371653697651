import moment from "moment-timezone"

import { FilmEntry } from "@common/wp-data"
import { EventFormatSlugs, AAIFF_TIMEZONE } from "@common/constants"
import { EventEntry } from "../common/wp-data"

/**
 * A helper function that creates a comparator function for comparing the dates of Event entries.
 * The comparator function can be customized by modifying the options
 *
 * @param {{ includePassed: boolean, includeOngoing: boolean }} options the options to customize
 * how events are sorted by date relative to the current date
 *
 * - includePassed – if `false`, will deprioritize any events that have already passed. Default: `true`
 * - includeOngoing - if `false`, will deprioritize any events have are currently ongoing. Default: `true`
 */
export const sortEntriesByDate = (options = {}) => {
  const { includePassed = true, includeOngoing = true } = options
  const now = moment().tz(AAIFF_TIMEZONE) // get the date now so that all entries are compared to the same now moment

  /**
   * Compares to entries based on the start and stop times and the sort options provided
   *
   * @param {EventEntry} entryA the 1st related entry
   * @param {EventEntry} entryB the 2nd related entry
   * @returns  `-1` if A starts before B, `1` if B starts before A,
   * and `0` if they have the same start time.
   */
  return (entryA, entryB) => {
    const startA = moment(`${entryA.startDate} ${entryA.startTime}`)
    const endA = moment(`${entryA.endDate} ${entryA.endTime}`)

    const startB = moment(`${entryB.startDate} ${entryB.startTime}`)
    const endB = moment(`${entryB.endDate} ${entryB.endTime}`)

    /**
     * If the option to include passed events is false, deprioritize any events
     * that have already passed
     */
    if (!includePassed) {
      const hasPassedA = now.isAfter(
        entryA.eventFormatSlug === EventFormatSlugs.ON_DEMAND ? startA : endA // treat on demands events that have started as already passed since they will available for multiple days
      )
      const hasPassedB = now.isAfter(
        entryB.eventFormatSlug === EventFormatSlugs.ON_DEMAND ? startB : endB // treat on demands events that have started as already passed since they will available for multiple days
      )

      if (hasPassedA && !hasPassedB) {
        return 1
      } else if (!hasPassedA && hasPassedB) {
        return -1
      }
    }

    /**
     * If the option to include ongoing entry is false, deprioritize any events
     * that are currently on going
     */
    if (!includeOngoing) {
      const isOngoingA = now.isSameOrAfter(startA) && now.isSameOrBefore(endA)
      const isOngoingB = now.isSameOrAfter(startB) && now.isSameOrBefore(endB)

      if (isOngoingA && !isOngoingB) {
        return 1
      } else if (!isOngoingA && isOngoingB) {
        return -1
      }
    }

    /**
     * Compare the entry start times to get their sort order
     */
    if (startA.isBefore(startB)) {
      return -1
    } else if (startB.isBefore(startA)) {
      return 1
    } else {
      return 0
    }
  }
}

export const sortEntriesByAlpha = (entryA, entryB) => {
  if (entryA.title < entryB.title) {
    return -1
  } else if (entryA.title > entryB.title) {
    return 1
  } else {
    return 0
  }
}

/**
 * A comparison function to be passed into Array.sort() that compares the event formats
 * of each entry and prioritizes them. By default, the formats are prioritized as follows:
 * 1. In Person events
 * 2. Livestream events
 * 3. On Demand events
 *
 * A custom `priorities` object can be passed into the function that can be used to overwrite the
 * default priorities. Make sure to provided the priorities by event format slug
 *
 * Example `priorities` object:
 * ```js
 * {
 *    [EventFormatSlugs.IN_PERSON]: 3,
 *    [EventFormatSlugs.LIVESTREAM]: 1,
 *    [EventFormatSlugs.ON_DEMAND]: 2,
 * }
 * ```
 */
export const sortEventsByEventFormat = (priorities = {}) => {
  const formatPriorities = {
    [EventFormatSlugs.IN_PERSON]: 1,
    [EventFormatSlugs.LIVESTREAM]: 2,
    [EventFormatSlugs.ON_DEMAND]: 3,
    ...priorities,
  }

  const getEntryPriority = entry => {
    return formatPriorities[entry?.eventFormatSlug] || Number.POSITIVE_INFINITY
  }

  return (entryA, entryB) => {
    if (getEntryPriority(entryA) < getEntryPriority(entryB)) {
      return -1
    } else if (getEntryPriority(entryA) > getEntryPriority(entryB)) {
      return 1
    } else {
      return 0
    }
  }
}

/**
 * A helper function to determine if 2 event entries have overlapping time
 * periods.
 *
 * @param {WpEntry} entryA the first entry
 * @param {WpEntry} entryB the second entry
 * @return if the 2 entries have overlapping time periods, return `true`, otherwise
 * return `false`
 */
export const doEntriesOverlap = (entryA, entryB) => {
  if (entryA.isEvent && entryB.isEvent) {
    const startA = moment(`${entryA.startDate} ${entryA.startTime}`)
    const endA = moment(`${entryA.endDate} ${entryA.endTime}`)
    const startB = moment(`${entryB.startDate} ${entryB.startTime}`)
    const endB = moment(`${entryB.endDate} ${entryB.endTime}`)

    return (
      (startB.isBefore(endA) && endB.isAfter(startA)) ||
      (startA.isBefore(endB) && endA.isAfter(startB))
    )
  }

  return false
}

/**
 * Slightly flattens the data coming from the wordpress
 * so that the fields aren't nested under the wordpress
 * ACV field groups.
 *
 * @param {Object} wpNode the data for the wordpress entry
 * @returns a version of the wordpress entry with its
 * field groups unnested
 */
export const flattenEntryFieldGroups = wpNode => {
  if (!wpNode) {
    return wpNode
  }

  const {
    filmGeneralInformation = {},
    filmLoglineSynopsis = {},
    filmTaxonomies = {},
    filmCredits = {},
    filmSocialAndBonusContent = {},
    eventGeneralInformation = {},
    eventDescription = {},
    eventPartners = {},
    eventSponsors = {},
    eventEleventInformation = {},
    eventScreeningUrls = {},
    eventPanelists = {},
    eventTaxonomies = {},
    eventVenueInformation = {},
    eventAssociatedEvents = {},
    eventFilmScreeningInformation = {},
    eventShortsProgramInformation = {},
    ...rest
  } = wpNode

  // If short program information exists, overwrite the shortFilms
  // with the films in the shorts program
  if (eventShortsProgramInformation.shortFilms) {
    eventFilmScreeningInformation.shortFilms =
      eventShortsProgramInformation.shortFilms
  }

  return {
    ...filmGeneralInformation,
    ...filmLoglineSynopsis,
    ...filmTaxonomies,
    ...filmCredits,
    ...filmSocialAndBonusContent,
    ...eventGeneralInformation,
    ...eventDescription,
    ...eventPartners,
    ...eventSponsors,
    ...eventEleventInformation,
    ...eventScreeningUrls,
    ...eventPanelists,
    ...eventTaxonomies,
    ...eventVenueInformation,
    ...eventAssociatedEvents,
    ...eventFilmScreeningInformation,
    ...rest,
  }
}

/**
 * Converts an array of raw film data into a Map object with each film entry stored
 * by film id
 *
 * @param {Array<any>} films an array containing raw film data
 * @returns {Map<string, FilmEntry>} A Map object containing film entries stored by
 * film id
 */
export const mapFilmsById = (films = []) => {
  if (!Array.isArray(films)) {
    throw new Error("mapFilmsById can only accept array parameters")
  }

  const filmsMap = new Map()

  films.forEach(film => {
    if (film) {
      const { id, slug, ...data } = flattenEntryFieldGroups(film)

      if (!filmsMap.has(id)) {
        filmsMap.set(id, new FilmEntry(id, slug, data))
      }
    }
  })

  return filmsMap
}

/**
 * Filters a list of event entries so that no films are featured more than once
 * in the returned list.
 *
 * @param {Array<EventEntry>} events the list of Event entries to filter
 * @param {Number} resultCount specifies the number of event results to return. If there
 * are not enough events after filtering, events that feature an already included film
 * will be added until the number is reached. Default: 3
 * @returns {Array<EventEntry>} the filtered list of events that feature any film no more than once
 */
export const filterEventWithRepeatedFilms = (events, resultCount = 3) => {
  let featuredFilmIds = new Set()
  const filteredEvents = []
  const duplicateFilmEvents = []

  events.forEach(event => {
    const containsExistingFilm = event.hasFilmAttributes
      ? event.allFilmIds.some(filmId => featuredFilmIds.has(filmId))
      : false

    if (!containsExistingFilm) {
      filteredEvents.push(event)

      if (event.hasFilmAttributes) {
        event.allFilmIds.forEach(filmId => featuredFilmIds.add(filmId))
      }
    } else {
      duplicateFilmEvents.push(event)
    }
  })

  if (filteredEvents.length < resultCount) {
    return filteredEvents.concat(
      duplicateFilmEvents.slice(0, resultCount - filteredEvents.length)
    )
  } else if (filteredEvents.length === resultCount) {
    return filteredEvents
  } else {
    return filteredEvents.slice(0, resultCount)
  }
}

/**
 * Replace any events with a `mainFilm` property with the main film's FilmEntry object.
 *
 * @param {EventEntry[]} events the event entries
 * @param {FilmEntry[]} filmEntries an array of all film entries
 * @param {number} resultCount the number of results to return. Default: 3
 * @returns {Array<FilmEntry | EventEntry>} a mixed array of film and event entries
 */
export const extractFilmsFromEvents = (events, filmEntries, resultCount) => {
  const resultEntries = []
  const resultFilmIds = new Set()
  const filmsById = filmEntries.reduce((acc, film) => {
    acc[film.id] = film

    return acc
  }, {})

  events.forEach(event => {
    if (event.mainFilm && !resultFilmIds.has(event.mainFilm.id)) {
      const filmEntry = filmsById[event.mainFilm.id]

      // Attempt to use the `filmEntries` version of the main film because
      // it has the screening information already attached to that object
      if (filmEntry) {
        resultEntries.push(filmsById[event.mainFilm.id])
      } else {
        console.warn(`Could not find film ${event.mainFilm.id} with screenings`)
        resultEntries.push(event.mainFilm)
      }

      resultFilmIds.add(event.mainFilm.id)
    } else if (!event.mainFilm) {
      resultEntries.push(event)
    }
  })

  if (resultCount && resultEntries.length > resultCount) {
    return resultEntries.slice(0, resultCount)
  }

  return resultEntries
}

/**
 * A modified sort that extracts the firstScreening event from any film
 * entries and uses those for the comparison.
 *
 * @param {FilmEntry|EventEntry} entryA the first entry
 * @param {FilmEntry|EventEntry} entryB the second entry
 */
export const sortFilmsByFirstScreening = (entryA, entryB) => {
  const now = moment().tz(AAIFF_TIMEZONE)

  const screeningA = entryA.isEvent ? entryA : entryA.getFirstScreening(now)
  const screeningB = entryB.isEvent ? entryB : entryB.getFirstScreening(now)

  return sortEntriesByDate({ includePassed: false })(screeningA, screeningB)
}
