import moment from "moment-timezone"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useEffect } from "react"

import {
  useLocalTimezone,
  useSelectFestivalByYear,
  useFeaturedEvents,
} from "../"
import { AdSpaceTypes, EventFormatSlugs } from "../../common/constants"
import { sortEventsByEventFormat } from "../../util/entry_utils"
import { useActiveScreeningEntry } from "./useActiveScreening"
import { useEleventService } from "../useEleventListeners"

export function useWpEntryLayout(props) {
  const { wpNode, screeningNodes = [], location } = props
  const eleventService = useEleventService()

  const isSmDown = useMediaQuery("(max-width:600px)")
  const isMdUp = useMediaQuery("(min-width:900px)")

  const {
    baseEntry,
    activeScreeningEntry: entry,
    changeActiveScreeningTo,
    screeningToggleOptions,
    screeningEntries,
  } = useActiveScreeningEntry(wpNode, screeningNodes, location)

  // Whenever the base entry for this page changes, initialize the widgets on the page
  useEffect(() => {
    eleventService.initializeWidgets()
  }, [baseEntry?.id])

  const { getFeaturedEvents } = useFeaturedEvents() // only used for film entries

  const { convertToLocalTimeZone } = useLocalTimezone()
  const { endDate: festEndDate, name: festName } = useSelectFestivalByYear()(
    entry.festivalYear
  )

  const getDisplayedDate = () => {
    let dateStr = ""

    if (entry.isEvent) {
      const localStartDate = convertToLocalTimeZone(
        entry.startDate,
        entry.startTime
      )

      // format the displayed dates depending on the format of the event.
      if (entry.eventFormatSlug === EventFormatSlugs.ON_DEMAND) {
        /**
         * If the event is On Demand, format the date so that it is
         * displayed as [startDate] - [endDate].
         *
         * If the event is not On Demand, format the date so that
         * it shows the start date and time separated by a "|".
         */
        const localEndDate = entry.endDate
          ? convertToLocalTimeZone(entry.endDate, entry.endTime)
          : convertToLocalTimeZone(festEndDate)

        if (localStartDate.format("MMMM") === localEndDate.format("MMMM")) {
          dateStr = `${localStartDate.format(
            "MMMM DD"
          )} — ${localEndDate.format("DD YYYY")}`
        } else {
          dateStr = `${localStartDate.format(
            "MMMM DD"
          )} — ${localEndDate.format("MMMM DD YYYY")}`
        }
      } else if (entry.eventFormatSlug === EventFormatSlugs.LIVESTREAM) {
        /**
         * The event is a live online event. Convert the event time from the AAIFF timezone
         * to the user's current timezone. Also display the timezone
         */

        dateStr = convertToLocalTimeZone(
          entry.startDate,
          entry.startTime,
          entry.endTime
        ).format("MMMM DD YYYY | h:mm A z")
      } else {
        /**
         * The event is an in-person event. No need to convert to a different timezone or
         * display which timezone it is
         */

        dateStr = (
          <div>
            <p>{moment(`${entry.startDate}`).format("dddd, MMMM DD YYYY")}</p>
            <p>
              {moment(`${entry.startDate} ${entry.startTime}`).format("h:mm A")}{" "}
              to{" "}
              {moment(`${entry.startDate} ${entry.endTime}`).format("h:mm A")}
            </p>
          </div>
        )
      }
    }

    return dateStr
  }

  /**
   * If the entry is a film, this returns a list of objects for the featured events. If this is
   * for an event, this returns the related/associated events
   *
   * @returns {Array} the list of objects that contain the event titles, slugs, and display dates
   */
  const getRelatedEvents = () => {
    // sort the related events so that they are displayed in order of start
    // date
    const formatDateRange = (startDate, endDate) => {
      const startMoment = moment(startDate, "MM/DD/YYYY")
      const endMoment = moment(endDate, "MM/DD/YYYY")

      const formattedStartDate = startMoment.format("MMMM DD")
      const formattedEndDate = endMoment.format("MMMM DD")

      return `${formattedStartDate} - ${formattedEndDate}`
    }

    const eventsToSort = entry.isEvent
      ? entry.relatedEvents
      : getFeaturedEvents(entry)

    const sortedEvents = eventsToSort.sort(sortEventsByEventFormat())

    const relatedEvents = sortedEvents.map(event => {
      const localDate = convertToLocalTimeZone(event.startDate, event.startTime)
      // on-demand events use a date range, other events use single date and start time
      const displayedDate =
        event.eventFormat.slug === EventFormatSlugs.ON_DEMAND ||
        event.eventFormatSlug === EventFormatSlugs.ON_DEMAND
          ? formatDateRange(event.startDate, event.endDate)
          : localDate.format("MMMM DD | h:mm A z")

      // Return an empty string if either the festival name or event slug are missing
      const pageSlug =
        festName && event.slug ? `/${festName.toLowerCase()}/${event.slug}` : ""
      return {
        title: event.title,
        pageSlug,
        eventFormatSlug: event.eventFormat.slug || event.eventFormatSlug,
        displayedDate,
      }
    })
    return relatedEvents || []
  }

  const getBodyAdType = () => {
    return isSmDown ? AdSpaceTypes.MOBILE : AdSpaceTypes.HORIZONTAL
  }

  const getSidebarAdType = () => {
    if (isSmDown) {
      return AdSpaceTypes.MOBILE
    } else if (isMdUp) {
      return AdSpaceTypes.VERTICAL
    } else {
      return AdSpaceTypes.HORIZONTAL
    }
  }

  return {
    entry,
    baseEntry,
    screeningEntries,
    screeningToggleOptions,
    changeActiveScreeningTo,
    getDisplayedDate,
    getRelatedEvents,
    getBodyAdType,
    getSidebarAdType,
  }
}
