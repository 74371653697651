import React from "react"

import { Navbar } from "../navbar"
import { EleventCart } from "../../elevent"
import { AaiffLogo } from "@components/logos"
import { HeaderBarRight } from "./header_bar_right"

export function HeaderNavDesktop({ navbarOptions }) {
  return (
    <div className="headerBar">
      <AaiffLogo />
      <Navbar navbarOptions={navbarOptions} />

      <HeaderBarRight />
    </div>
  )
}
