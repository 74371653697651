/**
 * Defined within are constants to be used in FilmDetails component logic.
 */

/**
 * The DISPLAY_FORMATS Array lists all distinct display formats for
 * film `entry` detail properties and is used by FilmDetailsItem to decide how to display
 * the property's corresponding value at `entry[getterName]`
 */
export const DISPLAY_FORMATS = {
  LIST_ONE_LINE: "LIST_ONE_LINE",
  SUBTITLED_LANGUAGES: "LANGUAGE",
  LIST_MULTI_LINE: "LIST_MULTI_LINE",
  PREMIERE: "PREMIERE",
  RUNTIME: "RUNTIME",
  DIRECTORS_BIO: "DIRECTORS_BIO",
  ONE_LINE: "ONE_LINE",
}

/**
 * The FILM_DETAILS_ORDER Map lists all `entry` details that are to be displayed
 * on the FilmDetails component,
 * mapping each detail's getter method name, in display order, to an array containing:
 * - the displayed label text
 * - the display format of its corresponding value
 * The corresponding value is accessed via `entry[getterName]`
 *
 * Allows maintenance via desired changes to:
 * (1) `entry` getter names,
 * (2) their corresponding displayed labels,
 * (3) order of display, and
 * (4) adding or removing `entry` details as desired.
 */
export const FILM_DETAILS_ORDER = new Map([
  ["contentAdvisories", ["Content Warnings", DISPLAY_FORMATS.LIST_ONE_LINE]],
  ["languages", ["Language", DISPLAY_FORMATS.LIST_ONE_LINE]],
  ["subtitledLanguages", ["Subtitles", DISPLAY_FORMATS.SUBTITLED_LANGUAGES]],
  ["premiereStatus", ["Premiere Status", DISPLAY_FORMATS.PREMIERE]],
  ["year", ["Year of Completion", DISPLAY_FORMATS.ONE_LINE]],
  ["runtime", ["Runtime", DISPLAY_FORMATS.RUNTIME]],
  ["genres", ["Genre", DISPLAY_FORMATS.LIST_ONE_LINE]],
  ["filmTypes", ["Film Type", DISPLAY_FORMATS.LIST_ONE_LINE]],
  ["countries", ["Country of Origin", DISPLAY_FORMATS.LIST_ONE_LINE]],
  ["directors", ["Director", DISPLAY_FORMATS.DIRECTORS_BIO]],
  // Note: `entry.directorsBio` contains bio string, but displayed
  ["producers", ["Producer", DISPLAY_FORMATS.LIST_MULTI_LINE]],
  ["writers", ["Writer", DISPLAY_FORMATS.LIST_MULTI_LINE]],
  ["castMembers", ["Cast", DISPLAY_FORMATS.LIST_MULTI_LINE]],
  ["crewMembers", ["Crew", DISPLAY_FORMATS.LIST_MULTI_LINE]],
])
