import React from "react"
import { Link } from "../link"
import { SocialMediaPlatforms } from "../../common/constants"
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaGlobe,
  FaTwitch,
} from "react-icons/fa"

const components = {
  [SocialMediaPlatforms.FACEBOOK]: FaFacebookF,
  [SocialMediaPlatforms.INSTAGRAM]: FaInstagram,
  [SocialMediaPlatforms.TWITTER]: FaTwitter,
  [SocialMediaPlatforms.YOUTUBE]: FaYoutube,
  [SocialMediaPlatforms.WEBSITE]: FaGlobe,
  [SocialMediaPlatforms.TWITCH]: FaTwitch,
}

export function SocialLink({ platform, redirectUrl, className }) {
  const BrandIcon = components[platform]

  return (
    <Link
      className={`aaiffBtn__socialLink ${className || ""}`}
      to={redirectUrl}
      external
    >
      <BrandIcon />
    </Link>
  )
}
