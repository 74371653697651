import React from "react"

import { FooterCopyright } from "./footer_copyright"
import { FooterContentMobile } from "./footer_content_mobile"
import { FooterContentDesktop } from "./footer_content_desktop"

export function Footer(props) {
  return (
    <footer className="footer">
      <FooterContentMobile {...props} />
      <FooterContentDesktop {...props} />

      <FooterCopyright />
    </footer>
  )
}
