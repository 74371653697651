import React from "react"
import PropTypes from "prop-types"

import ImageWrapper from "../../image_wrapper"
import { Link } from "../../link"
import { MediaDto } from "@common/dto"
import styled from "@emotion/styled"

const ItemContainer = styled("div")`
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
`
const CarouselCard = styled("div")`
  border-radius: 8px;
  padding-bottom: 0.5rem;
`

const Figure = styled("figure")`
  overflow: hidden;
  aspect-ratio: 4/3;
  margin-bottom: 1rem;

  img {
    transform: scale(1);
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: transform filter;

    &:hover {
      transform: scale(1.03);
    }
  }
`
const Label = styled("span")`
  font-size: 1rem;

  @media (min-width: 800px) {
    font-size: 1.25rem;
  }

  &:hover {
    text-decoration: none;
  }
`

export function UpcomingEventsItem(props) {
  const { label, link, imageMedia } = props

  return (
    <ItemContainer>
      <CarouselCard>
        <Link
          to={link.type === "internal" ? `/${link.path}` : link.url}
          external={link.type === "external"}
          className="upcomingEventsItem"
        >
          <Figure>
            <ImageWrapper
              media={imageMedia}
              alt={label}
              objectFit="cover"
              className="upcomingEventsItem__image"
            />
          </Figure>
          <Label>{label}</Label>
        </Link>
      </CarouselCard>
    </ItemContainer>
  )
}

UpcomingEventsItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.exact({
    type: PropTypes.oneOf(["internal", "external"]),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  imageMedia: PropTypes.instanceOf(MediaDto),
}
