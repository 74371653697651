import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { ProgramCard } from "../../../cards/program_card/program_card"
import { useRecommendedEvents } from "../../../../hooks"

export function YouMayLike({ entry }) {
  const recommendedEvents = useRecommendedEvents(entry)

  if (recommendedEvents.length === 0) {
    return null
  }

  return (
    <aside className="entryRecommendations">
      <h2 className="event__title">You May Also Like</h2>
      <YMLul>
        {recommendedEvents.map(event => (
          <ProgramCard 
            key={event.title}
            entry={event}
            variantProp="YOU_MAY_LIKE"
          />
        ))}
      </YMLul>
    </aside>
  )
}

YouMayLike.propTypes = {
  entry: PropTypes.object.isRequired,
}

const YMLul = styled.ul`
  @media(max-width: 899px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }

  @media(max-width: 640px) {
    display: block;
  }    
`
