import React from "react"
import styled from "@emotion/styled"
import { DISPLAY_FORMATS } from "@constants"

/**
 * FilmDetailsItem Component
 *
 * This component creates an <li> for each detail in the parent FilmDetails component,
 * containing a detail's label and its corresponding `value`, formatted as `displayValue`.
 * `displayValue` is formatted according to the detail's associated DISPLAY_FORMATS format.
 *
 * Renders nothing instead if `value` prop is null or empty (if Array).
 */

export function FilmDetailsItem(props) {
  const { detailLabel, value, format, directorsBio } = props

  // Default value in case a value-less row somehow gets rendered, should only display for subtitles.
  let displayValue = "N/A"

  if (
    (!value || value.length === 0) &&
    format !== DISPLAY_FORMATS.SUBTITLED_LANGUAGES
  ) {
    return null
  }

  switch (format) {
    case DISPLAY_FORMATS.ONE_LINE:
      if (value) displayValue = <p>{value}</p>
    case DISPLAY_FORMATS.SUBTITLED_LANGUAGES:
      // Closed Captions should display N/A if none, as is it informative
      if (value.length > 0) displayValue = <p>{value.join(", ")}</p>
      break
    case DISPLAY_FORMATS.LIST_ONE_LINE:
      displayValue = <p>{value.join(", ")}</p>
      break
    case DISPLAY_FORMATS.LIST_MULTI_LINE:
      displayValue = (
        <ol>
          {value.map(item => (
            <p key={item}>{item}</p>
          ))}
        </ol>
      )
      break
    case DISPLAY_FORMATS.PREMIERE:
      displayValue = <p>{value} Premiere</p>
      break
    case DISPLAY_FORMATS.RUNTIME:
      displayValue = <p>{value} minutes</p>
      break
    case DISPLAY_FORMATS.DIRECTORS_BIO:
      displayValue = (
        <article>
          <ol>
            {value.map(item => (
              <p key={item}>{item}</p>
            ))}
          </ol>
          <br />
          {directorsBio && <FDDirectorBio>{directorsBio}</FDDirectorBio>}
        </article>
      )
      break
    default:
      break
  }

  return (
    <FDItem>
      <FDLabel>{detailLabel}</FDLabel>
      {displayValue}
    </FDItem>
  )
}

const FDItem = styled.li`
  display: flex;
  flex-direction: row;
  line-height: 1.5em; // 24px desktop / 18px mobile

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const FDLabel = styled.label`
  min-width: 10.625em; // Desktop only: 170px with 30px gap (in case long labels) for 200px fixed width total.
  max-width: 10.625em;
  margin-right: 1.875em;
  display: block;
  font-weight: 700;

  @media only screen and (max-width: 900px) {
    min-width: initial;
    max-width: initial;
  }
`

const FDDirectorBio = styled.p`
  // directorsBio string includes linebreak characters, this displays those linebreaks:
  white-space: pre-wrap;
`
