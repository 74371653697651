import React, { useEffect } from "react"
import PropTypes from "prop-types"
import useMediaQuery from "@mui/material/useMediaQuery"

import { DrawerDesktop } from "./drawer_desktop"
import { DrawerMobile } from "./drawer_mobile"
import { EventDrawerDesktop } from "./event_drawer_desktop"
import { EventDrawerMobile } from "./event_drawer_mobile"
import { SearchPageTypes } from "../../../../common/constants"

export function FilterDrawer(props) {
  const { pageType } = props
  const isMobile = useMediaQuery("(max-width:900px)")
  const { activeFilters, updateBadges } = props
  
  useEffect(() => {
    // make sure the badges are render if the results are loaded if a search url is entered
    updateBadges(activeFilters)
  }, [])

  if (pageType === SearchPageTypes.EVENTS) {
    if (isMobile) {
      return (
        <EventDrawerMobile
          {...props}
        />
      )
    }

    return <EventDrawerDesktop {...props} />
  } else {
    if (isMobile) {
      return (
        <DrawerMobile
          {...props}
        />
      )
    }
    return (
      <DrawerDesktop
        {...props}
      />
    )
  }
}

FilterDrawer.propTypes = {
  query: PropTypes.string,
  sortOrder: PropTypes.string.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  setMobileOpen: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
  drawerContainerRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  clearAllFilters: PropTypes.func.isRequired,
}
