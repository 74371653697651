import React from "react"
import styled from "@emotion/styled"

import InnerHTML from "../../../inner_html/inner_html"

const NotesContainer = styled("div")`
  border: 1px solid #111111;
  border-radius: 8px;
  padding: 0.75rem 1.125rem;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 600px) {
    gap: 16px;
    padding: 1.75rem 2.5rem;
  }

  h2 {
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
`

const NotesDetails = styled(InnerHTML)`
  // Remove the bottom margin from the last item to avoid unbalanced padding
  *:last-child {
    margin-bottom: 0;
  }
`

export function EventNotes({ htmlContent }) {
  if (!htmlContent) {
    return null
  }

  return (
    <NotesContainer>
      <h2>EVENT NOTES:</h2>

      <NotesDetails htmlContent={htmlContent} />
    </NotesContainer>
  )
}
