import React from "react"
import styled from "@emotion/styled"

import { AccountButton } from "./account_button"
import { EleventCart } from "../../elevent"

const StyledCart = styled(EleventCart)`
  &:hover {
    background-color: #333333;
    border-radius: 4px;
    text-decoration: none;
  }
`

export function HeaderBarRight(props) {
  const { isMobile = false } = props

  return (
    <div className="headerBar__right">
      <AccountButton buttonClassName="headerBar__rightBtn" mobile={isMobile} />
      <StyledCart
        buttonClassName="headerBar__cart headerBar__rightBtn"
        variant="overBackground"
      />
    </div>
  )
}
