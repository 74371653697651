import {
  SortOrders,
  FilterTypes,
  WpEntryTypes,
  ProgramTypes,
  AAIFF_TIMEZONE,
} from "../common/constants"
import moment from "moment-timezone"
import { EventEntry, FilmEntry } from "../common/wp-data"

export function sortEntries(entries, sortOrder) {
  if (sortOrder === SortOrders.ALPHABETICAL) {
    return entries.sort(orderAlphabetically)
  } else if (sortOrder === SortOrders.SCREENING_TYPE) {
    return entries.sort(orderByScreeningType)
  } else {
    return entries.sort(orderByStartDate)
  }
}

function orderAlphabetically(entryA, entryB) {
  if (entryA.title.toLowerCase() < entryB.title.toLowerCase()) {
    return -1
  } else if (entryA.title > entryB.title) {
    return 1
  } else {
    return 0
  }
}

/**
 * Sorts film or event entries by their start date and time. If the entry is an
 * event, the start date and time of the entry itself will be used for comparison.
 * Otherwise, the entry is assumed to be a film, and the start date and time of the
 * first screening will be used.
 * @param {EventEntry | FilmEntry} entryA The first event or film entry to sort
 * @param {EventEntry | FilmEntry} entryB The second event or film entry to sort
 * @returns
 */
function orderByStartDate(entryA, entryB) {
  const now = moment().tz(AAIFF_TIMEZONE)

  try {
    const screeningA = entryA.isEvent
      ? entryA
      : entryA.screeningEventsCount > 0
      ? entryA.getFirstScreening(now)
      : entryA
    const screeningB = entryB.isEvent
      ? entryB
      : entryB.screeningEventsCount > 0
      ? entryB.getFirstScreening(now)
      : entryB

    const dateA = screeningA.startDate
      ? moment(`${screeningA.startDate} ${screeningA.startTime}`)
      : null
    const dateB = screeningB.startDate
      ? moment(`${screeningB.startDate} ${screeningB.startTime}`)
      : null

    if (dateA && dateB) {
      if (dateA.isBefore(dateB)) {
        return -1
      } else if (dateA.isAfter(dateB)) {
        return 1
      } else {
        0
      }
    } else if (dateA && !dateB) {
      return -1
    } else if (!dateA && dateB) {
      return 1
    } else {
      return 0
    }
  } catch (err) {
    console.error("orderByStartDate - error", { entryA, entryB })

    throw err
  }
}

/**
 * Orders the entries by their screening type. In-person screenings are
 * prioritized over on-demand screenings.
 *
 * @param {EventEntry} entryA The first event entry
 * @param {EventEntry} entryB The second event entry
 * @returns
 */
function orderByScreeningType(entryA, entryB) {
  const screeningA = entryA.isEvent ? entryA : null
  const screeningB = entryB.isEvent ? entryB : null

  if (screeningA && screeningB) {
    if (!screeningA.isOnline && screeningB.isOnline) {
      return -1
    } else if (screeningA.isOnline && !screeningB.isOnline) {
      return 1
    } else {
      return 0
    }
  } else if (screeningA) {
    return -1
  } else if (screeningB) {
    return 1
  } else {
    return 0
  }
}

/**
 * Loops through each active filter and applies that filters on the list of
 * entries.
 *
 * @param {Array<Entry>} entryList the list of entries to be filtered
 * @param {Object|undefined} activeFilters an object that may contain any active
 * filters and the their current value
 * @returns the filtered entries
 */
export const applyActiveFilters = (entryList, activeFilters) => {
  if (!activeFilters) {
    return entryList
  }

  let filteredEntries = entryList
  const filterTypes = Object.keys(activeFilters)

  if (filterTypes.length) {
    filterTypes.forEach(filterType => {
      const currentFilterValue = activeFilters[filterType]

      try {
        switch (filterType) {
          case FilterTypes.FILM_TYPE:
            filteredEntries = filteredEntries.filter(
              entry =>
                entry.hasFilmAttributes &&
                entry.filmTypes.includes(currentFilterValue)
            )
            break
          case FilterTypes.FILM_FORMAT:
            filteredEntries = filteredEntries.filter(entry => {
              if (entry.hasFilmAttributes) {
                if (entry.type === WpEntryTypes.SHORTS_PROGRAM) {
                  return entry.filmFormats.includes(currentFilterValue)
                } else {
                  return entry.filmFormat === currentFilterValue
                }
              }

              return false
            })
            break
          // TODO: Find a way to consolidate the single and multiSelect types
          case FilterTypes.GENRE:
            // For the genres filter, check both the genres and the
            // film format attributes.
            filteredEntries = filteredEntries.filter(
              entry =>
                entry.hasFilmAttributes &&
                containsValues(entry.genres, currentFilterValue)
            )
            break
          case FilterTypes.LANGUAGE:
            // For languages, check both the languages and subtitled languages
            // attributes
            filteredEntries = filteredEntries.filter(
              entry =>
                entry.hasFilmAttributes &&
                containsValues(
                  entry.languages.concat(entry.subtitledLanguages),
                  currentFilterValue
                )
            )
            break
          case FilterTypes.COUNTRY:
            filteredEntries = filteredEntries.filter(
              entry =>
                entry.hasFilmAttributes &&
                containsValues(entry.countries, currentFilterValue)
            )
            break
          case FilterTypes.EVENT_FORMAT:
            filteredEntries = filteredEntries.filter(entry =>
              entry.isEvent
                ? entry.eventFormat === currentFilterValue
                : entry.screeningEvents?.some(
                    screenings => screenings.eventFormat === currentFilterValue
                  )
            )
            break
          case FilterTypes.EVENT_TYPE:
            filteredEntries = filteredEntries.filter(entry => {
              if (currentFilterValue === "Special Presentations") {
                return entry.isEvent
                  ? entry.eventType === currentFilterValue
                  : // Check screening events in film entries
                    entry?.screeningEvents?.some(
                      event => event.eventType === currentFilterValue
                    )
              } else {
                return entry.isEvent && entry.eventType === currentFilterValue
              }
            })
            break
          case FilterTypes.PROGRAM_TYPE:
            filteredEntries = filteredEntries.filter(entry =>
              currentFilterValue === ProgramTypes.FILMS_ONLY
                ? entry.hasFilmAttributes
                : !entry.hasFilmAttributes
            )
            break
          default:
            break
        }
      } catch (err) {
        console.error(
          `An error occurred while applying filter [${filterType}] with a value of [${currentFilterValue}]`,
          err
        )
      }
    })
  }

  return filteredEntries
}

/**
 * Checks if the `toCheck` array contains any of the values inside the values array
 *
 * @param {Array} toCheck the array to check against
 * @param {Array|String} values the array of values or comma separated string to check
 * @returns
 */
function containsValues(toCheck, values) {
  const toCompare = typeof values === "string" ? values.split(",") : values

  const intersection = new Set([...toCheck].filter(x => toCompare.includes(x)))

  return intersection.size === toCompare.length
}
