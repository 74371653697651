import React from "react"
import styled from "@emotion/styled"

import { ToggleButton, ToggleButtonGroup } from "@mui/material"

export function EntryTicketToggle(props) {
  const { onToggleChange, toggleOptions, selectedKey } = props

  const handleClick = (_, value) => {
    // Don't allow the user to deselect the active filter option
    if (value !== null && value !== selectedKey) {
      onToggleChange(value)
    }
  }

  if (toggleOptions.length > 1) {
    const buttonItems = toggleOptions.map(option => {
      return (
        <StyledToggleButton
          key={option.id}
          value={option.id}
          aria-label={option.label}
          onClick={handleClick}
        >
          {option.label}
        </StyledToggleButton>
      )
    })

    return (
      <StyledToggleGroup orientation="horizontal" value={selectedKey} exclusive>
        {buttonItems}
      </StyledToggleGroup>
    )
  } else if (toggleOptions.length == 1) {
    // Show the label of the only toggle option if there is only 1
    return <SoloLabel>{toggleOptions[0].label}</SoloLabel>
  } else {
    return null
  }
}

const toggleFontStyles = `
  font-size: 1em;

  @media (min-width: 600px) {
    font-size: 0.85em;
  }
`

const SoloLabel = styled.strong`
  font-weight: bold;
  line-height: 1;

  ${toggleFontStyles}
`

const StyledToggleGroup = styled(ToggleButtonGroup)`
  border-radius: 62px;
  background: #f2f2f2;

  height: 40px;
  width: 100%;

  display: flex;
  position: relative;

  ${toggleFontStyles}
`

const StyledToggleButton = styled(ToggleButton)`
  position: absolute;

  background: none;
  border: none;
  border-radius: 62px;
  box-shadow: none;

  font-size: 1em;

  // use this width to make it seem like the selected toggle is overlapping
  width: 54.5%;
  height: 100%;

  text-transform: capitalize;
  color: #111111;

  &.Mui-selected {
    // Raise it higher so that it overlaps with the unselected item
    z-index: 5;
    background: #111111;
    color: white;
    border-radius: 62px;

    padding-left: 11px;
    padding-right: 11px;

    &:hover {
      color: #111111;
    }
  }

  &.MuiToggleButtonGroup-firstButton {
    left: 0;
  }

  &.MuiToggleButtonGroup-lastButton {
    right: 0;
  }
`
