import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import moment from "moment"

import { FestivalPageLayout } from "@components/layout"
import { PageHead } from "@components/page_head"
import {
  TicketingCardGroup,
  TicketingHowToBanner,
  TicketingQuestions,
  TicketingArriving,
  TicketingFestivalVenues,
} from "@components/ticketing"

import { useCurrentFestival, useTicketingState } from "../hooks"

const TicketingHeaderSection = styled("section")`
  text-align: center;
  margin: 2.5rem 1rem;
`
const TicketingHeader = styled("h1")`
  text-align: center;
  font-weight: bold;
  font-size: 2.5rem;

  @media (min-width: 800px) {
    font-size: 3rem;
  }
`

const TicketSection = styled("div")`
  margin: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    flex-direction: row;
  }

  @media (min-width: 1100px) {
    margin: 3rem 1.75rem;
  }

  @media (min-width: 1220px) {
    margin: 3rem 2.5rem;
  }
`
/**
 * Parses an array of in-person events for the current festival year and
 * returns a list of all of the venues for that year.
 *
 * @param {Array<any>} eventNodes an array of event nodes for the current festival year
 * @returns {Array<any>} the list of venues for this year
 */
const _getVenuesFromEvent = eventNodes => {
  const venues = {}

  eventNodes.forEach(event => {
    const {
      eventVenueInformation: { venue },
    } = event

    // If the venue is a subvenue, use the parent venue data instead of
    // the current venue
    const venueToAdd = venue.venueStatusAndParentVenue.isSubvenue
      ? venue.venueStatusAndParentVenue.parent
      : venue

    const { name, address, coordinates } = venueToAdd.venueGeneralInformation

    if (!venues[name]) {
      venues[name] = { name, address, coordinates }
    }
  })

  return Object.values(venues)
}

export default function Ticketing({ data }) {
  const {
    wpFestivalPage: {
      festivalPageMetadata: { title },
      wipMode: { isWipModeActive, wipModeSettings },
      festivalPageTicketingV3,
    },
    allWpEvent,
  } = data

  const currentFestival = useCurrentFestival()
  const currentVenues = _getVenuesFromEvent(allWpEvent.nodes)

  const { pageTitle, pageSections } = festivalPageTicketingV3

  const start = moment(currentFestival.startDate)
  const final = moment(currentFestival.endDate)

  const { ticketingItems } = useTicketingState(festivalPageTicketingV3)

  return (
    <FestivalPageLayout
      title={title}
      isWipModeActive={isWipModeActive}
      wipModeSettings={wipModeSettings}
    >
      <TicketingHeaderSection>
        <TicketingHeader>{pageTitle}</TicketingHeader>
        <span>
          {start.format("MMMM")} {start.format("D")} - {final.format("D")},{" "}
          {start.format("YYYY")}
        </span>
      </TicketingHeaderSection>
      <TicketingHowToBanner />

      {/* { festival passes section } */}

      <TicketSection>
        <TicketingCardGroup
          title={pageSections.passes.title}
          subtitle={pageSections.passes.subtitle}
          packType="festivalPasses"
          {...ticketingItems.festivalPasses}
        />
      </TicketSection>
      {/* Packages tickets section */}

      <TicketSection>
        <TicketingCardGroup
          title={pageSections.packagesAndTickets.title}
          subtitle={pageSections.packagesAndTickets.subtitle}
          packType="ticketsAndPackages"
          {...ticketingItems.ticketsAndPackages}
        />
      </TicketSection>

      {/* General Admission Tickets */}

      <TicketSection>
        <TicketingCardGroup
          title={pageSections.individualTickets.title}
          subtitle={pageSections.individualTickets.subtitle}
          packType="individualTickets"
          {...ticketingItems.individualTickets}
        />
      </TicketSection>
      <TicketingArriving arrivalInfo={pageSections.venueInstructions} />
      <TicketingFestivalVenues
        sectionContent={pageSections.festivalVenues}
        currentVenues={currentVenues}
      />
      <TicketingQuestions date={start} />
    </FestivalPageLayout>
  )
}

export const Head = ({ data }) => {
  const {
    festivalPageMetadata: { title, description },
  } = data.wpFestivalPage

  return <PageHead title={title} description={description} />
}

export const query = graphql`
  query Ticketing($id: String, $festivalYear: String) {
    wpFestivalPage(id: { eq: $id }) {
      content
      festivalPageMetadata {
        title
        description
        bannerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          sourceUrl
          altText
        }
      }
      ...PageWipModeSettings
      ...PageTicketingConfigV3
    }
    allWpEvent(
      filter: {
        eventGeneralInformation: {
          festivalYear: { slug: { eq: $festivalYear } }
        }
        eventTypeFormatRestrictions: {
          eventFormat: { slug: { eq: "in-person" } }
        }
      }
    ) {
      nodes {
        title
        eventVenueInformation {
          venue: venueId {
            ... on WpVenue {
              id
              title
              ...VenueGeneralInformation
              venueStatusAndParentVenue {
                parent: parentId {
                  ... on WpVenue {
                    id
                    title
                    ...VenueGeneralInformation
                  }
                }
                isSubvenue
              }
            }
          }
        }
      }
    }
  }
`
