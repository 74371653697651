/**
 * The anchor links used in the FAQ page. If any of the anchor tags are changed on
 * the WordPress side, these constants will need to be updated to match.
 */
export const faqAnchorLinks = [
  { label: "Purchasing Tickets", anchor: "Purchasing-Tickets" },
  { label: "Passes and Packages", anchor: "Passes-and-Packages" },
  { label: "In-Person Events", anchor: "In-Person-Events" },
  { label: "Viewing Content", anchor: "Viewing-Content" },
  { label: "Accreditation", anchor: "Accreditation" },
  { label: "System Requirements", anchor: "System-Requirements" },
  // { label: "Technical Support", anchor: "Technical-Support" },
]

/**
 * The slugs for the page types that are used in the footer. Update these
 * constants if the Page Types in WordPress are changed
 */
export const LinkSectionSlugs = {
  FESTIVAL_INFO: "festival-information",
  SUPPORT: "support",
  FAQ: "faq",
}
