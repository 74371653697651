import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "../link"
import ImageWrapper, { DefaultImageTypes } from "../image_wrapper"

import { MediaDto } from "../../common/dto"

export function AcvLogo({ className = "", imgClassName = "" }) {
  const data = useStaticQuery(graphql`
    query AcvLogoQuery {
      aaiffSettings: wpAaiffSiteSettings {
        websiteSettings {
          acvLogo: acvLogoUrl {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `)

  const {
    aaiffSettings: {
      websiteSettings: { acvLogo },
    },
  } = data

  const logoMedia = useMemo(() => MediaDto.fromWpMediaItem(acvLogo), [acvLogo])

  return (
    <Link
      to={"https://www.asiancinevision.org/"}
      external
      className={className}
    >
      <ImageWrapper
        media={logoMedia}
        objectFit="contain"
        className={imgClassName}
        DefaultImageType={DefaultImageTypes.NONE}
      />
    </Link>
  )
}
