import React from "react"

import { BaseClearButton } from "./inputs.styled"
import { FaTimes } from "react-icons/fa"

export const ClearButton = props => {
  const { variant, size, isHidden = false, ...restProps } = props

  if (isHidden) {
    return null
  }

  return (
    <BaseClearButton variant="ghost" size="S" {...restProps}>
      <FaTimes />
    </BaseClearButton>
  )
}
