import { WpEntryTypes } from "../constants"
import { EventEntry } from "./EventEntry"
import { mapFilmsById } from "../../util/entry_utils"
import { MediaDto } from "../dto"

/**
 * This class represents an Event that is a screening of a feature
 * film. The screening may include a few additional films.
 *
 * @class
 */
export class FeatureScreeningEntry extends EventEntry {
  /**
   * Combines a film entry with an event entry to create a FeatureScreeningEntry
   * with the film set as the main film
   *
   * @param {FilmEntry} filmEntry the film entry to combine
   * @param {EventEntry} eventEntry the event entry to combine
   * @returns the FeatureScreeningEntry created from the film and event entries
   */
  static fromFilmAndEventEntries(filmEntry, eventEntry) {
    // the new event data for the FeatureScreeningEntry constructor
    const newEventData = {
      ...eventEntry.__data,
      mainFilm: {
        id: filmEntry.id,
        slug: filmEntry.slug,
        ...filmEntry.__data,
      },
    }

    return new FeatureScreeningEntry(
      eventEntry.id,
      eventEntry.slug,
      newEventData
    )
  }

  constructor(id, slug, data) {
    super(id, slug, data)

    this.type = WpEntryTypes.FEATURE_SCREENING
  }

  get runtime() {
    // For a feature screening the run time should be the length of the
    // main film while the event duration will be the length of the
    // entire event
    if (this.mainFilm) {
      return this.mainFilm.runtime
    }

    return this.eventDuration
  }

  /**
   * Returns the poster image url for the event. If there is no poster image,
   * pull the poster image for the main film.
   *
   * @return {MediaDto} the poster image as a MediaDto instance
   */
  get posterImageMedia() {
    if (this.__data.posterImage) {
      return MediaDto.fromWpMediaItem(this.__data.posterImage)
    }

    if (this.mainFilm) {
      return this.mainFilm.posterImageMedia
    }

    return null
  }

  get logline() {
    if (!this.__data.logline && this.mainFilm && this.mainFilm.logline) {
      return this.mainFilm.logline
    }

    return this.__data.logline
  }

  /**
   * Returns the description of the event. If there is a main film, add the main film's
   * description after the event description.
   */
  get description() {
    // combine the main film description with the event description
    if (this.mainFilm && this.mainFilm.description) {
      return `${this.__data.description ? `${this.__data.description}` : ""}${
        this.mainFilm.description
      }`
    }

    return this.__data.description
  }

  get synopsis() {
    const synopsis = this.__data.synopsis || this.__data.description

    if (!synopsis && this.mainFilm && this.mainFilm.synopsis) {
      return this.mainFilm.synopsis
    }

    return synopsis
  }

  get year() {
    return this.mainFilm ? this.mainFilm.year : null
  }

  get filmTypes() {
    return this.mainFilm ? this.mainFilm.filmTypes : []
  }

  get filmFormat() {
    return this.mainFilm ? this.mainFilm.filmFormat : null
  }

  get countries() {
    return this.mainFilm ? this.mainFilm.countries : []
  }

  get genres() {
    return this.mainFilm ? this.mainFilm.genres : []
  }

  get languages() {
    return this.mainFilm ? this.mainFilm.languages : []
  }

  get subtitledLanguages() {
    return this.mainFilm ? this.mainFilm.subtitledLanguages : []
  }

  get contentAdvisories() {
    return this.mainFilm ? this.mainFilm.contentAdvisories : []
  }

  get premiereStatus() {
    return this.mainFilm ? this.mainFilm.premiereStatus : null
  }

  get keywords() {
    return this.mainFilm ? this.mainFilm.keywords : []
  }

  get socialLinks() {
    return this.mainFilm ? this.mainFilm.socialLinks : []
  }

  get bonusContent() {
    return this.mainFilm ? this.mainFilm.bonusContent : []
  }

  get trailerLink() {
    return this.mainFilm ? this.mainFilm.trailerLink : null
  }

  get directors() {
    return this.mainFilm ? this.mainFilm.directors : []
  }

  get directorsBio() {
    return this.mainFilm ? this.mainFilm.directorsBio : ""
  }

  get producers() {
    return this.mainFilm ? this.mainFilm.producers : ""
  }

  get writers() {
    return this.mainFilm ? this.mainFilm.writers : ""
  }

  get castMembers() {
    return this.mainFilm ? this.mainFilm.castMembers : ""
  }
}
