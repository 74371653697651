import React from "react"

import { CategoriesList } from "./categories_list"
import { SearchResults } from "./search_results"
import { ScheduleViewsRoot } from "./schedule_views_root"
import { SearchPageTypes } from "../../../../common/constants"

export function SearchContent(props) {
  const {
    isSearchActive,
    pageType,
    searchResults,
    sortOrder,
    query,
    eventEntries,
    filmEntries,
    currentViewType,
    setActiveFilters,
    updateBadges,
    categoryQuery,
  } = props

  return (
    <section className="search__content">
      {pageType === SearchPageTypes.EVENTS ? (
        <ScheduleViewsRoot
          query={query}
          currentViewType={currentViewType}
          eventEntries={eventEntries}
          isSearchActive={isSearchActive}
          searchResults={searchResults}
        />
      ) : null}

      {pageType === SearchPageTypes.PROGRAM && !isSearchActive && (
        <CategoriesList
          filmEntries={filmEntries}
          eventEntries={eventEntries}
          setActiveFilters={setActiveFilters}
          updateBadges={updateBadges}
        />
      )}

      {pageType === SearchPageTypes.PROGRAM && isSearchActive && (
        <SearchResults
          query={query}
          sortOrder={sortOrder}
          searchResults={searchResults}
          categoryQuery={categoryQuery}
        />
      )}
    </section>
  )
}
