import React from 'react'
import { Popup, Marker} from 'react-leaflet'
import logo from '../../images/location_marker_aaiff.png'

export function CustomMarker(props) {
  const {
    coordinates
  } = props

  const CustomMarkerIcon = new L.Icon({
    iconUrl: logo,
    iconSize: [45, 45],
    iconAnchor: [22.5, 45],
    // popupAnchor: [20, -22],
  });

  return (
      <Marker 
        position={coordinates}
        icon={CustomMarkerIcon}
      >
        {/* <Popup>
          <div>
            <h2> Theater # 1 </h2>
            <h4> Address : 123 main street, Ny 10001 </h4>
          </div>
        </Popup> */}
      </Marker>
  )
}