import React from "react"

import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import ScheduleHeaderItem from "./schedule_header_item"
import ScheduleButton from "./schedule_header_button"

export function ScheduleHeader({
  visibleRange,
  datesList,
  handleOnNext,
  handleOnPrevious,
  // jumpToDate,
  // selectedDate,
}) {
  let { start, end } = visibleRange
  let isDateRangeMoreThanOne = end - start > 1

  let visibleDates = []
  // if (isDateRangeMoreThanOne) {
  while (visibleDates.length < end - start) {
    for (let i = start; i < end; i++) {
      visibleDates.push(datesList[i])
    }
  }
  // } else {
  //   visibleDates = datesList
  // }

  const headerItems = visibleDates.map((date, i) => {
    const dateObj = new Date(date)

    // Determine if it is a selected header item
    let isSelected = false
    if (
      isDateRangeMoreThanOne
      // && dateObj.toString() === datesList[selectedDate]
    ) {
      // It isn't a mobile breakpoint so the current column that the mouse is over is
      // marked as selected.
      isSelected = true
    } else if (
      !isDateRangeMoreThanOne &&
      dateObj.toString() === datesList[visibleRange.start]
    ) {
      // It is a mobile breakpoint so the selected date is the current column that is being
      // viewed.
      isSelected = true
    }

    // const onHeaderItemClick = () => {
    //   if (!isDateRangeMoreThanOne) {
    //     jumpToDate(i)()
    //   }
    // }

    return (
      <ScheduleHeaderItem
        key={`date-${i}`}
        date={date}
        // onClick={onHeaderItemClick}
        // isSelected={isSelected}
      />
    )
  })

  return (
    <section className="scheduleHeader">
      <div className="scheduleHeader__container">
        <ScheduleButton
          className="schedule__arrow__left"
          onClick={handleOnPrevious}
          isVisible={visibleRange.start !== 0}
        >
          <FaAngleLeft />
        </ScheduleButton>

        <ul className="scheduleHeader__dates">{headerItems}</ul>

        <ScheduleButton
          className="schedule__arrow__right"
          onClick={handleOnNext}
          isVisible={visibleRange.end < datesList.length}
        >
          <FaAngleRight />
        </ScheduleButton>
      </div>
    </section>
  )
}
